import React, { PropsWithChildren } from 'react';
import { Box, BoxProps, Spinner, SpinnerProps } from '@chakra-ui/react';

export const Loader = ({
  centralized,
  ...rest
}: {
  centralized?: boolean;
} & SpinnerProps): JSX.Element => {
  return (
    <Spinner
      thickness="4px"
      speed="0.65s"
      emptyColor="gray.200"
      color="red.500"
      size="xl"
      {...(centralized
        ? ({
            style: {
              position: 'fixed',
              top: '50%',
              left: '50%',
            },
          } satisfies SpinnerProps)
        : {})}
      {...rest}
    />
  );
};

const LoaderWrapperLoadingStyles: BoxProps = {
  opacity: 0.6,
  pointerEvents: 'none',
  userSelect: 'none',
};

export const LoaderWrapper = (
  props: PropsWithChildren<{ loading: boolean } & BoxProps>
) => {
  const { loading, children, ...boxProps } = props;

  return (
    <Box {...(loading ? LoaderWrapperLoadingStyles : {})} {...boxProps}>
      {props.loading && (
        <Loader
          style={{
            position: 'absolute',
            left: '50%',
            top: '50%',
            zIndex: 1,
          }}
        />
      )}
      {children}
    </Box>
  );
};
