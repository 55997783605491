import { DomainRecord } from '@/model/common';
import { RowDataStructure } from '@/components/OrderTableRow/OrderTableRow';
import { LANGUAGES_OPTIONS } from '@/constants/languages';
import { t } from 'i18next';
import { Product } from '@/model/IProduct';

function getTemporaryStaffConfig(products: Product[]): DomainRecord[] {
  return products.map((product) => {
    const params: RowDataStructure[] = [];

    switch (product?.fieldType) {
      case 'select':
        params.push({
          type: 'badge',
          name: 'language',
          text: product.formattedPrice,
          options: LANGUAGES_OPTIONS,
          settings: { required: true },
          placeholder: t('chooseLanguage') as string,
        });
        break;
      case 'input':
        params.push({
          type: 'input',
          name: 'language',
          text: product.formattedPrice,
          placeholder: t('enterLanguage') as string,
          settings: { required: true },
        });
        break;
    }

    params.push(
      {
        type: 'inputNumber',
        name: 'staff_amount',
        placeholder: t('personAmountPlaceholder') as string,
        settings: { required: true, min: 1 },
      },
      {
        type: 'inputNumber',
        name: 'day_amount',
        placeholder: t('dayAmountPlaceholder') as string,
        settings: { required: true, min: 1 },
      }
    );

    return {
      key: product.getProductId(),
      text:
        product.productDisplayName ||
        product.productName ||
        product.getProductId() + '',
      product: product,
      params,
    };
  });
}

export default getTemporaryStaffConfig;
