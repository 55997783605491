import { useNameTranslate } from '@/hooks/useStringName';
import React, { SetStateAction, useEffect, useMemo, useState } from 'react';
import { SelectComponent } from '@/components/MultiSelect/MultiSelect';
import {
  Box,
  ButtonGroup,
  Divider,
  FormControl,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Flex,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { DeleteIcon, EditIcon } from '@chakra-ui/icons';
import { Table } from '@/components/Table/Table';
import { Card } from '@/components/Card/Card';
import {
  Button as OrderButton,
  Button,
} from '@/components/Buttons/Button/Button';
import { CiImport } from 'react-icons/ci';
import fetchApi from '@/utils/fetchAPI';
import {
  DELETE_PRICE_LIST,
  EDIT_PRICELIST_ITEM,
  GET_PRICELISTS,
  IMPORT_PRICELIST_ITEMS,
} from '@/constants/endpoints';
import { FetchMethods } from '@/utils/constants';
import { Loader } from '@/components/Loader/Loader';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import { IdType, OptionType } from '@/model/common';
import { UploadFileDropzone } from '@/components/FileUpload/FileUpload';
import { toast } from 'react-toastify';
import { DeleteModal } from '@/pages/Exhibition/DeleteModal';
import { EditPriceListItemForm } from '@/pages/AdminPanel/Exhibitions/EditPriceListItemForm';
import { typedAxios } from '@/api/typed-axios';
import type { ITEPortal_Domain_Dto_AdminPriceListItem } from '@/api';
import { editExhibitionRoute } from '@/features/Routing/protected/admin-exhibitions';
import {
  ButtonContentContainer,
  LoaderWrapper,
  OptionWrapper,
  SelectedPriceContainer,
  SelectedPriceWrapper,
  ToolBarWrapper,
  TransparentButtonsTextContainer,
} from '@/pages/AdminPanel/Exhibitions/PriceList.styled';
import { Column, Row } from 'react-table';
import { TableIncludedPriceListItemsBadge } from '@/pages/AdminPanel/Exhibitions/TableIncludedPriceListItemsBadge';
import { ITEPortal_Domain_Dto_CurrencyDto } from '@/api';
import { ButtonContainer } from '@/components/OrderTableRow/styled';
import { ModalFactory } from '@/components/Modal/api';

interface PriceListsOptionsType {
  row: Row<ITEPortal_Domain_Dto_AdminPriceListItem>;
}

type PriceListType = {
  exhibitionId: number;
  priceListId: number;
  currencyId: number;
};

export const PriceLists = (): JSX.Element => {
  const { exhibitionId } = editExhibitionRoute.useParams();
  const [currency, setCurrency] = useState<any>();
  const [currencyOptions, setCurrencyOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [priceListsData, setPriceListsData] = useState<
    ITEPortal_Domain_Dto_AdminPriceListItem[]
  >([]);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openImportModal, setOpenImportModal] = useState(false);
  const defaultValues = { name: null, price: null };
  const [editData, setEditData] = useState<any>(defaultValues);
  const [editTableData, setEditTableData] =
    useState<ITEPortal_Domain_Dto_AdminPriceListItem | null>(null);
  const [priceLists, setPriceLists] = useState<any>();
  const [selectedPriceId, setSelectedPriceId] = useState<IdType>('');
  const [openDeletePriceListItem, setOpenDeletePriceListItem] = useState(false);
  const [deleteAll, setDeleteAll] = useState(false);
  const [priceListItemId, setPriceListItemId] = useState();
  const { t } = useTranslation();
  const [eventEditionId, setEventEditionId] = useState<number | null>();

  useEffect(() => {
    const fetchEventEditionId = async () => {
      if (exhibitionId) {
        const data = await typedAxios.exhibition.getExhibitions({
          id: +exhibitionId,
        });

        setEventEditionId(data.eventEditionId);
      }
    };

    fetchEventEditionId();
  }, []);

  const closeDeletePriceListItemModal = () => setOpenDeletePriceListItem(false);

  const deletePriceListItemModalShow = (id: any) => {
    setPriceListItemId(id);
    setDeleteAll(false);
    setOpenDeletePriceListItem(!openDeletePriceListItem);
  };

  const deletePriceModalShow = () => {
    setDeleteAll(true);
    setOpenDeletePriceListItem(!openDeletePriceListItem);
  };

  const methods = useForm({
    mode: 'onBlur',
  });

  useEffect(() => {
    Object.keys(editData).forEach((field) => {
      methods.setValue(field, editData[field]);
    });
  }, [editData]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const currencyData = await typedAxios.currency.getCurrency();

        const options = currencyData?.map(
          (option: ITEPortal_Domain_Dto_CurrencyDto) => {
            return {
              label: option?.name,
              value: option?.id,
            };
          }
        ) as SetStateAction<never[]>;

        setCurrencyOptions(options);

        const response = await fetchApi(GET_PRICELISTS(exhibitionId));

        setPriceLists(response?.data);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const PriceListsOptions = ({ row }: PriceListsOptionsType) => {
    const rowValues = row.original;

    return (
      <OptionWrapper>
        <Link onClick={() => editRow(rowValues)}>
          <EditIcon />
        </Link>
        <Link onClick={() => deletePriceListItemModalShow(rowValues.id)}>
          <DeleteIcon />
        </Link>
      </OptionWrapper>
    );
  };

  const PriceListsToolbar = () => {
    return (
      <ToolBarWrapper>
        <Button colorScheme="transparent" onClick={importCSV}>
          <ButtonContentContainer>
            <CiImport size="20px" />
            <TransparentButtonsTextContainer>
              {t('importCSV')}
            </TransparentButtonsTextContainer>
          </ButtonContentContainer>
        </Button>
        <Button colorScheme="red" onClick={addRow}>
          {t('add-price')}
        </Button>
      </ToolBarWrapper>
    );
  };

  const importCSV = () => {
    setOpenImportModal(!openImportModal);
  };

  const closeImportModal = () => setOpenImportModal(false);

  const importItems = async (values: FieldValues) => {
    setIsLoading(true);
    try {
      const response = await fetchApi(
        IMPORT_PRICELIST_ITEMS(exhibitionId, selectedPriceId),
        {
          method: FetchMethods.Post,
          data: { importPriceListItems: values?.['upload-csv'][0] },
        },
        { 'Content-Type': 'multipart/form-data' }
      );

      if (response?.data?.errors) {
        const message = `${t('import-errors')} ${response?.data?.errors.join(
          ','
        )}`;

        toast.error(message);
      }

      await showPriceList(currency);
    } finally {
      setIsLoading(false);
      closeImportModal();
    }
  };

  const ImportCSVModal = () => {
    return (
      <Modal
        isOpen={openImportModal}
        onClose={closeImportModal}
        size="lg"
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t('set-file')}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormProvider {...methods}>
              <form>
                <FormControl>
                  <UploadFileDropzone
                    label={useNameTranslate('upload-csv')}
                    name="upload-csv"
                    accept={{ 'text/csv': ['.csv'] }}
                  />
                </FormControl>
              </form>
            </FormProvider>
          </ModalBody>
          <ModalFooter justifyContent="center">
            <ButtonGroup gap="1" justifyContent="center">
              <OrderButton
                colorScheme="red"
                onClick={methods.handleSubmit(importItems)}
              >
                {t('choose')}
              </OrderButton>
              <OrderButton colorScheme="secondary" onClick={closeImportModal}>
                {t('cancel')}
              </OrderButton>
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  };

  const addRow = () => {
    setEditData(defaultValues);
    setEditTableData(null);
    setOpenConfirm(!openConfirm);
  };

  const closeModal = () => setOpenConfirm(false);

  const editRow = (values: ITEPortal_Domain_Dto_AdminPriceListItem) => {
    setEditData(values);
    setEditTableData(values);
    setOpenConfirm(!openConfirm);
  };

  const deleteRow = async () => {
    if (priceListItemId) {
      try {
        setIsLoading(true);
        await fetchApi(
          EDIT_PRICELIST_ITEM(exhibitionId, selectedPriceId, priceListItemId),
          {
            method: FetchMethods.Delete,
          }
        );

        closeModal();
        setEditData(defaultValues);
        setEditTableData(null);
        await showPriceList(currency);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const priceListsColumns = React.useMemo<
    Array<Column<ITEPortal_Domain_Dto_AdminPriceListItem>>
  >(
    () => [
      {
        Header: ``,
        accessor: 'id',
        show: false,
      },
      {
        Header: ``,
        accessor: 'productId',
        show: false,
      },
      {
        Header: `${t('service-name')}`,
        accessor: 'displayName',
        width: '30%',
      },
      {
        Header: `${t('service-id')}`,
        accessor: 'name',
        width: '20%',
      },
      {
        Header: `${t('price')}`,
        accessor: 'price',
        width: '10%',
      },
      {
        Header: `${t('linked-services')}`,
        accessor: 'includedPriceListItems',
        Cell: ({ value }) => {
          return (
            <TableIncludedPriceListItemsBadge
              includedPriceListItems={value}
              source={priceListsData}
            />
          );
        },
      },
      {
        Header: '',
        id: 'options',
        width: '10%',
        Cell: ({ row }: PriceListsOptionsType) => (
          <PriceListsOptions row={row} />
        ),
      },
    ],
    [priceListsData]
  );

  const existingPriceListItems = useMemo<
    Array<OptionType<ITEPortal_Domain_Dto_AdminPriceListItem>>
  >(() => {
    return priceListsData.map((pi) => ({
      value: pi.id,
      label: pi.name,
      meta: pi,
    }));
  }, [priceListsData]);

  const showPriceList = async (value: OptionType) => {
    setCurrency(value);

    const priceListId = priceLists.find(
      (priceList: PriceListType) => priceList?.currencyId === value?.value
    )?.priceListId;

    setSelectedPriceId(priceListId);

    if (priceListId) {
      setIsLoading(true);
      try {
        const data =
          await typedAxios.priceListItem.getExhibitionsPriceListsItems({
            exhibitionId: +exhibitionId,
            priceListId,
          });

        setPriceListsData(data);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    }

    if (!priceListId) {
      setPriceListsData([]);
    }
  };

  const deletePriceList = async () => {
    try {
      setIsLoading(true);
      await fetchApi(DELETE_PRICE_LIST(exhibitionId, selectedPriceId), {
        method: FetchMethods.Delete,
      });

      closeModal();
      setEditData(defaultValues);
      setEditTableData(null);
      await showPriceList(currency);
    } finally {
      setIsLoading(false);
    }
  };

  const showImportModal = () => {
    ModalFactory.show({
      title: t('bitrixImport'),
      Component: () => <Box>{t('import-from-bitrix-message')}</Box>,
      okLabel: t('importButton'),
      onOk: async () => {
        try {
          setIsLoading(true);
          const pricelists = await typedAxios.bitrix.postBitrixPriceList({
            exhibitionId: +exhibitionId,
          });

          ModalFactory.close();

          if (pricelists?.length === 0) {
            toast.success(t('pricelist-is-empty'));
          } else {
            setPriceLists(pricelists);
            toast.success(t('pricelist-import-success'));
          }
        } catch {
          toast.error(t('error-occurred'));
        } finally {
          setIsLoading(false);
        }
      },
    });
  };

  const deletePriceListFunc = deleteAll ? deletePriceList : deleteRow;

  return (
    <Box minH="300px">
      <FormProvider {...useForm()}>
        <Flex
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <SelectComponent
            label={useNameTranslate('select-currency')}
            options={[{ label: 'Выбрать...', value: '' }, ...currencyOptions]}
            name="currency"
            maxWidth="360px"
            minWidth="360px"
            onClear={() => setPriceListsData([])}
            onChange={(value) => {
              showPriceList(value);
            }}
          />
          {!currency?.['value'] && eventEditionId && (
            <ButtonContainer>
              <Button colorScheme="red" onClick={showImportModal}>
                + {t('bitrixImport')}
              </Button>
            </ButtonContainer>
          )}
        </Flex>
      </FormProvider>
      {currency?.['value'] && (
        <>
          <SelectedPriceWrapper>
            <SelectedPriceContainer>
              {`${t('price-list')} ${currency?.['label']}`}
              <Link onClick={deletePriceModalShow}>
                <DeleteIcon paddingRight="5px" boxSize={5} />
                {t('delete-price')}
              </Link>
            </SelectedPriceContainer>
            <Divider />
          </SelectedPriceWrapper>
          <PriceListsToolbar />
          {isLoading ? (
            <LoaderWrapper>
              <Loader />
            </LoaderWrapper>
          ) : (
            <Card>
              <DeleteModal
                onSubmit={deletePriceListFunc}
                isOpen={openDeletePriceListItem}
                onClose={closeDeletePriceListItemModal}
              />
              <Table columns={priceListsColumns} data={priceListsData} />
              {openConfirm && (
                <EditPriceListItemForm
                  openConfirm={openConfirm}
                  closeModal={closeModal}
                  editTableData={editTableData}
                  priceListId={selectedPriceId}
                  currency={currency}
                  reloadPriceList={showPriceList}
                  existingPriceListItems={existingPriceListItems}
                />
              )}
              <ImportCSVModal />
            </Card>
          )}
        </>
      )}
    </Box>
  );
};
