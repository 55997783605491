import { createApi, createStore } from 'effector';
import { useUnit } from 'effector-react';
import { ITEPortal_Domain_Dto_StandSummariesDto } from '@/api';

type FilesStorageStateType = {
  stand: ITEPortal_Domain_Dto_StandSummariesDto | null;
};

const $filesStorage = createStore<FilesStorageStateType>({
  stand: null,
});

export const filesStorageApi = createApi($filesStorage, {
  setStand: (_, stand: ITEPortal_Domain_Dto_StandSummariesDto) => ({
    stand,
  }),

  reset: () => ({
    stand: null,
  }),
});

export const useFilesStorage = () => useUnit($filesStorage);
