import React, { useCallback, useMemo } from 'react';
import { Icon, Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import { ArrowBackIcon, SmallCloseIcon } from '@chakra-ui/icons';
import { Card } from '@/components/Card/Card';
import { useTranslation } from 'react-i18next';
import { OrderItem, TemporaryStaffOrderItem } from '@/model/IOrderItem';
import { CART_TABLE } from '@/constants/technical-services';
import { numberWithSpaces } from '@/utils/amountTransformation';
import Layout from '@/layout/Default';
import { Content, ContentInner } from '@/components/Content';
import { replacePaths } from '@/utils/route';
import { CREATE_EXHIBITION_ORDER_PATH } from '@/constants/router';
import Breadcrumbs from '@/components/Breadcrumbs';
import { IBreadcrumbItem } from '@/model/common';
import { ExhibitionTitle } from '@/components/Sidebar/OrderList/OrderList.styled';
import { Loader } from '@/components/Loader/Loader';
import { IsLoadingWrapper, LoaderWrapper } from '@/theme/css/styles';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import { viewExhibitionCart } from '../../features/Routing/protected/exhibitions-by-role';
import { AmountCounter } from '../../components/AmountCounter';
import { CATEGORY_TYPES } from '../../constants/categorie';
import { Product } from '../../model/IProduct';
import { Cart as CartInstance } from '../../model/ICart';
import {
  BackButtonWrapper,
  Button,
  ExhibitionBoxWrapper,
  OrderCartWrapper,
  OrderPageWrapper,
  PageTitleWithService,
} from './styled';
import { CartTotal } from './CartTotal';
import { IBaseOrder } from '../../model/IBaseOrder';
import { isNil } from '../../utils/is-nil';
import { useExhibition } from '@/store/effector/exhibition';
import { orderApi, useOrder, useOrderTotal } from '@/store/effector/order';
import { useStand } from '@/store/effector/stand';
import { useAppLoading } from '@/store/effector/loading';
import { OrderItemDependencyWarning } from '@/components/Sidebar/OrderList/OrderItemDependencyWarning';

dayjs.extend(isSameOrAfter);

interface CartRowProps {
  item: OrderItem | TemporaryStaffOrderItem;
  currency: string;
}

const CartRow: React.FC<CartRowProps> = ({ item, currency }) => {
  const { t } = useTranslation();
  const { product } = item;
  const total = numberWithSpaces(item.total, currency);
  const isDisabled =
    product?.category?.name === CATEGORY_TYPES.TEMPORARY_STAFF ||
    product?.category?.name === CATEGORY_TYPES.SELECT_BUILDING ||
    item.included;

  const itemName = item?.displayName;

  const precision = (() => {
    if (product?.category?.name === CATEGORY_TYPES.SELECT_BUILDING) {
      return 1;
    }

    return item.product.category.isDecimal ? 2 : 0;
  })();

  const deleteHandler = useCallback(() => {
    orderApi.removeItem(item);
  }, []);

  const changeHandler = (value: number) => {
    orderApi.changeItem({
      id: product.getProductId(),
      product,
      amount: value,
    });
  };

  const unit = (() => {
    if (product.category.name === CATEGORY_TYPES.SELECT_BUILDING) {
      return t('sqm');
    }

    return item.product instanceof Product ? item.product.unit : undefined;
  })();

  return (
    <>
      <Tr>
        <Td>{itemName}</Td>
        <Td isNumeric whiteSpace="nowrap">
          {product?.formattedPrice}
        </Td>
        <Td w={120} isNumeric>
          <div style={{ marginLeft: 'auto', width: 'fit-content' }}>
            <AmountCounter
              amountCounterValue={item.getAmount()}
              onChange={(counterValue: number) => changeHandler(counterValue)}
              disabled={isDisabled}
              precision={precision}
              step={product instanceof Product ? product.amountStep : 1}
              suffix={unit}
            />
          </div>
        </Td>
        <Td w={160} isNumeric whiteSpace="nowrap">
          {total}
        </Td>
        <Td w={50} isNumeric>
          {!item.included && (
            <Button
              onClick={deleteHandler}
              disabled={
                product?.category?.id === CATEGORY_TYPES.SELECT_BUILDING
              }
            >
              <Icon as={SmallCloseIcon} />
            </Button>
          )}
        </Td>
      </Tr>
      {item && (
        <OrderItemDependencyWarning
          orderItem={item}
          ml="-1.5rem"
          mr="-1.5rem"
          pl="20px"
          pr="20px"
          width="unset"
          renderProps={(children) => (
            <Tr>
              <Td colSpan={5} pt={0} pb={0}>
                {children}
              </Td>
            </Tr>
          )}
        />
      )}
    </>
  );
};

const BackButton = () => {
  const { t } = useTranslation();

  return (
    <BackButtonWrapper>
      <ArrowBackIcon w={5} h={5} color="grey.300" />
      {t('toService')}
    </BackButtonWrapper>
  );
};

const Cart: React.FC = () => {
  const { currency, margin, exhibitionName } = useExhibition();
  const { exhibitionId, stand } = viewExhibitionCart.useParams();
  const { cleaningDiscount } = viewExhibitionCart.useLoaderData();
  const { items, orderDto } = useOrder();
  const total = useOrderTotal();
  const isLoader = useAppLoading();
  const { standForOrder } = useStand();

  const cart = useMemo(() => {
    const order = new IBaseOrder({
      orderId: orderDto?.orderId || 0,
      exhibitionId: orderDto?.exhibitionId || 0,
      // typeOfDiscount: orderDto?.typeOfDiscount || 0,
      // personalDiscount: orderDto?.personalDiscount || 0,
      standId: orderDto?.standId || 0,
      personalExtraChargePercentage: orderDto?.personalExtraChargePercentage,
      removeExtraCharge: orderDto?.removeExtraCharge,
    });

    const cartInstance = new CartInstance();

    cartInstance
      .setItems(items)
      .setOrder(order)
      .setItems(items)
      .setCleaningDiscounts(cleaningDiscount)
      .setMargin(margin);

    if (standForOrder) {
      cartInstance.setStandInfo({
        type: standForOrder.buildingType,
        // this is just to mock, type only will be used
        id: standForOrder.standNumber || 0,
        // this is just to mock, type only will be used
        name: standForOrder.standNumber || '',
      });
    }

    if (!isNil(currency)) {
      cartInstance.setCurrency(currency);
    }

    return cartInstance;
  }, [orderDto, items, currency, margin, cleaningDiscount, standForOrder]);

  const { t } = useTranslation();

  const getBack: IBreadcrumbItem[] = [
    {
      label: <BackButton />,
      link: replacePaths(CREATE_EXHIBITION_ORDER_PATH, {
        ':exhibitionId': exhibitionId,
        ':stand': stand,
      }),
    },
  ];

  return (
    <>
      {isLoader && (
        <IsLoadingWrapper>
          <LoaderWrapper>
            <Loader />
          </LoaderWrapper>
        </IsLoadingWrapper>
      )}
      <Layout>
        <Content>
          <ContentInner>
            <OrderPageWrapper>
              <PageTitleWithService>
                <Breadcrumbs items={getBack} />
                {t('yourOrder')}
                <ExhibitionBoxWrapper>
                  <ExhibitionTitle>{exhibitionName}</ExhibitionTitle>
                </ExhibitionBoxWrapper>
              </PageTitleWithService>
              <OrderCartWrapper>
                <Card width="75%">
                  <Table colorScheme="gray">
                    <Thead>
                      <Tr>
                        {CART_TABLE?.map((columnName, index) => {
                          const firstColumn = index === 0;

                          return (
                            <Th key={columnName} isNumeric={!firstColumn}>
                              {t(columnName)}
                            </Th>
                          );
                        })}
                      </Tr>
                    </Thead>
                    {items?.length > 0 && (
                      <Tbody>
                        {items?.map((tableRow) => (
                          <CartRow
                            item={tableRow}
                            key={tableRow.uniqueKey}
                            currency={currency || ''}
                          />
                        ))}
                      </Tbody>
                    )}
                  </Table>
                </Card>
                <CartTotal
                  total={total}
                  currency={currency || ''}
                  items={items}
                  cart={cart}
                />
              </OrderCartWrapper>
            </OrderPageWrapper>
          </ContentInner>
        </Content>
      </Layout>
    </>
  );
};

export default Cart;
