import {
  ButtonGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { Button } from '@/components/Buttons/Button/Button';

import { useTranslation } from 'react-i18next';

interface ConfirmModalProps {
  modalMessage: string;
  isOpen: boolean;
  closeModal: () => void;
  confirm: any;
}

export const ConfirmModal = ({
  modalMessage,
  isOpen,
  closeModal,
  confirm,
}: ConfirmModalProps) => {
  const { t } = useTranslation();

  return (
    <Modal isOpen={isOpen} onClose={closeModal} size="lg" isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t('attention')}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>{modalMessage}</ModalBody>
        <ModalFooter onClick={closeModal}>
          <ButtonGroup gap="1">
            <Button colorScheme="secondary" onClick={confirm}>
              {t('yes')}
            </Button>
            <Button colorScheme="black" onClick={closeModal}>
              {t('cancel')}
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
