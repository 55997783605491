import styled from 'styled-components';
import { Box } from '@chakra-ui/react';
import { CenteredPageTitle } from '../../components/Typography/PageTitle';
import { ExhibitionBox } from '../../components/Sidebar/OrderList/OrderList.styled';

export const Button = styled.button`
  opacity: 0.5;

  transition: all ease-in-out 0.15s;

  &:hover {
    opacity: 1;
  }
`;

export const OrderPageWrapper = styled.div``;

export const OrderCartWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 0 100px;
  gap: 24px;
`;

export const TotalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 5px;
`;

export const TotalRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 5px 0 10px 0;
`;

export const TotalRowContainerSummary = styled(TotalRowContainer)`
  padding-top: 20px;
  padding-bottom: 20px;
`;

export const CartButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const PageTitleWithService = styled(CenteredPageTitle)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 100px;
`;

export const ExhibitionBoxWrapper = styled(ExhibitionBox)`
  max-width: 25%;
  text-align: end;
  background-color: inherit;
`;

export const BackButtonWrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 16px;
  gap: 10px;
`;
