import { Product } from './IProduct';
import {
  ITEPortal_Contracts_Enums_BuildingType,
  ITEPortal_Domain_Dto_OrderItemDto,
} from '@/api';
import { ISerialisable, Serializable } from './Serializable';
import { ProductId } from '@/types/support-types';

interface OrderItemConstructorDetails {
  id: ProductId;
  product: Product;
  amount?: number;
  buildingTypes?: Array<ITEPortal_Contracts_Enums_BuildingType>;
  included?: boolean;
  includedReason?: string;
}

interface TemporaryServiceOrderItemConstructorDetails {
  id: ProductId;
  product: Product;
  language?: string | null;
  peopleAmount?: number;
  dayAmount?: number;
  buildingTypes?: Array<ITEPortal_Contracts_Enums_BuildingType>;
}

export interface IOrderItem extends ISerialisable {
  id: ProductId;
  product: Product;
  buildingTypes?: Array<ITEPortal_Contracts_Enums_BuildingType>;

  get total(): number;
  get displayName(): string | null;
  getAmount(): number;

  // new (orderItemDetails: OrderItemConstructorDetails): OrderItem;
}

export interface ITemporaryStaffOrderItem extends IOrderItem {
  // new (orderItemDetails: OrderItemConstructorDetails): TemporaryStaffOrderItem;
}

export class OrderItem extends Serializable implements IOrderItem {
  id: ProductId;
  product: Product;
  included: boolean = false;
  includedReason: string | null = null;

  private amount: number = 0;

  buildingTypes: Array<ITEPortal_Contracts_Enums_BuildingType> = [0];

  constructor(item: OrderItemConstructorDetails) {
    super();

    this.id = item.id;
    this.product = item.product;
    if (item.amount) {
      this.amount = item.amount;
    } else {
      this.amount = 0;
    }

    if (item.buildingTypes) {
      this.buildingTypes = item.buildingTypes;
    }

    if (item.included) {
      this.included = true;
      if (item.includedReason) {
        this.includedReason = item.includedReason;
      }
    }
  }

  get total() {
    if (this.included) {
      return 0;
    }

    return Number((this.amount * this.product.price).toFixed(2));
  }

  setAmount(amount: number) {
    this.amount = amount;
  }

  getAmount(): number {
    return this.amount;
  }

  get displayName() {
    return this.product.productDisplayName || this.product.productName;
  }

  get uniqueKey() {
    return this.id.toString();
  }

  static fromJSON(
    json: Omit<ITEPortal_Domain_Dto_OrderItemDto, 'id'> & {
      product: Product;
      included?: boolean;
      includedReason?: string;
      id: ProductId;
    }
  ) {
    if (TemporaryStaffOrderItem.is(json)) {
      return TemporaryStaffOrderItem.fromJSON(json);
    }

    const item = new OrderItem({
      id: json.product.getProductId(),
      product: json.product,
      buildingTypes: json.buildingTypes?.length ? json.buildingTypes : [0],
      amount: json.amount,
      included: json.included,
      includedReason: json.includedReason,
    });

    if (json.amount) {
      item.setAmount(json.amount);
    }

    return item;
  }

  toJSON() {
    return {
      id: this.product.getProductId(),
      amount: this.amount,
      product: this.product,
      buildingTypes: this.buildingTypes,
      included: this.included,
      includedReason: this.includedReason,
    };
  }
}

// prettier-ignore
export class TemporaryStaffOrderItem
  extends OrderItem
  implements ITemporaryStaffOrderItem {
  public peopleAmount: number;
  public dayAmount: number;
  public language: string | undefined;

  constructor(itemDetails: TemporaryServiceOrderItemConstructorDetails) {
    super({
      id: itemDetails.product.getProductId(),
      product: itemDetails.product,
      buildingTypes: itemDetails.buildingTypes,
    });

    this.language = itemDetails.language || undefined;
    this.peopleAmount = itemDetails.peopleAmount || 0;
    this.dayAmount = itemDetails.dayAmount || 0;
  }

  getAmount() {
    return this.peopleAmount;
  }

  get total() {
    return this.product.price * (this.peopleAmount * this.dayAmount);
  }

  get displayName() {
    if (!this.language) {
      return super.displayName;
    }

    return `${super.displayName} (${this.language})`;
  }

  get uniqueKey() {
    return `${super.uniqueKey}-${this.language}`;
  }

  toJSON() {
    return {
      ...super.toJSON(),
      peopleAmount: this.peopleAmount,
      dayAmount: this.peopleAmount,
      language: this.language,
    };
  }

  static fromJSON(
    json: ITEPortal_Domain_Dto_OrderItemDto & { product: Product }
  ) {
    return new TemporaryStaffOrderItem({
      id: json.product.getProductId(),
      product: json.product,
      dayAmount: json.dayAmount,
      peopleAmount: json.peopleAmount,
      language: json.language,
    });
  }

  static is(json: ITEPortal_Domain_Dto_OrderItemDto) {
    return json.peopleAmount && json.dayAmount;
  }
}
