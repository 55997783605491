export const CATEGORY_TYPES = {
  SELECT_BUILDING: 'select-building',
  TEMPORARY_STAFF: 'temporary-staff',
  CLEANING: 'cleaning',
};

export const PRODUCT_TYPES = {
  TEMPORARY_STAFF: 'temporary-staff',
  BUILDING: 'select-building',
  COMMON: 'common',
} as const;

export type ProductTypesType =
  (typeof PRODUCT_TYPES)[keyof typeof PRODUCT_TYPES];
