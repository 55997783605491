import { Serializable } from './Serializable';
import { OrderItem, TemporaryStaffOrderItem } from './IOrderItem';
import { Category } from './ICategory';
import { Product } from './IProduct';
import { InitialState } from '@/store/effector/order';
import type { ITEPortal_Domain_Dto_OrderItem_CreateOrderItemDto } from '@/api';
import { ProductId } from '@/types/support-types';

export class Order extends Serializable {
  private items: Array<OrderItem | TemporaryStaffOrderItem> = [];
  constructor({
    items,
  }: {
    items: Array<OrderItem | TemporaryStaffOrderItem>;
  }) {
    super();

    this.items = items;
  }

  getItems() {
    return this.items;
  }

  toJSON(): unknown {
    return undefined;
  }

  static fromJSON(state: InitialState): InitialState {
    if (!Array.isArray(state.items)) {
      state.items = [];
    }

    state.items = state.items.filter(Boolean);

    state.items.forEach((item, i) => {
      try {
        const category = item.product.category;

        const product = item.product;
        const categoryObj = new Category(category);

        item.product = new Product({
          ...product,
          category: categoryObj,
        });

        item.id = item.product.getProductId();

        state.items[i] = OrderItem.fromJSON(
          // @ts-expect-error improve types
          item
        );
      } catch (e) {
        console.log(e);
      }
    });

    return state;
  }

  static prepareItemsForPlace(
    items: Array<OrderItem | TemporaryStaffOrderItem>
  ): Array<ITEPortal_Domain_Dto_OrderItem_CreateOrderItemDto> {
    return items.map((item) => {
      let originalProductId = item.product.getProductId();

      if (item.included) {
        originalProductId = originalProductId.replace(
          '-included',
          ''
        ) as ProductId;
      }

      if (item instanceof TemporaryStaffOrderItem) {
        return {
          id: originalProductId,
          amount: item.getAmount(),
          peopleAmount: item.peopleAmount,
          dayAmount: item.dayAmount,
          language: item.language,
        };
      }

      return {
        id: originalProductId,
        amount: item.getAmount(),
        included: item.included,
      };
    });
  }
}
