import { FormProvider, useForm } from 'react-hook-form';
import React, { useState } from 'react';
import { FormControl, Stack, Button } from '@chakra-ui/react';
import { useNameTranslate } from '@/hooks/useStringName';
import Input from '@/components/Fields/Input';
import styled from 'styled-components';
import { EDIT_PASSWORD } from '@/constants/endpoints';
import { toast } from 'react-toastify';
import fetchApi from '@/utils/fetchAPI';
import { FetchMethods } from '@/utils/constants';

const FormControlWrapper = styled(FormControl)`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

export const ChangePassword = (): JSX.Element => {
  const methods = useForm();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const submitHandler = async (value: any) => {
    setIsSubmitting(true);
    try {
      await fetchApi(EDIT_PASSWORD, {
        method: FetchMethods.Post,
        data: {
          ...value,
        },
      });
    } catch (error: any) {
      const fieldErrors = error?.response?.data?.errors;

      if (fieldErrors) {
        toast.error(fieldErrors);
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <FormProvider {...methods}>
      <form>
        <Stack spacing="5">
          <FormControlWrapper>
            <Input
              maxWidth="360px"
              name="currentPassword"
              label={useNameTranslate('current-password')}
            />
            <Input
              maxWidth="360px"
              name="newPassword"
              label={useNameTranslate('new-password')}
            />
            <Input
              maxWidth="360px"
              name="confirmNewPassword"
              label={useNameTranslate('confirm-new-password')}
            />

            <Button
              colorScheme="red"
              width="100px"
              isLoading={isSubmitting}
              onClick={methods.handleSubmit(submitHandler)}
            >
              {useNameTranslate('save')}
            </Button>
          </FormControlWrapper>
        </Stack>
      </form>
    </FormProvider>
  );
};
