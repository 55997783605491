import { FieldProps } from './interfaces';
import {
  FormErrorMessage,
  Input as ChakraInput,
  Box,
  FormControl,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
  InputProps as ChakraInputProps,
  Tooltip,
  Flex,
} from '@chakra-ui/react';
import React, { ReactNode } from 'react';
import { FormLabel } from '@/components/FormLabel/FormLabel';
import styled from 'styled-components';
import {
  FieldValues,
  Path,
  useController,
  useFormContext,
} from 'react-hook-form';
import { InfoIcon } from '@chakra-ui/icons';

interface InputProps<T extends FieldValues> extends FieldProps<T> {
  maxWidth?: number | string | null;
  defaultValue?: string;
  disabled?: boolean;
  value?: string;
  onChange?: any;
  addonLeft?: ReactNode;
  addonRight?: ReactNode;
  tooltip?: string;
}

const FormControlWrapper = styled(FormControl)`
  display: flex;
  flex-direction: column;
`;

export const ParticipantCheckContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 100%;
`;

const Input = <T extends FieldValues = FieldValues>({
  // @ts-expect-error as default string doesn't found in generic T
  name = 'input',
  label,
  placeholder,
  settings,
  maxWidth = '170px',
  defaultValue,
  isRequired,
  disabled,
  value,
  onChange,
  addonLeft,
  addonRight,
  tooltip,
  children,
  ...rest
}: InputProps<T> & ChakraInputProps) => {
  const formContext = useFormContext();

  if (!formContext) {
    throw new Error(
      `This field (${name}) should be used only within FormContext`
    );
  }

  const { fieldState } = useController({
    name: name,
  });

  if (!fieldState) {
    throw new Error(
      'fieldState not found. Is this field used within FormContext?'
    );
  }

  const registerFieldName = formContext.register(name as Path<T>, {
    onChange: (event) => {
      if (typeof onChange === 'function') {
        onChange(event);
      }

      formContext.clearErrors(name);
    },
    ...settings,
  });

  return (
    <Box width="100%" maxWidth={maxWidth !== null ? maxWidth : undefined}>
      <FormControlWrapper
        isInvalid={fieldState.invalid}
        isRequired={isRequired}
      >
        <Flex flexDirection="row">
          {label && <FormLabel htmlFor={name}>{label}</FormLabel>}
          {tooltip && (
            <Tooltip label={tooltip} hasArrow placement="top">
              <InfoIcon
                color="lightgray"
                boxSize={4}
                style={{ marginTop: 4 }}
              />
            </Tooltip>
          )}
        </Flex>
        <InputGroup>
          {addonLeft && <InputLeftAddon>{addonLeft}</InputLeftAddon>}
          <ParticipantCheckContainer>
            <ChakraInput
              placeholder={placeholder}
              maxWidth={maxWidth !== null ? maxWidth : undefined}
              defaultValue={defaultValue}
              disabled={disabled}
              value={value}
              id={name}
              {...rest}
              {...registerFieldName}
            />
            {children}
          </ParticipantCheckContainer>
          {addonRight && <InputRightAddon>{addonRight}</InputRightAddon>}
        </InputGroup>
        {fieldState.error?.message && (
          <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
        )}
      </FormControlWrapper>
    </Box>
  );
};

export default Input;
