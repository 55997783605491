import { FormProvider, useForm, UseFormReturn } from 'react-hook-form';
import * as v from 'zod';
import { useModalFactoryContextProvider } from '../../../components/Modal/api';
import { zodResolver } from '@hookform/resolvers/zod';
import { TextArea } from '../../../components/TextArea/TextArea';
import { useTranslation } from 'react-i18next';

export const SpaceOnlyStandOrderCommentsFormSchema = v.object({
  additionalComments: v.string().max(1000).optional(),
});

type ValuesType = v.infer<typeof SpaceOnlyStandOrderCommentsFormSchema>;

export type SpaceOnlyStandOrderCommentsFormType = UseFormReturn<ValuesType>;

export const SpaceOnlyStandOrderCommentsForm = () => {
  const { t } = useTranslation();
  const instance: SpaceOnlyStandOrderCommentsFormType = useForm<ValuesType>({
    resolver: zodResolver(SpaceOnlyStandOrderCommentsFormSchema),
  });

  useModalFactoryContextProvider(instance);

  return (
    <FormProvider {...instance}>
      <TextArea<ValuesType> name="additionalComments" label={t('comment')} />
    </FormProvider>
  );
};
