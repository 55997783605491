import { UserRoleType } from '@/types/open-api/domain-types';
import { User } from '@/model/IUser';

export const ADMIN_AREA_PREFIX = '/admin' as const;
export const PROFILE_PATH = '/profile' as const;

export const superAdminPathRootPath =
  `${ADMIN_AREA_PREFIX}/exhibitions` as const;

export const exhibitorPathRootPath = PROFILE_PATH;
export const agentPathRootPath = '/agent-home' as const;

export const adminPathRootPath = `${ADMIN_AREA_PREFIX}/users` as const;

export const supportPathRootPath = superAdminPathRootPath;

export const technicalManagerPathRootPath = superAdminPathRootPath;

export const moderatorPathRootPath = superAdminPathRootPath;

export const indexRouteFactory = (role: UserRoleType) => {
  switch (role) {
    case User.Role.Agent:
      return agentPathRootPath;
    case User.Role.SuperAdmin:
      return superAdminPathRootPath;
    case User.Role.Admin:
      return adminPathRootPath;
    case User.Role.SupportManager:
      return supportPathRootPath;
    case User.Role.TechnicalManager:
      return technicalManagerPathRootPath;
    case User.Role.Moderator:
      return moderatorPathRootPath;
    case User.Role.Exhibitor:
      return exhibitorPathRootPath;
    default:
      return '/';
  }
};
