import { createApi, createStore } from 'effector';
import { OptionType, SystemLangType } from '@/model/common';
import { produce } from 'immer';
import { useUnit } from 'effector-react/compat';

type CommonState = {
  categoryOptions: OptionType[];
  lang: SystemLangType;
};

const $common = createStore<CommonState>({
  categoryOptions: [],
  lang:
    (localStorage.getItem('lang') as SystemLangType) ||
    (navigator.language as SystemLangType),
});

export const commonApi = createApi($common, {
  setCategoryOptions: (state, payload: OptionType[]) => {
    return produce(state, (draft) => {
      draft.categoryOptions = payload;
    });
  },

  setLanguage: (state, payload: SystemLangType) => {
    return produce(state, (draft) => {
      draft.lang = payload;
    });
  },
});

export const useCommonStore = () => useUnit($common);
