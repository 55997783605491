import {
  ITEPortal_Domain_Dto_CategoryDto,
  ITEPortal_Domain_Dto_ProductDto,
} from '@/api';
import { DraggableUtils } from '@/utils/draggable-utils';
import { useMutation } from '@tanstack/react-query';
import { queryClient } from '@/features/query';
import { typedAxios } from '@/api/typed-axios';
import { toast } from 'react-toastify';
import i18n from '@/i18n';
import { useProductsLoaderOptions } from '@/pages/AdminPanel/Accordion/use-products-loader';
import { isNil } from '@/utils/is-nil';

export const useProductsOrderUpdate = (
  categoryId: ITEPortal_Domain_Dto_CategoryDto['id']
) => {
  const { getProductsLoaderOptions } = useProductsLoaderOptions();

  return useMutation({
    mutationKey: ['reorder-products-mutation', categoryId],
    mutationFn: async (opts: {
      productsSource: ITEPortal_Domain_Dto_ProductDto[];
      startIndex: number;
      endIndex: number;
    }) => {
      const { productsSource, startIndex, endIndex } = opts;

      const initialTableData = queryClient.getQueryData(
        getProductsLoaderOptions(categoryId).queryKey
      );

      if (startIndex === endIndex) {
        return Promise.resolve();
      }

      const localOrdered = DraggableUtils.reorder(
        productsSource,
        startIndex,
        endIndex
      );

      queryClient.setQueryData(
        getProductsLoaderOptions(categoryId).queryKey,
        localOrdered
      );

      try {
        await typedAxios.product.postProductsOrderIndex({
          requestBody: localOrdered
            .filter((pr) => !isNil(pr.id))
            .map((pr) => ({
              // "as" allowed here as we filter products above using isNil(pr.id)
              productId: pr.id as number,
              orderIndex: pr.orderIndex || 0,
            })),
        });
      } catch {
        toast.error(i18n.t('unable-to-apply-reorder'));

        queryClient.setQueryData(
          getProductsLoaderOptions(categoryId).queryKey,
          initialTableData
        );
      }
    },
  });
};
