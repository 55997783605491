import styled from 'styled-components';
import Sidebar from '../Sidebar';
import { Button, Text } from '@chakra-ui/react';

export const StyledSidebar = styled(Sidebar)`
  display: flex;
  flex-direction: column;

  max-height: calc(100% - var(--chakra-sizes-nav-bar-height));
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

export const StyledText = styled(Text)`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
`;

export const StyledButton = styled(Button)`
  margin-top: 20px;
  width: 100%;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;

  :hover {
    background-color: var(--chakra-colors-brandColor) !important;
  }
`;

export const HeaderTitle = styled.h3`
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;

  margin: 20px 0;
`;

export const StyledHeader = styled.div`
  margin-top: 10px;
`;

export const ExhibitionBox = styled.div`
  background: #e2e8f0;
  border-radius: 6px;
  text-align: left;
  padding: 8px 12px;
`;

export const ExhibitionTitle = styled.h4`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const BoothNumber = styled.h6`
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  margin-top: 4px;
`;
