import { Product } from '@/model/IProduct';
import { CREATE_EXHIBITION_ORDER_TAB_PATH } from '@/constants/router';
import { IdType } from '@/model/common';

export const replacePaths = (
  route: string,
  replace: Record<string, string | number>
) => {
  Object.keys(replace).forEach((key) => {
    route = route.replace(key, <string>replace[key]);
  });

  return route;
};

export const getPathByProduct = (
  exhibitionId: IdType,
  standId: IdType,
  product: Product
): string => {
  return replacePaths(CREATE_EXHIBITION_ORDER_TAB_PATH, {
    ':exhibitionId': exhibitionId,
    ':stand': standId,
    ':service': product.category.name,
  });
};
