import { Box, Button } from '@chakra-ui/react';
import React, { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { AddItemToOrderModal } from './AddItemToOrderModal';

const AddButtonWrapper = styled(Box)`
  padding-bottom: 10px;
  display: flex;
  flex-direction: row;
  justify-content: end;
  gap: 20px;
`;

export const OrderControls = () => {
  const { t } = useTranslation();
  const [isOpened, setIsOpened] = useState(false);

  return (
    <AddButtonWrapper>
      <Button colorScheme="red" onClick={() => setIsOpened(true)}>
        {t('add-service')}
      </Button>
      {isOpened && (
        <AddItemToOrderModal
          items={[]}
          openConfirm={isOpened}
          closeModal={() => setIsOpened(false)}
        />
      )}
    </AddButtonWrapper>
  );
};
