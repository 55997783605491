import { Outlet, redirect, Route } from '@tanstack/react-router';
import { rootRoute } from './root';
import { Profile } from '@/pages/Profile/Profile';
import { AdminPanelPage } from '@/layout/AdminPanel';
import { exhibitionsRoutesTree } from './protected/admin-exhibitions';
import { serviceDictionaryRoutesRootTree } from './protected/service-dictionary';
import { memberDirectoryRoutesTree } from './protected/member-directory';
import { settingsRoutesRootTree } from './protected/settings';
import { usersRoutesRootTree } from './protected/users';
import {
  createExhibitionOrderCategoryRoute,
  createExhibitionOrderServiceRoute,
  exhibitionRootRoute,
  exhibitionViewRoute,
  standProjectRoute,
  viewExhibitionCart,
} from './protected/exhibitions-by-role';
import { ADMIN_AREA_PREFIX, indexRouteFactory } from './userPaths';
import { RoleType } from '@/model/common';
import { $auth } from '@/store/effector/auth';
import { agentRoutesRoot } from '@/features/Routing/protected/agent-pages';

export const protectedRoute = new Route({
  id: 'protected',
  getParentRoute: () => rootRoute,
  beforeLoad: (opts) => {
    const { isLoggedIn, user } = $auth.getState();

    if (!isLoggedIn) {
      throw redirect({
        to: '/login',
        search: {
          redirect: opts.location.href,
        },
      });
    }

    return {
      role: user?.role,
      user,
    };
  },
  component: () => {
    return <Outlet />;
  },
});

export const indexRoute = new Route({
  path: '/',
  beforeLoad: async (opts) => {
    const { role } = opts.context;

    if (role) {
      throw redirect({
        to: indexRouteFactory(role as RoleType),
        replace: true,
      });
    }
  },
  getParentRoute: () => protectedRoute,
});

export const profileRoute = new Route({
  path: '/profile',
  getParentRoute: () => protectedRoute,
  component: () => <Profile />,
});

export const cabinetRoute = new Route({
  path: ADMIN_AREA_PREFIX,
  getParentRoute: () => protectedRoute,
  component: () => <AdminPanelPage />,
});

export const flatRouteTree = new Route({
  id: 'routes-by-role',
  getParentRoute: () => protectedRoute,
});

export const protectedTree = protectedRoute.addChildren([
  cabinetRoute.addChildren([
    exhibitionsRoutesTree,
    serviceDictionaryRoutesRootTree,
    memberDirectoryRoutesTree,
    settingsRoutesRootTree,
    usersRoutesRootTree,
  ]),

  flatRouteTree.addChildren([
    indexRoute,
    agentRoutesRoot,
    profileRoute,
    exhibitionRootRoute.addChildren([
      exhibitionViewRoute,
      viewExhibitionCart,
      createExhibitionOrderCategoryRoute,
      createExhibitionOrderServiceRoute,
      standProjectRoute,
    ]),
  ]),
]);
