/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ITEPortal_Domain_Dto_TechnicalManagerDto } from '../models/ITEPortal_Domain_Dto_TechnicalManagerDto';
import type { ITEPortal_Domain_Dto_UserDto } from '../models/ITEPortal_Domain_Dto_UserDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class UserService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns any Success
     * @throws ApiError
     */
    public getUsers({
        search,
        roles,
    }: {
        search?: string,
        roles?: Array<number>,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/users',
            query: {
                'search': search,
                'roles': roles,
            },
            errors: {
                500: `Server Error`,
            },
        });
    }

    /**
     * @returns any Success
     * @throws ApiError
     */
    public getUsers1({
        userId,
    }: {
        userId: number,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/users/{userId}',
            path: {
                'userId': userId,
            },
            errors: {
                500: `Server Error`,
            },
        });
    }

    /**
     * @returns any Success
     * @throws ApiError
     */
    public getUsersEmail({
        email,
    }: {
        email?: string,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/users/email',
            query: {
                'email': email,
            },
        });
    }

    /**
     * @returns any Created
     * @throws ApiError
     */
    public postUsersAdd({
        requestBody,
    }: {
        requestBody?: ITEPortal_Domain_Dto_UserDto,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/users/add',
            body: requestBody,
            mediaType: 'application/json-patch+json',
            errors: {
                400: `Bad Request`,
                500: `Server Error`,
            },
        });
    }

    /**
     * @returns any Success
     * @throws ApiError
     */
    public deleteUsersDelete({
        id,
    }: {
        id: number,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/users/delete/{id}',
            path: {
                'id': id,
            },
            errors: {
                500: `Server Error`,
            },
        });
    }

    /**
     * @returns any Created
     * @throws ApiError
     */
    public putUsersUpdate({
        requestBody,
    }: {
        requestBody?: ITEPortal_Domain_Dto_UserDto,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/users/update',
            body: requestBody,
            mediaType: 'application/json-patch+json',
            errors: {
                400: `Bad Request`,
                500: `Server Error`,
            },
        });
    }

    /**
     * @returns ITEPortal_Domain_Dto_TechnicalManagerDto Success
     * @throws ApiError
     */
    public getUsersTechnicalManagers(): CancelablePromise<Array<ITEPortal_Domain_Dto_TechnicalManagerDto>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/users/technical-managers',
        });
    }

    /**
     * @returns ITEPortal_Domain_Dto_TechnicalManagerDto Success
     * @throws ApiError
     */
    public getUsersTechnicalManagers1({
        exhibitionId,
    }: {
        exhibitionId: number,
    }): CancelablePromise<Array<ITEPortal_Domain_Dto_TechnicalManagerDto>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/users/technical-managers/{exhibitionId}',
            path: {
                'exhibitionId': exhibitionId,
            },
        });
    }

}
