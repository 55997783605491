import FileDownload from 'js-file-download';

export const FileUtils = {
  saveBlobAsFile: (blob: Blob, fileName: string): void => {
    FileDownload(blob, fileName);
  },

  saveUrlAsFile: (url: string, fileName: string): void => {
    const link = document.createElement('a');

    link.href = url;
    link.target = '_blank';
    link.style.display = 'none';
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
  },

  saveUrlToBlob: async (url: string): Promise<void> => {
    async function toDataURL(urlToBlob: string) {
      const blob = await fetch(urlToBlob, {
        mode: 'cors',
      }).then(async (res) => {
        return res.blob();
      });

      return URL.createObjectURL(blob);
    }

    const fileName = url.split('/').reverse()[0];
    const a = document.createElement('a');

    a.href = await toDataURL(url);
    a.download = fileName;

    document.body.appendChild(a);

    const clickHandler = () => {
      setTimeout(() => {
        URL.revokeObjectURL(url);
        a.removeEventListener('click', clickHandler);
      }, 150);
    };

    a.addEventListener('click', clickHandler);

    a.click();
  },
};
