import { OptionType } from '@/model/common';
import i18n from '@/i18n';
import { ITEPortal_Contracts_Enums_PavilionHall } from '@/api';

export const PavilionOptions: Array<OptionType> = [
  { value: 1, label: i18n.t('Pav1Hall1') },
  { value: 2, label: i18n.t('Pav1Hall2') },
  { value: 3, label: i18n.t('Pav1Hall3') },
  { value: 4, label: i18n.t('Pav1Hall4') },
  { value: 5, label: i18n.t('Pav2Hall5') },
  { value: 6, label: i18n.t('Pav2Hall6') },
  { value: 7, label: i18n.t('Pav2Hall7') },
  { value: 8, label: i18n.t('Pav2Hall8') },
  { value: 9, label: i18n.t('Pav2Hall9') },
  { value: 10, label: i18n.t('Pav2Hall10') },
  { value: 11, label: i18n.t('Pav2Hall11') },
  { value: 12, label: i18n.t('Pav3Hall12') },
  { value: 13, label: i18n.t('Pav3Hall13') },
  { value: 14, label: i18n.t('Pav3Hall14') },
  { value: 15, label: i18n.t('Pav3Hall15') },
  { value: 16, label: i18n.t('Pav3Hall16') },
  { value: 17, label: i18n.t('Pav3Hall17') },
  { value: 18, label: i18n.t('Pav3Hall18') },
  { value: 19, label: i18n.t('Pav3Hall19') },
];

export const getPavilionLabelByCode = (
  code: ITEPortal_Contracts_Enums_PavilionHall
) => {
  return PavilionOptions.find((p) => p.value === code)?.label;
};
