import { isNil } from '@/utils/is-nil';

export const FEATURES = {
  VAT_DESCRIPTION: 'VAT_DESCRIPTION',
  LINKED_ZERO_PRICE_PRODUCTS: 'LINKED_ZERO_PRICE_PRODUCTS',
  PRODUCT_GALLERY_PREVIEW_IMAGES_COUNT:
    'PUBLIC_PRODUCT_GALLERY_PREVIEW_IMAGES_COUNT',
} as const;

type FeatureKeys = keyof typeof FEATURES;

const getFeaturesMap = () => {
  return {
    VAT_DESCRIPTION: process.env.PUBLIC_VAT_DESCRIPTION,
    PRODUCT_GALLERY_PREVIEW_IMAGES_COUNT:
      process.env.PUBLIC_PRODUCT_GALLERY_PREVIEW_IMAGES_COUNT || 3,
    LINKED_ZERO_PRICE_PRODUCTS: true,
  };
};

export const useFeature = (feature: FeatureKeys) => {
  const flag = getFeaturesMap()[feature];

  if (isNil(flag)) {
    return false;
  }

  return (
    flag &&
    flag !== '0' &&
    flag !== 0 &&
    flag !== 'false' &&
    flag !== 'undefined'
  );
};

export const useFeatureValue = <T>(
  feature: FeatureKeys,
  defaultValue: T
): T => {
  const hasFeature = useFeature(feature);

  if (!hasFeature) {
    return defaultValue;
  }

  return getFeaturesMap()[feature] as T;
};
