import { Column } from 'react-table';
import { Table } from '@/components/Table/Table';
import { Flex } from '@chakra-ui/react';
import i18n from '@/i18n';
import { useSuspenseQuery } from '@tanstack/react-query';
import { QueryOptions } from '@/features/query/query-options';
import { editExhibitionAccountRoute } from '@/features/Routing/protected/admin-exhibitions';
import { ITEPortal_Domain_Dto_Bitrix_Deal_StandSharerDto } from '@/api';
import { Loader } from '@/components/Loader/Loader';

const columns = [
  {
    Header: i18n.t('company'),
    accessor: 'companyId',
  },
  {
    Header: i18n.t('area'),
    accessor: 'area',
  },
  {
    Header: i18n.t('stand-number'),
    accessor: 'standNumber',
  },
  {
    Header: i18n.t('stand-type'),
    accessor: 'standConfiguration',
  },
] satisfies Array<Column<ITEPortal_Domain_Dto_Bitrix_Deal_StandSharerDto>>;

export const SubexponentsTab = () => {
  const { accountId, exhibitionId } = editExhibitionAccountRoute.useParams();

  const { data: stands = [] } = useSuspenseQuery(
    QueryOptions.standsQueryOptions({
      exhibitionId: +exhibitionId,
      exhibitorId: +accountId,
    })
  );

  const { data, isLoading } = useSuspenseQuery(
    QueryOptions.subexponentsQueryOptions({
      standId: stands?.[0]?.id,
    })
  );

  return (
    <Flex flexDir="column">
      {isLoading ? <Loader /> : <Table columns={columns} data={data} />}
    </Flex>
  );
};
