import {
  Route,
  Router,
  RouterProvider as TanstackRouterProvider,
} from '@tanstack/react-router';
import { authRouteTree } from './auth';
import { protectedTree } from './protected';
import { rootRoute } from './root';
import { queryClient } from '../query';

const indexRoute = new Route({
  getParentRoute: () => rootRoute,
  path: '/index',
  component: () => 'index',
});

const routeTree = rootRoute.addChildren([
  protectedTree,
  authRouteTree,
  indexRoute,
]);

export const router = new Router({
  routeTree,
  context: {
    queryClient,
  },
});

export const RouterProvider = () => {
  return <TanstackRouterProvider router={router} />;
};
