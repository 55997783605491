import { useQuery } from '@tanstack/react-query';
import { typedAxios } from '@/api/typed-axios';

export const useParentCategoriesLoader = () => {
  return useQuery({
    queryKey: ['parent-categories'],
    queryFn: async () => {
      const data = await typedAxios.category.getCategoriesParentCategories();

      const categories = data?.map((category) => {
        return {
          value: category?.name,
          label: category?.displayName,
        };
      });

      return categories || [];
    },
  });
};
