// @ts-expect-error this looks odd, but https://github.com/facebook/create-react-app/issues/13118#issuecomment-1540018089
export * from 'core-js/actual';

import './i18n';
import './store/effector/init';
import './api/setup-openapi';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import axios from 'axios';
import { bootstrapSentry } from './features/sentry';
// import { router } from '@/features/Routing/provider';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

console.info(`App version: ${process.env.PUBLIC_VERSION}`);

bootstrapSentry();

// declare module '@tanstack/react-router' {
//   interface Register {
//     router: typeof router;
//   }
// }

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error?.code === 'ERR_NETWORK') {
      // localStorage.clear();
      // localStorage.setItem('lang', 'ru');
      // window.location.href = '/';
    } else {
      return Promise.reject(error);
    }
  }
);

async function enableMocking() {
  if (process.env.NODE_ENV !== 'development') {
    return;
  }

  // const { worker } = await import('./features/msw/browser');
  //
  // return worker.start({
  //   quiet: true,
  //   onUnhandledRequest: 'bypass',
  // });
}

enableMocking().finally(() => {
  root.render(<App />);
});
