import React, { useCallback, useEffect, useState } from 'react';
import getTemporaryStaffConfig from './config';
import { Product } from '@/model/IProduct';
import { FieldValues } from 'react-hook-form';
import ProductCard from '@/components/Card/ProductCard';
import ReactShowMoreText from 'react-show-more-text';
import styled from 'styled-components';
import Row from './Row';
import { TemporaryStaffOrderItem } from '@/model/IOrderItem';
import fetchApi from '@/utils/fetchAPI';
import { GET_PRICELIST_PRODUCTS } from '@/constants/endpoints';
import { FetchMethods } from '@/utils/constants';
import { DomainRecord } from '@/model/common';
import { Loader } from '@/components/Loader/Loader';
import { t } from 'i18next';
import { LoaderWrapper } from '@/theme/css/styles';
import { createExhibitionOrderServiceRoute } from '@/features/Routing/protected/exhibitions-by-role';
import { CATEGORY_TYPES } from '@/constants/categorie';
import { ITEPortal_Domain_Dto_PriceListItemSummary } from '@/api';
import { ProductUtils } from '@/utils/product-utils';
import { useStand } from '@/store/effector/stand';
import { orderApi } from '@/store/effector/order';
import { ITEPortal_Domain_Dto_CategoryDto } from '../../../../api';
import { useVatSettings } from '@/store/effector/vat-settings';
import { Box, Text } from '@chakra-ui/react';

interface TemporaryStaffProps {
  description: ITEPortal_Domain_Dto_CategoryDto['description'];
}

export const ShowMoreText = styled(ReactShowMoreText)`
  position: relative;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 24px;
  padding-bottom: 20px;

  .show-more-less-clickable {
    position: absolute;
    right: 0;
    cursor: pointer;
    color: var(--chakra-colors-brandColor);
    text-decoration: none;
    transform: translateY(100%);
  }
`;

const TemporaryStaff: React.FC<TemporaryStaffProps> = ({ description }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [productItems, setProductItems] = useState<DomainRecord[]>([]);
  const { standForOrder } = useStand();
  const { vatMessage } = useVatSettings();
  const { exhibitionId } = createExhibitionOrderServiceRoute.useParams();

  const clickHandler = useCallback((product: Product, values: FieldValues) => {
    const language = values?.language as string | undefined;
    const personAmount = parseInt(values?.staff_amount);
    const dayAmount = parseInt(values?.day_amount);

    orderApi.addItem(
      new TemporaryStaffOrderItem({
        id: product.getProductId(),
        product: product,
        language: language,
        peopleAmount: personAmount,
        dayAmount: dayAmount,
      })
    );
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const culture = localStorage.getItem('lang');

      setIsSubmitting(true);
      try {
        const { data } = await fetchApi<
          Array<ITEPortal_Domain_Dto_PriceListItemSummary>
        >(GET_PRICELIST_PRODUCTS, {
          method: FetchMethods.Get,
          params: {
            exhibitionId,
            currency: standForOrder?.currency || undefined,
            buildingType: standForOrder?.buildingType,
            culture,
          },
        });

        const productItemCardData = data.filter(
          (item: any) => item.categoryName === CATEGORY_TYPES.TEMPORARY_STAFF
        );

        const productItemCardObjects = productItemCardData?.map((product) => {
          return ProductUtils.createProductFromPriceListItem(product);
        });

        const productItemsObjects = getTemporaryStaffConfig(
          productItemCardObjects
        );

        setProductItems(productItemsObjects);
      } finally {
        setIsSubmitting(false);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      {vatMessage && (
        <Box marginY={4}>
          <Text color="gray.500">{vatMessage}</Text>
        </Box>
      )}
      {description && !isSubmitting && (
        <ShowMoreText
          lines={2}
          keepNewLines={false}
          more={t('showMore')}
          less={t('showLess')}
          anchorClass="show-more-less-clickable"
        >
          {description}
        </ShowMoreText>
      )}
      {isSubmitting ? (
        <LoaderWrapper>
          <Loader />
        </LoaderWrapper>
      ) : (
        productItems?.length > 0 && (
          <ProductCard>
            {productItems.map(({ key, text, product, params }) => (
              <Row
                key={key}
                submitHandler={clickHandler}
                label={text}
                product={product}
                params={params || []}
              />
            ))}
          </ProductCard>
        )
      )}
    </>
  );
};

export default TemporaryStaff;
