import React, { useCallback } from 'react';
import { ModalFactory } from '@/components/Modal/api';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { ApiError } from '@/api';
import { toast } from 'react-toastify';
import { ExportToBitrixForm } from '@/pages/AdminPanel/PlacedOrders/exportOrderForm';
import * as vali from 'zod';
import i18n from '@/i18n';
import { zodResolver } from '@hookform/resolvers/zod';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { editOrderRoute } from '@/features/Routing/protected/admin-exhibitions';
import { typedAxios } from '@/api/typed-axios';

const OptionSchema = vali.object(
  {
    value: vali.coerce.number(),
    label: vali.string(),
  },
  {
    required_error: i18n.t('required-field'),
  }
);

const ValidationSchema = vali.object({
  company: OptionSchema,
  bankAccount: OptionSchema,
});

export type ExportToBitrixFormValidationSchemaType = vali.infer<
  typeof ValidationSchema
>;

export const useExportOrder = () => {
  const { t } = useTranslation();
  const { orderId } = editOrderRoute.useParams();
  const queryClient = new QueryClient();

  const instance = useForm<ExportToBitrixFormValidationSchemaType>({
    resolver: zodResolver(ValidationSchema),
    mode: 'onChange',
  });

  const exportOrder = async (
    values: ExportToBitrixFormValidationSchemaType
  ) => {
    try {
      console.log(values);
      await typedAxios.bitrix.postBitrixOrders({
        orderId: +orderId,
        requestBody: {
          bankAccountId: values?.bankAccount?.value,
          iteCompanyId: values?.company?.value,
        },
      });

      ModalFactory.close();
      toast.success(t('order-exported-successfully'));
    } catch (e) {
      if (e instanceof ApiError) {
        const message = e.body;

        if (message && typeof message === 'string') {
          toast.error(message);
        } else {
          toast.error(t('error-occurred'));
        }
      }
    }
  };

  const openExportModal = useCallback(() => {
    ModalFactory.show({
      title: t('bitrixExport'),
      Component: () => (
        <QueryClientProvider client={queryClient}>
          <ExportToBitrixForm instance={instance} />
        </QueryClientProvider>
      ),
      okLabel: t('export'),
      onOk: async () => {
        return instance.handleSubmit(exportOrder)();
      },
    });
  }, []);

  return {
    openExportModal,
  };
};
