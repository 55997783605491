import { Category } from '@/model/ICategory';
import { createApi, createEffect, createStore } from 'effector';
import { produce } from 'immer';
import { useUnit } from 'effector-react';
import { queryClient } from '@/features/query';
import { leaveExhibitionOrderEvent } from '@/store/effector/cleanup';
import { QueryOptions } from '@/features/query/query-options';
import { CategoryQueryOptionsType } from '@/features/query/query-options/types';

type CatalogStateType = {
  rootCategories: Category[];
  flatMap: Map<string, Category>;
  isLoading: boolean;
};

const initialState: CatalogStateType = {
  rootCategories: [],
  flatMap: new Map<string, Category>(),
  isLoading: true,
};

export const $catalog = createStore<CatalogStateType>(initialState).reset(
  leaveExhibitionOrderEvent
);

const catalogApi = createApi($catalog, {
  updateCategories: (
    state,
    payload: Pick<CatalogStateType, 'rootCategories' | 'flatMap'>
  ) => {
    return produce(state, (draft) => {
      draft.rootCategories = payload.rootCategories;
      draft.flatMap = payload.flatMap;
      draft.isLoading = false;
    });
  },
});

export const loadCategoriesFX = createEffect(
  async (opts: CategoryQueryOptionsType) => {
    const data = await queryClient.ensureQueryData(
      QueryOptions.loadCategoriesQueryOptions(opts)
    );

    const sortedCategories = data.map((category) => new Category(category));

    const flatMap = new Map<string, Category>();

    sortedCategories.forEach((ct) => {
      flatMap.set(ct.name, ct);
    });

    sortedCategories.forEach((ct) => {
      if (ct.parentCategoryId && flatMap.get(ct.parentCategoryId)) {
        ct.parent = flatMap.get(ct.parentCategoryId);
        flatMap.get(ct.parentCategoryId)?.tree.push(ct);
      }
    });

    const rootCategories = [...flatMap.values()].filter(
      (ct) => !ct.parentCategoryId
    );

    catalogApi.updateCategories({
      rootCategories: rootCategories,
      flatMap: flatMap,
    });
  }
);

export const useCatalog = () => {
  const state = useUnit($catalog);

  return {
    ...state,
    isEmpty: state.rootCategories.length === 0,
  };
};

export const useCatalogLoading = () => {
  return useUnit(loadCategoriesFX.inFlight);
};
