import { Button, Flex } from '@chakra-ui/react';
import { editProductApi } from '@/pages/AdminPanel/ServiceDirectory/EditProductFieldsets/useProductForm';
import { CreateProductUtils } from '@/pages/AdminPanel/ServiceDirectory/EditProductFieldsets/utils';
import { editCategoryApi } from '@/pages/AdminPanel/ServiceDirectory/ServiceDirectory.Accordion/edit-category-api';
import { ITEPortal_Domain_Dto_CategoryDto } from '@/api';
import { ProductTypesType } from '@/constants/categorie';
import i18n from '@/i18n';
import { isNil } from '@/utils/is-nil';

type CategoryControlsPropsType = {
  category: ITEPortal_Domain_Dto_CategoryDto;
  productType: ProductTypesType;
};
export const CategoryControls = (props: CategoryControlsPropsType) => {
  const { category, productType } = props;
  const isSubCategory = !isNil(category.parentCategoryId);

  return (
    <Flex>
      <Button
        size="sm"
        ml={4}
        mr={4}
        onClick={() =>
          editProductApi.open({
            product: null,
            productType,
            categoryName: category.name,
            categoryId: category.id,
          })
        }
      >
        {CreateProductUtils.getCreateButtonLabelByType(productType)}
      </Button>
      {!isSubCategory && (
        <Button
          size="sm"
          ml={4}
          mr={4}
          onClick={() =>
            editCategoryApi.open({
              category: null,
              parentCategoryName: category.name,
            })
          }
        >
          {i18n.t('add-sub-category')}
        </Button>
      )}
    </Flex>
  );
};
