import React, { CSSProperties, ReactNode } from 'react';
import {
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberInputProps,
  FormControl,
  FormErrorMessage,
  InputGroup,
  InputLeftAddon,
} from '@chakra-ui/react';
import { FieldProps } from './interfaces';
import {
  FieldValues,
  Path,
  useController,
  useFormContext,
} from 'react-hook-form';
import { FormLabel } from '../FormLabel/FormLabel';

type InputNumberProps<T extends FieldValues = FieldValues> = NumberInputProps &
  FieldProps<T> & {
    defaultValue?: number;
    addon?: ReactNode;
  };

const StyledForCaseWithAddon: CSSProperties = {
  borderTopLeftRadius: 0,
  borderBottomLeftRadius: 0,
};

const InputNumber = <T extends FieldValues = FieldValues>({
  name,
  placeholder,
  settings,
  min,
  max,
  defaultValue,
  label,
  addon,
  ...rest
}: InputNumberProps<T>) => {
  const formContext = useFormContext();

  if (!formContext) {
    throw new Error(
      `This field (${name}) should be used only within FormContext`
    );
  }

  const { fieldState, field } = useController({
    name: name,
  });

  if (!fieldState) {
    throw new Error(
      'fieldState not found. Is this field used within FormContext?'
    );
  }

  const { onChange, ...registerFieldName } = formContext.register(
    name as Path<FieldValues>,
    {
      onChange: () => {
        formContext.clearErrors(name);
      },
      ...settings,
    }
  );

  return (
    <FormControl isInvalid={fieldState.invalid}>
      {label && <FormLabel htmlFor={name}>{label}</FormLabel>}

      <NumberInput
        step={1}
        precision={0}
        min={min}
        max={max}
        defaultValue={defaultValue}
        {...rest}
        onChange={(valueAsString, valueAsNumber) => {
          onChange({ target: { value: valueAsNumber, name } });
        }}
      >
        <InputGroup>
          {addon && <InputLeftAddon>{addon}</InputLeftAddon>}
          <NumberInputField
            id={name}
            placeholder={placeholder}
            {...registerFieldName}
            value={field.value}
            style={addon ? StyledForCaseWithAddon : {}}
          />
        </InputGroup>
        <NumberInputStepper>
          <NumberIncrementStepper />
          <NumberDecrementStepper />
        </NumberInputStepper>
      </NumberInput>

      {fieldState.error?.message && (
        <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
      )}
    </FormControl>
  );
};

export default InputNumber;
