export const SELECT_BUILDING = 'select-building';
export const CLEANING = 'cleaning';
export const CAR_PASSES = 'auto-card';
export const WATER_SUPPLY = 'water';
export const TELECOMMUNICATIONS = 'telecommunication';
export const GRAPHIC_WORKS = 'graphic-works';
export const COMPRESSED_AIR = 'air';
export const TV_RENT = 'tv-rent';
export const STAND_DESIGN = 'stand-design';
export const CARPET_COVERING = 'carpet-covering';
export const WALL_PANELS = 'wall-panels';
export const TEMPORARY_STAFF = 'temporary-staff';
export const POWER = 'power';
export const DOORS_FENCES = 'doors-fences';
export const OTHER_CONSTRUCTIVE = 'other-constructive';
export const SOCKETS_LIGHTING = 'sockets-lights';
export const SOCKETS = 'sockets';
export const LIGHTING = 'lighting';
export const CART_TABLE = [
  'name',
  'price',
  'amount',
  'total',
  'delete',
] as const;
export const FURNITURE = 'furniture';
export const CHAIR = 'chair';
export const TABLE = 'table';
export const RACK = 'rack';
export const RACKS_CABINETS_SHELVES = 'racks-cabinets-shelves';
export const SHOWCASE = 'showcase';
export const PODIUM = 'podium';
export const OTHER_FURNITURE = 'other-furniture';
export const KITCHEN_EQUIPMENT = 'kitchen-equipment';

export const USERS = 'users';
export const SETTINGS = 'settings';
export const EXHIBITIONS = 'exhibitions';
export const SERVICE_DICTIONARY = 'service-dictionary';
export const MEMBER_DIRECTORY = 'member-directory';
export const OTHER_REFERENCE = 'other-reference';
export const PERSONAL_ACCOUNTS = 'personal-accounts';
export const PLACED_ORDERS = 'placed-orders';
export const CREATE_USER = 'users';
export const CREATE_USER_ADD_PAGE = 'users/add';
export const CREATE_USER_EDIT_PAGE = 'users/edit';
