import { PropsWithChildren } from 'react';

const isDebugEnabled = sessionStorage.getItem('debug-enabled') === '1';

export const Debug = ({ children }: PropsWithChildren) => {
  if (!isDebugEnabled) {
    return null;
  }

  return <>{children}</>;
};
