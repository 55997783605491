import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Card,
  CardBody,
  Flex,
} from '@chakra-ui/react';
import { ProductsList } from '@/pages/AdminPanel/ServiceDirectory/ServiceDirectory.Accordion/ProductsList';
import { ITEPortal_Domain_Dto_CategoryDto } from '@/api';
import React, { ReactNode } from 'react';
import { EditIcon } from '@chakra-ui/icons';
import { editCategoryApi } from '@/pages/AdminPanel/ServiceDirectory/ServiceDirectory.Accordion/edit-category-api';
import { SubCategoriesList } from '@/pages/AdminPanel/ServiceDirectory/ServiceDirectory.Accordion/SubCategoriesList';
import { CreateProductUtils } from '@/pages/AdminPanel/ServiceDirectory/EditProductFieldsets/utils';
import { CategoryControls } from '@/pages/AdminPanel/ServiceDirectory/ServiceDirectory.Accordion/CategoryControls';
import { Debug } from '@/components/Debug';

type CategoryItemPropsType = {
  category: ITEPortal_Domain_Dto_CategoryDto;
  isDragDisabled?: boolean;
  dragHandle?: ReactNode;
};

export const CategoryItem = (props: CategoryItemPropsType) => {
  const { category, dragHandle } = props;
  const productType = CreateProductUtils.getProductType({
    id: 0,
    categoryName: category.name,
  });

  return (
    <AccordionItem key={category.id} border="unset">
      {({ isExpanded }) => (
        <Card mb={2}>
          <CardBody padding={2} pb={isExpanded ? 4 : 2}>
            <Flex flexDirection="row" alignItems="center">
              {dragHandle}
              <AccordionButton>
                <Box as="span" flex="1" textAlign="left" fontWeight="500">
                  <Debug>{category.id}</Debug>
                  {category.displayName || category.name}
                </Box>
              </AccordionButton>
              <EditIcon
                ml={2}
                mr={2}
                cursor="pointer"
                onClick={() => {
                  editCategoryApi.open({
                    category,
                    parentCategoryName: category.parentCategoryId,
                  });
                }}
              />

              <AccordionButton
                width="fit-content"
                background="transparent !important"
              >
                <AccordionIcon cursor="pointer" />
              </AccordionButton>
            </Flex>
            {isExpanded && (
              <>
                <AccordionPanel pb={4}>
                  {isExpanded && (
                    <SubCategoriesList parentCategoryId={category.id} />
                  )}
                  {isExpanded && <ProductsList categoryId={category.id} />}
                </AccordionPanel>
                {productType && (
                  <CategoryControls
                    category={category}
                    productType={productType}
                  />
                )}
              </>
            )}
          </CardBody>
        </Card>
      )}
    </AccordionItem>
  );
};
