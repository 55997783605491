import { createApi, createStore } from 'effector';
import { useUnit } from 'effector-react';

const $loading = createStore<boolean>(false);

export const loadingApi = createApi($loading, {
  on: () => true,
  off: () => false,
  toggle: (state) => !state,
});

export const useAppLoading = () => useUnit($loading);
