import {
  ButtonGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { Button } from '@/components/Buttons/Button/Button';

import { useTranslation } from 'react-i18next';

interface DeleteModalProps {
  onSubmit: () => void;
  isOpen: boolean;
  onClose: () => void;
}

export const DeleteModal = ({
  onSubmit,
  isOpen,
  onClose,
}: DeleteModalProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg" isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t('attention')}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>{t('deletePosition')}</ModalBody>
        <ModalFooter onClick={onClose}>
          <ButtonGroup gap="1">
            <Button colorScheme="secondary" onClick={onSubmit}>
              {t('yes')}
            </Button>
            <Button colorScheme="black" onClick={onClose}>
              {t('cancel')}
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
