import { FormProvider, useForm } from 'react-hook-form';

import InputNumber from '../../../../../components/Fields/InputNumber';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { IBaseOrder } from '../../../../../model/IBaseOrder';
import { ModalFactory } from '../../../../../components/Modal/api';
import { ITEPortal_Domain_Dto_Order_PersonalExtraChargeDto } from '../../../../../api';
import { isNil } from '../../../../../utils/is-nil';

export type EditChargeContextType =
  ITEPortal_Domain_Dto_Order_PersonalExtraChargeDto;

export const MarkupForm = ({ order }: { order: IBaseOrder }) => {
  const { t } = useTranslation();
  const instance = useForm<{ markup: number | null }>();

  useEffect(() => {
    instance.watch((value) => {
      ModalFactory.updateContext({
        personalExtraChargePercentage: value.markup,
      });
    });
  }, []);

  useEffect(() => {
    if (!isNil(order.personalExtraChargePercentage)) {
      instance.reset({
        markup: +order.personalExtraChargePercentage,
      });
    }
  }, [order]);

  return (
    <FormProvider {...instance}>
      <InputNumber
        name="markup"
        label={t('markup')}
        addon="%"
        max={100}
        min={0.1}
        step={0.1}
        precision={1}
        defaultValue={
          !isNil(order.personalExtraChargePercentage)
            ? order.personalExtraChargePercentage
            : undefined
        }
      />
    </FormProvider>
  );
};
