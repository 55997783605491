import { CommentsHistory } from '@/pages/StandProject/StandProjectDescription/CommentsHistory';
import { TextArea } from '@/components/TextArea/TextArea';
import { Flex } from '@chakra-ui/react';
import { AttachmentUploader } from '@/pages/StandProject/StandProjectDescription/AttachmentUploader';
import { ITEPortal_Domain_Dto_Stand_GetStandProjectDto } from '@/api';
import { editExhibitionAccountRoute } from '@/features/Routing/protected/admin-exhibitions';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import * as vali from 'zod';
import i18n from '@/i18n';
import { zodResolver } from '@hookform/resolvers/zod';
import { AsyncButton } from '@/components/AsyncButton';
import { toast } from 'react-toastify';
import { queryClient } from '@/features/query';
import { QueryOptions } from '@/features/query/query-options';
import { typedAxios } from '@/api/typed-axios';
import { useStandProject } from '@/pages/AdminPanel/PersonalAccounts/StandsTab/StandProjectForm/api';

const ValidationSchema = vali.object({
  changeDescription: vali
    .string({
      required_error: i18n.t('required-field'),
      invalid_type_error: i18n.t('required-field'),
    })
    .min(1, {
      message: i18n.t('required-field'),
    }),
  file: vali.instanceof(File).optional().nullish(),
});

type FormValues = vali.infer<typeof ValidationSchema>;

export const ProjectChangeForm = ({
  project,
}: {
  project: ITEPortal_Domain_Dto_Stand_GetStandProjectDto;
}) => {
  const editable = project.approved !== true;
  const { t } = useTranslation();
  const { data: exhibitor } = editExhibitionAccountRoute.useLoaderData();
  const { standId } = useStandProject();
  const [uploaderKey, setUploaderKey] = useState<number | null>(null);

  const employeesIds = useMemo(() => {
    return exhibitor.exhibitorEmployees?.map((empl) => empl.userId) || [];
  }, [exhibitor]);

  const formInstance = useForm<FormValues>({
    resolver: zodResolver(ValidationSchema),
  });

  const handleSubmit = async (values: FormValues) => {
    try {
      if (!standId || !project.projectId) {
        return;
      }

      const changeId = await typedAxios.stand.postStandsProjectChanges({
        standId: standId,
        projectId: project.projectId,
        requestBody: {
          comment: values.changeDescription,
        },
      });

      if (values.file instanceof File) {
        await typedAxios.stand.putStandsProjectChanges({
          changesId: +changeId,
          projectId: +project.projectId,
          standId: +standId,
          formData: {
            file: values.file,
          },
        });
      }

      await queryClient.refetchQueries({
        queryKey: QueryOptions.loadStandProject({
          standId: standId,
        }).queryKey,
      });

      setUploaderKey(changeId);

      formInstance.reset({
        changeDescription: '',
      });
    } catch {
      toast.error(t('error-occurred'));
    }
  };

  if (!project || !project?.projectChangesHistory) {
    return null;
  }

  return (
    <FormProvider {...formInstance}>
      <CommentsHistory
        comments={project.projectChangesHistory}
        ownershipResolver={(historyItem) =>
          historyItem.createdBy
            ? employeesIds.includes(historyItem.createdBy)
            : false
        }
      />
      {editable && (
        <>
          <TextArea<FormValues>
            label={t('stand-change-description')}
            name="changeDescription"
          />
          <Flex direction="row" gap={4} mb={6} justifyContent="flex-end">
            <AttachmentUploader
              key={uploaderKey}
              onFileChange={(file) => {
                formInstance.setValue('file', file);
              }}
            />
            <AsyncButton
              size="sm"
              variant="solid"
              colorScheme="red"
              onClick={formInstance.handleSubmit(handleSubmit)}
            >
              {t('publish-stand-project-change')}
            </AsyncButton>
          </Flex>
        </>
      )}
    </FormProvider>
  );
};
