import { IMenuConfig } from '@/components/Menu/config';
import { WarningIcon } from '@chakra-ui/icons';
import { typedAxios } from '../../api/typed-axios';
import { UrlUtils } from '../../utils/url-utils';

const saveManual = async () => {
  typedAxios.request
    .request({
      url: '/global-settings/exhibitor-manual',
      method: 'GET',
      query: {
        culture: localStorage.getItem('lang'),
      },
    })
    .then((manualUrl) => {
      if (manualUrl && typeof manualUrl === 'string') {
        window.open(UrlUtils.appendTimestampToUrl(manualUrl), '_blank');
      }
    });
};

export const headerMenuConfig = (t: any) => {
  return {
    items: [
      {
        key: 'manual',
        name: t('participantManual'),
        to: saveManual,
        icon: <WarningIcon fontSize="11px" />,
      },
    ],
  } as IMenuConfig;
};
