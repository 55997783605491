import {
  ITEPortal_Domain_Dto_AddProductDto,
  ITEPortal_Domain_Dto_CategoryDto,
  ITEPortal_Domain_Dto_ProductDto,
} from '@/api';
import { createApi, createStore } from 'effector';
import { useUnit } from 'effector-react';
import { UseFormReturn } from 'react-hook-form/dist/types';
import {
  BuildingProductValidationSchemaType,
  CommonProductValidationSchemaType,
  TemporaryStaffValidationSchemaType,
} from '@/pages/AdminPanel/ServiceDirectory/EditProductFieldsets/validation-schemas';
import { ProductTypesType } from '@/constants/categorie';
import { useQuery } from '@tanstack/react-query';
import { QueryOptions } from '@/features/query/query-options';

const $editProduct = createStore<{
  isOpened: boolean;
  product: ITEPortal_Domain_Dto_ProductDto | null;
  productType?: ProductTypesType | null;
  categoryName?: ITEPortal_Domain_Dto_CategoryDto['name'];
  categoryId?: ITEPortal_Domain_Dto_CategoryDto['id'] | null;
  formInstance: UseFormReturn<
    | CommonProductValidationSchemaType
    | TemporaryStaffValidationSchemaType
    | BuildingProductValidationSchemaType
  > | null;
  map:
    | ((
        values:
          | CommonProductValidationSchemaType
          | TemporaryStaffValidationSchemaType
          | BuildingProductValidationSchemaType
      ) => ITEPortal_Domain_Dto_AddProductDto)
    | null;
}>({
  categoryName: null,
  categoryId: null,
  isOpened: false,
  product: null,
  formInstance: null,
  productType: null,
  map: null,
});

export const editProductApi = createApi($editProduct, {
  open: (
    state,
    payload: {
      product: ITEPortal_Domain_Dto_ProductDto | null;
      productType?: ProductTypesType | null;
      categoryName?: ITEPortal_Domain_Dto_CategoryDto['name'];
      categoryId?: ITEPortal_Domain_Dto_CategoryDto['id'];
    }
  ) => {
    return {
      ...state,
      product: payload.product,
      productType: payload.productType,
      categoryName: payload.categoryName,
      categoryId: payload.categoryId,
      isOpened: true,
    };
  },

  setFormInstance: (
    state,
    payload: {
      formInstance?: UseFormReturn<
        | CommonProductValidationSchemaType
        | TemporaryStaffValidationSchemaType
        | BuildingProductValidationSchemaType
      >;
      map: (
        values:
          | CommonProductValidationSchemaType
          | TemporaryStaffValidationSchemaType
          | BuildingProductValidationSchemaType
      ) => ITEPortal_Domain_Dto_AddProductDto;
    }
  ) => {
    return {
      ...state,
      ...payload,
    };
  },

  close: () => {
    return {
      isOpened: false,
      product: null,
      formInstance: null,
      productType: null,
      map: null,
      categoryName: null,
      categoryId: null,
    };
  },
});

export const useProductForm = () => {
  const { data: categoryOptions = [] } = useQuery(
    QueryOptions.loadCategoryOptions()
  );

  return {
    ...useUnit($editProduct),
    categoryOptions,
  };
};
