import React, { useCallback, useState } from 'react';
import { CloseIcon, InfoIcon } from '@chakra-ui/icons';
import styled from 'styled-components';
import { OrderItem, TemporaryStaffOrderItem } from '@/model/IOrderItem';
import { Link, useNavigate } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';
import { getPathByProduct } from '@/utils/route';
import classNames from 'classnames';
import { viewExhibitionCart } from '@/features/Routing/protected/exhibitions-by-role';
import { CATEGORY_TYPES } from '@/constants/categorie';
import { TKey } from '@/i18n/types';
import { useExhibition } from '@/store/effector/exhibition';
import { orderApi } from '@/store/effector/order';
import { OrderItemDependencyWarning } from '@/components/Sidebar/OrderList/OrderItemDependencyWarning';
import { Tooltip } from '@chakra-ui/react';

interface RowProps {
  className?: string;
  item: OrderItem | TemporaryStaffOrderItem;
}

const Wrapper = styled.div`
  overflow-x: hidden;
  transition: all ease-in-out 0.3s;

  transform: translateX(0);

  &.showActions {
    transform: translateX(-2rem);

    .actions {
      width: 1rem;
    }
  }

  &:not(:last-child) .wrapper-inner {
    border-bottom: 1px solid #e2e8f0;
  }
`;

const WrapperInner = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  gap: 20px;

  padding: 12px 0;

  font-size: 12px;
  line-height: 16px;
`;

const Name = styled(Link)`
  padding-left: 20px;
  width: 50%;
  font-weight: 400;

  &:hover {
    text-decoration: underline;
    color: var(--chakra-colors-brandColor);
  }
`;

const Count = styled.div`
  text-align: center;
  width: 20%;
  font-weight: 400;
  white-space: nowrap;
`;

const Price = styled.div`
  text-align: right;
  width: 30%;

  font-weight: 600;
  white-space: nowrap;
`;

const Actions = styled.div`
  text-align: right;
  transition: width ease-in-out 0.3s;
  width: 0;
`;

const Button = styled.button`
  transition: all ease-in-out 0.15s;

  color: #718096;

  &:hover {
    color: var(--chakra-colors-brandColor);
  }
`;

const Row: React.FC<RowProps> = ({ item, className }) => {
  const { currency } = useExhibition();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const productPrice = item.total;

  const itemName = item.displayName;

  const [visible, setVisible] = useState(false);

  const { exhibitionId, stand } = viewExhibitionCart.useParams();
  const isBuilding = item.product.category.isBuilding;

  const deleteHandler = useCallback(async () => {
    if (isBuilding) {
      await navigate({
        to: `/exhibitions/${exhibitionId}/${stand}/create/${CATEGORY_TYPES.SELECT_BUILDING}`,
        replace: true,
      });
    }

    orderApi.removeItem(item);
  }, []);

  const unitOfMeasurement = isBuilding
    ? 'sqm'
    : item.product.category.unitOfMeasure;

  return (
    <>
      <Wrapper
        className={classNames({
          [className || '']: true,
          showActions: visible,
        })}
        onMouseLeave={() => !item.included && setVisible(false)}
      >
        <WrapperInner className="wrapper-inner">
          <Name to={getPathByProduct(exhibitionId, stand, item.product)}>
            {itemName}{' '}
            {item.included && (
              <Tooltip
                label={t('included-into-price-of')}
                placement="top"
                hasArrow
              >
                <InfoIcon color="brandColor" />
              </Tooltip>
            )}
          </Name>
          <Count onMouseEnter={() => !item.included && setVisible(true)}>
            {item.getAmount()}{' '}
            {unitOfMeasurement ? `(${t(unitOfMeasurement as TKey)})` : null}
          </Count>
          <Price onMouseEnter={() => !item.included && setVisible(true)}>
            {t('result-price', {
              cost: productPrice.toLocaleString(),
              currency,
            })}
          </Price>

          <Actions className="actions">
            {!item.included && (
              <Button onClick={deleteHandler}>
                <CloseIcon />
              </Button>
            )}
          </Actions>
        </WrapperInner>
      </Wrapper>
      <OrderItemDependencyWarning
        orderItem={item}
        pl={5}
        pr={5}
        pt={2}
        pb={2}
      />
    </>
  );
};

export default Row;
