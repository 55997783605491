import { Serializable } from './Serializable';
import {
  type ITEPortal_Contracts_Enums_OrderStatus,
  type ITEPortal_Domain_Dto_OrderDto,
  type ITEPortal_Domain_Dto_OrderItemDto,
} from '../api';
import { isNil } from '../utils/is-nil';
import { ORDER_STATUSES } from '../constants/order';

// prettier-ignore
export class IBaseOrder
  extends Serializable
  implements ITEPortal_Domain_Dto_OrderDto {
  exhibitionId?: number;
  exhibitorId?: number;
  standId?: number;
  orderId?: number | null;
  products?: Array<ITEPortal_Domain_Dto_OrderItemDto> | null;
  personalDiscount?: number;
  orderStatus?: ITEPortal_Contracts_Enums_OrderStatus;
  orderSummary?: number;
  currency?: string | null;
  typeOfDiscount?: ITEPortal_Domain_Dto_OrderDto['typeOfDiscount'] = 0;
  markup?: number;
  removeExtraCharge?: boolean | null;
  personalExtraChargePercentage?: number | null;
  
  constructor(order: ITEPortal_Domain_Dto_OrderDto) {
    super();

    Object.assign(this, order);

    // this.personalDiscount = 15.1
    // this.typeOfDiscount = 1
  }

  public get hasDiscount() {
    return !isNil(this.personalDiscount) && this.personalDiscount > 0
  }

  public get hasPersonalExtraCharge() {
    return !isNil(this.personalExtraChargePercentage) && this.personalExtraChargePercentage > 0
  }

  public get commonExtraChargeDisabled() {
    return this.removeExtraCharge === true
  }

  public setDiscount(discount: number) {
    if (typeof discount === 'number' && Number.isFinite(discount)) {
      this.personalDiscount = discount
    } else {
      this.personalDiscount = discount
    }

    return this;
  }

  public setDiscountType(type: ITEPortal_Domain_Dto_OrderDto['typeOfDiscount']) {
    this.typeOfDiscount = type

    return this;
  }

  public get isDraft() {
    return this.orderStatus === ORDER_STATUSES.Draft
  }

  toJSON(): unknown {
    return {};
  }
}
