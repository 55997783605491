import styled from 'styled-components';
import { Select } from '@chakra-ui/react';
import { BadgeItem } from '@/components/OrderTableRow/OrderTableRow';
import { FieldProps } from './interfaces';
import {
  FieldValues,
  Path,
  useController,
  useFormContext,
} from 'react-hook-form';

const OrderTableRowContainerBadgeComponent = styled(Select)`
  //padding: 5px 0;
  width: 170px !important;
`;

interface BadgeDataProp extends FieldProps {
  options?: BadgeItem[];
}

const SelectComponent: React.FC<BadgeDataProp> = ({
  options,
  placeholder,
  settings,
  name = 'select',
}) => {
  const formContext = useFormContext();

  if (!formContext) {
    throw new Error(
      `This field (${name}) should be used only within FormContext`
    );
  }

  const { fieldState } = useController({
    name: name,
  });

  if (!fieldState) {
    throw new Error(
      'fieldState not found. Is this field used within FormContext?'
    );
  }

  const registerFieldName = formContext.register(name as Path<FieldValues>, {
    onChange: () => {
      formContext.clearErrors(name);
    },
    ...settings,
  });

  return (
    <OrderTableRowContainerBadgeComponent
      rootProps={{ width: '170px' }}
      defaultValue=""
      {...registerFieldName}
    >
      <option value="" disabled>
        {placeholder}
      </option>
      {options?.map(({ key, value }) => {
        return (
          <option value={key} key={key}>
            {value}
          </option>
        );
      })}
    </OrderTableRowContainerBadgeComponent>
  );
};

export default SelectComponent;
