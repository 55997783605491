import {
  FieldValues,
  Path,
  useController,
  useFormContext,
} from 'react-hook-form';
import {
  Checkbox as BaseCheckbox,
  CheckboxProps as BaseCheckboxProps,
} from '@chakra-ui/react';

interface CheckboxProps<T extends FieldValues = FieldValues>
  extends Omit<BaseCheckboxProps, 'name'> {
  name: Path<T>;
}

export const Checkbox = <T extends FieldValues = FieldValues>(
  props: CheckboxProps<T>
) => {
  const { onChange, name } = props;
  const formContext = useFormContext();

  if (!formContext) {
    throw new Error(
      `This field (${name}) should be used only within FormContext`
    );
  }

  const { fieldState, field } = useController({
    name: name,
  });

  if (!fieldState) {
    throw new Error(
      'fieldState not found. Is this field used within FormContext?'
    );
  }

  const registeredFieldProps = formContext.register(name as Path<T>, {
    onChange: (event) => {
      if (typeof onChange === 'function') {
        onChange(event);
      }

      formContext.clearErrors(name);
    },
  });

  return (
    <>
      <BaseCheckbox
        colorScheme="red"
        id={name}
        {...props}
        {...registeredFieldProps}
        isChecked={field.value}
        isInvalid={!!fieldState.error}
      />
      {/*  todo add error handling */}
    </>
  );
};
