import { FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect } from 'react';
import { ModalFactory } from '@/components/Modal/api';
import { Stack } from '@chakra-ui/react';
import Input from '@/components/Fields/Input';
import { useTranslation } from 'react-i18next';
import { type ITEPortal_Domain_Dto_Exhibitor_GetExhibitorEmployeeDto } from '@/api';
import {
  EmployeeFormValues,
  EmployeeFormSchema,
} from '@/pages/AdminPanel/PersonalAccounts/UsersTab/types';

export const AddNewEmployeeForm = (props: {
  model?: ITEPortal_Domain_Dto_Exhibitor_GetExhibitorEmployeeDto;
}) => {
  const { model } = props;
  const { t } = useTranslation();
  const instance = useForm<EmployeeFormValues>({
    resolver: zodResolver(EmployeeFormSchema),
    mode: 'onChange',
  });

  useEffect(() => {
    ModalFactory.updateContext(instance);
  }, [instance]);

  useEffect(() => {
    if (model && model.email && model.name) {
      instance.reset({
        id: model.employeeId,
        email: model.email,
        name: model.name,
        phoneNumber: model.phoneNumber,
      });
    } else {
      instance.reset({
        name: '',
      });
    }
  }, [model]);

  return (
    <FormProvider {...instance}>
      <Stack spacing={4}>
        <Input<EmployeeFormValues>
          name="email"
          placeholder={t('employee-email')}
          maxWidth="unset"
        />
        <Input<EmployeeFormValues>
          name="name"
          label={t('employee-name')}
          maxWidth="unset"
        />
        <Input<EmployeeFormValues>
          name="phoneNumber"
          type="tel"
          label={t('phone')}
          maxWidth="unset"
        />
      </Stack>
    </FormProvider>
  );
};
