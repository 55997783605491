import { Route } from '@tanstack/react-router';
import { cabinetRoute } from '../protected';

import { MembersDirectory } from '../../../pages/AdminPanel/MembersDirectory/MembersDirectory';
import { SearchBoxOptionsType } from '../../../components/AdminSearch/types';
import i18n from '../../../i18n';
import { ManageExhibitorUsers } from '@/pages/AdminPanel/MembersDirectory/ManageExhibitorUsers';
import { typedAxios } from '@/api/typed-axios';
import { Loader } from '@/components/Loader/Loader';
import { routerRBACGuard } from '@/features/Routing/guard';

export const memberDirectoryRoutesRoot = new Route({
  path: '/member-directory',
  getParentRoute: () => cabinetRoute,
  beforeLoad: async ({ context: { user } }) => {
    await routerRBACGuard(user, 'canAccessAdminCompanies');

    return { page: 'member-directory' };
  },
});

export const memberDictionaryRoute = new Route({
  path: '/',
  getParentRoute: () => memberDirectoryRoutesRoot,
  component: () => <MembersDirectory />,
  beforeLoad: () => {
    return {
      searchOptions: [
        {
          placeholder: i18n.t('search-exhibitors'),
        } satisfies SearchBoxOptionsType,
      ],
    };
  },
});

export const viewExhibitorRoute = new Route({
  path: '/$exhibitorId',
  getParentRoute: () => memberDirectoryRoutesRoot,
  component: () => <ManageExhibitorUsers />,
  pendingComponent: () => <Loader centralized />,
  beforeLoad: async (opts) => {
    const {
      params: { exhibitorId },
    } = opts;

    const exhibitor = await typedAxios.exhibitor.getExhibitors({
      id: +exhibitorId,
    });

    return {
      pageTitle: i18n.t('company-employees-title', {
        companyName: exhibitor.name,
      }),
      exhibitor,
    };
  },
  loader: async ({ context: { exhibitor } }) => {
    return {
      exhibitor,
    };
  },
});

export const memberDirectoryRoutesTree = memberDirectoryRoutesRoot.addChildren([
  memberDictionaryRoute,
  viewExhibitorRoute,
]);
