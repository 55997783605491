export const attempt = <T = unknown>(
  fn: () => T | null,
  options?: { silent: boolean }
) => {
  try {
    return fn();
  } catch (e) {
    if (options?.silent && process.env.NODE_ENV === 'development') {
      console.warn(e);
    }

    return null;
  }
};
