import { ContentInner, ContentWithSidebars } from '@/components/Content';
import { PageTitle } from '@/components/Typography/PageTitle';
import CategoriesSidebar from '@/components/Sidebar/CategoriesSidebar';
import Breadcrumbs from '@/components/Breadcrumbs';
import ServiceFactory from '@/components/Forms/Services/ServiceFactory';
import OrderListSidebar from '@/components/Sidebar/OrderList';
import styled from 'styled-components';
import { IBreadcrumbItem } from '@/model/common';
import { useTranslation } from 'react-i18next';
import { CREATE_EXHIBITION_ORDER_PATH } from '@/constants/router';
import { replacePaths } from '@/utils/route';
import { getBreadcrumbsByCategory } from '@/utils/breadcrumbs';
import Layout from '@/layout/Default';
import { useCategoriesByData } from '@/hooks/categoriesConverter/useCategoriesByData';
import { createExhibitionOrderServiceRoute } from '../../features/Routing/protected/exhibitions-by-role';
import { useCatalog } from '@/store/effector/catalog';
import { Loader } from '@/components/Loader/Loader';

const StyledContentInner = styled(ContentInner)`
  padding: 26px 24px;
`;

const Service = () => {
  const { t } = useTranslation();
  const { rootCategories, flatMap, isLoading } = useCatalog();

  const exhibitionCategoriesObject = useCategoriesByData(rootCategories);
  // const exhibitionSubCategoriesObject = useSubCategoriesByData(subCategories);
  const { exhibitionId, stand, service } =
    createExhibitionOrderServiceRoute.useParams();

  const categoryInfo = flatMap.get(service); //useProductCategory(mergedCategories, service);
  const category = categoryInfo;
  const description = categoryInfo?.description || '';

  if (isLoading) {
    return <Loader centralized />;
  }

  if (!category) {
    // todo update it
    return <>not found</>;
  }

  const breadcrumbsItems = () => {
    return [
      {
        label: t('technicalServices'),
        link: replacePaths(CREATE_EXHIBITION_ORDER_PATH, {
          ':exhibitionId': exhibitionId,
          ':stand': stand,
        }),
      },
      ...getBreadcrumbsByCategory(category, exhibitionId, stand),
    ] as IBreadcrumbItem[];
  };

  return (
    <Layout>
      <CategoriesSidebar categories={exhibitionCategoriesObject} />
      <OrderListSidebar />
      <ContentWithSidebars>
        <StyledContentInner>
          <Breadcrumbs items={breadcrumbsItems()} />
          <PageTitle>{category.displayName}</PageTitle>
          <ServiceFactory
            category={category}
            description={description}
            // subCategoryId={subCategory?.parent?.id}
          />
        </StyledContentInner>
      </ContentWithSidebars>
    </Layout>
  );
};

export default Service;
