import { IdType } from './common';
import { Category } from './ICategory';
import { ISerialisable, Serializable } from './Serializable';
import i18n from '../i18n';
import { Nullable, ProductId } from '@/types/support-types';
import {
  ITEPortal_Contracts_Enums_BuildingType,
  type ITEPortal_Domain_Dto_PriceList_IncludedPriceListItemDto,
  ITEPortal_Domain_Dto_PriceListItemSummary,
} from '@/api';
import { ProductUtils } from '@/utils/product-utils';

export interface IProduct extends ISerialisable {
  id: IdType;
  name: string;
  price: number;
  formattedPrice: string;
  category: Category;
  displayName?: Nullable<string>;
  categoryId?: IdType;
  description?: Nullable<string>;
  imageUrls?: string[];
  imageDescription?: Nullable<string>;
  buildingTypes?: ITEPortal_Contracts_Enums_BuildingType[] | null;
  fieldType?: Nullable<string>;
  linkedProducts?: Array<IProduct> | null;
  linkedZeroPriceProducts?: Array<IProduct> | null;
}

// prettier-ignore
export class Product
  extends Serializable
  implements ITEPortal_Domain_Dto_PriceListItemSummary {
  id: number = 0;
  additionalNotes: string | null = null;
  buildingTypes: Array<ITEPortal_Contracts_Enums_BuildingType> | null = null;
  categoryDisplayName: string | null = null;
  categoryId: number = 0;
  categoryName: string | null = null;
  description: string | null = null;
  fieldType: string | null = null;
  formattedPrice: string | null = null;
  imageDescription: string | null = null;
  imageUrls: Array<string> | null = null;
  linkedProducts: Array<Product> | null = null;
  includedPriceListItems: Array<Product> | null = null;
  price: number = 0;
  productDisplayName: string | null = null;
  productId: number = 0;
  productName: string | null = null;
  quantity: number = 0;

  category: Category = Category.empty();

  constructor(
    opts: ITEPortal_Domain_Dto_PriceListItemSummary & { category?: Category, quantity?: number }
  ) {
    super();
    const { category, linkedProducts, includedPriceListItems } = opts;

    Object.assign(this, opts);

    if (category instanceof Category) {
      this.category = category;
    } else {
      this.category = Category.empty();
    }

    if (Array.isArray(linkedProducts)) {
      this.linkedProducts = linkedProducts.map((json) =>
        Product.fromJSON(json)
      );
    }

    if (Array.isArray(includedPriceListItems)) {
      this.includedPriceListItems = includedPriceListItems.map((json) =>
        Product.linkedFromJSON(json)
      );
    }
  }

  getProductId(): ProductId {
    return this.productName as ProductId;
  }

  get unit() {
    if (!this.category.unitOfMeasure) {
      return undefined;
    }

    // @ts-expect-error todo uncomment
    return i18n.t(this.category.unitOfMeasure) || undefined;
  }

  get amountStep() {
    if (!this.category.isDecimal) {
      return 1;
    }

    return this.category.unitOfMeasure === 'sqm' ? 0.25 : 0.1;
  }

  get hasLinkedProducts() {
    return Array.isArray(this.linkedProducts) && this.linkedProducts.length > 0;
  }

  toJSON() {
    return this;
  }

  static fromJSON(
    json: ITEPortal_Domain_Dto_PriceListItemSummary & { category?: Category }
  ) {
    const { category, ...rest } = json;

    return ProductUtils.createProductFromPriceListItem(rest, { category });
  }

  static linkedFromJSON(
    json: ITEPortal_Domain_Dto_PriceList_IncludedPriceListItemDto & { category?: Category }
  ) {
    const { category, productName, ...rest } = json;

    let includedProductName = productName;

    if (includedProductName && !includedProductName.includes('-included')) {
      includedProductName = `${includedProductName}-included`
    }

    return ProductUtils.createProductFromPriceListItem({ ...rest, productName: includedProductName }, { category });
  }

  setLinkedItems(includedPriceListItems: Array<ITEPortal_Domain_Dto_PriceList_IncludedPriceListItemDto>) {
    this.includedPriceListItems = includedPriceListItems.map((json) =>
      Product.linkedFromJSON(json)
    );
  }
}
