import {
  filesStorageApi,
  useFilesStorage,
} from '@/pages/Exhibition/FilesStorage/filesStorageApi';
import { useQuery } from '@tanstack/react-query';
import { typedAxios } from '@/api/typed-axios';
import {
  Button,
  Card,
  CardBody,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { AsyncButton } from '@/components/AsyncButton';
import { useTranslation } from 'react-i18next';
import React, { useRef } from 'react';
import { toast } from 'react-toastify';
import { queryClient } from '@/features/query';
import { LoaderWrapper } from '@/components/Loader/Loader';
import fetchApi from '@/utils/fetchAPI';
import { FileUtils } from '@/utils/file-utils';
import type { ITEPortal_Domain_Dto_Stand_GetStandFileDto } from '@/api';
import { DeleteIcon } from '@chakra-ui/icons';
import { DownloadFileLink } from '@/components/DownloadFileLink';

type FilesStoragePropsType = {
  canUploadNewFiles: boolean;
};

export const FilesStorage = (props: FilesStoragePropsType) => {
  const { canUploadNewFiles } = props;
  const inputRef = useRef<HTMLInputElement>(null);

  const { t } = useTranslation();
  const { stand } = useFilesStorage();

  const hasStandId = typeof stand?.standId === 'number';

  const { isFetching, data = [] } = useQuery({
    queryKey: ['stand-files-storage', stand?.standId],
    enabled: hasStandId,
    queryFn: async () => {
      if (!stand?.standId) {
        return [];
      }

      return typedAxios.stand.getStandsFiles({
        standId: stand.standId,
      });
    },
  });

  const handleUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!canUploadNewFiles) {
      return;
    }

    try {
      const file = e.currentTarget.files?.[0];

      if (!stand?.standId || !(file instanceof File)) {
        return;
      }

      await typedAxios.stand.putStandsFiles({
        standId: stand?.standId,
        formData: {
          file,
        },
      });

      await queryClient.refetchQueries({
        queryKey: ['stand-files-storage', stand?.standId],
      });

      toast.success(t('file-upload-success'));
    } catch {
      toast.error(t('error-occurred'));
    }
  };

  const handleClose = () => {
    filesStorageApi.reset();
  };

  const handleDownloadArchive = async () => {
    if (!stand?.standId) {
      return;
    }

    const { data: stream } = await fetchApi(`stands/${stand?.standId}/zip`, {
      method: 'GET',
      responseType: 'blob',
    });

    FileUtils.saveBlobAsFile(stream, `stand-files-${stand.standId}.zip`);
  };

  const handleFileDelete =
    (file: ITEPortal_Domain_Dto_Stand_GetStandFileDto) => async () => {
      if (!stand?.standId) {
        return;
      }

      try {
        await typedAxios.stand.deleteStandsFiles({
          standId: stand?.standId,
          fileId: file.id,
        });
        await queryClient.refetchQueries({
          queryKey: ['stand-files-storage', stand?.standId],
        });

        toast.success(t('file-delete-success'));
      } catch {
        toast.error(t('error-occurred'));
      }
    };

  return (
    <Drawer
      size="lg"
      isOpen={hasStandId}
      placement="right"
      onClose={handleClose}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>
          {t('stand-files', { standNumber: stand?.standNumber })}
        </DrawerHeader>
        <DrawerBody>
          <LoaderWrapper
            loading={isFetching}
            display="flex"
            flexDirection="column"
          >
            {canUploadNewFiles && (
              <>
                <input
                  ref={inputRef}
                  type="file"
                  hidden
                  onChange={handleUpload}
                />
                <AsyncButton
                  marginLeft="auto"
                  colorScheme="blue"
                  mt={2}
                  mb={4}
                  onClick={() => {
                    inputRef.current?.click();
                  }}
                >
                  {t('upload')}
                </AsyncButton>
              </>
            )}

            {data.length > 0 && (
              <Card>
                <CardBody>
                  <TableContainer>
                    <Table variant="simple">
                      <Thead>
                        <Tr>
                          <Th>{t('file')}</Th>
                          <Th>{t('delete')}</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {data.map((file) => {
                          return (
                            <Tr key={file.fileName}>
                              <Td>
                                <DownloadFileLink
                                  attachmentUrl={file.fileUrl}
                                  fileName={file.fileName}
                                  withIcon={false}
                                  color="inherit"
                                  fontSize="sm"
                                />
                              </Td>
                              <Td isNumeric>
                                <DeleteIcon
                                  cursor="pointer"
                                  onClick={handleFileDelete(file)}
                                />
                              </Td>
                            </Tr>
                          );
                        })}
                      </Tbody>
                    </Table>
                  </TableContainer>
                </CardBody>
              </Card>
            )}

            {!isFetching && data.length === 0 && (
              <Text color="gray.500">{t('no-files-uploaded')}</Text>
            )}
          </LoaderWrapper>
        </DrawerBody>

        <DrawerFooter>
          <Button
            variant="outline"
            colorScheme="red"
            mr={3}
            onClick={handleClose}
          >
            {t('cancel')}
          </Button>
          <AsyncButton
            variant="outline"
            colorScheme="red"
            mr={3}
            onClick={handleDownloadArchive}
          >
            {t('download-files-archive')}
          </AsyncButton>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};
