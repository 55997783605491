import {
  Card as ChakraCard,
  CardBody,
  CardFooter,
  CardHeader,
} from '@chakra-ui/react';
import { ReactNode } from 'react';
import styled from 'styled-components';

export interface CardProps {
  className?: string;
  header?: ReactNode;
  children: ReactNode;
  footer?: ReactNode;
  width?: string | number;
}

const ChakraCardWrapper = styled.div<{
  width: number | string;
}>`
  width: ${({ width }) => width};
`;

const ChakraCardContainer = styled(ChakraCard)`
  border-radius: 12px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.06);

  &.animated-card {
    transition: all ease-in-out 0.3s;
    transform: translateY(0);
  }

  &.animated-card:hover {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
      0 2px 4px -1px rgba(0, 0, 0, 0.06);
  }
`;

const ChakraCardTitle = styled(CardHeader)`
  font-size: 20px;
  font-weight: 600;
  padding-left: 24px;
  padding-top: 26px;
`;

const ChakraCardFooter = styled(CardFooter)`
  display: flex;
  justify-content: end;
`;

export const Card = ({
  className,
  header,
  children,
  footer,
  width = 'auto',
}: CardProps): JSX.Element => {
  return (
    <ChakraCardWrapper width={width}>
      <ChakraCardContainer className={className}>
        {header && <ChakraCardTitle>{header}</ChakraCardTitle>}
        <CardBody>{children}</CardBody>
        {footer && <ChakraCardFooter>{footer}</ChakraCardFooter>}
      </ChakraCardContainer>
    </ChakraCardWrapper>
  );
};
