import { toast } from 'react-toastify';
import { typedAxios } from '../../../../../api/typed-axios';
import { ModalFactory } from '../../../../../components/Modal/api';
import { useTranslation } from 'react-i18next';
import { TextArea } from '../../../../../components/TextArea/TextArea';
import { FormProvider, useForm } from 'react-hook-form';
import * as v from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import i18n from '../../../../../i18n';
import { Nullable } from '../../../../../types/support-types';

const Schema = v.object({
  comment: v.string().min(1, {
    message: i18n.t('required-field'),
  }),
});

type FormValues = v.infer<typeof Schema>;

export const useSendOrderEmail = (orderId: Nullable<string>) => {
  const { t } = useTranslation();

  const instance = useForm<FormValues>({
    resolver: zodResolver(Schema),
  });

  const sendEmail = async (values: FormValues) => {
    try {
      if (!orderId) {
        return;
      }

      await typedAxios.order.postOrdersSendOrderChangedConfirmation({
        orderId: +orderId,
        requestBody: values.comment,
      });

      toast.success(t('success-email'));

      ModalFactory.close();
      instance.reset();
    } catch (error: any) {
      const fieldErrors = error?.response?.data?.errors;

      if (fieldErrors) {
        toast.error(fieldErrors);
      }
    }
  };

  const requestSendOrderEmail = () => {
    if (!orderId) {
      return;
    }

    ModalFactory.show({
      title: t('order-comments'),
      Component: () => (
        <FormProvider {...instance}>
          <TextArea<FormValues> name="comment" />
        </FormProvider>
      ),
      okLabel: t('send-email'),
      onOk: async () => {
        return instance.handleSubmit(sendEmail)();
      },
    });
  };

  return {
    requestSendOrderEmail,
  };
};
