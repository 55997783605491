import { MarginType } from '@/model/common';
import { IExhibitionSummary } from '@/model/IExhibitionSummary';
import { typedAxios } from '@/api/typed-axios';
import { exhibitionApi } from '@/store/effector/exhibition';
import { IExhibition } from '@/model/IExhibition';

export function useExhibitionDetailedPreload() {
  const preloadExhibition = async (exhibition: IExhibitionSummary) => {
    if (!exhibition.exhibitionSummary?.id) {
      return;
    }

    try {
      const data = await typedAxios.exhibition.getExhibitions({
        id: exhibition.exhibitionSummary.id,
      });

      const margins: MarginType = {
        firstMargin: data.firstMargin,
        firstMarginDate: data.firstMarginDate,
        secondMargin: data.secondMargin,
        secondMarginDate: data.secondMarginDate,
        thirdMargin: data.thirdMargin,
        thirdMarginDate: data.thirdMarginDate,
      };

      const exhibitionNewInstance = new IExhibition({
        id: exhibition.exhibitionSummary.id,
        ...exhibition,
      });

      exhibitionApi.prepare({
        exhibitionName: exhibition.exhibitionSummary.name,
        margin: margins,
        sellingOfficeId: exhibition.sellingOfficeId || 0,
        currency: exhibition.currency,
        exhibitionId: exhibition.exhibitionSummary.id,
        exhibition: exhibitionNewInstance,
      });
    } catch (error: any) {}
  };

  return {
    preloadExhibition,
  };
}
