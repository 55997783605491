import { Category } from '@/model/ICategory';

export const useCategoriesByData = (categoriesData: Category[]) => {
  return categoriesData.map((category) => {
    return {
      category: category,
      image: category.imageUrl,
      id: category.id,
      description: category.description,
    };
  });
};
