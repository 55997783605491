/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { WebApi_ViewModels_Auth_Login } from '../models/WebApi_ViewModels_Auth_Login';
import type { WebApi_ViewModels_Login_AuthCodeRequest } from '../models/WebApi_ViewModels_Login_AuthCodeRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class AuthService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns any Created
     * @throws ApiError
     */
    public postAuthEmail({
        requestBody,
    }: {
        requestBody?: WebApi_ViewModels_Auth_Login,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/auth/email',
            body: requestBody,
            mediaType: 'application/json-patch+json',
            errors: {
                400: `Bad Request`,
                500: `Server Error`,
            },
        });
    }

    /**
     * @returns any Success
     * @throws ApiError
     */
    public postAuthToken({
        requestBody,
    }: {
        requestBody?: WebApi_ViewModels_Login_AuthCodeRequest,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/auth/token',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @returns any Success
     * @throws ApiError
     */
    public getAuthRefreshToken(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/auth/refresh-token',
        });
    }

}
