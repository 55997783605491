import { OptionType } from '../model/common';
import { isNil } from './is-nil';

export const isOption = (
  value: OptionType | undefined | null
): value is OptionType => {
  return !isNil(value?.value) && !isNil(value?.label);
};

export const isArrayOfOptions = (
  value: unknown
): value is Array<OptionType> => {
  if (Array.isArray(value)) {
    return value.every((opt) => !isNil(opt.value));
  }

  return false;
};
