import { createApi, createStore } from 'effector';
import { useUnit } from 'effector-react';

type StandProjectStateType = {
  standId: number | null;
  projectId?: number | null;
};

const $standProject = createStore<StandProjectStateType>({
  standId: null,
  projectId: null,
});

export const standProjectApi = createApi($standProject, {
  setStandId: (state, payload: StandProjectStateType) => {
    return {
      ...state,
      ...payload,
    };
  },
  reset: () => {
    return {
      standId: null,
      projectId: null,
    };
  },
});

export const useStandProject = () => useUnit($standProject);
