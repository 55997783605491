import { HStack, Input, InputProps, Text, useCounter } from '@chakra-ui/react';
import { AddIcon, MinusIcon } from '@chakra-ui/icons';
import { AmountWrapper, CounterButtonDec, CounterButtonInc } from './styled';
import { MathUtils } from '../../utils/math-utils';
import { ButtonHTMLAttributes, DetailedHTMLProps } from 'react';
const minValue = 0;
const maxValue = 9999;

interface AmountCounterProps {
  amountCounterValue: string | number | undefined;
  onChange?: (counterValue: number) => void;
  suffix?: string;
  disabled?: boolean;
  precision?: number;
  step?: number;
}

export const AmountCounter = ({
  amountCounterValue,
  onChange,
  disabled = false,
  suffix,
  step = 1,
  precision = 0,
}: AmountCounterProps) => {
  useCounter({});
  const {
    increment,
    decrement,
    update,
    value: controlledValue,
  } = useCounter({
    step,
    defaultValue: amountCounterValue,
    min: minValue,
    max: maxValue,
    precision,
    keepWithinRange: true,
    onChange(_: string, valueAsNumber: number) {
      if (valueAsNumber > 0) {
        if (step === 0.25 && valueAsNumber % step !== 0) {
          onChange?.(MathUtils.roundToNearestQuarter(valueAsNumber));
        } else {
          onChange?.(valueAsNumber);
        }
      }
    },
  });

  const inc = {
    onClick: () => {
      increment(step);
    },
  } satisfies DetailedHTMLProps<
    ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  >;

  const dec = {
    onClick: () => {
      decrement(step);
    },
  } satisfies DetailedHTMLProps<
    ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  >;

  const input = {
    onChange: (e) => {
      if (step < 1) {
        update((e.target.value || '').replace(/[^0-9.,]/g, ''));
      } else {
        if (
          typeof e.target.value === 'string' &&
          e.target.value.match(/\.|,/)
        ) {
          return;
        }

        if (
          typeof e.target.value === 'string' &&
          e.target.value.startsWith('0')
        ) {
          update((e.target.value || '').slice(1));

          return;
        }

        update((e.target.value || '').replace(/[^0-9]/g, ''));
      }
    },
    onBlur: (event) => {
      if (step !== 0.25) {
        return;
      }

      const value = event.target.value;

      const valueAsNumber = parseFloat(value);

      if (
        typeof valueAsNumber === 'number' &&
        !Number.isNaN(valueAsNumber) &&
        valueAsNumber % 0.25 !== 0
      ) {
        update(MathUtils.roundToNearestQuarter(valueAsNumber));
      }
    },
    value: controlledValue,
  } satisfies InputProps;

  return (
    <AmountWrapper lang="ar-EG" suffix={suffix} disabled={disabled}>
      <HStack maxW="320px">
        <CounterButtonDec
          {...dec}
          type="button"
          suffix={suffix}
          disabled={disabled}
          aria-label="decrement"
        >
          <MinusIcon boxSize={3} />
        </CounterButtonDec>
        <Input
          {...input}
          textAlign="center"
          min={minValue}
          // maxLength={4}
          height="28px"
          maxWidth={!suffix ? '35px' : '60px'}
          variant="unstyled"
          disabled={disabled}
          role="textbox"
        />
        {suffix && <Text>{suffix}</Text>}
        <CounterButtonInc
          {...inc}
          type="button"
          suffix={suffix}
          disabled={disabled}
          aria-label="increment"
        >
          <AddIcon boxSize={3} />
        </CounterButtonInc>
      </HStack>
    </AmountWrapper>
  );
};
