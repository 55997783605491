import { UsersTableActions } from './Actions';
import { Column } from 'react-table';
import { ITEPortal_Domain_Dto_Exhibitor_GetExhibitorEmployeeDto } from '@/api';
import { viewExhibitorRoute } from '@/features/Routing/protected/member-directory';
import { Table } from '@/components/Table/Table';
import { Card } from '@/components/Card/Card';
import { Button, Flex } from '@chakra-ui/react';
import i18n from '@/i18n';
import { useSaveEmployee } from '@/pages/AdminPanel/MembersDirectory/ManageExhibitorUsers/hooks/use-save-employee';
import dayjs from 'dayjs';

const columns = [
  {
    Header: i18n.t('employee-name'),
    accessor: 'name',
  },
  {
    Header: 'Email',
    accessor: 'email',
  },
  {
    Header: i18n.t('phone-number'),
    accessor: 'phoneNumber',
  },
  {
    Header: i18n.t('creation-date'),
    accessor: 'createdDate',
    Cell: ({ value }) => {
      if (value && dayjs(value).isValid()) {
        return <>{dayjs(value).format('DD.MM.YYYY')}</>;
      }

      return <>-</>;
    },
  },
  {
    Header: i18n.t('last-visit-date'),
    accessor: 'lastVisitDate',
    Cell: ({ value }) => {
      console.log(dayjs(value).isValid(), value);
      if (value && dayjs(value).isValid()) {
        return <>{dayjs(value).format('DD.MM.YYYY')}</>;
      }

      return <>-</>;
    },
  },
  {
    id: 'actions',
    width: 90,
    Cell: ({ row }) => {
      return <UsersTableActions row={row} />;
    },
  },
] satisfies Array<
  Column<ITEPortal_Domain_Dto_Exhibitor_GetExhibitorEmployeeDto>
>;

export const ManageExhibitorUsers = () => {
  const { exhibitor } = viewExhibitorRoute.useLoaderData();
  const { openForm } = useSaveEmployee();

  return (
    <Flex direction="column" gap={4}>
      <Button ml="auto" onClick={() => openForm()}>
        + {i18n.t('add-employee')}
      </Button>
      <Card>
        <Table columns={columns} data={exhibitor.exhibitorEmployees || []} />
      </Card>
    </Flex>
  );
};
