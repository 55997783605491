import { FormProvider, useForm, UseFormReturn } from 'react-hook-form';
import * as v from 'zod';
import { useModalFactoryContextProvider } from '../../../components/Modal/api';
import { zodResolver } from '@hookform/resolvers/zod';
import { TextArea } from '../../../components/TextArea/TextArea';
import { useTranslation } from 'react-i18next';
import { SpaceOnlyStandOrderCommentsFormSchema } from './SpaceOnlyStandOrderCommentsForm';
import Input from '../../../components/Fields/Input';
import i18n from '../../../i18n';
import { Stack } from '@chakra-ui/react';
import { SelectComponent } from '@/components/MultiSelect/MultiSelect';
import { CARPET_COLORS, FASCIA_INSCRIPTION_COLORS } from '@/i18n/lang/colors';

const OptionSchema = v.object(
  {
    value: v.coerce.string(),
    label: v.string(),
  },
  {
    required_error: i18n.t('required-field'),
  }
);

const Schema = v
  .object({
    fasciaInscription: v
      .string()
      .min(1, i18n.t('required-field'))
      .max(500, i18n.t('exceed-n-characters', { n: 500 })),
    fasciaInscriptionColor: OptionSchema,
    carpetColor: OptionSchema,
  })
  .merge(SpaceOnlyStandOrderCommentsFormSchema);

export type ExtendedStandOrderCommentsFormValuesType = v.infer<typeof Schema>;

export type ExtendedStandOrderCommentsFormType =
  UseFormReturn<ExtendedStandOrderCommentsFormValuesType>;

export const ExtendedStandOrderCommentsForm = () => {
  const { t } = useTranslation();
  const instance: ExtendedStandOrderCommentsFormType =
    useForm<ExtendedStandOrderCommentsFormValuesType>({
      resolver: zodResolver(Schema),
    });

  useModalFactoryContextProvider(instance);

  return (
    <FormProvider {...instance}>
      <Stack spacing={5}>
        <Input<ExtendedStandOrderCommentsFormValuesType>
          name="fasciaInscription"
          label={t('fascia-inscription')}
          maxWidth="unset"
          isRequired
        />
        <SelectComponent<ExtendedStandOrderCommentsFormValuesType>
          name="fasciaInscriptionColor"
          label={t('fascia-inscription-color')}
          maxWidth="unset"
          isRequired
          options={FASCIA_INSCRIPTION_COLORS}
        />
        <SelectComponent<ExtendedStandOrderCommentsFormValuesType>
          name="carpetColor"
          label={t('carpet-color')}
          maxWidth="unset"
          isRequired
          options={CARPET_COLORS}
        />
        <TextArea<ExtendedStandOrderCommentsFormValuesType>
          name="additionalComments"
          label={t('comment')}
        />
      </Stack>
    </FormProvider>
  );
};
