import {
  ITEPortal_Domain_Dto_AdminExhibitionSummary,
  ITEPortal_Domain_Dto_CategoryDropdownDto,
  ITEPortal_Domain_Dto_ExhibitorDto,
} from '@/api';
import { queryOptions } from '@tanstack/react-query';
import { typedAxios } from '@/api/typed-axios';
import { isNil } from '@/utils/is-nil';
import { CategoryQueryOptionsType } from '@/features/query/query-options/types';
import { IdType, OptionType } from '@/model/common';
import { ITEPortal_Domain_Dto_Bitrix_EventMaster_EventMasterDto } from '@/api/models/ITEPortal_Domain_Dto_Bitrix_EventMaster_EventMasterDto';

export const QueryOptions = {
  standsQueryOptions: (opts: {
    exhibitorId: ITEPortal_Domain_Dto_ExhibitorDto['id'];
    exhibitionId: ITEPortal_Domain_Dto_AdminExhibitionSummary['id'];
  }) =>
    queryOptions({
      queryKey: ['exhibitor-stands', opts],
      queryFn: () =>
        typedAxios.stand.getExhibitionsExhibitorsStands({
          exhibitorId: opts.exhibitorId,
          exhibitionId: opts.exhibitionId,
        }),
    }),
  exhibitorQueryOptions: (opts: {
    exhibitionId: ITEPortal_Domain_Dto_AdminExhibitionSummary['id'];
    exhibitorId?: ITEPortal_Domain_Dto_ExhibitorDto['id'];
  }) =>
    queryOptions({
      queryKey: ['exhibitor', opts],
      queryFn: async () => {
        if (isNil(opts.exhibitorId)) {
          return {} as ITEPortal_Domain_Dto_ExhibitorDto;
        }

        return typedAxios.exhibitor.getExhibitors({
          exhibitionId: opts.exhibitionId,
          id: opts.exhibitorId,
        });
      },
    }),

  loadExhibitorStand: (opts: { standId: number }) => {
    return queryOptions({
      queryKey: ['stands', opts.standId],
      queryFn: async () =>
        await typedAxios.stand.getExhibitorStands({
          standId: opts.standId,
        }),
    });
  },

  loadCategoriesQueryOptions: (opts: CategoryQueryOptionsType) => {
    return queryOptions({
      queryKey: [
        'load-catalog-categories',
        opts.buildingType,
        opts.exhibitionId,
        opts.currency,
      ],
      queryFn: async () => {
        const { buildingType, exhibitionId, currency } = opts;

        if (isNil(buildingType) || isNil(exhibitionId) || isNil(currency)) {
          return [];
        }

        const data = await typedAxios.category.getCategoriesPriceList({
          buildingType: buildingType,
          exhibitionId: +exhibitionId,
          currency: currency ? currency : undefined,
        });

        return data;
      },
    });
  },

  loadPriceListItemsShort: (exceptPriceListId?: IdType) => {
    return queryOptions({
      queryKey: ['price-list-items', exceptPriceListId],
      initialData: [],
      queryFn: async () => {
        const data = await typedAxios.product.getProductsShort({
          exceptPriceListId: exceptPriceListId ? +exceptPriceListId : undefined,
        });

        return data.map((priceListItemOption) => {
          return {
            value: priceListItemOption?.id,
            label: priceListItemOption?.key,
          } satisfies OptionType;
        });
      },
    });
  },

  loadStandProject: (opts: { standId: number | null }) => {
    return queryOptions({
      queryKey: ['stand-project', opts],
      retry: 1,
      queryFn: async () => {
        if (!opts.standId) {
          return null;
        }

        return typedAxios.stand.getStandsProject({
          standId: opts.standId,
        });
      },
    });
  },

  loadCategoryOptions: () => {
    return queryOptions({
      queryKey: ['category-formatted-options'],
      retry: 1,
      queryFn: async () => {
        const categories =
          await typedAxios.category.getCategoriesAdminSummaries();

        return categories.map((ct) => ({
          value: ct.key as string,
          label: ct.value,
          meta: ct,
        })) satisfies Array<
          OptionType<ITEPortal_Domain_Dto_CategoryDropdownDto>
        >;
      },
    });
  },

  loadEventMasterOptions: () => {
    return queryOptions({
      queryKey: ['event-master-formatted-options'],
      retry: 1,
      queryFn: async () => {
        const eventMasters = await typedAxios.bitrix.getBitrixEventMasters();

        return eventMasters.map((em) => ({
          value: em.id,
          label: em.title,
          meta: em,
        })) satisfies Array<
          OptionType<ITEPortal_Domain_Dto_Bitrix_EventMaster_EventMasterDto>
        >;
      },
    });
  },
  loadCompanyOptions: () => {
    return queryOptions({
      queryKey: ['company-options'],
      retry: 1,
      queryFn: async () => {
        const eventMasters = await typedAxios.iteCompany.getCompanies();

        return eventMasters.map((em) => ({
          value: em.id,
          label: em.title,
          meta: em,
        })) satisfies Array<
          OptionType<ITEPortal_Domain_Dto_Bitrix_EventMaster_EventMasterDto>
        >;
      },
    });
  },
  subexponentsQueryOptions: (opts: { standId: number }) =>
    queryOptions({
      queryKey: ['stand-subexponents', opts],
      queryFn: () =>
        typedAxios.stand.getStandsSharers({
          standId: opts.standId,
        }),
    }),

  productsQueryOptions: (opts: { standId: number }) =>
    queryOptions({
      queryKey: ['stand-products', opts],
      queryFn: () =>
        typedAxios.stand.getStandsProducts({
          standId: opts.standId,
        }),
    }),
};
