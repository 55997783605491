import { OrderTableRow } from '@/components/OrderTableRow/OrderTableRow';
import ProductCard from '@/components/Card/ProductCard';
import { ShowMoreText } from '@/components/Forms/Services/TemporaryStaff';
import { useTranslation } from 'react-i18next';
import { Loader } from '@/components/Loader/Loader';
import { LoaderWrapper } from '@/theme/css/styles';
import { Category } from '@/model/ICategory';
import TechnicalServicesGrid from '@/components/TechnicalServicesGrid';
import { useProductsLoader } from './use-products-loader';
import { Box, Text } from '@chakra-ui/react';
import { useVatSettings } from '@/store/effector/vat-settings';

interface ProductItemsCardProps {
  serviceCategory: Category;
}

const ProductItemsCard = ({ serviceCategory }: ProductItemsCardProps) => {
  const serviceSpecification = serviceCategory.id;
  const { t } = useTranslation();
  const { vatMessage } = useVatSettings();

  const { productItems, isLoading } = useProductsLoader({
    categoryId: serviceSpecification,
  });

  if (isLoading) {
    return (
      <LoaderWrapper>
        <Loader />
      </LoaderWrapper>
    );
  }

  return (
    <>
      {vatMessage && (
        <Box marginY={4}>
          <Text color="gray.500">{vatMessage}</Text>
        </Box>
      )}
      {serviceCategory.description && (
        <ShowMoreText
          lines={2}
          keepNewLines
          more={t('showMore')}
          less={t('showLess')}
          anchorClass="show-more-less-clickable"
        >
          {serviceCategory.description}
        </ShowMoreText>
      )}

      {serviceCategory.tree.length > 0 && (
        <Box mb={4}>
          <TechnicalServicesGrid category={serviceCategory} />
        </Box>
      )}

      <ProductCard>
        {productItems.map(({ key, text, params, product }, index) => {
          //Temporary solution

          return (
            <OrderTableRow
              key={key}
              rowData={{
                text,
                params: params || [],
              }}
              product={product}
              withDivider={index !== productItems?.length - 1}
            />
          );
        })}
      </ProductCard>
    </>
  );
};

export default ProductItemsCard;
