import styled from 'styled-components';
import { Box, FormControl } from '@chakra-ui/react';

export const FormControlWrapper = styled(FormControl)`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

export const ParticipantCheckContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 100%;
`;

export const ParticipantCheckWrapper = styled(FormControl)`
  min-width: 100%;
`;
