import {
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { $modalContext, $modalStack, ModalFactory } from './api';
import { useUnit } from 'effector-react';
import { useTranslation } from 'react-i18next';
import { AsyncButton } from '@/components/AsyncButton';

export const ModalFactoryProvider = () => {
  const { t } = useTranslation();
  const modals = useUnit($modalStack);

  if (modals.length === 0) {
    return null;
  }

  return (
    <>
      {modals.map((modal, index) => {
        const shouldUseOverlay = index === 0;

        return (
          <Modal
            key={modal.id || index}
            isOpen={!!modal.isOpened}
            onClose={ModalFactory.close}
            size="xl"
            scrollBehavior="inside"
            isCentered
          >
            {shouldUseOverlay && <ModalOverlay />}
            <ModalContent>
              <ModalHeader>{modal.title}</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <modal.Component />
              </ModalBody>

              <ModalFooter justifyContent="center" alignItems="center">
                <ButtonGroup gap="1" justifyContent="center">
                  <Button
                    variant="outline"
                    colorScheme="red"
                    minW={120}
                    onClick={() => ModalFactory.close(modal.id)}
                  >
                    {modal.cancelLabel || t('cancel')}
                  </Button>
                  {modal.okLabel && (
                    <AsyncButton
                      minW={120}
                      colorScheme="red"
                      onClick={async () => {
                        return modal.onOk?.($modalContext.getState());
                      }}
                    >
                      {modal.okLabel}
                    </AsyncButton>
                  )}
                </ButtonGroup>
              </ModalFooter>
            </ModalContent>
          </Modal>
        );
      })}
    </>
  );
};
