import React, { useEffect, useRef } from 'react';
import { Link, useParams } from '@tanstack/react-router';
import Sidebar from './Sidebar';
import styled from 'styled-components';
import { replacePaths } from '@/utils/route';
import {
  CREATE_EXHIBITION_ORDER_TAB_PATH,
  SHOW_SUPPORT_MANAGER_ADDITIONAL_PATH,
  SHOW_SUPPORT_MANAGER_PATH,
} from '@/constants/router';
import SimpleBarCore from 'simplebar-core';
import classNames from 'classnames';
import { ITechnicalService } from '@/model/ITechnicalService';
import { Box, Image } from '@chakra-ui/react';
import { useProductCategory } from '@/hooks/useProductCategory';
import { Nullable } from '../../types/support-types';

const StyledLink = styled(Link)`
  background: transparent;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: 12px 0;

  &:first-child {
    margin-top: 12px;
  }

  &:last-child {
    margin-bottom: 12px;
  }

  transition: all ease-in-out 0.15s;

  text-align: center;
  color: #718096;

  &:hover,
  &.active {
    color: var(--chakra-colors-brandColor);

    > svg {
      fill: var(--chakra-colors-brandColor);
    }
  }

  > * {
    z-index: -1;
  }

  > svg {
    max-width: 57px;
    max-height: 57px;

    margin-left: auto;
    margin-right: auto;

    fill: #a0aec0;
    transition: all ease-in-out 0.15s;

    position: relative;
    z-index: -1;
  }
`;
const ServiceName = styled.div`
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;

  position: relative;
  z-index: -1;
`;

const Wrapper = styled.div`
  padding: 0 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const BarTitleWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 80px;
  font-size: 16px;
  font-weight: 600;
  color: #718096;
  text-align: center;
  border-bottom: 1px solid silver;
  border-top: 1px solid silver;
  box-shadow: 0 1px 2px rgb(0 0 0 / 5%);
`;

interface CategoriesSidebarProps {
  categories: ITechnicalService[];
  type?: 'adminPanel' | 'order' | 'additionalPanel';
  barTitle?: Nullable<string>;
}

const CategoriesSidebar = ({
  categories,
  type = 'order',
  barTitle,
}: CategoriesSidebarProps) => {
  const { exhibitionId, service, stand } = useParams({ strict: false }) || {};

  const ref = useRef<null | SimpleBarCore>(null);
  const itemRefs = useRef<Record<string, HTMLAnchorElement | null>>({});
  const categoryInfo = useProductCategory(categories, service);
  const category = categoryInfo?.category;

  useEffect(() => {
    setTimeout(() => {
      const target =
        itemRefs.current[(category?.parent?.id || category?.id) as string];

      if (target) {
        const top = target.offsetTop;

        ref.current?.getScrollElement()?.scrollTo({
          top: top > 0 ? top : 0,
          behavior: 'smooth',
        });
      }
    }, 300);
  }, [itemRefs, ref, category]);

  return (
    <Sidebar scrollRef={ref} fixed>
      {barTitle && <BarTitleWrapper>{barTitle}</BarTitleWrapper>}
      <Wrapper>
        {categories.map(({ category: serviceObject, image }) => {
          const serviceCategory = serviceObject.parent || serviceObject;

          const resolvedUlr = serviceCategory.resolveUrl?.();

          const pageUrl =
            type === 'adminPanel'
              ? SHOW_SUPPORT_MANAGER_PATH
              : SHOW_SUPPORT_MANAGER_ADDITIONAL_PATH;

          const link =
            type === 'order'
              ? replacePaths(CREATE_EXHIBITION_ORDER_TAB_PATH, {
                  ':exhibitionId': exhibitionId,
                  ':stand': stand,
                  ':service': serviceCategory.name,
                })
              : replacePaths(pageUrl, {
                  ':page': serviceCategory.name,
                });

          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          return (
            <StyledLink
              // _ref={(el) => {
              //   itemRefs.current[serviceCategory.id] = el;
              // }}
              id={`sidebar-${serviceCategory.id.toString()}`}
              key={serviceCategory.name}
              to={resolvedUlr || link}
              className={classNames({
                active: category?.parent?.name === serviceCategory.name,
              })}
            >
              {image && typeof image === 'string' ? (
                <Image boxSize="50px" src={image} alt={image} />
              ) : (
                image
              )}
              <ServiceName>
                {serviceCategory.displayName ||
                  serviceCategory.name ||
                  serviceCategory.id}
              </ServiceName>
            </StyledLink>
          );
        })}
      </Wrapper>
    </Sidebar>
  );
};

export default CategoriesSidebar;
