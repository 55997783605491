import { CSSProperties } from 'react';
import { DraggingStyle, NotDraggingStyle } from 'react-beautiful-dnd';

export const DraggableUtils = {
  canBeReordered: <T extends { orderIndex?: number | null }>(list: T[]) => {
    return true || list.length > 0;
    // if (list.length === 0 || list.length === 1) {
    //   return false;
    // }
    //
    // const sortedList = list
    //   .filter(
    //     (item) => item.orderIndex !== undefined && item.orderIndex !== null
    //   )
    //   .sort((a, b) => (a.orderIndex || 0) - (b.orderIndex || 0));
    //
    // for (let i = 0; i < sortedList.length - 1; i++) {
    //   if (
    //     sortedList[i + 1].orderIndex === 0 &&
    //     sortedList[i].orderIndex === 0
    //   ) {
    //     return true;
    //   }
    //
    //   if (
    //     (sortedList[i + 1].orderIndex || 0) -
    //       (sortedList[i].orderIndex || 0) !==
    //     1
    //   ) {
    //     return false;
    //   }
    // }
    //
    // return true;
  },
  isBrokenIndexing: <T extends { orderIndex?: number | null }>(
    list: T[]
  ): boolean => {
    const hasAtLeastOneDuplicatedOrderIndex = (lst: T[]) => {
      return !lst
        .map((it) => it.orderIndex)
        .every((oi, index, arr) => arr.indexOf(oi) === index);
    };

    return (
      DraggableUtils.canBeReordered(list) &&
      hasAtLeastOneDuplicatedOrderIndex(list)
    );
  },
  reorder: <T extends { orderIndex?: number | null }>(
    list: T[],
    startIndex: number,
    endIndex: number,
    forceNormalized?: boolean
  ): T[] => {
    if (list.length === 0) {
      return list;
    }

    if (forceNormalized) {
      list.forEach((it, i) => {
        it.orderIndex = i;
      });
    }

    const minIndex = list[0].orderIndex || 0;
    const greatestIndex = list.at(-1)?.orderIndex || list.length - 1;
    const isFullListOrdering = greatestIndex === list.length - 1;
    const hasBrokenIndexing = DraggableUtils.isBrokenIndexing(list);

    if (isFullListOrdering || hasBrokenIndexing) {
      const result = Array.from(list);
      const [removed] = result.splice(startIndex, 1);

      result.splice(endIndex, 0, removed);
      result.forEach((item, index) => {
        item.orderIndex = index;
      });

      return result;
    }

    const ghostStartIndex = (() => {
      switch (true) {
        case startIndex === list.length - 1: {
          return greatestIndex;
        }

        case startIndex > endIndex: {
          return greatestIndex - startIndex;
        }

        default: {
          return minIndex + startIndex;
        }
      }
    })();
    const ghostEndIndex = (() => {
      switch (true) {
        case endIndex === 0: {
          return minIndex;
        }

        case startIndex > endIndex: {
          return greatestIndex - (startIndex - endIndex || 1);
        }

        default: {
          return minIndex + endIndex;
        }
      }
    })();

    const ghostArray = Array(greatestIndex + 1).fill(null);

    list.reverse().forEach((item, i) => (ghostArray[greatestIndex - i] = item));

    const [removed] = ghostArray.splice(ghostStartIndex, 1);

    ghostArray.splice(ghostEndIndex, 0, removed);

    ghostArray.forEach((it, i) => {
      if (it) {
        it.orderIndex = i;
      }
    });

    return ghostArray.filter(Boolean);
  },

  getListStyle: (isDraggingOver: boolean): CSSProperties => ({
    background: isDraggingOver
      ? 'var(--chakra-colors-green-50)'
      : 'transparent',
    padding: 'var(--chakra-space-2)',
    border: isDraggingOver ? '2px dashed lightgray' : '2px dashed transparent',
    borderRadius: isDraggingOver ? 'var(--chakra-radii-md)' : undefined,
    width: '100%',
  }),

  getItemStyle: (
    isDragging: boolean,
    styles: DraggingStyle | NotDraggingStyle | undefined
  ): CSSProperties => ({
    ...styles,
    // width: '100%',
  }),
};
