import { useTranslation } from 'react-i18next';
import { useNavigate } from '@tanstack/react-router';
import { viewExhibitionCart } from '@/features/Routing/protected/exhibitions-by-role';
import { numberWithSpaces } from '@/utils/amountTransformation';
import { replacePaths } from '@/utils/route';
import { SHOW_EXHIBITION_PATH } from '@/constants/router';
import { Divider, Text } from '@chakra-ui/react';
import { Button as OrderButton } from '@/components/Buttons/Button/Button';
import { TKey } from '@/i18n/types';
import { OrderItem, TemporaryStaffOrderItem } from '@/model/IOrderItem';
import { ORDER_STATUSES } from '@/constants/order';
import {
  ITEPortal_Domain_Dto_Order_CreateOrderDto,
  ITEPortal_Domain_Dto_Order_CreateOrderFromAdminDto,
} from '@/api';
import { typedAxios } from '@/api/typed-axios';
import { Card } from '@/components/Card/Card';
import {
  CartButtonsContainer,
  TotalRowContainer,
  TotalRowContainerSummary,
  TotalWrapper,
} from './styled';
import { ModalFactory } from '@/components/Modal/api';
import { Cart } from '@/model/ICart';
import { SpaceOnlyStandOrderCommentsForm } from './OrderComments/SpaceOnlyStandOrderCommentsForm';
import {
  ExtendedStandOrderCommentsForm,
  ExtendedStandOrderCommentsFormType,
  ExtendedStandOrderCommentsFormValuesType,
} from './OrderComments/ExtendedStandOrderCommentsForm';
import { orderApi, useOrder } from '@/store/effector/order';
import { loadingApi } from '@/store/effector/loading';
import { useOrderCompleteness } from '@/store/effector/order-completeness';
import { Order } from '@/model/IOrder';

interface TotalCardProps {
  total: number;
  currency: string;
  items: Array<OrderItem | TemporaryStaffOrderItem>;
  cart: Cart;
}

export const CartTotal = ({ currency, items, cart }: TotalCardProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { orderId } = useOrder();

  const { exhibitionId, stand } = viewExhibitionCart.useParams();
  const { isOrderCompleted } = useOrderCompleteness();

  const canPlaceOrder = items?.length === 0 || !isOrderCompleted;

  const navigateToOrders = () => {
    const orderPath = replacePaths(SHOW_EXHIBITION_PATH, {
      ':exhibitionId': exhibitionId,
    });

    navigate({
      to: orderPath,
    });

    orderApi.clearProducts();
  };

  const createOrder = async ({
    asDraft,
    comments,
  }: {
    asDraft: boolean;
    comments?: ExtendedStandOrderCommentsFormValuesType;
  }) => {
    const modalMessage: TKey = asDraft ? 'draftConfirmed' : 'orderConfirmed';

    const products = Order.prepareItemsForPlace(items);

    const dataForSave = {
      exhibitionId: +exhibitionId,
      standId: +stand,
      products,
      orderStatus: asDraft ? ORDER_STATUSES.Draft : ORDER_STATUSES.Submitted,
    } satisfies ITEPortal_Domain_Dto_Order_CreateOrderDto;

    if (orderId) {
      Object.assign(dataForSave, { orderId });
    }

    let payload: ITEPortal_Domain_Dto_Order_CreateOrderFromAdminDto = {
      ...dataForSave,
      additionalComments: comments?.additionalComments,
    };

    if (orderId || !asDraft) {
      payload = {
        ...payload,
        carpetColor: comments?.carpetColor?.value,
        fasciaInscription: comments?.fasciaInscription,
        fasciaInscriptionColor: comments?.fasciaInscriptionColor?.value,
      };
    }

    try {
      loadingApi.on();

      if (orderId) {
        await typedAxios.order.putOrders({
          requestBody: payload,
        });
      } else {
        await typedAxios.order.postOrders({
          requestBody: payload,
        });
      }

      ModalFactory.closeById('order-comments');

      ModalFactory.info({
        title: t('thankYou'),
        Component: () => <>{t(modalMessage)}</>,
        cancelLabel: t('toOrders'),
        onCancel: async () => {
          orderApi.cleanUpOrderState();

          navigateToOrders();
        },
      });
    } catch (error) {
    } finally {
      loadingApi.off();
    }
  };

  const amount = (value: number) => {
    return numberWithSpaces(value, currency, false);
  };

  const requestComments = () => {
    ModalFactory.show({
      id: 'order-comments',
      title: t('order-comments'),
      Component: cart.isSpaceOnlyStand
        ? SpaceOnlyStandOrderCommentsForm
        : ExtendedStandOrderCommentsForm,
      okLabel: t('confirmOrder'),
      onOk: async (context?: ExtendedStandOrderCommentsFormType) => {
        return context?.handleSubmit((v) => {
          createOrder({
            asDraft: false,
            comments: v,
          });
        })();
      },
    });
  };

  return (
    <Card width="25%">
      <TotalWrapper>
        <TotalRowContainer>
          <Text fontSize="md">{t('cartTotal')}</Text>
          <Text fontSize="md">{amount(cart.itemsTotal)}</Text>
        </TotalRowContainer>
        {cart.hasPersonalDiscount && (
          <TotalRowContainer>
            <Text fontSize="md">{t('personal-discount')}:</Text>
            <Text fontSize="md">-{amount(cart.personalDiscount)}</Text>
          </TotalRowContainer>
        )}
        {cart.hasCleaningDiscount && (
          <TotalRowContainer>
            <Text fontSize="md">{t('cleaning-discount')}:</Text>
            <Text fontSize="md">{amount(cart.cleaningDiscount)}</Text>
          </TotalRowContainer>
        )}
        {Boolean(cart.markup) && (
          <TotalRowContainer>
            <Text fontSize="md">{t('cartMarkup')}</Text>
            <Text fontSize="md">{amount(cart.markup)}</Text>
          </TotalRowContainer>
        )}
        {cart.hasVat && (
          <TotalRowContainer>
            <Text fontSize="md">{t('cartTax')}</Text>
            <Text fontSize="md">{amount(cart.vat)}</Text>
          </TotalRowContainer>
        )}
        <Divider />
        <TotalRowContainerSummary>
          <Text fontSize="md">{t('cartTotal')}</Text>
          <Text fontSize="md" fontWeight="700">
            {amount(cart.total)}
          </Text>
        </TotalRowContainerSummary>
      </TotalWrapper>
      <CartButtonsContainer>
        <OrderButton
          width="100%"
          colorScheme="red"
          onClick={() => {
            requestComments();
          }}
          disabled={canPlaceOrder}
        >
          {t('confirmOrder')}
        </OrderButton>
        <OrderButton
          width="100%"
          colorScheme="black"
          onClick={() => createOrder({ asDraft: true })}
          disabled={canPlaceOrder}
        >
          {t('saveDraft')}
        </OrderButton>
      </CartButtonsContainer>
    </Card>
  );
};
