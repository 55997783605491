import { createDomain, createApi } from 'effector';
import { ComponentType, ReactNode, useEffect } from 'react';
import { attempt } from '../../utils/attempt';
import i18n from '../../i18n';

export type ModalDescriptor = {
  id?: string;
  isOpened?: boolean;
  okLabel?: ReactNode;
  cancelLabel?: ReactNode;
  onOk?: (modalContext?: any) => Promise<void>;
  onCancel?: () => Promise<void>;
  Component: ComponentType;
  title?: ReactNode;
};

const domain = createDomain('modal-factory');

type StackType = ModalDescriptor[];
export const $modalStack = domain.createStore<StackType>([]);

const defaultModalProps: Partial<ModalDescriptor> = {
  isOpened: true,
};

const infoModalDefaultProps: Partial<ModalDescriptor> = {
  title: 'Success',
  okLabel: '',
  cancelLabel: 'Close',
  ...defaultModalProps,
};

const confirmModalDefaultProps: Partial<ModalDescriptor> = {
  title: i18n.t('attention'),
  okLabel: i18n.t('yes'),
  cancelLabel: i18n.t('cancel'),
  onCancel: async () => {
    ModalFactory.close();
  },
  ...defaultModalProps,
};

export const $modalContext = domain.createStore<unknown>(null);

export const ModalFactory = {
  ...createApi($modalStack, {
    show: (_, newModal: ModalDescriptor) => [
      ..._,
      { ...defaultModalProps, ...newModal, isOpened: true },
    ],
    info: (_, newModal: ModalDescriptor) => [
      ..._,
      { ...infoModalDefaultProps, ...newModal, isOpened: true },
    ],
    confirm: (_, newModal: ModalDescriptor) => [
      ..._,
      { ...confirmModalDefaultProps, ...newModal, isOpened: true },
    ],
    closeAll: () => [],
    closeById: (_, id: ModalDescriptor['id']) => {
      if (!id) {
        return _;
      }

      return _.filter((d) => d.id !== id);
    },
    close: (_, id: string | undefined) => {
      let descriptorById: ModalDescriptor | null = null;

      if (typeof id === 'string') {
        const indexOfDescriptor = _.findIndex((d) => d.id === id);

        if (indexOfDescriptor === -1) {
          return _;
        }

        descriptorById = _.splice(indexOfDescriptor, 1)[0];
      }

      const lastModalDescriptor = descriptorById ? descriptorById : _.pop();

      if (!lastModalDescriptor) {
        return [];
      }

      setTimeout(() => {
        // todo this breaks a purity of reducer, needs to be refined
        if (typeof lastModalDescriptor?.onCancel === 'function') {
          attempt(() => lastModalDescriptor.onCancel?.());
        }
      }, 0);

      return [..._];
    },
  }),
  isOpened: $modalStack.map((stack) => {
    return stack.length > 0;
  }),

  isOpenedById: (id: string) => {
    return $modalStack.getState().some((d) => d.id === id);
  },
  ...createApi($modalContext, {
    updateContext: <S, T>(_: S, payload: T) => payload,
  }),
};

export const useModalFactoryContextProvider = <T,>(context: T) => {
  useEffect(() => {
    ModalFactory.updateContext(context);
  }, [context]);
};
