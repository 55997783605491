import { ITechnicalService } from '@/model/ITechnicalService';
import styled from 'styled-components';
import { Card } from './Card';
import { StyledLink } from '../Buttons/Link';
import { replacePaths } from '@/utils/route';
import { CREATE_EXHIBITION_ORDER_TAB_PATH } from '@/constants/router';
import { Flex, Image } from '@chakra-ui/react';
import { viewExhibitionCart } from '@/features/Routing/protected/exhibitions-by-role';

interface TechnicalServiceProps {
  service: ITechnicalService;
}

const Wrapper = styled(StyledLink)`
  max-width: 240px;
  width: 100%;
  text-align: center;

  svg {
    transition: all ease-in-out 0.15s;
    max-width: 96px;
    width: 100%;
    display: block;
    margin-left: auto;
    margin-right: auto;

    fill: #a0aec0;
  }

  .service-name {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    word-wrap: normal;

    color: #4a5568;
  }

  &:hover {
    svg {
      fill: var(--chakra-colors-brandColor);
    }
  }
`;

const ServiceName = styled.div`
  margin-top: 0.5rem;
  height: 2.3rem;

  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  color: inherit;
`;

const TechnicalCardWrapper = styled(Flex)`
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const TechnicalServiceCard: React.FC<TechnicalServiceProps> = ({ service }) => {
  const { exhibitionId, stand } = viewExhibitionCart.useParams();
  const category = service.category;

  const pathName = replacePaths(CREATE_EXHIBITION_ORDER_TAB_PATH, {
    ':exhibitionId': exhibitionId,
    ':stand': stand,
    ':service': category.name,
  });

  return (
    <Wrapper to={pathName}>
      <Card className="animated-card">
        <TechnicalCardWrapper>
          {service.image && typeof service.image === 'string' ? (
            <Image boxSize="100px" src={service.image} alt={service.image} />
          ) : (
            service.image
          )}
          <ServiceName className="service-name">
            {category.displayName || category.name || category.id}
          </ServiceName>
        </TechnicalCardWrapper>
      </Card>
    </Wrapper>
  );
};

export default TechnicalServiceCard;
