/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ITEPortal_Domain_Dto_BankAccount_BankAccountDto } from '../models/ITEPortal_Domain_Dto_BankAccount_BankAccountDto';
import type { ITEPortal_Domain_Dto_BankAccount_GetBankAccountDto } from '../models/ITEPortal_Domain_Dto_BankAccount_GetBankAccountDto';
import type { ITEPortal_Domain_Dto_IteCompany_GetIteCompanyDto } from '../models/ITEPortal_Domain_Dto_IteCompany_GetIteCompanyDto';
import type { ITEPortal_Domain_Dto_IteCompany_IteCompanyDto } from '../models/ITEPortal_Domain_Dto_IteCompany_IteCompanyDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class IteCompanyService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns ITEPortal_Domain_Dto_IteCompany_GetIteCompanyDto Success
     * @throws ApiError
     */
    public getCompanies(): CancelablePromise<Array<ITEPortal_Domain_Dto_IteCompany_GetIteCompanyDto>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/companies',
            errors: {
                400: `Bad Request`,
            },
        });
    }

    /**
     * @returns any Created
     * @throws ApiError
     */
    public postCompany({
        requestBody,
    }: {
        requestBody?: ITEPortal_Domain_Dto_IteCompany_IteCompanyDto,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/company',
            body: requestBody,
            mediaType: 'application/json-patch+json',
            errors: {
                400: `Bad Request`,
                500: `Server Error`,
            },
        });
    }

    /**
     * @returns any Created
     * @throws ApiError
     */
    public putCompany({
        companyId,
        requestBody,
    }: {
        companyId: number,
        requestBody?: ITEPortal_Domain_Dto_IteCompany_IteCompanyDto,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/company/{companyId}',
            path: {
                'companyId': companyId,
            },
            body: requestBody,
            mediaType: 'application/json-patch+json',
            errors: {
                400: `Bad Request`,
                500: `Server Error`,
            },
        });
    }

    /**
     * @returns any Success
     * @throws ApiError
     */
    public postCompaniesBatch({
        formData,
    }: {
        formData?: {
            companiesFile?: Blob;
        },
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/companies/batch',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @returns ITEPortal_Domain_Dto_BankAccount_GetBankAccountDto Success
     * @throws ApiError
     */
    public getCompaniesBankAccounts({
        companyId,
    }: {
        companyId: number,
    }): CancelablePromise<Array<ITEPortal_Domain_Dto_BankAccount_GetBankAccountDto>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/companies/{companyId}/bank-accounts',
            path: {
                'companyId': companyId,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }

    /**
     * @returns any Success
     * @throws ApiError
     */
    public postCompaniesBankAccountsBatch({
        formData,
    }: {
        formData?: {
            bankAccountsFile?: Blob;
        },
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/companies/bank-accounts/batch',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @returns any Success
     * @throws ApiError
     */
    public postCompaniesBankAccount({
        requestBody,
    }: {
        requestBody?: ITEPortal_Domain_Dto_BankAccount_BankAccountDto,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/companies/bank-account',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @returns any Success
     * @throws ApiError
     */
    public putCompaniesBankAccount({
        bankAccountId,
        requestBody,
    }: {
        bankAccountId: number,
        requestBody?: ITEPortal_Domain_Dto_BankAccount_BankAccountDto,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/companies/bank-account/{bankAccountId}',
            path: {
                'bankAccountId': bankAccountId,
            },
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

}
