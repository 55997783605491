import { ITEPortal_Domain_Dto_ProductDto } from '@/api';
import { PRODUCT_TYPES, ProductTypesType } from '@/constants/categorie';
import { Fragment, useEffect, useMemo } from 'react';
import { Stack } from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';
import { SelectComponent } from '@/components/MultiSelect/MultiSelect';
import { OptionType } from '@/model/common';
import { EditBuildingProductFieldset } from '@/pages/AdminPanel/ServiceDirectory/EditProductFieldsets/EditBuildingTypeProduct';
import { EditTemporaryStaffProductFieldset } from '@/pages/AdminPanel/ServiceDirectory/EditProductFieldsets/EditTemporaryStaffProduct';
import { EditDefaultProductFieldset } from '@/pages/AdminPanel/ServiceDirectory/EditProductFieldsets/EditDefaultProduct';
import i18n from '@/i18n';

type AbstractEditProductModalFormPropsType = {
  product: ITEPortal_Domain_Dto_ProductDto | null;
  productType?: ProductTypesType | null;
};

const productTypeOptions: Array<OptionType> = [
  {
    value: PRODUCT_TYPES.COMMON,
    label: i18n.t('product-type-common'),
  },
  {
    value: PRODUCT_TYPES.BUILDING,
    label: i18n.t('product-type-select-building'),
  },
  {
    value: PRODUCT_TYPES.TEMPORARY_STAFF,
    label: i18n.t('product-type-temporary-staff'),
  },
];

export const AbstractEditProductDrawerForm = (
  props: AbstractEditProductModalFormPropsType
) => {
  const { product, productType } = props;
  const formInstance = useForm<{
    productType: { value: ProductTypesType; label: string };
  }>();
  const productTypeFromSelect = formInstance.watch('productType');

  const Fieldset = useMemo(() => {
    switch (productTypeFromSelect?.value) {
      case 'select-building': {
        return EditBuildingProductFieldset;
      }

      case 'temporary-staff': {
        return EditTemporaryStaffProductFieldset;
      }

      case 'common': {
        return EditDefaultProductFieldset;
      }

      default: {
        return Fragment;
      }
    }
  }, [productTypeFromSelect]);

  useEffect(
    function setProductType() {
      if (productType) {
        formInstance.setValue(
          'productType',
          // @ts-expect-error desc
          productTypeOptions.find((po) => po.value === productType) ||
            productTypeOptions[0]
        );
      }
    },
    [productType]
  );

  return (
    <Stack spacing={5}>
      {!product?.id && (
        <FormProvider {...formInstance}>
          <SelectComponent
            label={i18n.t('product-type')}
            name="productType"
            disabled={Boolean(productType)}
            options={productTypeOptions}
          />
        </FormProvider>
      )}
      {Fieldset === Fragment ? (
        <Fragment />
      ) : (
        <Fieldset editTableData={product} />
      )}
    </Stack>
  );
};
