import {
  ITEPortal_Contracts_Enums_BuildingType,
  ITEPortal_Contracts_Enums_OrderStatus,
} from '../api';

export const ORDER_STATUSES = {
  Undefined: 0,
  Submitted: 1,
  Draft: 2,
  Invoiced: 3,
  Paid: 4,
  Cancelled: 5,
  Processing: 6,
} satisfies Record<
  | 'Undefined'
  | 'Submitted'
  | 'Draft'
  | 'Invoiced'
  | 'Paid'
  | 'Cancelled'
  | 'Processing',
  ITEPortal_Contracts_Enums_OrderStatus
>;

export const PERSONAL_DISCOUNT_TYPE = {
  PERCENT: 0,
  AMOUNT: 1,
} as const;

export const SPACE_ONLY_BUILDING_TYPE: ITEPortal_Contracts_Enums_BuildingType = 1;
