import { IBaseOrder } from '../../../../../model/IBaseOrder';
import { Button, Flex, Switch, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { ModalFactory } from '../../../../../components/Modal/api';
import { EditChargeContextType, MarkupForm } from './MarkupForm';
import {
  useManageCommonExtraCharge,
  useManagePersonalExtraCharge,
} from './use-order-extra-charge-management';
import { useRouter } from '@tanstack/react-router';

type VATManagementPropsType = {
  order: IBaseOrder;
};

export const MarkupManagement = ({ order }: VATManagementPropsType) => {
  const router = useRouter();
  const { t } = useTranslation();
  const { toggleCommonExtraCharge } = useManageCommonExtraCharge();
  const { savePersonalExtraCharge } = useManagePersonalExtraCharge();

  const toggleCharge = () => {
    toggleCommonExtraCharge(order, () => {
      ModalFactory.close();
      router.invalidate();
    });
  };

  const addVAT = () => {
    ModalFactory.show({
      title: t('personal-markup'),
      Component: () => <MarkupForm order={order} />,
      onOk: async (context?: EditChargeContextType) => {
        if (!context) {
          return Promise.resolve();
        }

        return savePersonalExtraCharge(order, context, () => {
          router.invalidate();
          ModalFactory.close();
        });
      },
      okLabel: t('save'),
    });
  };

  return (
    <>
      <Flex alignItems="center" justifyContent="space-between">
        <Switch
          colorScheme="red"
          isChecked={order.removeExtraCharge === true}
          onChange={toggleCharge}
        />
        <Text ml="10px">{t('remove-markup')}</Text>
      </Flex>
      <Button
        colorScheme="red"
        onClick={addVAT}
        isDisabled={order.removeExtraCharge === true}
      >
        + {t('personal-markup')}{' '}
        {order.hasPersonalExtraCharge &&
          `(${order.personalExtraChargePercentage}%)`}
      </Button>
    </>
  );
};
