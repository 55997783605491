import styled from 'styled-components';
import { Accordion, AccordionItem, Box, Text } from '@chakra-ui/react';
import { PageTitle } from '@/components/Typography/PageTitle';

export const AccordionButtonWrapper = styled(AccordionItem)`
  border: none;
  width: 100%;
`;

export const PageTitleContainer = styled(PageTitle)`
  padding-top: 30px;
  text-align: center;
`;

export const AccordionButtonDescription = styled(Box)`
  flex: 1;
  text-align: left;
`;

export const AccordionTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const AccordionTitleContainer = styled(Text)`
  font-size: 18px;
  font-weight: 600;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;

export const DotContainer = styled(Box)`
  margin: 0 2px;
  border-radius: 50%;
  display: inline-block;
`;

export const AccordionItemWrapper = styled.div`
  width: 50%;
  min-width: 800px;
  padding: 10px;
`;

export const AccordionWrapper = styled(Accordion)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const OrderRowWrapper = styled(Box)`
  padding: 10px;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const OrdersWrapper = styled(Box)`
  width: 100%;
`;

export const OrderInfoContainer = styled(Box)`
  width: 60%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 50px;
  color: #2d3748;
  font-size: 16px;
`;

export const OrderOptionsContainer = styled(Box)`
  width: 40%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
`;

export const OptionBox = styled(Box)`
  color: #718096;
  cursor: pointer;

  &:hover {
    color: #e53e3e;
  }
`;

export const AddOrderButtonContainer = styled(Box)`
  padding-top: 20px;
  padding-bottom: 10px;
  display: flex;
  justify-content: flex-start;
`;
