export interface ISerialisable {
  toString(): string;
  toJSON(): unknown;
}

export abstract class Serializable implements ISerialisable {
  public toString(): string {
    return JSON.stringify(this.toJSON());
  }

  public abstract toJSON(): unknown;
}
