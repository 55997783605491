import {
  SELECT_BUILDING,
  TEMPORARY_STAFF,
} from '@/constants/technical-services';
import { SelectBuilding } from './SelectBuilding';
import ProductItemsCard from './common/ProductItemsCard';
import TemporaryStaff from './TemporaryStaff';
import { Category } from '@/model/ICategory';

interface ServiceFactoryProps {
  category: Category;
  description: string;
}

const ServiceFactory = ({ category }: ServiceFactoryProps) => {
  switch (category.name) {
    case SELECT_BUILDING:
      return <SelectBuilding description={category.description} />;
    case TEMPORARY_STAFF:
      return <TemporaryStaff description={category.description} />;
    default:
      return <ProductItemsCard serviceCategory={category} />;
  }
};

export default ServiceFactory;
