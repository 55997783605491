import { Stack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { IdType, OptionType } from '@/model/common';
import { FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { typedAxios } from '@/api/typed-axios';
import { ModalFactory } from '@/components/Modal/api';
import { SelectComponent } from '@/components/MultiSelect/MultiSelect';
import * as vali from 'zod';
import i18n from '@/i18n';

const OptionSchema = vali.object(
  {
    value: vali.coerce.number(),
    label: vali.string(),
  },
  {
    required_error: i18n.t('required-field'),
  }
);

const ValidationSchema = vali.object({
  technicalManager: OptionSchema,
});

export type ValidationSchemaType = vali.infer<typeof ValidationSchema>;

export const EditManagerModal = ({
  exhibitionId,
}: {
  exhibitionId: IdType;
}) => {
  const { t } = useTranslation();
  const [technicalManagers, setTechnicalManagers] = useState<OptionType[]>();
  const instance = useForm<ValidationSchemaType>({
    resolver: zodResolver(ValidationSchema),
    mode: 'onChange',
  });

  useEffect(() => {
    const fetchTechnicalManagers = async () => {
      const technicalManagersData = await typedAxios.user
        .getUsersTechnicalManagers1({
          exhibitionId: +exhibitionId,
        })
        .catch(() => []);

      const technicalManagersOptions = technicalManagersData.map((manager) => {
        return {
          label: manager.email || manager.name,
          value: manager.id,
          meta: { contactId: manager.contactId },
        };
      });

      setTechnicalManagers(technicalManagersOptions);
    };

    fetchTechnicalManagers();
    ModalFactory.updateContext(instance);
  }, [instance]);

  return (
    <FormProvider {...instance}>
      <Stack spacing={4}>
        <SelectComponent<ValidationSchemaType>
          label={t('technical-manager')}
          options={technicalManagers}
          isRequired
          name="technicalManager"
        />
      </Stack>
    </FormProvider>
  );
};
