import styled from 'styled-components';

export const Thumbnail = styled.img`
  height: 40px;
  border-radius: 5px;
  width: auto;
  margin-right: 0.5rem;
  cursor: pointer;
  opacity: 0.9;

  &:hover {
    opacity: 1;
  }
`;
