import { Flex, Text } from '@chakra-ui/react';
import { HistoryItem } from '@/pages/StandProject/StandProjectDescription/HistoryItem';
import { useTranslation } from 'react-i18next';
import {
  type ITEPortal_Domain_Dto_Stand_GetStandProjectChangesDto,
  type ITEPortal_Domain_Dto_Stand_GetStandProjectDto,
} from '@/api';

import { CustomScroll } from 'react-custom-scroll';
import { DateUtils } from '@/utils/date-utils';

type CommentsHistoryPropsType = {
  comments?: ITEPortal_Domain_Dto_Stand_GetStandProjectDto['projectChangesHistory'];
  ownershipResolver?: (
    historyItem: ITEPortal_Domain_Dto_Stand_GetStandProjectChangesDto
  ) => boolean;
};

export const CommentsHistory = (props: CommentsHistoryPropsType) => {
  const { comments, ownershipResolver } = props;
  const { t } = useTranslation();

  if (!Array.isArray(comments) || comments.length === 0) {
    return null;
  }

  const sortedComments =
    DateUtils.sortByDateAddedDescending<ITEPortal_Domain_Dto_Stand_GetStandProjectChangesDto>(
      comments
    );

  return (
    <Flex flexDirection="column" maxHeight="calc(100% - 250px)">
      <Text fontWeight={500} mb={4}>
        {t('stand-changes-history')}:
      </Text>

      <CustomScroll heightRelativeToParent="100%">
        <Flex flexDirection="column" gap={4}>
          {sortedComments.map((historyItem, index) => (
            <HistoryItem
              key={index}
              historyItem={historyItem}
              ownershipResolver={ownershipResolver}
            />
          ))}
        </Flex>
      </CustomScroll>
    </Flex>
  );
};
