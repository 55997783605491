/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ITEPortal_Domain_Dto_AdminExhibitorSummary } from '../models/ITEPortal_Domain_Dto_AdminExhibitorSummary';
import type { ITEPortal_Domain_Dto_AssignManagerToExhibitorsDto } from '../models/ITEPortal_Domain_Dto_AssignManagerToExhibitorsDto';
import type { ITEPortal_Domain_Dto_CatalogExhibitorSummary } from '../models/ITEPortal_Domain_Dto_CatalogExhibitorSummary';
import type { ITEPortal_Domain_Dto_CreateExhibitorDto } from '../models/ITEPortal_Domain_Dto_CreateExhibitorDto';
import type { ITEPortal_Domain_Dto_Exhibitor_ExhibitorEmployeeDto } from '../models/ITEPortal_Domain_Dto_Exhibitor_ExhibitorEmployeeDto';
import type { ITEPortal_Domain_Dto_ExhibitorDto } from '../models/ITEPortal_Domain_Dto_ExhibitorDto';
import type { ITEPortal_Domain_Dto_UpdateExhibitorDto } from '../models/ITEPortal_Domain_Dto_UpdateExhibitorDto';
import type { ITEPortal_Domain_Dto_UpdateExhibitorUserDataDto } from '../models/ITEPortal_Domain_Dto_UpdateExhibitorUserDataDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ExhibitorService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns ITEPortal_Domain_Dto_CatalogExhibitorSummary Success
     * @throws ApiError
     */
    public getAdminSummaries({
        search,
        pageNumber,
        pageSize,
    }: {
        search?: string,
        pageNumber?: number,
        pageSize?: number,
    }): CancelablePromise<Array<ITEPortal_Domain_Dto_CatalogExhibitorSummary>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/admin-summaries',
            query: {
                'Search': search,
                'PageNumber': pageNumber,
                'PageSize': pageSize,
            },
        });
    }

    /**
     * @returns ITEPortal_Domain_Dto_ExhibitorDto Success
     * @throws ApiError
     */
    public getExhibitors({
        id,
        exhibitionId,
    }: {
        id: number,
        exhibitionId?: number,
    }): CancelablePromise<ITEPortal_Domain_Dto_ExhibitorDto> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/exhibitors/{id}',
            path: {
                'id': id,
            },
            query: {
                'exhibitionId': exhibitionId,
            },
        });
    }

    /**
     * @returns any Success
     * @throws ApiError
     */
    public putExhibitors({
        id,
        requestBody,
    }: {
        id: number,
        requestBody?: ITEPortal_Domain_Dto_UpdateExhibitorDto,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/exhibitors/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @returns ITEPortal_Domain_Dto_ExhibitorDto Success
     * @throws ApiError
     */
    public getExhibitors1({
        email,
    }: {
        email?: string,
    }): CancelablePromise<ITEPortal_Domain_Dto_ExhibitorDto> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/exhibitors',
            query: {
                'email': email,
            },
        });
    }

    /**
     * @returns any Success
     * @throws ApiError
     */
    public postExhibitors({
        requestBody,
    }: {
        requestBody?: ITEPortal_Domain_Dto_CreateExhibitorDto,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/exhibitors',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @returns ITEPortal_Domain_Dto_AdminExhibitorSummary Success
     * @throws ApiError
     */
    public getExhibitorsExhibition({
        exhibitionId,
        search,
        hasTechnicalManager = true,
    }: {
        exhibitionId?: number,
        search?: string,
        hasTechnicalManager?: boolean,
    }): CancelablePromise<Array<ITEPortal_Domain_Dto_AdminExhibitorSummary>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/exhibitors/exhibition',
            query: {
                'exhibitionId': exhibitionId,
                'search': search,
                'hasTechnicalManager': hasTechnicalManager,
            },
            errors: {
                400: `Bad Request`,
                500: `Server Error`,
            },
        });
    }

    /**
     * @returns any Success
     * @throws ApiError
     */
    public postExhibitionExhibitorsBatch({
        exhibitionId,
        formData,
    }: {
        exhibitionId: number,
        formData?: {
            importFile?: Blob;
        },
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/exhibition/{exhibitionId}/exhibitors/batch',
            path: {
                'exhibitionId': exhibitionId,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @returns any Success
     * @throws ApiError
     */
    public putAdminSummaries({
        id,
        requestBody,
    }: {
        id: number,
        requestBody?: ITEPortal_Domain_Dto_UpdateExhibitorUserDataDto,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/admin-summaries/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @returns any Success
     * @throws ApiError
     */
    public deleteExhibitionsExhibitors({
        exhibitionId,
        exhibitorId,
    }: {
        exhibitionId: number,
        exhibitorId: number,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/exhibitions/{exhibitionId}/exhibitors/{exhibitorId}',
            path: {
                'exhibitionId': exhibitionId,
                'exhibitorId': exhibitorId,
            },
        });
    }

    /**
     * @returns number Success
     * @throws ApiError
     */
    public postExhibitorsExhibitionEmployees({
        exhibitorId,
        exhibitionId,
        requestBody,
    }: {
        exhibitorId: number,
        exhibitionId: number,
        requestBody?: ITEPortal_Domain_Dto_Exhibitor_ExhibitorEmployeeDto,
    }): CancelablePromise<number> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/exhibitors/{exhibitorId}/exhibition/{exhibitionId}/employees',
            path: {
                'exhibitorId': exhibitorId,
                'exhibitionId': exhibitionId,
            },
            body: requestBody,
            mediaType: 'application/json-patch+json',
            errors: {
                400: `Bad Request`,
            },
        });
    }

    /**
     * @returns number Success
     * @throws ApiError
     */
    public postExhibitorsEmployees({
        exhibitorId,
        requestBody,
    }: {
        exhibitorId: number,
        requestBody?: ITEPortal_Domain_Dto_Exhibitor_ExhibitorEmployeeDto,
    }): CancelablePromise<number> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/exhibitors/{exhibitorId}/employees',
            path: {
                'exhibitorId': exhibitorId,
            },
            body: requestBody,
            mediaType: 'application/json-patch+json',
            errors: {
                400: `Bad Request`,
            },
        });
    }

    /**
     * @returns boolean Success
     * @throws ApiError
     */
    public putExhibitorsEmployees({
        employeeId,
        requestBody,
    }: {
        employeeId: number,
        requestBody?: ITEPortal_Domain_Dto_Exhibitor_ExhibitorEmployeeDto,
    }): CancelablePromise<boolean> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/exhibitors/employees/{employeeId}',
            path: {
                'employeeId': employeeId,
            },
            body: requestBody,
            mediaType: 'application/json-patch+json',
            errors: {
                400: `Bad Request`,
            },
        });
    }

    /**
     * @returns number Success
     * @throws ApiError
     */
    public deleteExhibitorsEmployees({
        employeeId,
    }: {
        employeeId: number,
    }): CancelablePromise<number> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/exhibitors/employees/{employeeId}',
            path: {
                'employeeId': employeeId,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }

    /**
     * @returns boolean Success
     * @throws ApiError
     */
    public deleteExhibitorsEmployeesExhibitions({
        employeeId,
        exhibitionId,
    }: {
        employeeId: number,
        exhibitionId: number,
    }): CancelablePromise<boolean> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/exhibitors/employees/{employeeId}/exhibitions/{exhibitionId}',
            path: {
                'employeeId': employeeId,
                'exhibitionId': exhibitionId,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }

    /**
     * @returns ITEPortal_Domain_Dto_AdminExhibitorSummary Success
     * @throws ApiError
     */
    public getExhibitorsExhibitionsAgents({
        exhibitionId,
    }: {
        exhibitionId: number,
    }): CancelablePromise<Array<ITEPortal_Domain_Dto_AdminExhibitorSummary>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/exhibitors/exhibitions/{exhibitionId}/agents',
            path: {
                'exhibitionId': exhibitionId,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }

    /**
     * @returns boolean Success
     * @throws ApiError
     */
    public putExhibitorsExhibitionsTechnicalManagers({
        exhibitorId,
        exhibitionId,
        id,
    }: {
        exhibitorId: number,
        exhibitionId: number,
        id: number,
    }): CancelablePromise<boolean> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/exhibitors/{exhibitorId}/exhibitions/{exhibitionId}/technical-managers/{id}',
            path: {
                'exhibitorId': exhibitorId,
                'exhibitionId': exhibitionId,
                'id': id,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }

    /**
     * @returns any Success
     * @throws ApiError
     */
    public putExhibitorsExhibition({
        exhibitionId,
        requestBody,
    }: {
        exhibitionId: number,
        requestBody?: ITEPortal_Domain_Dto_AssignManagerToExhibitorsDto,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/exhibitors/exhibition/{exhibitionId}',
            path: {
                'exhibitionId': exhibitionId,
            },
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

}
