import { useForm, FormProvider } from 'react-hook-form';
import { FormControl, Stack } from '@chakra-ui/react';
import { useNameTranslate } from '@/hooks/useStringName';
import Input from '@/components/Fields/Input';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { UploadFileDropzone } from '@/components/FileUpload/FileUpload';
import { TextArea } from '@/components/TextArea/TextArea';
import fetchApi from '@/utils/fetchAPI';
import { EDIT_PRODUCT_RESOURCES } from '@/constants/endpoints';
import { FetchMethods } from '@/utils/constants';
import { toast } from 'react-toastify';
import { BUILDING_TYPES } from '@/constants/admin-pages';
import { SelectComponent } from '@/components/MultiSelect/MultiSelect';
import { OptionType } from '@/model/common';
import { CATEGORY_TYPES } from '@/constants/categorie';
import {
  ITEPortal_Domain_Dto_AddProductDto,
  ITEPortal_Domain_Dto_ProductDto,
} from '@/api';
import {
  BuildingProductValidationSchema,
  BuildingProductValidationSchemaType,
  CommonProductValidationSchemaType,
} from '@/pages/AdminPanel/ServiceDirectory/EditProductFieldsets/validation-schemas';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  editProductApi,
  useProductForm,
} from '@/pages/AdminPanel/ServiceDirectory/EditProductFieldsets/useProductForm';

const FormControlWrapper = styled(FormControl)`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

interface AddBuildingTypeProductProps {
  editTableData: ITEPortal_Domain_Dto_ProductDto | null;
}

const mapValues = (
  values: BuildingProductValidationSchemaType
): ITEPortal_Domain_Dto_AddProductDto => {
  const buildingTypes = values?.buildingTypes?.map(
    (type: OptionType) => type?.value
  );

  return {
    name: values?.name,
    categoryName: CATEGORY_TYPES.SELECT_BUILDING,
    // @ts-expect-error to think how to map Enum to zod type
    buildingTypes: buildingTypes,
    resources: [
      {
        key: 'name',
        value: values?.displayNameRu || '',
        cultureName: 'ru',
      },
      {
        key: 'name',
        value: values?.displayNameEn || '',
        cultureName: 'en',
      },
      {
        key: 'description',
        value: values?.descriptionRu || '',
        cultureName: 'ru',
      },
      {
        key: 'description',
        value: values?.descriptionEn || '',
        cultureName: 'en',
      },
      {
        key: 'imageDescription',
        value: values?.imageDescriptionRu || '',
        cultureName: 'ru',
      },
      {
        key: 'imageDescription',
        value: values?.imageDescriptionEn || '',
        cultureName: 'en',
      },
      {
        key: 'additionalNotes',
        value: values?.additionalNotesRu || '',
        cultureName: 'ru',
      },
      {
        key: 'additionalNotes',
        value: values?.additionalNotesEn || '',
        cultureName: 'en',
      },
    ],
  };
};

export const EditBuildingProductFieldset = ({
  editTableData,
}: AddBuildingTypeProductProps) => {
  const culture = localStorage.getItem('lang');

  const methods = useForm<BuildingProductValidationSchemaType>({
    mode: 'onBlur',
    resolver: zodResolver(BuildingProductValidationSchema),
  });

  const { categoryOptions } = useProductForm();

  useEffect(() => {
    editProductApi.setFormInstance({
      map: mapValues,
      formInstance: methods,
    });
  }, []);

  useEffect(() => {
    if (editTableData) {
      const fetchData = async () => {
        const productID = editTableData?.name;

        if (!productID) {
          return;
        }

        try {
          const { data } = await fetchApi(EDIT_PRODUCT_RESOURCES(productID), {
            method: FetchMethods.Get,
            params: {
              culture,
            },
          });
          const buildingTypeValues = editTableData?.buildingTypes?.map(
            (type: number) => {
              const buildingTypeLabel = BUILDING_TYPES.find(
                (dictionaryType) => dictionaryType.value === type
              )?.label;

              return {
                value: type,
                label: buildingTypeLabel,
              };
            }
          );

          const editDataWithResources = {
            name: editTableData?.name,
            imageUrls: editTableData?.imageUrls,
            displayNameRu: data?.ru?.name,
            displayNameEn: data?.en?.name,
            buildingTypes: buildingTypeValues,
            descriptionRu: data?.ru?.description,
            descriptionEn: data?.en?.description,
            imageDescriptionRu: data?.ru?.imageDescription,
            imageDescriptionEn: data?.en?.imageDescription,
            additionalNotesRu: data?.ru?.additionalNotes,
            additionalNotesEn: data?.en?.additionalNotes,
            categoryName: categoryOptions.find(
              (ct) => ct.value === editTableData.categoryName
            ),
          } as any;

          methods.reset(editDataWithResources);
        } catch (error: any) {
          const fieldErrors = error?.response?.data?.errors;

          if (fieldErrors) {
            toast.error(fieldErrors);
          }
        } finally {
        }
      };

      fetchData();
    }
  }, [editTableData]);

  return (
    <FormProvider {...methods}>
      <form>
        <Stack spacing="5">
          <FormControlWrapper>
            <Input<BuildingProductValidationSchemaType>
              label="ID"
              maxWidth="100%"
              name="name"
              disabled={Boolean(editTableData)}
              isRequired
            />
            <Input<BuildingProductValidationSchemaType>
              label={`${useNameTranslate('service-name')} (RU)`}
              maxWidth="100%"
              name="displayNameRu"
              isRequired
            />
            <Input<BuildingProductValidationSchemaType>
              label={`${useNameTranslate('service-name')} (EN)`}
              maxWidth="100%"
              name="displayNameEn"
              isRequired
            />
            <SelectComponent<BuildingProductValidationSchemaType>
              label={useNameTranslate('building-type')}
              options={BUILDING_TYPES}
              closeMenuOnSelect={false}
              isMulti
              isRequired
              name="buildingTypes"
            />
            <SelectComponent<CommonProductValidationSchemaType>
              label={useNameTranslate('category-name')}
              options={categoryOptions}
              isRequired
              name="categoryName"
            />
            <UploadFileDropzone<BuildingProductValidationSchemaType>
              label={useNameTranslate('upload-image')}
              defaultValue={editTableData?.imageUrls?.join('\n')}
              multiple={true}
              name="imageUrls"
            />
            <TextArea<BuildingProductValidationSchemaType>
              label={`${useNameTranslate('image-description')} (RU)`}
              name="imageDescriptionRu"
            />
            <TextArea<BuildingProductValidationSchemaType>
              label={`${useNameTranslate('image-description')} (EN)`}
              name="imageDescriptionEn"
            />
            <TextArea<BuildingProductValidationSchemaType>
              label={`${useNameTranslate('service-description')} (RU)`}
              name="descriptionRu"
            />
            <TextArea<BuildingProductValidationSchemaType>
              label={`${useNameTranslate('service-description')} (EN)`}
              name="descriptionEn"
            />
            <TextArea<BuildingProductValidationSchemaType>
              label={`${useNameTranslate('additional-notes')} (RU)`}
              name="additionalNotesRu"
            />
            <TextArea<BuildingProductValidationSchemaType>
              label={`${useNameTranslate('additional-notes')} (EN)`}
              name="additionalNotesEn"
            />
          </FormControlWrapper>
        </Stack>
      </form>
    </FormProvider>
  );
};
