import { $exhibition } from '@/store/effector/exhibition';
import { useUnit } from 'effector-react';
import i18n from '@/i18n';
import { FEATURES, useFeature } from '@/features/feautres';

export const $vatSettings = $exhibition.map((state) => {
  return {
    vatMessage:
      state.currency?.toLowerCase() === 'rub' ? i18n.t('vat-message') : null,
  };
});

export const useVatSettings = () => {
  const featureEnabled = useFeature(FEATURES.VAT_DESCRIPTION);

  if (!featureEnabled) {
    return {
      vatMessage: null,
    };
  }

  return useUnit($vatSettings);
};
