import styled from 'styled-components';
import { Input, Select } from '@chakra-ui/react';

export const OrderTableRowWrapper = styled.div`
  width: 100%;
`;

export const OrderTableRowContainer = styled.form`
  display: flex;
  flex-direction: row;
  margin-left: auto;
  justify-content: space-between;
  align-items: center;
  max-width: 100%;
  padding-top: 1.3rem;
  padding-bottom: 1.3rem;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: end;
  height: 30px;
`;

export const OrderTableRowContainerNameComponent = styled.div`
  font-size: 14px;
  line-height: 1.2;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;

  @media all and (max-width: 1300px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const OrderTableRowContainerPriceComponent = styled.div`
  font-size: 14px;
  min-width: 100px;
  text-align: center;
  font-weight: 600;
`;

export const OrderTableRowContainerInputComponent = styled(Input)`
  padding: 0 5px;
  max-width: 50px;
  text-align: center;

  ::placeholder {
    text-align: center;
  }

  :focus {
    text-align: center;
  }
`;

export const InfoSectionContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 20px;
  gap: 20px;
`;

export const ParamsSectionContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 20px;
`;

export const OrderTableRowContainerInputWrapper = styled.div`
  padding: 5px 0;
  min-width: 50px;
`;

export const OrderTableRowContainerBadgeComponent = styled(Select)`
  padding: 5px 0;
  width: 150px;
`;
