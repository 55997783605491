import { Product } from '@/model/IProduct';
import { Category } from '@/model/ICategory';
import i18n from '@/i18n';
import { TKey } from '@/i18n/types';
import { ITEPortal_Domain_Dto_PriceListItemSummary } from '@/api';

export const ProductUtils = {
  createProductFromPriceListItem: (
    priceListItem: ITEPortal_Domain_Dto_PriceListItemSummary,
    opts?: {
      category?: Category;
      categorySource?: Category[];
    }
  ) => {
    let category = (() => {
      if (opts?.category) {
        return opts.category;
      }

      if (opts?.categorySource?.length) {
        return opts.categorySource.find((cat) => {
          return cat.id === priceListItem.categoryId;
        });
      }
    })();

    if (!category) {
      // console.warn('category mocked');

      category = new Category({
        id: priceListItem.categoryId || 0,
        name: priceListItem.categoryName,
        displayName: i18n.t(priceListItem.categoryName as TKey),
      });
    }

    return new Product({
      ...priceListItem,
      category,
      imageUrls: priceListItem.imageUrls || undefined,
    });
  },
};
