import { IdType } from '@/model/common';
import { ITEPortal_Domain_Dto_OrderDto } from '../api';

export const LOGIN = 'auth/email';
export const CODE_VERIFY = 'auth/token';
export const SESSION_EXTEND = 'auth/refresh-token';
export const EXHIBITIONS = 'exhibitions/exhibitor-statistics';
export const STANDS = (exhibitionId: IdType) =>
  `exhibitions/${exhibitionId}/exhibitor-stands`;
export const CATEGORIES = 'categories';
export const EXHIBITOR_CATEGORIES = 'categories/price-list';
export const SAVE_ORDER = 'orders';
export const SAVE_DRAFT_ORDER = 'orders/draft';
export const DELETE_ORDER = (orderId: IdType) => `orders/${orderId}`;
export const GET_ORDER = (orderId: IdType) => `orders/${orderId}`;
export const GET_PDF_BY_ORDER = (
  orderId: ITEPortal_Domain_Dto_OrderDto['orderId']
) => `orders/${orderId}/pdf`;
export const EXHIBITIONS_TABLE = 'exhibitions/admin-summaries';
export const EXHIBITORS_FROM_EXHIBITOR = 'exhibitors/exhibition';
export const CREATE_EXHIBITION = 'exhibitions';
export const EDIT_EXHIBITION = (exhibitionId: IdType) =>
  `exhibitions/${exhibitionId}`;
export const EXHIBITION_DATA = (exhibitionId: IdType) =>
  `exhibitions/${exhibitionId}`;
export const SELLING_OFFICES = 'selling-offices';
export const USERS = 'users';
export const EDIT_USER_INFO = (userId: IdType) => `users/${userId}`;
export const ADD_USER = `users/add`;
export const EDIT_USER = `users/update`;
export const DELETE_USER = (userId: IdType) => `users/delete/${userId}`;
export const TECHNICAL_MANAGERS = 'users/technical-managers';
export const ADD_EXHIBITOR = 'exhibitors';
export const EDIT_EXHIBITOR = (exhibitorId: IdType) =>
  `exhibitors/${exhibitorId}`;
export const USER_CHECK = 'users/email';
export const PLACED_ORDERS = 'orders';
export const CHANGE_ORDER_STATUS = (orderId: IdType) =>
  `orders/${orderId}/status`;
export const SAVE_STAND = (exhibitionId: IdType) =>
  `exhibitions/${exhibitionId}/stands`;
export const EDIT_STAND = (exhibitionId: IdType, standId: IdType) =>
  `exhibitions/${exhibitionId}/stands/${standId}`;
export const EDIT_MAIN_INFO = (exhibitorId: IdType) =>
  `exhibitors/${exhibitorId}`;
export const GET_STAND = (exhibitionId: IdType, exhibitorId: IdType) =>
  `exhibitions/${exhibitionId}/exhibitors/${exhibitorId}/stands`;
export const SAVE_EXHIBITION_IMG = (exhibitionId: IdType) =>
  `exhibitions/${exhibitionId}/logo`;
export const GET_PRICELISTS = (exhibitionId: IdType) =>
  `exhibitions/${exhibitionId}/price-lists`;
export const GET_PRICELIST_ITEMS = (
  exhibitionId: IdType,
  priceListId: IdType
) => `exhibitions/${exhibitionId}/price-lists/${priceListId}/items`;
export const GET_PRICELIST_ITEM_IDS = 'products';
export const GET_PRICELIST_ITEM_IDS_SHORT = 'products/short';
export const DOWNLOAD_EXHIBITOR_CSV = (exhibitionId: IdType) =>
  `exhibition/${exhibitionId}/exhibitors/batch`;
export const ADD_PRICELIST_ITEM = (exhibitionId: IdType, priceListId: IdType) =>
  `exhibitions/${exhibitionId}/price-lists/${priceListId}/items`;
export const EDIT_PRICELIST_ITEM = (
  exhibitionId: IdType,
  priceListId: IdType,
  priceListItemId: IdType
) =>
  `exhibitions/${exhibitionId}/price-lists/${priceListId}/items/${priceListItemId}`;
export const IMPORT_PRICELIST_ITEMS = (
  exhibitionId: IdType,
  priceListId: IdType
) => `exhibitions/${exhibitionId}/price-lists/${priceListId}/items/batch`;
export const GET_PRICELIST_PRODUCTS = 'pricelist-items';
export const GET_PRODUCTS = 'products';
export const SAVE_PRODUCT_IMAGES = (name: string) => `products/${name}/images`;
export const SAVE_CATEGORIES = 'categories';
export const SAVE_CATEGORY_IMAGES = (name: string) =>
  `categories/${name}/image`;
export const MEMBERS = 'admin-summaries';
export const EDIT_MEMBERS = (memberId: IdType) => `admin-summaries/${memberId}`;
export const DELETE_EXHIBITOR = (exhibitionId: IdType, exhibitorId: IdType) =>
  `exhibitions/${exhibitionId}/exhibitors/${exhibitorId}`;
export const EDIT_PRODUCT_RESOURCES = (name: string) =>
  `products/${name}/resources`;
export const EDIT_CATEGORY_RESOURCES = (name: string) =>
  `categories/${name}/resources`;
export const PARENT_CATEGORIES = 'categories/parent-categories';
export const GLOBAL_SETTINGS = 'global-settings';
export const SAVE_FROM_EMAIL = 'global-settings/edit-fromEmail';
export const SAVE_USER_NAME = 'global-settings/edit-username';
export const SAVE_HOST = 'global-settings/edit-host';
export const SAVE_PORT = 'global-settings/edit-port';
export const EDIT_PASSWORD = 'global-settings/edit-password';
export const DELETE_PRICE_LIST = (exhibitionId: IdType, priceListId: IdType) =>
  `exhibitions/${exhibitionId}/price-lists/${priceListId}`;
export const CHANGE_ORDER_PRODUCT = (exhibitionId: IdType, orderId: IdType) =>
  `exhibitions/${exhibitionId}/orders/${orderId}`;
export const CHANGE_MAIN_LOGO = 'global-settings/edit-logo';
export const SEND_EMAIL = (orderId: IdType) =>
  `orders/${orderId}/send-order-changed-confirmation`;
export const DELETE_ORDER_ITEM = (exhibitionId: IdType, orderId: IdType) =>
  `exhibitions/${exhibitionId}/orders/${orderId}`;
export const DELETE_SERVICE = 'products';
