import { useMatches } from '@tanstack/react-router';
import { SidebarByRole } from './SidebarsByPageType/SidebarByRole';
import { ExhibitionSidebar } from './SidebarsByPageType/ExhibitionSidebar';

export const ConnectedSidebar = () => {
  // todo ad refactoring
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { context = {} } = useMatches().at(-1);

  switch (context.page) {
    case 'personal-accounts':
    case 'placed-orders': {
      return <ExhibitionSidebar />;
    }

    default:
      return <SidebarByRole />;
  }
};
