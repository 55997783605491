import { FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import React, { useEffect, useState } from 'react';
import { ModalFactory } from '@/components/Modal/api';
import { Stack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { SelectComponent } from '@/components/MultiSelect/MultiSelect';
import * as vali from 'zod';
import i18n from '@/i18n';
import { OptionType } from '@/model/common';
import { typedAxios } from '@/api/typed-axios';
import { useQuery } from '@tanstack/react-query';
import { QueryOptions } from '@/features/query/query-options';
import { ITEPortal_Domain_Dto_Bitrix_EventEdition_EventEditionDropdownDto } from '@/api/models/ITEPortal_Domain_Dto_Bitrix_EventEdition_EventEditionDropdownDto';

const OptionSchema = vali.object(
  {
    value: vali.coerce.number(),
    label: vali.string(),
  },
  {
    required_error: i18n.t('required-field'),
  }
);

const ValidationSchema = vali.object({
  eventMaster: OptionSchema,
  exhibition: OptionSchema,
  technicalManagers: vali.array(OptionSchema),
});

export type ValidationSchemaType = vali.infer<typeof ValidationSchema>;

export const ImportExhibitionForm = () => {
  const { t } = useTranslation();
  const [eventMasterId, setEventMasterId] = useState<number>();
  const [exhibition, setExhibition] =
    useState<
      Array<
        OptionType<ITEPortal_Domain_Dto_Bitrix_EventEdition_EventEditionDropdownDto>
      >
    >();
  const [technicalManagers, setTechnicalManagers] = useState<OptionType[]>();
  const instance = useForm<ValidationSchemaType>({
    resolver: zodResolver(ValidationSchema),
    mode: 'onChange',
  });
  const { data: eventMasterOptions = [] } = useQuery(
    QueryOptions.loadEventMasterOptions()
  );

  useEffect(() => {
    const fetchExhibition = async () => {
      const exhibitionsData = await typedAxios.bitrix.getBitrixEventEditions({
        eventMasterId,
      });

      const exhibitionOptions = exhibitionsData.map((ex) => ({
        value: ex.id,
        label: ex.title,
        meta: ex,
      })) satisfies Array<
        OptionType<ITEPortal_Domain_Dto_Bitrix_EventEdition_EventEditionDropdownDto>
      >;

      setExhibition(exhibitionOptions);
    };

    if (eventMasterId) {
      instance.resetField('exhibition');
      fetchExhibition();
    }
  }, [eventMasterId]);

  useEffect(() => {
    const fetchTechnicalManagers = async () => {
      const technicalManagersData = await typedAxios.user
        .getUsersTechnicalManagers()
        .catch(() => []);

      const technicalManagersOptions = technicalManagersData.map((manager) => {
        return {
          label: manager.email || manager.name,
          value: manager.id,
          meta: { contactId: manager.contactId },
        };
      });

      setTechnicalManagers(technicalManagersOptions);
    };

    fetchTechnicalManagers();
    ModalFactory.updateContext(instance);
  }, [instance]);

  return (
    <FormProvider {...instance}>
      <Stack spacing={4}>
        <SelectComponent<ValidationSchemaType>
          label={t('event-master')}
          options={eventMasterOptions}
          isRequired
          name="eventMaster"
          onChange={({ value }) => setEventMasterId(value)}
        />
        {eventMasterId && (
          <>
            <SelectComponent<ValidationSchemaType>
              label={t('exhibitions')}
              options={exhibition}
              isRequired
              name="exhibition"
            />
            <SelectComponent<ValidationSchemaType>
              label={t('technical-manager')}
              options={technicalManagers}
              isRequired
              name="technicalManagers"
              isMulti
            />
          </>
        )}
      </Stack>
    </FormProvider>
  );
};
