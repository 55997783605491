import Layout from '@/layout/Default';
import TechnicalServicesGrid from '@/components/TechnicalServicesGrid';
import { TKey } from '../../i18n/types';

interface CategoriesProps {
  title?: TKey;
}

const Categories: React.FC<CategoriesProps> = ({ title }) => {
  return (
    <Layout>
      <TechnicalServicesGrid title={title} />
    </Layout>
  );
};

export default Categories;
