import { queryOptions, useQuery } from '@tanstack/react-query';
import { typedAxios } from '@/api/typed-axios';
import { useSearch } from '@tanstack/react-router';
import { isNil } from '@/utils/is-nil';

export const useHasCategorySearchCriteria = () => {
  const { search, searchProduct } = useSearch({
    strict: false,
  });

  return !isNil(search) || !isNil(searchProduct);
};

export const useHasProductSearchCriteria = () => {
  const { searchProduct } = useSearch({
    strict: false,
  });

  return !isNil(searchProduct);
};

export const useCategoriesLoaderQueryOptions = () => {
  const { search, searchProduct } = useSearch({
    strict: false,
  });

  return queryOptions({
    queryKey: ['service-directory-categories', { search, searchProduct }],
    queryFn: async () =>
      await typedAxios.category.getCategories({
        pageSize: -1,
        search,
        product: searchProduct,
      }),

    structuralSharing: true,
  });
};

export const useSubCategoriesLoaderQueryOptions = (
  parentCategoryId: number
) => {
  const { searchProduct } = useSearch({
    strict: false,
  });

  return queryOptions({
    queryKey: [
      'service-directory-sub-categories',
      { searchProduct, parentCategoryId },
    ],
    queryFn: async () =>
      await typedAxios.category.getCategories({
        pageSize: -1,
        // product: searchProduct,
        parentCategoryId: parentCategoryId,
      }),

    structuralSharing: true,
  });
};

export const useCategoriesLoader = () => {
  return useQuery(useCategoriesLoaderQueryOptions());
};

export const useSubCategoriesLoader = (parentCategoryId: number) => {
  return useQuery(useSubCategoriesLoaderQueryOptions(parentCategoryId));
};
