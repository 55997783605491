import * as v from 'zod';
import dayjs from 'dayjs';
import i18n from '../../../i18n';

export const ValidationSchema = v
  .object({
    'name': v.string().min(1, { message: 'Name field is required' }),
    'description': v.string().nullable(),
    'logoUrl': v.string().optional().nullable(),
    'startDate': v.coerce.date(),
    'endDate': v.coerce.date(),
    'place': v.string().min(1, { message: 'Place field is required' }),
    'technicalManagers': v
      .array(
        v.object({
          value: v.string(),
          label: v.string(),
        })
      )
      .min(1, { message: 'At least one manager is required' }),
    'firstMargin': v.coerce.number(),
    'firstMarginDate': v.coerce.date(),
    'secondMargin': v.coerce.number().gt(0),
    'secondMarginDate': v.coerce.date(),
    'thirdMargin': v.coerce.number().gt(0),
    'thirdMarginDate': v.coerce.date(),
    'orderCreationLastDate': v.coerce.date().optional().nullable(),
    'upload-image': v.array(v.instanceof(File)).optional(),
  })
  .refine(
    (data) => {
      if (!data.firstMarginDate || !data.secondMarginDate) {
        return true;
      }

      const firstMargin = dayjs(data.firstMarginDate);
      const secondMarginDate = dayjs(data.secondMarginDate);

      return firstMargin.isBefore(secondMarginDate);
    },
    {
      path: ['secondMarginDate'],
      message: i18n.t('first-margin-before second-margin'),
    }
  )
  .refine(
    (data) => {
      if (!data.secondMarginDate || !data.thirdMarginDate) {
        return true;
      }

      const secondMarginDate = dayjs(data.secondMarginDate);
      const thirdMarginDate = dayjs(data.thirdMarginDate);

      return secondMarginDate.isBefore(thirdMarginDate);
    },
    {
      path: ['thirdMarginDate'],
      message: i18n.t('second-margin-before third-margin'),
    }
  )
  .refine(
    (data) => {
      if (!data.startDate || !data.endDate) {
        return true;
      }

      const startDate = dayjs(data.startDate);
      const endDate = dayjs(data.endDate);

      return startDate.isBefore(endDate);
    },
    {
      path: ['startDate'],
      message: i18n.t('exhibition-start-date-error'),
    }
  )
  .refine(
    (data) => {
      if (!data.orderCreationLastDate) {
        return true;
      }

      const startDate = dayjs(data.startDate);
      const endDate = dayjs(data.endDate);
      const orderCreationLastDate = dayjs(data.orderCreationLastDate);

      // Validate if orderCreationLastDate is after endDate
      if (orderCreationLastDate.isAfter(endDate)) {
        return false;
      }

      // Validate if orderCreationLastDate is before startDate
      if (orderCreationLastDate.isBefore(startDate)) {
        return false;
      }

      return true;
    },
    {
      path: ['orderCreationLastDate'],
      message: i18n.t('order-creation-last-date-error'),
    }
  )
  .transform((data) => ({
    ...data,
    startDate: new Date(data.startDate),
    endDate: new Date(data.endDate),
    firstMarginDate: new Date(data.firstMarginDate),
    secondMarginDate: new Date(data.secondMarginDate),
    thirdMarginDate: new Date(data.thirdMarginDate),
    orderCreationLastDate: data.orderCreationLastDate
      ? new Date(data.orderCreationLastDate)
      : null,
  }));

export type FormValues = v.infer<typeof ValidationSchema>;
