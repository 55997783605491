import { Box, Code } from '@chakra-ui/react';
import { Table } from '@/components/Table/Table';
import { useMemo } from 'react';
import { Column } from 'react-table';
import { BitrixTableData } from '@/pages/AdminPanel/Settings/BitrixPassport/TableData';
import styled from 'styled-components';

export type BitrixPassportTableFields = {
  name: string;
  description: string;
  method: string;
  url: string;
  queryParams?: string;
  requestBody?: string;
  response?: string;
};

const TableMainCallData = styled(Box)`
  font-weight: 600;
  font-size: 14px;
`;

const TableCallData = styled(Box)`
  font-size: 14px;
`;

const CodeWrapper = styled(Code)`
  text-align: left;
  white-space: pre;
  word-spacing: normal;
  word-break: normal;
  word-wrap: normal;
  line-height: 1.5;
  font-size: 14px;
  font-weight: 400;
`;

export const BitrixIntegrationPassportTab = (): JSX.Element => {
  const codeCellData = (value: string | undefined) => {
    return value ? <CodeWrapper>{value}</CodeWrapper> : <Box>-</Box>;
  };

  const columns = useMemo<Array<Column<BitrixPassportTableFields>>>(() => {
    return [
      {
        accessor: 'description',
        Header: 'Description',
        width: '20%',
        Cell: ({ value }) => {
          return <TableMainCallData>{value}</TableMainCallData>;
        },
      },
      {
        accessor: 'name',
        Header: 'Name',
        Cell: ({ value }) => {
          return <TableCallData>{value}</TableCallData>;
        },
      },
      {
        accessor: 'method',
        Header: 'Method',
        width: '5%',
        Cell: ({ value }) => {
          return <TableCallData>{value}</TableCallData>;
        },
      },
      {
        accessor: 'url',
        Header: 'URL',
        Cell: ({ value }) => {
          return codeCellData(value);
        },
      },
      {
        accessor: 'queryParams',
        Header: 'Query params',
        width: '15%',
        Cell: ({ value }) => {
          return codeCellData(value);
        },
      },
      {
        accessor: 'requestBody',
        Header: 'Request body',
        Cell: ({ value }) => {
          return codeCellData(value);
        },
      },
      {
        accessor: 'response',
        Header: 'Response',
        Cell: ({ value }) => {
          return codeCellData(value);
        },
      },
    ];
  }, []);

  return (
    <Box>
      <Table columns={columns} data={BitrixTableData} />
    </Box>
  );
};
