import { SingleDatepicker } from 'chakra-dayzed-datepicker';
import { DatePickerDays, DatePickerMonths } from '@/utils/constants';
import { FieldProps } from '@/components/Fields/interfaces';
import { FormErrorMessage, Box, FormControl } from '@chakra-ui/react';
import { FormLabel } from '@/components/FormLabel/FormLabel';
import {
  Controller,
  FieldValues,
  useController,
  useFormContext,
} from 'react-hook-form';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

interface DatePickerProps<T extends FieldValues> extends FieldProps<T> {
  defaultValue?: Date;
  dateFormat?: string;
  width?: string | number;
  errors?: any;
}

export const DatePicker = <T extends FieldValues = FieldValues>({
  dateFormat = 'dd.MM.yyyy',
  // @ts-expect-error as default string doesn't found in generic T
  name = 'date-picker',
  label,
  isRequired,
  control,
  defaultValue,
}: DatePickerProps<T>) => {
  const formContext = useFormContext();

  if (!formContext) {
    throw new Error(
      `This field (${name}) should be used only within FormContext`
    );
  }

  const { fieldState } = useController({
    name: name,
  });

  if (!fieldState) {
    throw new Error(
      'fieldState not found. Is this field used within FormContext?'
    );
  }

  dayjs.extend(utc);
  dayjs.extend(timezone);

  const defaultDate = defaultValue
    ? new Date(dayjs(defaultValue).toString())
    : undefined;

  return (
    <Box width="100%">
      <FormControl
        isInvalid={fieldState.invalid}
        isRequired={isRequired}
        display="felx"
        flexDirection="column"
      >
        {label && <FormLabel>{label}</FormLabel>}
        <Controller
          control={control}
          name={name}
          render={({ field }) => {
            let fieldValue = field.value;

            if (typeof field.value === 'string') {
              // @ts-expect-error desc
              fieldValue = new Date(dayjs(field.value).toString());
            }

            return (
              <SingleDatepicker
                date={fieldValue || defaultDate}
                onDateChange={(date: Date) => {
                  field.onChange(date);
                }}
                configs={{
                  dateFormat: dateFormat,
                  dayNames: DatePickerDays,
                  monthNames: DatePickerMonths,
                }}
              />
            );
          }}
        />
        {fieldState.error?.message && (
          <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
        )}
      </FormControl>
    </Box>
  );
};
