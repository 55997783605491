import { FormProvider, useForm } from 'react-hook-form';
import React, { useEffect, useState } from 'react';
import { Box, FormControl, Stack, Button } from '@chakra-ui/react';
import { FormLabel } from '@/components/FormLabel/FormLabel';
import { useNameTranslate } from '@/hooks/useStringName';
import Input from '@/components/Fields/Input';
import styled from 'styled-components';
import { settingsRoute } from '../../../features/Routing/protected/settings';
import { typedAxios } from '../../../api/typed-axios';
import {
  ApiError,
  CancelablePromise,
  ITEPortal_Domain_Dto_GlobalSettingsDto,
} from '../../../api';

const FormControlWrapper = styled(FormControl)`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const ParticipantCheckContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  gap: 10px;
  width: 100%;
`;

const ParticipantCheckWrapper = styled(Box)`
  min-width: 100%;
`;

type SettingsFormValues = Pick<
  ITEPortal_Domain_Dto_GlobalSettingsDto,
  'host' | 'port' | 'username' | 'fromEmail'
>;

export const Settings = (): JSX.Element => {
  const methods = useForm<SettingsFormValues>();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { settings } = settingsRoute.useLoaderData();

  useEffect(
    function setFormValuesOfLoadedModel() {
      methods.reset({
        ...settings,
      });
    },
    [settings]
  );

  const save = async (saverFn: CancelablePromise<any>) => {
    try {
      setIsSubmitting(true);
      await saverFn;
    } catch (e) {
      if (e instanceof ApiError) {
        const fieldErrors = e.body.errors;

        if (fieldErrors) {
          Object.entries(fieldErrors).forEach(([fieldName, errors]) => {
            methods.setError(fieldName as keyof SettingsFormValues, {
              message: errors as string,
            });
          });
        }
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <FormProvider {...methods}>
      <form>
        <Stack spacing="5">
          <FormControlWrapper>
            <ParticipantCheckWrapper>
              <FormLabel>{useNameTranslate('from-email')}</FormLabel>
              <ParticipantCheckContainer>
                <Input<SettingsFormValues> maxWidth="360px" name="fromEmail" />
                <Button
                  colorScheme="red"
                  isLoading={isSubmitting}
                  onClick={methods.handleSubmit(({ fromEmail }) =>
                    save(
                      typedAxios.globalSettings.postGlobalSettingsEditFromEmail(
                        {
                          fromEmail: fromEmail || undefined,
                        }
                      )
                    )
                  )}
                >
                  {useNameTranslate('save')}
                </Button>
              </ParticipantCheckContainer>
            </ParticipantCheckWrapper>
            <ParticipantCheckWrapper>
              <FormLabel>{useNameTranslate('username-email')}</FormLabel>
              <ParticipantCheckContainer>
                <Input maxWidth="360px" name="username" />
                <Button
                  colorScheme="red"
                  isLoading={isSubmitting}
                  onClick={methods.handleSubmit(({ username }) =>
                    save(
                      typedAxios.globalSettings.postGlobalSettingsEditUsername({
                        username: username || undefined,
                      })
                    )
                  )}
                >
                  {useNameTranslate('save')}
                </Button>
              </ParticipantCheckContainer>
            </ParticipantCheckWrapper>
            <ParticipantCheckWrapper>
              <FormLabel>{useNameTranslate('host')}</FormLabel>
              <ParticipantCheckContainer>
                <Input maxWidth="360px" name="host" />
                <Button
                  colorScheme="red"
                  isLoading={isSubmitting}
                  onClick={methods.handleSubmit(({ host }) =>
                    save(
                      typedAxios.globalSettings.postGlobalSettingsEditHost({
                        host: host || undefined,
                      })
                    )
                  )}
                >
                  {useNameTranslate('save')}
                </Button>
              </ParticipantCheckContainer>
            </ParticipantCheckWrapper>
            <ParticipantCheckWrapper>
              <FormLabel>{useNameTranslate('port')}</FormLabel>
              <ParticipantCheckContainer>
                <Input maxWidth="360px" name="port" />
                <Button
                  colorScheme="red"
                  isLoading={isSubmitting}
                  onClick={methods.handleSubmit(({ port }) =>
                    save(
                      typedAxios.globalSettings.postGlobalSettingsEditPort({
                        port: port || undefined,
                      })
                    )
                  )}
                >
                  {useNameTranslate('save')}
                </Button>
              </ParticipantCheckContainer>
            </ParticipantCheckWrapper>
          </FormControlWrapper>
        </Stack>
      </form>
    </FormProvider>
  );
};
