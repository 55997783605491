import { FieldValues } from 'react-hook-form';
import { isOption } from './is-option';

export const formOptionsAsValues = <T extends FieldValues = FieldValues>(
  values: T
): T => {
  const transformed: FieldValues = {};

  Object.entries(values).forEach(([key, value]) => {
    if (isOption(value)) {
      transformed[key] = value.value;
    } else {
      transformed[key] = value;
    }
  });

  return transformed as T;
};
