export const USERS_PATH = '/users';
export const SHOW_EXHIBITION_PATH = '/exhibitions/:exhibitionId';
export const SHOW_SUPPORT_MANAGER_PATH = '/admin/:page';
export const SHOW_SUPPORT_MANAGER_ACTION_PATH = '/admin/:page/:type';
export const SHOW_SUPPORT_MANAGER_ADDITIONAL_PATH = '/additionalPage/:page';
export const SHOW_SUPPORT_MANAGER_ADDITIONAL_ACTION_PATH =
  '/additionalPage/:page/:type';
export const CREATE_EXHIBITION_ORDER_PATH =
  '/exhibitions/:exhibitionId/:stand/create';
export const CREATE_EXHIBITION_ORDER_TAB_PATH =
  '/exhibitions/:exhibitionId/:stand/create/:service';
export const CREATE_EXHIBITION_CART_PATH =
  '/exhibitions/:exhibitionId/:stand/cart';

export const MANUAL_PATH = '/manual';
