import { ITechnicalService } from '@/model/ITechnicalService';
import DevelopmentIcon from '@/components/Icons/Development';
import { Category } from '@/model/ICategory';
import {
  PERSONAL_ACCOUNTS,
  PLACED_ORDERS,
} from '@/constants/technical-services';
import { IdType } from '@/model/common';
import { checkRBACRule } from '@/features/rbac/useRBAC';
import { $auth } from '@/store/effector/auth';

const supportManagerAdditionalMenu = (exhibitionId: IdType, t: any) => {
  const canManagePersonalAccounts = checkRBACRule($auth.getState().user)(
    'canAccessExhibitionPersonalAccounts'
  );

  const menuItems: ITechnicalService[] = [];

  if (canManagePersonalAccounts) {
    menuItems.push({
      category: new Category({
        id: PERSONAL_ACCOUNTS,
        name: PERSONAL_ACCOUNTS,
        displayName: t(PERSONAL_ACCOUNTS),

        resolveUrl: () => {
          return `/admin/exhibitions/${exhibitionId}/view/personal-accounts`;
        },
      }),
      image: <DevelopmentIcon />,
    });
  }

  menuItems.push({
    category: new Category({
      id: PLACED_ORDERS,
      name: PLACED_ORDERS,
      displayName: t(PLACED_ORDERS),

      resolveUrl: () => {
        return `/admin/exhibitions/${exhibitionId}/view/placed-orders`;
      },
    }),
    image: <DevelopmentIcon />,
  });

  return menuItems;
};

export default supportManagerAdditionalMenu;
