import { ChakraProvider } from '@chakra-ui/react';
import 'simplebar-react/dist/simplebar.min.css';
import { RouterProvider } from '@/features/Routing/provider';
import DEFAULT_THEME from './theme/default/index';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { ModalFactoryProvider } from './components/Modal/Provider';
import { GlobalStyles } from '@/theme/default/Globals';

function App(): JSX.Element {
  return (
    <ChakraProvider theme={DEFAULT_THEME}>
      <GlobalStyles />
      <RouterProvider />
      <ToastContainer />
      <ModalFactoryProvider />
    </ChakraProvider>
  );
}

export default App;
