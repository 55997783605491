import { FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect, useState } from 'react';
import { ModalFactory } from '@/components/Modal/api';
import { Button, Flex, Stack } from '@chakra-ui/react';
import Input from '@/components/Fields/Input';
import { useEmployeeSearch } from '@/pages/AdminPanel/PersonalAccounts/UsersTab/hooks/use-employee-search';
import { useTranslation } from 'react-i18next';
import { type ITEPortal_Domain_Dto_Exhibitor_GetExhibitorEmployeeDto } from '@/api';
import {
  EmployeeFormValues,
  EmployeeFormSchema,
} from '@/pages/AdminPanel/PersonalAccounts/UsersTab/types';
import { toast } from 'react-toastify';

export const AddNewEmployeeForm = () => {
  const { t } = useTranslation();
  const instance = useForm<EmployeeFormValues>({
    resolver: zodResolver(EmployeeFormSchema),
    mode: 'onChange',
  });

  const [foundModel, setFoundModel] = useState<
    ITEPortal_Domain_Dto_Exhibitor_GetExhibitorEmployeeDto | null | undefined
  >(null);

  const { findUser, isSearching } = useEmployeeSearch();

  useEffect(() => {
    ModalFactory.updateContext(instance);
  }, [instance]);

  useEffect(() => {
    if (foundModel && foundModel.email && foundModel.name) {
      instance.reset({
        id: foundModel.employeeId,
        email: foundModel.email,
        name: foundModel.name,
        phoneNumber: foundModel.phoneNumber,
      });
    } else {
      instance.reset({
        name: '',
      });
    }
  }, [foundModel]);

  return (
    <FormProvider {...instance}>
      <Stack spacing={4}>
        <Flex flexDir="row" gap={4}>
          <Input<EmployeeFormValues>
            name="email"
            placeholder={t('employee-email')}
            maxWidth="unset"
          />
          <Button
            minW="fit-content"
            isLoading={isSearching}
            isDisabled={isSearching}
            onClick={async () => {
              const foundEmployee = await findUser(instance);

              if (!foundEmployee) {
                toast.warning(t('employee-not-found'));
              }

              setFoundModel(foundEmployee);
            }}
          >
            {t('find-employee')}
          </Button>
        </Flex>
        <Input<EmployeeFormValues>
          name="name"
          disabled={Boolean(foundModel)}
          label={t('employee-name')}
          maxWidth="unset"
        />
        <Input<EmployeeFormValues>
          name="phoneNumber"
          disabled={Boolean(foundModel)}
          label={t('phone')}
          type="tel"
          maxWidth="unset"
        />
      </Stack>
    </FormProvider>
  );
};
