import { ModalFactory } from '@/components/Modal/api';
import i18n from '@/i18n';
import { AddNewEmployeeForm } from '@/pages/AdminPanel/MembersDirectory/ManageExhibitorUsers/AddNewEmployeeForm';
import { UseFormReturn } from 'react-hook-form';
import { EmployeeFormValues } from '@/pages/AdminPanel/PersonalAccounts/UsersTab/types';
import { typedAxios } from '@/api/typed-axios';
import { toast } from 'react-toastify';
import { ITEPortal_Domain_Dto_Exhibitor_GetExhibitorEmployeeDto } from '@/api';
import { useRouter } from '@tanstack/react-router';
import { viewExhibitorRoute } from '@/features/Routing/protected/member-directory';

export const useSaveEmployee = () => {
  const router = useRouter();
  const { exhibitorId } = viewExhibitorRoute.useParams();

  const openForm = (
    model?: ITEPortal_Domain_Dto_Exhibitor_GetExhibitorEmployeeDto
  ) => {
    ModalFactory.show({
      title: model ? i18n.t('edit-employee') : i18n.t('add-employee'),
      Component: () => <AddNewEmployeeForm model={model} />,
      okLabel: i18n.t('save'),
      onOk: async (ctx: UseFormReturn<EmployeeFormValues>) => {
        const isValid = await ctx.trigger();

        if (!isValid) {
          return;
        }

        const values = ctx.getValues();

        try {
          if (model && model.employeeId) {
            await typedAxios.exhibitor.putExhibitorsEmployees({
              employeeId: model.employeeId,
              requestBody: values,
            });
          } else {
            await typedAxios.exhibitor.postExhibitorsEmployees({
              exhibitorId: +exhibitorId,
              requestBody: values,
            });
          }

          router.invalidate();

          ModalFactory.close();
        } catch {
          toast.error(i18n.t('error-occurred'));
        }
      },
    });
  };

  return { openForm };
};
