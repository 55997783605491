import { ITEPortal_Domain_Dto_ExhibitionDto } from '../api';
import dayjs from 'dayjs';
import { isValidExhibition } from '@/model/IExhibition/shema';

// prettier-ignore
export class IExhibition implements ITEPortal_Domain_Dto_ExhibitionDto {
  id: number | null = null;
  description?: string | null;
  endDate?: string;
  firstMargin?: number | null;
  firstMarginDate?: string | null;
  logoUrl?: string | null;
  name?: string | null;
  place?: string | null;
  secondMargin?: number;
  secondMarginDate?: string;
  startDate?: string;
  orderCreationLastDate?: string;
  technicalManagers?: Array<string> | null;
  thirdMargin?: number;
  thirdMarginDate?: string;

  constructor(
    json: ITEPortal_Domain_Dto_ExhibitionDto & {id?: number}
  ) {
    Object.assign(this, json);
  }

  get isPast() {
    if (!this.endDate) {
      return true;
    }

    const currentDate = dayjs();
    const endDate = dayjs(this.endDate);

    return endDate.isBefore(currentDate);
  }

  get canEditOrders() {
    if (!this.orderCreationLastDate) {
      return !this.isPast
    }

    const currentDate = dayjs();
    const endDate = dayjs(this.orderCreationLastDate);

    return currentDate.isBefore(endDate);
  }

  get formattedStartDate() {
    if (!this.startDate) {
      return 'n/a';
    }

    return dayjs(this.startDate).format('DD.MM.YYYY');
  }

  get formattedEndDate() {
    if (!this.endDate) {
      return 'n/a';
    }

    return dayjs(this.endDate).format('DD.MM.YYYY');
  }

  static fromJSON(json: ITEPortal_Domain_Dto_ExhibitionDto) {
    if (isValidExhibition(json)) {
      return new  IExhibition(json)
    }

    return null
  }
}
