import { Alert, AlertIcon, Link } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

type NoStandsLinkPropsType = {
  exhibitionId: number;
  exhibitorId: number;
};

export const NoPriceListsLink = (props: NoStandsLinkPropsType) => {
  const { t } = useTranslation();

  return (
    <Alert status="warning" mt={4} mb={4} whiteSpace="break-spaces">
      <AlertIcon />
      {t('no-price-lists-warning', {
        here: (
          <Link
            fontWeight="bolder"
            textDecoration="underline"
            target="_blank"
            href={`/admin/exhibitions/${props.exhibitionId}/edit`}
          >
            {t('here')}
          </Link>
        ),
        postProcess: 'reactPostprocessor',
      })}
    </Alert>
  );
};
