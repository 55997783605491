import styled from 'styled-components';
import { Text, Avatar as ChakraAvatar } from '@chakra-ui/react';

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Avatar = styled(ChakraAvatar)`
  max-width: 40px;
  max-height: 40px;

  border-radius: 50%;
  margin-left: 12px;
`;

export const UserInfo = styled(Text)`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
`;
