import { Table } from '@/components/Table/Table';
import { useTranslation } from 'react-i18next';
import { Card } from '@/components/Card/Card';
import { EditIcon, ExternalLinkIcon } from '@chakra-ui/icons';
import { Box, Flex } from '@chakra-ui/react';
import styled from 'styled-components';
import { Button } from '@/components/Buttons/Button/Button';
import { Link, useNavigate } from '@tanstack/react-router';
import { Column, Row } from 'react-table';
import { EXHIBITIONS_TABLE } from '@/constants/endpoints';
import { LoaderWrapper } from '@/components/Loader/Loader';
import dayjs from 'dayjs';
import Pagination from '@/components/Table/Pagination';
import { useTableLoader } from '@/components/Table/useTableLoader';
import { useMemo } from 'react';
import { exhibitionApi } from '@/store/effector/exhibition';
import { ITEPortal_Domain_Dto_AdminExhibitionSummary } from '@/api';
import { exhibitionListRoute } from '@/features/Routing/protected/admin-exhibitions';
import { useRBAC } from '@/features/rbac/useRBAC';
import { ButtonGroup } from '@chakra-ui/react';
import { useExportExhibition } from '@/pages/AdminPanel/Exhibitions/hooks/use-import-exhibition';

const OptionWrapper = styled(Flex)`
  flex-direction: row;
  gap: 20px;
`;

const ButtonContainer = styled(Box)`
  padding-top: 20px;
`;

const FooterContainer = styled(Flex)`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

interface ExhibitionOptionsType {
  row: Row<ITEPortal_Domain_Dto_AdminExhibitionSummary>;
}

export const Exhibitions = (): JSX.Element => {
  const { tab } = exhibitionListRoute.useSearch();
  const { t } = useTranslation();
  const { can, user } = useRBAC();
  const { isLoading, data, onPageChange, onPageSizeChange, paginationInfo } =
    useTableLoader<ITEPortal_Domain_Dto_AdminExhibitionSummary>({
      resource: EXHIBITIONS_TABLE,
      additionalPrams: {
        type: tab,
      },
    });
  const navigate = useNavigate();

  const { openImportModal } = useExportExhibition();

  // const { user } = useAuth();
  // const role = user?.role as AdminRoleType;

  const ExhibitionOptions = ({ row }: ExhibitionOptionsType) => {
    // const editAvailable = !(role == 4 || role == 5);
    // const showAvailable = role !== 3;

    return (
      <OptionWrapper>
        {can('canManageExhibition') && (
          <Link
            to={`/admin/exhibitions/$exhibitionId/view/${
              user?.isAgent ? 'placed-orders' : 'personal-accounts'
            }`}
            params={{
              exhibitionId: row.original.id,
            }}
            onClick={() => {
              exhibitionApi.setEventEditionId(
                row.original.eventEditionId || null
              );

              if (row.original.name) {
                exhibitionApi.setName(row.original.name);
              }
            }}
          >
            <ExternalLinkIcon />
          </Link>
        )}
        {can('canEditExhibition') && (
          <Link
            to="/admin/exhibitions/$exhibitionId/edit"
            params={{
              exhibitionId: row.original.id,
            }}
          >
            <EditIcon />
          </Link>
        )}
      </OptionWrapper>
    );
  };

  const exhibitionColumns = useMemo<
    Array<Column<ITEPortal_Domain_Dto_AdminExhibitionSummary>>
  >(() => {
    return [
      {
        Header: `${t('exhibitionId')}`,
        accessor: 'id',
        width: '10%',
      },
      {
        accessor: 'eventEditionId',
        show: false,
      },
      {
        Header: `${t('exhibitionName')}`,
        accessor: 'name',
      },
      {
        Header: `${t('startDate')}`,
        accessor: 'startDate',
        Cell: ({ row: { values } }) => (
          <>
            {values?.startDate
              ? dayjs(values?.startDate).format('DD.MM.YYYY')
              : ''}
          </>
        ),
      },
      {
        Header: `${t('finDate')}`,
        accessor: 'endDate',
        Cell: ({ row: { values } }) => (
          <>
            {values?.endDate ? dayjs(values?.endDate).format('DD.MM.YYYY') : ''}
          </>
        ),
      },
      {
        Header: `${t('location')}`,
        accessor: 'place',
      },
      {
        Header: '',
        id: 'options',
        width: '5%',
        Cell: ({ row }) => <ExhibitionOptions row={row} />,
      },
    ];
  }, []);

  const addExhibition = () => {
    navigate({ to: '/admin/exhibitions/add' });
  };

  return (
    <LoaderWrapper loading={isLoading}>
      <Card>
        <Table columns={exhibitionColumns} data={data} />
        <FooterContainer>
          <ButtonGroup gap="4" justifyContent="flex-end">
            {can('canAddExhibition') && (
              <ButtonContainer>
                <Button colorScheme="red" onClick={addExhibition}>
                  {t('addExhibition')}
                </Button>
              </ButtonContainer>
            )}
            {can('canAddExhibition') && (
              <ButtonContainer>
                <Button colorScheme="red" onClick={openImportModal}>
                  + {t('bitrixImport')}
                </Button>
              </ButtonContainer>
            )}
          </ButtonGroup>
          <Pagination
            setPageNumber={onPageChange}
            setPageSize={onPageSizeChange}
            paginationInfo={paginationInfo}
          />
        </FooterContainer>
      </Card>
    </LoaderWrapper>
  );
};
