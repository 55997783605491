import { Card, CardProps } from './Card';
import styled from 'styled-components';

const StyledCard = styled(Card)`
  .chakra-card__body {
    padding-top: 0;
    padding-bottom: 0;
  }
`;

const ProductCard = ({ children, className, ...props }: CardProps) => {
  return (
    <StyledCard {...props} className={className}>
      {children}
    </StyledCard>
  );
};

export default ProductCard;
