import { IExhibitionSummary } from '../model/IExhibitionSummary';

const splitExhibitionByDate = (exhibitions: IExhibitionSummary[]) => {
  const { currentExhibitions, pastExhibitions } = exhibitions.reduce(
    (result, exhibition) => {
      if (exhibition.isPast) {
        result.pastExhibitions.push(exhibition);
      } else {
        result.currentExhibitions.push(exhibition);
      }

      return result;
    },
    {
      currentExhibitions: [] as IExhibitionSummary[],
      pastExhibitions: [] as IExhibitionSummary[],
    }
  );

  return { currentExhibitions, pastExhibitions };
};

export const ExhibitionUtils = {
  splitExhibitionByDate,
};
