import {
  Box,
  FormControl,
  FormErrorMessage,
  Textarea as ChakraTextArea,
  TextareaProps as ChakraTextareaProps,
} from '@chakra-ui/react';

import { FieldProps } from '@/components/Fields/interfaces';
import { FormLabel } from '@/components/FormLabel/FormLabel';
import {
  FieldValues,
  Path,
  useController,
  useFormContext,
} from 'react-hook-form';

interface TextAreaProps<T extends FieldValues = FieldValues>
  extends FieldProps<T>,
    Omit<ChakraTextareaProps, 'name'> {
  defaultValue?: string;
}

export const TextArea = <T extends FieldValues = FieldValues>({
  label,
  placeholder,
  // @ts-expect-error as default string doesn't found in generic T
  name = 'text-area',
  defaultValue,
  isRequired,
  ...rest
}: TextAreaProps<T>): JSX.Element => {
  const formContext = useFormContext();

  if (!formContext) {
    throw new Error(
      `This field (${name}) should be used only within FormContext`
    );
  }

  const { fieldState } = useController({
    name: name,
  });

  if (!fieldState) {
    throw new Error(
      'fieldState not found. Is this field used within FormContext?'
    );
  }

  const registerFieldName = formContext.register(name as Path<T>, {
    onChange: () => {
      formContext.clearErrors(name);
    },
  });

  return (
    <Box>
      <FormControl isRequired={isRequired} isInvalid={fieldState.invalid}>
        {label && <FormLabel htmlFor={name}>{label}</FormLabel>}
        <ChakraTextArea
          id={name}
          placeholder={placeholder}
          defaultValue={defaultValue}
          {...rest}
          {...registerFieldName}
        />
        {fieldState.error?.message && (
          <FormErrorMessage>{fieldState.error.message}</FormErrorMessage>
        )}
      </FormControl>
    </Box>
  );
};
