import { Button } from '../../../../components/Buttons/Button/Button';
import { Box } from '@chakra-ui/react';
import React, { useState } from 'react';
import { useCreateOrderContext } from './CreateOrderContext';
import { useTranslation } from 'react-i18next';

export const CreateOrderButton = () => {
  const { t } = useTranslation();
  const { selectedItems, createOrder } = useCreateOrderContext();
  const [isLoading, toggleLoading] = useState(false);

  const hasItems = selectedItems.length > 0;

  const onClick = async () => {
    try {
      toggleLoading(true);

      await createOrder?.();
    } finally {
      toggleLoading(false);
    }
  };

  if (!hasItems) {
    return null;
  }

  return (
    <Box paddingTop="20px" justifyContent="flex-end" display="flex">
      <Button
        colorScheme="primary"
        isLoading={isLoading}
        disabled={isLoading}
        onClick={onClick}
      >
        {t('save')}
      </Button>
    </Box>
  );
};
