type valueType = number;

const isFloat = (n: valueType) => {
  return Number(n) === n && n % 1 !== 0;
};

export const numberWithSpaces = (
  value: valueType,
  currency: string,
  useIntl: boolean = false
) => {
  let buffer = value;

  if (useIntl) {
    return new Intl.NumberFormat(navigator.language, {
      style: 'currency',
      currency: currency || 'RUB',
    }).format(+buffer);
  }

  if (isFloat(buffer)) {
    buffer = Number(buffer.toFixed(2));
  }

  const parts = buffer.toString().split('.');

  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

  return `${parts.join('.')} ${currency}`;
};
