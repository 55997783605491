const DevelopmentIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Layer_1"
    data-name="Layer 1"
    viewBox="0 0 85.04 85.04"
  >
    <path
      d="M41.65 65.39H30.36c-.58 0-1.04-.47-1.04-1.04V33.97c0-.58.47-1.04 1.04-1.04h11.29c.58 0 1.04.47 1.04 1.04v30.38c0 .58-.47 1.04-1.04 1.04ZM31.4 63.31h9.2V35.02h-9.2v28.29Z"
      className="cls-1"
    />
    <path
      d="M45.38 35.02H26.63c-.58 0-1.04-.47-1.04-1.04V23.72c0-.58.47-1.04 1.04-1.04h18.76c.58 0 1.04.47 1.04 1.04v10.26c0 .58-.47 1.04-1.04 1.04Zm-17.71-2.08h16.67v-8.17H27.67v8.17Z"
      className="cls-1"
    />
    <path
      d="M26.63 32.12h-5.12c-.58 0-1.04-.47-1.04-1.04v-4.46c0-.58.47-1.04 1.04-1.04h5.12a1.04 1.04 0 1 1 0 2.08h-4.08v2.38h4.08a1.04 1.04 0 1 1 0 2.08Zm35.3 0H45.39a1.04 1.04 0 1 1 0-2.08h15.5v-2.38h-15.5a1.04 1.04 0 1 1 0-2.08h16.54c.58 0 1.04.47 1.04 1.04v4.46c0 .58-.47 1.04-1.04 1.04Zm1.6 27.89H52.42c-.58 0-1.04-.47-1.04-1.04v-5.48c0-.58.47-1.04 1.04-1.04h11.11c.58 0 1.04.47 1.04 1.04v5.48c0 .58-.47 1.04-1.04 1.04Zm-10.07-2.08h9.03v-3.39h-9.03v3.39Z"
      className="cls-1"
    />
    <path
      d="M57.97 48.9c-.58 0-1.04-.47-1.04-1.04v-16.7a1.04 1.04 0 1 1 2.08 0v16.7c0 .58-.47 1.04-1.04 1.04Z"
      className="cls-1"
    />
    <path
      d="M52.42 54.54c-.28 0-.56-.11-.77-.33-.39-.42-.36-1.08.06-1.47l5.78-5.34c.41-.38 1.05-.37 1.44.03l5.34 5.34c.41.41.41 1.07 0 1.47-.41.41-1.07.41-1.47 0l-4.63-4.63-5.04 4.66c-.2.19-.45.28-.71.28ZM41.65 65.39c-.22 0-.45-.07-.64-.22L29.72 56.4c-.28-.21-.43-.55-.4-.9.03-.35.22-.66.53-.83l9.88-5.54-9.99-7.38c-.28-.21-.44-.54-.42-.88.01-.35.2-.66.5-.84l11.29-6.93a1.039 1.039 0 1 1 1.09 1.77L32.23 41l10.05 7.42c.28.21.44.55.42.9-.02.35-.22.67-.53.84l-9.92 5.57 10.05 7.81c.45.35.54 1.01.18 1.46-.21.26-.51.4-.82.4Z"
      className="cls-1"
    />
  </svg>
);

export default DevelopmentIcon;
