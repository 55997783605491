import { FormProvider, useForm } from 'react-hook-form';
import { TextArea } from '@/components/TextArea/TextArea';
import * as vali from 'zod';
import i18n from '@/i18n';
import { zodResolver } from '@hookform/resolvers/zod';
import { Flex } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { AttachmentUploader } from '@/pages/StandProject/StandProjectDescription/AttachmentUploader';
import { toast } from 'react-toastify';
import { AsyncButton } from '@/components/AsyncButton';
import { typedAxios } from '@/api/typed-axios';
import { standProjectRoute } from '@/features/Routing/protected/exhibitions-by-role';
import { useRouter } from '@tanstack/react-router';
import { useState } from 'react';

const ValidationSchema = vali.object({
  comment: vali.string().min(1, {
    message: i18n.t('required-field'),
  }),
  file: vali.instanceof(File).optional(),
});

type FormValues = vali.infer<typeof ValidationSchema>;

export const CommentForm = () => {
  const { t } = useTranslation();
  const { stand, projectId } = standProjectRoute.useParams();
  const router = useRouter();
  const [uploaderKey, setUploaderKey] = useState<number | null>(null);

  const formInstance = useForm<FormValues>({
    resolver: zodResolver(ValidationSchema),
  });

  const handleSubmit = async (values: FormValues) => {
    try {
      const changeId = await typedAxios.stand.postStandsProjectChanges({
        standId: +stand,
        projectId: +projectId,
        requestBody: {
          comment: values.comment,
        },
      });

      if (values.file instanceof File) {
        await typedAxios.stand.putStandsProjectChanges({
          changesId: +changeId,
          projectId: +projectId,
          standId: +stand,
          formData: {
            file: values.file,
          },
        });
      }

      setUploaderKey(changeId);

      router.invalidate();

      formInstance.reset({
        comment: '',
      });
      toast.success(t('saved'));
    } catch {
      toast.error(t('error-occurred'));
    }
  };

  const approveProject = async () => {
    try {
      await typedAxios.stand.postStandsUpdateProjectStatus({
        projectId: +projectId,
        standId: +stand,
        requestBody: {
          standStatus: true,
        },
      });

      router.invalidate();

      toast.success(t('saved'));
    } catch {
      toast.error(t('error-occurred'));
    }
  };

  return (
    <FormProvider {...formInstance}>
      <Flex direction="column" mt="auto">
        <TextArea<FormValues>
          label={`${t('stand-change-request-label')}:`}
          name="comment"
        />

        <Flex direction="row" gap={4} justifyContent="flex-end" mt={4}>
          <AttachmentUploader
            key={uploaderKey}
            onFileChange={(file) => {
              formInstance.setValue('file', file);
            }}
          />
          <AsyncButton
            size="sm"
            variant="outline"
            colorScheme="red"
            onClick={formInstance.handleSubmit(handleSubmit)}
          >
            {t('request-changes')}
          </AsyncButton>
          <AsyncButton
            size="sm"
            variant="solid"
            colorScheme="red"
            onClick={approveProject}
          >
            {t('approve-project')}
          </AsyncButton>
        </Flex>
      </Flex>
    </FormProvider>
  );
};
