import * as v from 'zod';
import { z } from 'zod';
import i18n from '@/i18n';

const OptionSchema = v.object({
  value: v.coerce.string(),
  label: v.string(),
  __isNew__: v.boolean().optional(),
});

export const NewCompanyFormSchema = z.object({
  id: v.number().optional(),
  companyName: OptionSchema,
  technicalManagerEmail: OptionSchema,
  sellingOfficeId: OptionSchema,
  currencyId: v.object({
    value: v.coerce.number(),
    label: v.string().nullable(),
  }),
  phoneNumber: v.string(),
  employeeName: v.string(),
  employeeEmail: v.string().email({
    message: i18n.t('invalid-email'),
  }),
  isAgent: v.boolean().optional().nullable(),
  agent: v
    .object({
      value: v.coerce.number(),
      label: v.string().nullable(),
    })
    .optional()
    .nullable(),
});

export const UpdateCompanyFormSchema = z.object({
  id: v.number().optional(),
  sellingOfficeId: OptionSchema,
  technicalManagerEmail: OptionSchema,
  currencyId: v.object({
    value: v.coerce.number(),
    label: v.string().nullable(),
  }),
  phoneNumber: v.string(),
  agent: v
    .object({
      value: v.coerce.number(),
      label: v.string().nullable(),
    })
    .optional()
    .nullable(),
});

export type CompanyFormSchemaType = v.infer<typeof NewCompanyFormSchema>;
