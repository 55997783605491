/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ITEPortal_Contracts_Enums_BuildingType } from '../models/ITEPortal_Contracts_Enums_BuildingType';
import type { ITEPortal_Domain_Dto_AddProductDto } from '../models/ITEPortal_Domain_Dto_AddProductDto';
import type { ITEPortal_Domain_Dto_Product_ProductOrderIndexDto } from '../models/ITEPortal_Domain_Dto_Product_ProductOrderIndexDto';
import type { ITEPortal_Domain_Dto_ProductDto } from '../models/ITEPortal_Domain_Dto_ProductDto';
import type { ITEPortal_Domain_Dto_ShortenedProductDto } from '../models/ITEPortal_Domain_Dto_ShortenedProductDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ProductService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns ITEPortal_Domain_Dto_ProductDto Success
     * @throws ApiError
     */
    public getProducts({
        pageNumber,
        pageSize,
        categoryId,
        buildingType,
        search,
    }: {
        pageNumber?: number,
        pageSize?: number,
        categoryId?: number,
        buildingType?: ITEPortal_Contracts_Enums_BuildingType,
        search?: string,
    }): CancelablePromise<Array<ITEPortal_Domain_Dto_ProductDto>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/products',
            query: {
                'PageNumber': pageNumber,
                'PageSize': pageSize,
                'categoryId': categoryId,
                'buildingType': buildingType,
                'search': search,
            },
        });
    }

    /**
     * @returns any Success
     * @throws ApiError
     */
    public postProducts({
        requestBody,
    }: {
        requestBody?: ITEPortal_Domain_Dto_AddProductDto,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/products',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @returns any Success
     * @throws ApiError
     */
    public putProducts({
        requestBody,
    }: {
        requestBody?: ITEPortal_Domain_Dto_AddProductDto,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/products',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @returns any Success
     * @throws ApiError
     */
    public deleteProducts({
        name,
    }: {
        name?: string,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/products',
            query: {
                'name': name,
            },
        });
    }

    /**
     * @returns any Success
     * @throws ApiError
     */
    public getProductsResources({
        name,
    }: {
        name: string,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/products/{name}/resources',
            path: {
                'name': name,
            },
        });
    }

    /**
     * @returns ITEPortal_Domain_Dto_ShortenedProductDto Success
     * @throws ApiError
     */
    public getProductsShort({
        exceptPriceListId,
    }: {
        exceptPriceListId?: number,
    }): CancelablePromise<Array<ITEPortal_Domain_Dto_ShortenedProductDto>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/products/short',
            query: {
                'exceptPriceListId': exceptPriceListId,
            },
        });
    }

    /**
     * @returns any Success
     * @throws ApiError
     */
    public postProductsBatch({
        formData,
    }: {
        formData?: {
            importProductsFile?: Blob;
        },
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/products/batch',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @returns any Success
     * @throws ApiError
     */
    public postProductsResourcesBatch({
        formData,
    }: {
        formData?: {
            importProductsResourcesFile?: Blob;
        },
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/products/resources/batch',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @returns any Success
     * @throws ApiError
     */
    public postProductsImages({
        name,
        formData,
    }: {
        name: string,
        formData?: {
            images?: Array<Blob>;
        },
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/products/{name}/images',
            path: {
                'name': name,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @returns any Success
     * @throws ApiError
     */
    public postProductsOrderIndex({
        requestBody,
    }: {
        requestBody?: Array<ITEPortal_Domain_Dto_Product_ProductOrderIndexDto>,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/products/order-index',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

}
