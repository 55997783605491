import { useEffect } from 'react';
import { orderApi } from '@/store/effector/order';

/**
 * @description this hook should be used on exhibition screen to reset all the data from the previously edited order
 */
export const useExhibitionStateReset = () => {
  useEffect(() => {
    orderApi.cleanUpOrderState();
  }, []);
};
