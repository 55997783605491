import { Breadcrumb, BreadcrumbItem, BreadcrumbLink } from '@chakra-ui/react';
import styled, { css } from 'styled-components';
import { BreadcrumbOptions } from '@chakra-ui/breadcrumb/dist/breadcrumb-types';
import { ChevronRightIcon } from '@chakra-ui/icons';
import { Link } from '@tanstack/react-router';
import { ifProp } from 'styled-tools';
import { IBreadcrumbItem } from '@/model/common';

interface BreadcrumbsProps {
  id?: string;
  options?: BreadcrumbOptions;
  items?: IBreadcrumbItem[];
}

const BreadcrumbsWrapper = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
`;

const BreadcrumbsLinkContainer = styled(BreadcrumbLink)`
  ${ifProp(
    'disabled',
    css`
      cursor: default;
    `
  )}
`;

const Breadcrumbs: React.FC<BreadcrumbsProps> = ({
  items,
  id,
  options = {
    spacing: '8px',
    separator: <ChevronRightIcon />,
  },
}) => {
  return (
    <BreadcrumbsWrapper>
      <Breadcrumb id={id} {...options}>
        {items &&
          items.map((breadcrumbItem, index) => {
            const { label, link } = breadcrumbItem;
            const isDisabled = index !== 0 && items.length === index + 1;

            return (
              <BreadcrumbItem key={index}>
                <BreadcrumbsLinkContainer
                  as={Link}
                  to={link}
                  disabled={isDisabled}
                >
                  {label}
                </BreadcrumbsLinkContainer>
              </BreadcrumbItem>
            );
          })}
      </Breadcrumb>
    </BreadcrumbsWrapper>
  );
};

export default Breadcrumbs;
