import styled from 'styled-components';
import { Card, Image } from '@chakra-ui/react';

export const StyledCard = styled(Card)`
  max-width: 450px;
  width: 100%;

  .chakra-card__body {
    padding: 0 40px 36px;
  }

  .chakra-card__header {
    padding-top: 48px;
    text-align: center;
  }

  .chakra-button {
    margin-top: 32px;
  }
`;

export const Logo = styled(Image)`
  width: auto;
  margin-bottom: 1.5rem;
  margin-left: auto;
  margin-right: auto;
  height: 120px;
  object-fit: contain;
`;
