import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
} from '@chakra-ui/react';
import { useParams, useSearch } from '@tanstack/react-router';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  standProjectApi,
  useStandProject,
} from '@/pages/AdminPanel/PersonalAccounts/StandsTab/StandProjectForm/api';
import { queryClient } from '@/features/query';
import { QueryOptions } from '@/features/query/query-options';
import { ProjectImageUploader } from '@/pages/AdminPanel/PersonalAccounts/StandsTab/StandProjectForm/ProjectImageUploader';
import { FormProvider, useForm } from 'react-hook-form';
import { TextArea } from '@/components/TextArea/TextArea';
import { ProjectChangeForm } from '@/pages/AdminPanel/PersonalAccounts/StandsTab/StandProjectForm/ProjectChangeForm';
import * as vali from 'zod';
import i18n from '@/i18n';
import { zodResolver } from '@hookform/resolvers/zod';
import { toast } from 'react-toastify';
import { typedAxios } from '@/api/typed-axios';
import { useQuery } from '@tanstack/react-query';
import { AsyncButton } from '@/components/AsyncButton';

const ValidationSchema = vali.object({
  projectDescription: vali
    .string({
      required_error: i18n.t('required-field'),
      invalid_type_error: i18n.t('required-field'),
    })
    .nullish(),
  file: vali.array(vali.instanceof(File)).optional().nullish(),
});

type FormValues = vali.infer<typeof ValidationSchema>;

export const StandProjectForm = () => {
  const { t } = useTranslation();
  const { exhibitionId, accountId: exhibitorId } = useParams({ strict: false });
  const { standId: standIdFromUrl } = useSearch({ strict: false });
  const { standId, projectId } = useStandProject();
  const [isOpened, toggleOpened] = useState(false);
  const formInstance = useForm<FormValues>({
    resolver: zodResolver(ValidationSchema),
  });
  const [isDescriptionEditable, setDescriptionEditable] =
    useState<boolean>(true);

  const { data: project, dataUpdatedAt } = useQuery({
    ...QueryOptions.loadStandProject({
      standId: standId,
    }),
    enabled: Boolean(standId) && Boolean(projectId),
  });

  const isExistingProject = Boolean(project);

  useEffect(
    function openDrawerIdStandIdFromUrlExists() {
      toggleOpened(Boolean(standId));
    },
    [standId]
  );

  useEffect(
    function setStandIdToStoreViaApi() {
      if (typeof +standIdFromUrl === 'number') {
        standProjectApi.setStandId(standIdFromUrl);
      }
    },
    [standIdFromUrl]
  );

  useEffect(
    function setFormValues() {
      if (project) {
        formInstance.reset({
          projectDescription: project.projectDescription || '',
        });

        setDescriptionEditable(false);
      }
    },
    [project]
  );

  const handleClose = () => {
    standProjectApi.reset();
    formInstance.reset({
      projectDescription: '',
    });
  };

  const reopenProject = async () => {
    if (!project?.projectId || !standId) {
      return;
    }

    try {
      await typedAxios.stand.postStandsUpdateProjectStatus({
        projectId: +project.projectId,
        standId: +standId,
        requestBody: {
          standStatus: false,
        },
      });

      await queryClient.refetchQueries({
        queryKey: QueryOptions.loadStandProject({
          standId,
        }).queryKey,
      });

      toast.success(t('saved'));
    } catch {
      toast.error(t('error-occurred'));
    }
  };

  const handleSubmit = async (values: FormValues) => {
    if (!standId) {
      return;
    }

    try {
      if (project?.projectId) {
        await typedAxios.stand.putStandsProject({
          projectId: project.projectId,
          standId,
          requestBody: {
            projectDescription: values.projectDescription,
          },
        });

        if (values.file?.[0] instanceof File) {
          await typedAxios.stand.putStandsProjectImage({
            standId,
            projectId: project.projectId,
            formData: {
              image: values.file?.[0],
            },
          });
        }
      } else {
        const saveProjectId = await typedAxios.stand.postStandsProject({
          standId,
          requestBody: {
            projectDescription: values.projectDescription,
          },
        });

        standProjectApi.setStandId({
          projectId: saveProjectId,
          standId: standId,
        });

        if (values.file?.[0] instanceof File) {
          await typedAxios.stand.putStandsProjectImage({
            standId,
            projectId: saveProjectId,
            formData: {
              image: values.file?.[0],
            },
          });
        }
      }

      await queryClient.refetchQueries({
        queryKey: QueryOptions.loadStandProject({
          standId,
        }).queryKey,
      });

      if (!projectId) {
        await queryClient.refetchQueries(
          QueryOptions.standsQueryOptions({
            exhibitionId: +exhibitionId,
            exhibitorId: +exhibitorId,
          })
        );
      }

      toast.success(t('saved'));
    } catch {
      toast.error(t('error-occurred'));
    }
  };

  if (!isOpened) {
    return null;
  }

  const editable = project?.approved !== true;
  const canEditHistory = Boolean(project?.projectChangesHistory?.length);
  const canAmendInitialDescription = true; // !canEditHistory;

  return (
    <Drawer isOpen={isOpened} placement="right" size="xl" onClose={handleClose}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>{t('stand-project')}</DrawerHeader>

        <DrawerBody>
          <Flex direction="column" gap={8}>
            <FormProvider {...formInstance}>
              <ProjectImageUploader
                key={dataUpdatedAt}
                editable={editable}
                projectImageUrl={project?.projectImageUrl}
              />
              <TextArea<FormValues>
                label={t('project-description')}
                name="projectDescription"
                size="md"
                isDisabled={
                  !canAmendInitialDescription ||
                  !editable ||
                  !isDescriptionEditable
                }
              />
            </FormProvider>
            {canAmendInitialDescription && (
              <Flex direction="row" gap={2} justifyContent="flex-end">
                <Button variant="outline" size="sm" onClick={handleClose}>
                  {t('cancel')}
                </Button>
                {isExistingProject && (
                  <AsyncButton
                    size="sm"
                    colorScheme="brandColor"
                    onClick={() => {
                      if (isDescriptionEditable) {
                        formInstance.setValue(
                          'projectDescription',
                          project?.projectDescription
                        );
                      }

                      setDescriptionEditable(!isDescriptionEditable);
                    }}
                  >
                    {isDescriptionEditable
                      ? t('cancel-edit-description')
                      : t('edit-description')}
                  </AsyncButton>
                )}
                {editable && (
                  <AsyncButton
                    size="sm"
                    colorScheme="brandColor"
                    onClick={formInstance.handleSubmit(handleSubmit)}
                  >
                    {t('save')}
                  </AsyncButton>
                )}
                {!editable && (
                  <AsyncButton
                    onClick={reopenProject}
                    size="sm"
                    colorScheme="brandColor"
                  >
                    {t('reopen-project')}
                  </AsyncButton>
                )}
              </Flex>
            )}
            {canEditHistory && project?.projectChangesHistory && (
              <ProjectChangeForm project={project} />
            )}
          </Flex>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};
