import { Box, Flex } from '@chakra-ui/react';
import styled from 'styled-components';

export const IsLoadingWrapper = styled(Box)`
  background: silver;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999999;
  opacity: 0.4;
`;

export const LoaderWrapper = styled(Flex)`
  justify-content: center;
  padding-top: 10%;
`;
