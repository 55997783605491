import { IExhibitionSummary } from '@/model/IExhibitionSummary';
import { isNil } from '@/utils/is-nil';
import { ExhibitionCard } from '@/components/Card/ExhibitionCard';

import styled from 'styled-components';
import { Link } from '@tanstack/react-router';
import { useExhibitionDetailedPreload } from './hooks/use-exhibition-detailed-preload';
import { Stack } from '@chakra-ui/react';

const ExhibitionsCardWrapper = styled(Link)`
  width: 100%;
  //min-width: 800px;
  display: block;
`;

export const ExhibitionsList = (props: {
  exhibitions: IExhibitionSummary[];
}) => {
  const { preloadExhibition } = useExhibitionDetailedPreload();

  return (
    <Stack spacing={4}>
      {props.exhibitions.map((exhibition, index) => {
        const { exhibitionSummary, stands, orders } = exhibition;

        if (isNil(exhibitionSummary)) {
          return null;
        }

        const { logoUrl, name, id, place, description } = exhibitionSummary;

        const subtitles = [
          `${exhibition.formattedStartDate} - ${exhibition.formattedEndDate}`,
          place,
        ];

        return (
          <ExhibitionsCardWrapper
            to={`/exhibitions/${id}`}
            key={index}
            onClick={() => preloadExhibition(exhibition)}
          >
            <ExhibitionCard
              image={logoUrl || ''}
              name={name || ''}
              subtitles={subtitles || []}
              description={description}
              stands={stands}
              orders={orders}
            />
          </ExhibitionsCardWrapper>
        );
      })}
    </Stack>
  );
};
