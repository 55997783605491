import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import ReactPostprocessor from 'i18next-react-postprocessor';

import ruTranslation from './lang/ru.json';
import enTranslation from './lang/en.json';
import ruSearch from '@/i18n/lang/search/ru.json';
import enSearch from '@/i18n/lang/search/en.json';
import ruUnitOfMeasurement from './lang/unit-of-measurement/ru.json';
import enUnitOfMeasurement from './lang/unit-of-measurement/en.json';
import enStands from './lang/stands/en.json';
import ruStands from './lang/stands/ru.json';
import enColors from './lang/colors/en.json';
import ruColors from './lang/colors/ru.json';
import enOrder from './lang/order/en.json';
import ruOrder from './lang/order/ru.json';
import Backend from 'i18next-http-backend';
import { validateTranslationsMatch } from './validate-translations-match';

const ru = {
  ...ruTranslation,
  ...ruUnitOfMeasurement,
  ...ruStands,
  ...ruSearch,
  ...ruColors,
  ...ruOrder,
};
const en = {
  ...enTranslation,
  ...enUnitOfMeasurement,
  ...enStands,
  ...enSearch,
  ...enColors,
  ...enOrder,
};

validateTranslationsMatch([ru, en]);

declare module 'i18next' {
  // Extend CustomTypeOptions
  interface CustomTypeOptions {
    // custom namespace type, if you changed it
    defaultNS: 'ruTranslation';
    // custom resources type
    resources: {
      ruTranslation: typeof ru;
      enTranslation: typeof en;
    };
    // other
  }
}

const lang = localStorage.getItem('lang') as string;

i18n
  .use(Backend)
  .use(initReactI18next)
  .use(new ReactPostprocessor())
  .init({
    resources: {
      ru: {
        translation: ru,
      },
      en: {
        translation: en,
      },
    },
    fallbackLng: lang || 'ru',
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
