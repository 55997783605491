import React, { useMemo } from 'react';
import { Flex, IconButton, Select, Tooltip, Text } from '@chakra-ui/react';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import * as v from 'zod';

interface PaginationProps {
  setPageNumber: (pageIndex: number) => void;
  setPageSize: (pageSize: number) => void;
  paginationInfo: PaginationSchemaType;
}

const PaginationContainer = styled(Flex)`
  justify-content: space-between;
  margin: 4px 4px 4px auto;
  align-items: center;
  //min-width: 250px;
  padding-top: 20px;
  width: fit-content;
`;

const PaginationSchema = v.object({
  CurrentPage: v.number(),
  TotalPages: v.number(),
  PageSize: v.number(),
  TotalCount: v.number(),
});

type PaginationSchemaType = v.infer<typeof PaginationSchema>;

const Pagination = ({
  setPageNumber,
  setPageSize,
  paginationInfo,
}: PaginationProps) => {
  const { CurrentPage, TotalPages, PageSize, TotalCount } =
    useMemo<PaginationSchemaType>(() => {
      const parsedSchema = PaginationSchema.safeParse(paginationInfo);

      if (parsedSchema.success) {
        return parsedSchema.data;
      }

      return {
        PageSize: 0,
        TotalPages: 0,
        TotalCount: 0,
        CurrentPage: 1,
      };
    }, [paginationInfo]);

  const isActiveLast = CurrentPage === TotalPages || TotalCount === 0;
  const { t } = useTranslation();

  return (
    <PaginationContainer>
      <Flex>
        <Tooltip label="Previous Page">
          <IconButton
            onClick={() => setPageNumber(CurrentPage - 1)}
            isDisabled={CurrentPage === 1}
            icon={<ChevronLeftIcon h={6} w={6} />}
            aria-label="Previous Page"
          />
        </Tooltip>
      </Flex>

      <Text fontWeight="bold" as="span" minW="40px" textAlign="center">
        {CurrentPage}
      </Text>

      <Flex>
        <Tooltip label="Next Page">
          <IconButton
            onClick={() => setPageNumber(CurrentPage + 1)}
            isDisabled={isActiveLast}
            icon={<ChevronRightIcon h={6} w={6} />}
            aria-label="Next Page"
          />
        </Tooltip>
      </Flex>

      <Flex alignItems="center" ml="20px">
        <Select
          w={36}
          value={PageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
          }}
        >
          {[10, 20, 30, 40, 50].map((pageSizeNumber) => (
            <option key={pageSizeNumber} value={pageSizeNumber}>
              {t('show')} {pageSizeNumber}
            </option>
          ))}
        </Select>
      </Flex>
    </PaginationContainer>
  );
};

export default Pagination;
