import { EditCategoryForm } from '@/pages/AdminPanel/ServiceDirectory/EditCategoryForm/EditCategoryForm';
import {
  editCategoryApi,
  useEditCategoryApi,
} from '@/pages/AdminPanel/ServiceDirectory/ServiceDirectory.Accordion/edit-category-api';
import { useCategoriesLoaderQueryOptions } from '@/pages/AdminPanel/Accordion/use-categories-loader';
import { queryClient } from '@/features/query';

export const EditCategoryModalForm = () => {
  const { category, isModalOpened, parentCategoryName } = useEditCategoryApi();

  const { queryKey } = useCategoriesLoaderQueryOptions();

  if (!isModalOpened) {
    return null;
  }

  return (
    <EditCategoryForm
      openConfirm={isModalOpened}
      closeModal={() => editCategoryApi.close()}
      refresh={() => {
        queryClient.invalidateQueries({
          queryKey: queryKey,
        });
      }}
      // @ts-expect-error refactor types
      editTableData={category || undefined}
      parentCategoryName={parentCategoryName}
    />
  );
};
