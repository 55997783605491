import { Category } from '@/model/ICategory';
import { IBreadcrumbItem } from '@/model/common';
import { replacePaths } from './route';
import { CREATE_EXHIBITION_ORDER_TAB_PATH } from '@/constants/router';

export function getBreadcrumbsByCategory(
  category: Category,
  exhibitionId: string,
  stand: string
): IBreadcrumbItem[] {
  const items: IBreadcrumbItem[] = [];

  if (category.parent) {
    items.push(
      ...getBreadcrumbsByCategory(category.parent, exhibitionId, stand)
    );
  }

  items.push({
    label: category.displayName,
    link: replacePaths(CREATE_EXHIBITION_ORDER_TAB_PATH, {
      ':exhibitionId': exhibitionId,
      ':stand': stand,
      ':service': category.name,
    }),
  });

  return items;
}
