import { useCallback } from 'react';
import { ModalFactory } from '@/components/Modal/api';
import { useTranslation } from 'react-i18next';
import { EmployeeFormValues } from '@/pages/AdminPanel/PersonalAccounts/UsersTab/types';
import { UseFormReturn } from 'react-hook-form';
import { useQueryClient } from '@tanstack/react-query';
import { AddNewEmployeeForm } from '@/pages/AdminPanel/PersonalAccounts/UsersTab/AddNewEmployeeForm';
import { typedAxios } from '@/api/typed-axios';
import { QueryOptions } from '@/features/query/query-options';
import { ApiError } from '@/api';
import { toast } from 'react-toastify';

export const useEmployeeCreate = (opts: {
  exhibitionId: number;
  exhibitorId: number;
}) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const saveEmployee = async (values: EmployeeFormValues) => {
    try {
      await typedAxios.exhibitor.postExhibitorsExhibitionEmployees({
        exhibitorId: opts.exhibitorId,
        exhibitionId: opts.exhibitionId,
        requestBody: {
          email: values.email,
          name: values.name,
          phoneNumber: values.phoneNumber,
        },
      });

      await queryClient.invalidateQueries(
        QueryOptions.exhibitorQueryOptions(opts)
      );

      ModalFactory.close();
    } catch (e) {
      if (e instanceof ApiError) {
        const message = e.body;

        if (message && typeof message === 'string') {
          toast.error(message);
        } else {
          toast.error(t('error-occurred'));
        }
      }
    }
  };

  const openCreateModal = useCallback(() => {
    ModalFactory.show({
      title: t('add-employee'),
      Component: AddNewEmployeeForm,
      okLabel: t('add-employee'),
      onOk: async (ctx: UseFormReturn<EmployeeFormValues>) => {
        return ctx.handleSubmit(saveEmployee)();
      },
    });
  }, []);

  return {
    openCreateModal,
  };
};
