import styled from 'styled-components';
import BrandBox from '@/components/BrandBox';
import ProfileMenu from '@/components/Profile/ProfileMenu';
import Menu from '@/components/Menu';
import { headerMenuConfig } from '@/components/Header/headerMenuConfig';
import Search from '@/components/Search';
import { useMatches, useParams } from '@tanstack/react-router';
import { LanguageSwitcher } from '@/components/LanguageSwitcher/LanguageSwitcher';
import { Flex } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { AdminSearch } from '../AdminSearch';
import { useAuth } from '@/store/effector/auth';

const NavBar = styled.header`
  display: flex;
  flex-direction: row;

  padding: 16px 20px;
  height: var(--chakra-sizes-nav-bar-height);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);

  background: #fff;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;

  justify-content: end;
`;

const StyleMenu = styled(Menu)`
  margin-left: 32px;
`;

const StyledSearch = styled(Search)`
  max-width: 222px;
  margin-right: 32px;
`;

const RightBlockWrapper = styled(Flex)`
  flex-direction: column;
  align-items: end;
  justify-content: center;
  padding-bottom: 10px;
`;

const Header: React.FC = () => {
  const { user } = useAuth();

  const matchContext = useMatches().at(-1)?.context || {
    key: 'default',
  };

  const { exhibitionId, stand } = useParams({ strict: false }) || {};
  const { t } = useTranslation();
  const shouldRenderExhibitionSearchForExhibitor =
    Boolean(user?.isExhibitor) && Boolean(exhibitionId) && Boolean(stand);

  const shouldRenderPageSpecificSearch = !user?.isExhibitor;

  return (
    <NavBar>
      <BrandBox />
      {user?.isExhibitor && (
        <StyleMenu direction="row" config={headerMenuConfig(t)} />
      )}
      <Wrapper>
        {shouldRenderExhibitionSearchForExhibitor && (
          <StyledSearch exhibitionId={exhibitionId} stand={stand} />
        )}
        {shouldRenderPageSpecificSearch && (
          <AdminSearch key={JSON.stringify(matchContext)} />
        )}
        <RightBlockWrapper>
          <LanguageSwitcher />
          <ProfileMenu />
        </RightBlockWrapper>
      </Wrapper>
    </NavBar>
  );
};

export default Header;
