import styled from 'styled-components';
import { Flex } from '@chakra-ui/react';

interface LayoutProps {
  children?: React.ReactNode | React.ReactNode[];
}

const Grid = styled(Flex)`
  min-height: 100vh;
`;

const Layout: React.FC<LayoutProps> = ({ children }) => {
  return (
    <Grid alignItems="center" justifyContent="center" direction="column">
      {children}
    </Grid>
  );
};

export default Layout;
