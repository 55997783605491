import { useMemo, useState } from 'react';

import { Stack, Flex, Button } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { settingsRoute } from '../../../../features/Routing/protected/settings';
import { Table } from '../../../../components/Table/Table';
import { Column } from 'react-table';

import { EditIcon, DeleteIcon } from '@chakra-ui/icons';
import { EditDiscountModal } from './EditDiscountModal';
import { ITEPortal_Domain_Dto_Discount_GetCleaningDiscountDto } from '../../../../api';
import { useRouter } from '@tanstack/react-router';
import { ModalFactory } from '../../../../components/Modal/api';
import { toast } from 'react-toastify';
import { typedAxios } from '../../../../api/typed-axios';

export const DiscountsTab = () => {
  const router = useRouter();
  const { discounts } = settingsRoute.useLoaderData();
  const { t } = useTranslation();
  const [editableRow, setEditableRow] =
    useState<ITEPortal_Domain_Dto_Discount_GetCleaningDiscountDto | null>(null);

  const isEditModalOpened = editableRow !== null;

  const confirmDeletion = (
    discount: ITEPortal_Domain_Dto_Discount_GetCleaningDiscountDto
  ) => {
    ModalFactory.confirm({
      Component: () => <>{t('delete-cleaning-discount')}</>,
      onOk: async () => {
        try {
          await typedAxios.discount.deleteDiscountsCleaning({
            discountId: discount.id,
          });

          ModalFactory.close();
          router.invalidate();
        } catch {
          toast.error(
            t('unable-to-delete', {
              entity: t('discount_').toLowerCase(),
            })
          );
        }
      },
    });
  };

  const columns = useMemo(() => {
    return [
      {
        accessor: 'areaFrom',
        Header: t('areaFrom'),
        Cell: ({ value }) => {
          return <>{value}</>;
        },
      },
      {
        accessor: 'areaTo',
        Header: t('areaTo'),
        Cell: ({ value }) => {
          return <>{value}</>;
        },
      },
      {
        accessor: 'discountPercentage',
        Header: `${t('discount')} (%)`,
        Cell: ({ value }) => {
          return <>{value}</>;
        },
      },
      {
        id: 'actions',
        Header: '',
        width: '40px',
        Cell: ({ row }) => {
          return (
            <Flex columnGap="10px" maxWidth="40px">
              <EditIcon onClick={() => setEditableRow(row.original)} />
              <DeleteIcon onClick={() => confirmDeletion(row.original)} />
            </Flex>
          );
        },
      },
    ] satisfies Column<ITEPortal_Domain_Dto_Discount_GetCleaningDiscountDto>[];
  }, [editableRow]);

  const data = useMemo(() => {
    return discounts;
  }, [discounts]);

  return (
    <Stack spacing={5} direction="column" paddingBottom={200}>
      <Flex flexDirection="row" justifyContent="flex-end">
        <Button
          colorScheme="red"
          onClick={() =>
            setEditableRow({
              id: 0,
            })
          }
        >
          + {t('add-discount')}
        </Button>
      </Flex>
      <Table columns={columns} data={data} />

      {isEditModalOpened && (
        <EditDiscountModal
          model={editableRow}
          close={() => {
            setEditableRow(null);
          }}
          onSaved={() => {
            router.invalidate();
          }}
        />
      )}
    </Stack>
  );
};
