import { useRBAC } from '@/features/rbac/useRBAC';
import { Alert, AlertIcon, Link } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

type NoStandsLinkPropsType = {
  exhibitionId: number;
  exhibitorId: number;
};

export const NoStandsLink = (props: NoStandsLinkPropsType) => {
  const { t } = useTranslation();
  const { can } = useRBAC();

  return (
    <Alert status="warning" mt={4} mb={4} whiteSpace="break-spaces">
      <AlertIcon />
      {can('canAddStand')
        ? t('no-stands-warning', {
            here: (
              <Link
                fontWeight="bolder"
                textDecoration="underline"
                target="_blank"
                href={`/admin/exhibitions/${props.exhibitionId}/view/personal-accounts/${props.exhibitorId}/edit/?tab=stands`}
              >
                {t('here')}
              </Link>
            ),
            postProcess: 'reactPostprocessor',
          })
        : t('no-stands-warning-without-link')}
    </Alert>
  );
};
