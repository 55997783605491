import { Flex, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { DateUtils } from '@/utils/date-utils';
import { useAuth } from '@/store/effector/auth';
import { DownloadFileLink } from '@/components/DownloadFileLink';
import { useMemo } from 'react';
import { ITEPortal_Domain_Dto_Stand_GetStandProjectChangesDto } from '@/api';

type HistoryItemPropsType = {
  historyItem: ITEPortal_Domain_Dto_Stand_GetStandProjectChangesDto;
  ownershipResolver?: (
    historyItem: ITEPortal_Domain_Dto_Stand_GetStandProjectChangesDto
  ) => boolean;
};

export const HistoryItem = (props: HistoryItemPropsType) => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const { historyItem, ownershipResolver } = props;
  const ownershipLabel =
    typeof ownershipResolver === 'function' ? t('exhibitor') : t('you');
  const postedByCurrentUser = useMemo(() => {
    if (typeof ownershipResolver === 'function') {
      return ownershipResolver(historyItem);
    }

    return historyItem.createdBy === user?.id;
  }, [ownershipResolver, user, historyItem]);

  if (!historyItem) {
    return null;
  }

  return (
    <Flex
      flexDirection="column"
      background="gray.100"
      padding={4}
      borderRadius="md"
    >
      <Text color={postedByCurrentUser ? 'red' : 'gray.600'} fontWeight={500}>
        {postedByCurrentUser ? ownershipLabel : t('technical-manager')}
      </Text>
      <Text fontSize={14} color="gray.600">
        {historyItem.comment}
      </Text>
      {historyItem.attachmentUrl && (
        <DownloadFileLink attachmentUrl={historyItem.attachmentUrl} />
      )}
      {historyItem.createdAt && (
        <Text fontSize={12} color="gray.400" marginLeft="auto">
          {DateUtils.format(historyItem.createdAt, 'DD.MM.YYYY HH:mm:ss')}
        </Text>
      )}
    </Flex>
  );
};
