import { SearchIcon } from '@chakra-ui/icons';
import {
  InputGroup,
  InputLeftElement,
  Input,
  Box,
  Flex,
} from '@chakra-ui/react';
import { useMatches, useNavigate, useSearch } from '@tanstack/react-router';
import { SearchBoxOptionsType } from './types';
import { useTranslation } from 'react-i18next';
import { useDebouncedCallback } from 'use-debounce';

export const AdminSearch = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const currentSearch = useSearch({ strict: false });
  const context = (useMatches().at(-1)?.context || {
    searchOptions: null,
  }) as {
    searchOptions: SearchBoxOptionsType[];
  };

  const doSearch = (parameterName: string, value: string | undefined) => {
    const params = {
      ...currentSearch,
      [parameterName]: value || undefined,
    };

    if (params.p) {
      params.p = undefined;
    }

    navigate({
      search: params,
    });
  };

  const doDebouncedSearch = useDebouncedCallback(
    (parameterName: string, value: string) => {
      doSearch(parameterName, value);
    },
    1000
  );

  const clearSearch = (parameterName: string) => {
    doSearch(parameterName, undefined);
  };

  if (!context.searchOptions || !Array.isArray(context.searchOptions)) {
    return null;
  }

  return (
    <Flex flexDirection="row">
      {context.searchOptions.map((searchDescriptor, index) => {
        const { placeholder = t('search'), queryParameterName = 'search' } =
          searchDescriptor;

        return (
          <Box mr="32px" key={index}>
            <InputGroup maxWidth="300px">
              <InputLeftElement pointerEvents="none">
                <SearchIcon color="gray.300" />
              </InputLeftElement>
              <Input
                type="search"
                defaultValue={currentSearch?.[queryParameterName]}
                placeholder={placeholder}
                onKeyUp={(e) => {
                  const target = e.target as HTMLInputElement;

                  if (!target.value) {
                    clearSearch(queryParameterName);

                    return;
                  }

                  if (e.key === 'Enter') {
                    doSearch(queryParameterName, target.value);

                    return;
                  }

                  doDebouncedSearch(queryParameterName, target.value);
                }}
                onChangeCapture={(e) => {
                  const target = e.target as HTMLInputElement;

                  if (!target.value) {
                    e.stopPropagation();
                    e.preventDefault();

                    clearSearch(queryParameterName);

                    doDebouncedSearch.cancel();

                    return;
                  }
                }}
              />
            </InputGroup>
          </Box>
        );
      })}
    </Flex>
  );
};
