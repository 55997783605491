import VerifyEmailPage from '@/pages/VerifyEmailPage';
import LoginPage from '@/pages/LoginPage';
import Layout from '@/layout/Auth';

import { Outlet, redirect, Route } from '@tanstack/react-router';
import { LOGIN_PATH, VERIFY_EMAIL_PATH } from './RoutesConfig/authRoutesConfig';
import { rootRoute } from './root';
import { indexRouteFactory } from './userPaths';
import { UserRoleType } from '../../types/open-api/domain-types';
import { $auth } from '@/store/effector/auth';

export const rootAuthRoute = new Route({
  id: 'auth-root',
  getParentRoute: () => rootRoute,
  beforeLoad: () => {
    const { isLoggedIn, user } = $auth.getState();

    if (isLoggedIn && user?.role) {
      throw redirect({
        to: indexRouteFactory(user.role as UserRoleType),
        replace: true,
      });
    }
  },
  component: () => {
    return (
      <Layout>
        <Outlet />
      </Layout>
    );
  },
});

export const loginRoute = new Route({
  path: LOGIN_PATH,
  component: () => <LoginPage />,
  getParentRoute: () => rootAuthRoute,
  validateSearch: (
    searchObj: Record<string, unknown>
  ): { redirect: string } => {
    return {
      redirect:
        typeof searchObj.redirect === 'string' ? searchObj.redirect : '',
    };
  },
});

export const verifyEmailRoute = new Route({
  path: VERIFY_EMAIL_PATH,
  component: () => <VerifyEmailPage />,
  getParentRoute: () => rootAuthRoute,
  validateSearch: (
    searchObj: Record<string, unknown>
  ): { redirect: string } => {
    return {
      redirect:
        typeof searchObj.redirect === 'string' ? searchObj.redirect : '',
    };
  },
});

export const authRouteTree = rootAuthRoute.addChildren([
  loginRoute,
  verifyEmailRoute,
]);
