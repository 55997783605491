import styled from 'styled-components';
import { Flex, Text, Tooltip } from '@chakra-ui/react';
import {
  StandConfDescriptionByConf,
  StandConfLabelsByConf,
  StandTypeLabelsByType,
} from '../../../utils/constants';
import { InfoIcon } from '@chakra-ui/icons';
import { useTranslation } from 'react-i18next';
import {
  ITEPortal_Contracts_Enums_BuildingType,
  ITEPortal_Domain_Dto_StandSummariesDto,
} from '../../../api';
import { isNil } from '@/utils/is-nil';
import { getPavilionLabelByCode } from '@/pages/AdminPanel/PersonalAccounts/StandsTab/contsants';

const AccordionSubTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  color: #718096;
  font-size: 14px;
  gap: 10px;
  align-items: center;
`;

export const StandDescription = ({
  stand,
  includeStandNumber,
  inline,
  originalBuildingType,
}: {
  originalBuildingType?: ITEPortal_Contracts_Enums_BuildingType;
  stand: ITEPortal_Domain_Dto_StandSummariesDto;
  includeStandNumber?: boolean;
  inline?: boolean;
}) => {
  const {
    buildingType,
    standConfiguration,
    standNumber,
    area,
    secondFloorArea,
    pavilionHall,
  } = stand;
  const totalArea = (area || 0) + (secondFloorArea || 0);
  const { t } = useTranslation();
  const shouldRenderStandNumber = includeStandNumber && standNumber;

  return (
    <AccordionSubTitleContainer>
      <Flex
        gap={inline ? 4 : 1}
        flexDirection={inline ? 'row' : 'column'}
        width="100%"
        flexWrap="wrap"
      >
        {shouldRenderStandNumber && (
          <Flex flexDirection="row" alignItems="center" gap={2}>
            <Text whiteSpace="nowrap">{standNumber}</Text>
            <Tooltip label={t('stand-number')} hasArrow placement="top">
              <InfoIcon color="lightgray" boxSize={4} />
            </Tooltip>
          </Flex>
        )}
        {!isNil(buildingType) && (
          <Flex flexDirection="row" alignItems="center" gap={2}>
            <Text whiteSpace="nowrap">
              {StandTypeLabelsByType[originalBuildingType || buildingType]}
            </Text>
            <Tooltip
              label={
                buildingType === 1
                  ? t('stand-space-only-description')
                  : t('area-type')
              }
              hasArrow
              placement="top"
            >
              <InfoIcon color="lightgray" boxSize={4} />
            </Tooltip>
          </Flex>
        )}
        <Flex flexDirection="row" alignItems="center" gap={2}>
          <Text whiteSpace="nowrap">
            {totalArea} {t('sqm')}
          </Text>
          <Tooltip label={t('stand-area')} hasArrow placement="top">
            <InfoIcon color="lightgray" boxSize={4} />
          </Tooltip>
        </Flex>
        {standConfiguration && (
          <Flex flexDirection="row" alignItems="center" gap={2}>
            <Text whiteSpace="nowrap">
              {StandConfLabelsByConf[standConfiguration]}
            </Text>
            <Tooltip
              label={
                StandConfDescriptionByConf[standConfiguration] ||
                t('stand-configuration')
              }
              hasArrow
              placement="top"
            >
              <InfoIcon color="lightgray" boxSize={4} />
            </Tooltip>
          </Flex>
        )}
        {pavilionHall && (
          <Flex flexDirection="row" alignItems="center" gap={2}>
            <Text whiteSpace="nowrap">
              {getPavilionLabelByCode(pavilionHall)}
            </Text>
            <Tooltip label={t('pavilion')} hasArrow placement="top">
              <InfoIcon color="lightgray" boxSize={4} />
            </Tooltip>
          </Flex>
        )}
      </Flex>
    </AccordionSubTitleContainer>
  );
};
