import { useForm, FormProvider } from 'react-hook-form';
import { FormControl, Stack } from '@chakra-ui/react';
import { useNameTranslate } from '@/hooks/useStringName';
import Input from '@/components/Fields/Input';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { TextArea } from '@/components/TextArea/TextArea';
import { SelectComponent } from '@/components/MultiSelect/MultiSelect';
import fetchApi from '@/utils/fetchAPI';
import { EDIT_PRODUCT_RESOURCES } from '@/constants/endpoints';
import { FetchMethods, FieldTypeConfig } from '@/utils/constants';
import { toast } from 'react-toastify';
import { OptionType } from '@/model/common';
import { BUILDING_TYPES } from '@/constants/admin-pages';
import { CATEGORY_TYPES } from '@/constants/categorie';
import {
  CommonProductValidationSchemaType,
  TemporaryStaffValidationSchema,
  TemporaryStaffValidationSchemaType,
} from '@/pages/AdminPanel/ServiceDirectory/EditProductFieldsets/validation-schemas';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  ITEPortal_Domain_Dto_AddProductDto,
  ITEPortal_Domain_Dto_ProductDto,
} from '@/api';
import {
  editProductApi,
  useProductForm,
} from '@/pages/AdminPanel/ServiceDirectory/EditProductFieldsets/useProductForm';

const FormControlWrapper = styled(FormControl)`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

interface AddBuildingTypeProductProps {
  editTableData: ITEPortal_Domain_Dto_ProductDto | null;
}

const mapData = (
  values: TemporaryStaffValidationSchemaType
): ITEPortal_Domain_Dto_AddProductDto => {
  const buildingTypes = values?.buildingTypes?.map(
    (type: OptionType) => type?.value
  );

  return {
    name: values?.name,
    categoryName: CATEGORY_TYPES.TEMPORARY_STAFF,
    fieldType: values?.fieldType?.value + '',
    // @ts-expect-error to think how to map Enum to zod type
    buildingTypes: buildingTypes,
    resources: [
      {
        key: 'name',
        value: values?.displayNameRu || '',
        cultureName: 'ru',
      },
      {
        key: 'name',
        value: values?.displayNameEn || '',
        cultureName: 'en',
      },
      {
        key: 'description',
        value: values?.descriptionRu || '',
        cultureName: 'ru',
      },
      {
        key: 'description',
        value: values?.descriptionEn || '',
        cultureName: 'en',
      },
    ],
  };
};

export const EditTemporaryStaffProductFieldset = ({
  editTableData,
}: AddBuildingTypeProductProps) => {
  const culture = localStorage.getItem('lang');
  const { categoryOptions } = useProductForm();

  useEffect(() => {
    if (editTableData) {
      const fetchData = async () => {
        const productID = editTableData?.name;

        if (!productID) {
          return;
        }

        const fieldTypeEditLabel = FieldTypeConfig.find(
          (type: OptionType) => type.value === editTableData?.fieldType
        )?.label;

        try {
          const { data } = await fetchApi(EDIT_PRODUCT_RESOURCES(productID), {
            method: FetchMethods.Get,
            params: {
              culture,
            },
          });
          const buildingTypeValues = editTableData?.buildingTypes?.map(
            (type: number) => {
              const buildingTypeLabel = BUILDING_TYPES.find(
                (dictionaryType) => dictionaryType.value === type
              )?.label;

              return {
                value: type,
                label: buildingTypeLabel,
              };
            }
          );

          const editDataWithResources: TemporaryStaffValidationSchemaType = {
            name: editTableData?.name,
            buildingTypes: buildingTypeValues,
            displayNameRu: data?.ru?.name,
            displayNameEn: data?.en?.name,
            descriptionRu: data?.ru?.description,
            descriptionEn: data?.en?.description,
            fieldType: {
              value: editTableData?.fieldType,
              label: fieldTypeEditLabel,
            },
            categoryName: categoryOptions.find(
              (ct) => ct.value === editTableData.categoryName
            ),
          } as any;

          methods.reset(editDataWithResources);
        } catch (error: any) {
          const fieldErrors = error?.response?.data?.errors;

          if (fieldErrors) {
            toast.error(fieldErrors);
          }
        } finally {
        }
      };

      fetchData();
    }
  }, [editTableData]);

  useEffect(() => {
    // @ts-expect-error todo investigate type error
    editProductApi.setFormInstance({
      formInstance: methods,
      map: mapData,
    });
  }, []);

  const methods = useForm<TemporaryStaffValidationSchemaType>({
    mode: 'onBlur',
    resolver: zodResolver(TemporaryStaffValidationSchema),
  });

  return (
    <FormProvider {...methods}>
      <Stack spacing="5">
        <FormControlWrapper>
          <Input<TemporaryStaffValidationSchemaType>
            label="ID"
            maxWidth="100%"
            name="name"
            disabled={Boolean(editTableData)}
            isRequired
          />
          <SelectComponent<TemporaryStaffValidationSchemaType>
            label={useNameTranslate('building-type')}
            options={BUILDING_TYPES}
            closeMenuOnSelect={false}
            isMulti
            isRequired
            name="buildingTypes"
          />
          <Input<TemporaryStaffValidationSchemaType>
            label={`${useNameTranslate('service-name')} (RU)`}
            maxWidth="100%"
            name="displayNameRu"
            isRequired
          />
          <Input<TemporaryStaffValidationSchemaType>
            label={`${useNameTranslate('service-name')} (EN)`}
            maxWidth="100%"
            name="displayNameEn"
            isRequired
          />
          <SelectComponent<CommonProductValidationSchemaType>
            label={useNameTranslate('category-name')}
            options={categoryOptions}
            isRequired
            name="categoryName"
          />
          <TextArea<TemporaryStaffValidationSchemaType>
            label={`${useNameTranslate('service-description')} (RU)`}
            name="descriptionRu"
          />
          <TextArea<TemporaryStaffValidationSchemaType>
            label={`${useNameTranslate('service-description')} (EN)`}
            name="descriptionEn"
          />
          <SelectComponent<TemporaryStaffValidationSchemaType>
            label={useNameTranslate('additional-field')}
            options={FieldTypeConfig}
            isRequired
            name="fieldType"
          />
        </FormControlWrapper>
      </Stack>
    </FormProvider>
  );
};
