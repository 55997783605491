import supportManagerAdditionalMenu from '../../../__mocks__/supportManagerAdditionalMenu';
import CategoriesSidebar from '../../../components/Sidebar/CategoriesSidebar';

import { useTranslation } from 'react-i18next';
import { useExhibition } from '@/store/effector/exhibition';
import { useParams } from '@tanstack/react-router';

export const ExhibitionSidebar = () => {
  const { exhibitionId } = useParams({ strict: false });
  const { exhibitionName } = useExhibition();
  const { t } = useTranslation();

  if (!exhibitionId) {
    return null;
  }

  return (
    <CategoriesSidebar
      categories={supportManagerAdditionalMenu(exhibitionId, t)}
      type="additionalPanel"
      barTitle={exhibitionName}
    />
  );
};
