import { OpenAPI } from './core/OpenAPI';
import { removeTrailingSlash } from '../utils/url';

OpenAPI.BASE = removeTrailingSlash(process.env.PUBLIC_API_URL as string);

OpenAPI.WITH_CREDENTIALS = true;

OpenAPI.HEADERS = {
  'Accept': 'application/json',
  'Content-Type': 'application/json',
  'Authorization': `Bearer ${localStorage.getItem('token')}`,
};
