import { IBaseOrder } from './IBaseOrder';
import { BuildingTypeInfo, MarginType } from './common';
import { OrderItem } from './IOrderItem';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import {
  PERSONAL_DISCOUNT_TYPE,
  SPACE_ONLY_BUILDING_TYPE,
} from '../constants/order';
import { isNil } from '../utils/is-nil';
import { ITEPortal_Domain_Dto_Discount_GetCleaningDiscountDto } from '../api';
import { CATEGORY_TYPES } from '../constants/categorie';

dayjs.extend(isSameOrAfter);

export class Cart {
  public currency?: string;

  private cleaningDiscounts?: ITEPortal_Domain_Dto_Discount_GetCleaningDiscountDto[] =
    [];

  private order?: IBaseOrder;
  private margin?: MarginType;
  private items: Array<OrderItem> = [];
  private standInfo: BuildingTypeInfo | null = null;

  setOrder(order: IBaseOrder) {
    this.order = order;

    return this;
  }

  setMargin(margin: MarginType) {
    this.margin = margin;

    return this;
  }

  setItems(items: Array<OrderItem>) {
    this.items = items;

    return this;
  }

  setCurrency(currency: string) {
    this.currency = currency;

    return this;
  }

  setCleaningDiscounts(
    discounts: ITEPortal_Domain_Dto_Discount_GetCleaningDiscountDto[]
  ) {
    this.cleaningDiscounts = discounts;

    return this;
  }

  setStandInfo(stand: BuildingTypeInfo) {
    this.standInfo = stand;

    return this;
  }

  get isSpaceOnlyStand() {
    if (!this.standInfo) {
      return true;
    }

    return this.standInfo.type === SPACE_ONLY_BUILDING_TYPE;
  }

  get cleaningItems() {
    if (!this.items) {
      return [];
    }

    return this.items.filter(
      (i) => i.product?.category?.name === CATEGORY_TYPES.CLEANING
    );
  }

  get cleaningArea() {
    const cleaningItems = this.cleaningItems;

    if (
      !cleaningItems ||
      !Array.isArray(cleaningItems) ||
      cleaningItems.length === 0
    ) {
      return 0;
    }

    return this.cleaningItems.reduce((totalArea, item) => {
      return totalArea + (item.getAmount() || 0);
    }, 0);
  }

  get cleaningTotal() {
    const cleaningItems = this.cleaningItems;

    if (
      !cleaningItems ||
      !Array.isArray(cleaningItems) ||
      cleaningItems.length === 0
    ) {
      return 0;
    }

    return this.cleaningItems.reduce((cleaningTotal, item) => {
      return cleaningTotal + item.total;
    }, 0);
  }

  get cleaningDiscountMultiplier(): number {
    if (!Array.isArray(this.cleaningDiscounts)) {
      return 0;
    }

    const cleaningArea = this.cleaningArea;

    const discount = this.cleaningDiscounts.find((d) => {
      return (
        typeof d.areaFrom === 'number' &&
        typeof d.areaTo === 'number' &&
        cleaningArea >= d.areaFrom &&
        cleaningArea <= d.areaTo
      );
    });

    if (typeof discount?.discountPercentage === 'number') {
      return discount.discountPercentage / 100;
    }

    return 0;
  }

  get cleaningDiscount() {
    if (!this.cleaningTotal || !this.cleaningDiscountMultiplier) {
      return 0;
    }

    return this.cleaningTotal * this.cleaningDiscountMultiplier;
  }

  get markup() {
    if (!this.margin) {
      return 0;
    }

    if (this.order && this.order.removeExtraCharge === true) {
      return 0;
    }

    let markupPercentage = 0;

    const currentDate = dayjs();
    const firstMarkup = dayjs(this.margin.firstMarginDate);
    const secondMarkup = dayjs(this.margin.secondMarginDate);
    const thirdMarkup = dayjs(this.margin.thirdMarginDate);

    switch (true) {
      case this.order &&
        this.order.hasPersonalExtraCharge &&
        typeof this.order.personalExtraChargePercentage === 'number': {
        markupPercentage = this.order?.personalExtraChargePercentage;
        break;
      }

      case currentDate.isSameOrAfter(thirdMarkup): {
        markupPercentage = this.margin.thirdMargin
          ? parseInt(this.margin.thirdMargin + '')
          : 0;
        break;
      }

      case currentDate.isSameOrAfter(secondMarkup): {
        markupPercentage = this.margin.secondMargin
          ? parseInt(this.margin.secondMargin + '')
          : 0;

        break;
      }

      case currentDate.isSameOrAfter(firstMarkup): {
        markupPercentage = this.margin.firstMargin
          ? parseInt(this.margin.firstMargin + '')
          : 0;

        break;
      }

      default:
        markupPercentage = 0;
    }

    if (markupPercentage === 0) {
      return 0;
    }

    if (markupPercentage > 1) {
      markupPercentage = markupPercentage / 100;
    }

    return this.subTotal * markupPercentage;
  }

  get personalDiscount() {
    if (!this.order) {
      return 0;
    }

    if (!this.hasPersonalDiscount) {
      return 0;
    }

    if (
      !this.order.personalDiscount ||
      typeof this.order.personalDiscount !== 'number'
    ) {
      return 0;
    }

    if (this.order.typeOfDiscount === PERSONAL_DISCOUNT_TYPE.AMOUNT) {
      return this.order.personalDiscount;
    }

    let discountPercentage = this.order.personalDiscount;

    if (discountPercentage > 1) {
      discountPercentage = discountPercentage / 100;
    }

    return this.itemsTotal * discountPercentage;
  }

  get itemsTotal() {
    if (this.items.length === 0) {
      return 0;
    }

    return this.items.reduce((total, item) => {
      return total + item.total;
    }, 0);
  }

  get vatPercent() {
    if (!this.hasVat) {
      return 0;
    }

    return 0.2;
  }

  get hasPersonalDiscount() {
    return (
      !isNil(this.order?.typeOfDiscount) &&
      [PERSONAL_DISCOUNT_TYPE.PERCENT, PERSONAL_DISCOUNT_TYPE.AMOUNT].includes(
        this.order?.typeOfDiscount
      ) &&
      !isNil(this.order?.personalDiscount) &&
      this.order?.personalDiscount > 0
    );
  }

  get hasVat() {
    if (!this.currency) {
      return false;
    }

    return this.currency.toLowerCase() === 'rub';
  }

  get hasCleaningDiscount() {
    return this.cleaningDiscount > 0;
  }

  get vat() {
    return this.subTotalWithMarkup * this.vatPercent;
  }

  get subTotal() {
    return this.itemsTotal - this.personalDiscount - this.cleaningDiscount;
  }

  get subTotalWithMarkup() {
    return this.subTotal + this.markup;
  }

  get subTotalWithVat() {
    return this.subTotalWithMarkup + this.vat;
  }

  get total() {
    return this.subTotalWithVat;
  }
}
