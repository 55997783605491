import { ITEPortal_Domain_Dto_Exhibitor_GetExhibitorEmployeeDto } from '@/api';
import { Column } from 'react-table';
import { Table } from '@/components/Table/Table';
import { UsersTableActions } from '@/pages/AdminPanel/PersonalAccounts/UsersTab/UsersTableActions';
import { Button, ButtonGroup, Flex } from '@chakra-ui/react';
import i18n from '@/i18n';
import { useEmployeeCreate } from '@/pages/AdminPanel/PersonalAccounts/UsersTab/hooks/use-employee-create';
import { editExhibitionAccountRoute } from '@/features/Routing/protected/admin-exhibitions';
import { useSuspenseQuery } from '@tanstack/react-query';
import { QueryOptions } from '@/features/query/query-options';
import dayjs from 'dayjs';

const columns = [
  {
    Header: i18n.t('employee-name'),
    accessor: 'name',
  },
  {
    Header: 'Email',
    accessor: 'email',
  },
  {
    Header: i18n.t('phone'),
    accessor: 'phoneNumber',
  },
  {
    Header: i18n.t('creation-date'),
    accessor: 'createdDate',
    Cell: ({ value }) => {
      if (value && dayjs(value).isValid()) {
        return <>{dayjs(value).format('DD.MM.YYYY')}</>;
      }

      return <>-</>;
    },
  },
  {
    Header: i18n.t('last-visit-date'),
    accessor: 'lastVisitDate',
    Cell: ({ value }) => {
      if (value && dayjs(value).isValid()) {
        return <>{dayjs(value).format('DD.MM.YYYY')}</>;
      }

      return <>-</>;
    },
  },
  {
    id: 'actions',
    width: 90,
    Cell: ({ row }) => {
      return <UsersTableActions row={row} />;
    },
  },
] satisfies Array<
  Column<ITEPortal_Domain_Dto_Exhibitor_GetExhibitorEmployeeDto>
>;

export const UsersTab = () => {
  const { accountId, exhibitionId } = editExhibitionAccountRoute.useParams();

  const { data } = useSuspenseQuery(
    QueryOptions.exhibitorQueryOptions({
      exhibitionId: +exhibitionId,
      exhibitorId: +accountId,
    })
  );

  const exhibitorEmployees = data?.exhibitorEmployees || [];

  const opts = {
    exhibitionId: +exhibitionId,
    exhibitorId: +accountId,
  };

  const { openCreateModal } = useEmployeeCreate(opts);

  return (
    <Flex flexDir="column">
      <ButtonGroup gap="4" justifyContent="flex-end">
        <Button onClick={openCreateModal}>+ {i18n.t('add-employee')}</Button>
      </ButtonGroup>
      <Table columns={columns} data={exhibitorEmployees} />
    </Flex>
  );
};
