import React, { useCallback } from 'react';
import { ModalFactory } from '@/components/Modal/api';
import { useTranslation } from 'react-i18next';
import { UseFormReturn } from 'react-hook-form';
import { ApiError } from '@/api';
import { toast } from 'react-toastify';
import {
  ImportExhibitionForm,
  ValidationSchemaType,
} from '@/pages/AdminPanel/Exhibitions/ImportExhibitionForm';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { typedAxios } from '@/api/typed-axios';
import { useNavigate } from '@tanstack/react-router';
import { OptionType } from '@/model/common';

type TechnicalManagersType = OptionType & {
  label: string;
  meta?: {
    contactId: string | null;
  };
};

export const useExportExhibition = () => {
  const { t } = useTranslation();
  const queryClient = new QueryClient();
  const navigate = useNavigate();

  const saveExhibition = async (values: ValidationSchemaType) => {
    const withoutContactIdManagers: string[] = [];

    if (values?.technicalManagers?.length > 0) {
      values?.technicalManagers?.forEach((value: TechnicalManagersType) => {
        if (value?.meta?.contactId === null && value.label) {
          withoutContactIdManagers.push(value.label);
        }
      });
    }

    if (withoutContactIdManagers.length > 0) {
      toast.error(
        `${t('managers-without-contact-id')} ${withoutContactIdManagers.join(
          '\n'
        )}`
      );

      return;
    }

    const technicalManagers = values?.technicalManagers?.map(
      (manager) => manager?.value
    );

    try {
      const exhibitionId = await typedAxios.bitrix.postBitrixExhibition({
        eventEditionId: values?.exhibition?.value,
        requestBody: technicalManagers,
      });

      ModalFactory.close();
      toast.success(t('exhibition-import-success'));

      navigate({
        to: `/admin/exhibitions/${exhibitionId}/edit`,
      });
    } catch (e) {
      if (e instanceof ApiError) {
        const message = e.body;

        if (message && typeof message === 'string') {
          toast.error(message);
        } else {
          toast.error(t('error-occurred'));
        }
      }
    }
  };

  const openImportModal = useCallback(() => {
    ModalFactory.show({
      title: t('importExhibition'),
      Component: () => (
        <QueryClientProvider client={queryClient}>
          <ImportExhibitionForm />
        </QueryClientProvider>
      ),
      okLabel: t('importButton'),
      onOk: async (ctx: UseFormReturn<ValidationSchemaType>) => {
        return ctx.handleSubmit(() => saveExhibition(ctx.getValues()))();
      },
    });
  }, []);

  return {
    openImportModal,
  };
};
