import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import { Box, Flex, Link, Select } from '@chakra-ui/react';
import {
  DeleteIcon,
  DownloadIcon,
  EditIcon,
  ExternalLinkIcon,
} from '@chakra-ui/icons';
import dayjs from 'dayjs';
import { LoaderWrapper } from '@/components/Loader/Loader';
import { Card } from '@/components/Card/Card';
import { Table } from '@/components/Table/Table';
import styled from 'styled-components';
import { Column, Row } from 'react-table';
import { ToExhibition } from '@/layout/AdminAdditionalPanel/ToExhibition';
import fetchApi from '@/utils/fetchAPI';
import { GET_PDF_BY_ORDER, PLACED_ORDERS } from '@/constants/endpoints';
import { FetchMethods } from '@/utils/constants';
import { useNavigate } from '@tanstack/react-router';
import { STATUS_CONFIG } from '@/constants/admin-pages';
import { EditStatusModal } from '@/pages/AdminPanel/PlacedOrders/EditStatus';
import {
  type ITEPortal_Domain_Dto_AdminOrderSummary,
  ITEPortal_Domain_Dto_OrderDto,
} from '@/api';
import { useRBAC } from '@/features/rbac/useRBAC';
import { ConfirmModal } from '../../ConfirmModal';
import { toast } from 'react-toastify';
import { typedAxios } from '../../../api/typed-axios';
import { isNil } from '../../../utils/is-nil';
import { useTableLoader } from '../../../components/Table/useTableLoader';
import { Button } from '../../../components/Buttons/Button/Button';
import { FileUtils } from '../../../utils/file-utils';
import { viewExhibitionPlacedOrdersRoute } from '@/features/Routing/protected/admin-exhibitions';
import { ORDER_STATUSES } from '@/constants/order';
import { OrderBadge } from '@/components/OrderBadge';

const OptionWrapper = styled(Flex)`
  flex-direction: row;
  gap: 20px;
`;

const ToExhibitionWrapper = styled(Box)`
  padding-bottom: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

interface PlacedOrdersOptionsType {
  row: Row<ITEPortal_Domain_Dto_AdminOrderSummary>;
}

export const PlacedOrders = () => {
  const { can } = useRBAC();
  const culture = localStorage.getItem('lang');
  const { exhibitionId } = viewExhibitionPlacedOrdersRoute.useParams();
  const { exhibitorId, orderStatus } =
    viewExhibitionPlacedOrdersRoute.useSearch();
  const { t } = useTranslation();
  const [isEditModalOpened, setIsEditModalOpened] = useState(false);
  const [orderIdToDelete, setOrderIdToDelete] = useState<number | null>(null);
  const [editTableData, setEditTableData] =
    useState<ITEPortal_Domain_Dto_AdminOrderSummary | null>(null);
  const navigate = useNavigate();
  const canCreateOrder = can('canCreateOrder');

  const {
    data: orders,
    refresh,
    isLoading,
  } = useTableLoader<ITEPortal_Domain_Dto_AdminOrderSummary>({
    resource: PLACED_ORDERS,
    additionalPrams: { exhibitionId, culture, exhibitorId, orderStatus },
  });

  const showPdfByOrder = async (
    order: ITEPortal_Domain_Dto_OrderDto['orderId']
  ) => {
    try {
      const { data } = await fetchApi(GET_PDF_BY_ORDER(order), {
        method: FetchMethods.Get,
        responseType: 'blob',
        params: {
          culture,
        },
      });

      FileUtils.saveBlobAsFile(data, `exhibition-order-${order}.pdf`);
    } catch {
      toast.error(t('unable-to-download', { object: 'PDF' }));
    }
  };

  const DownloadPDF = ({ row }: PlacedOrdersOptionsType) => {
    const orderId = row.original.orderId;

    return (
      <Link onClick={() => showPdfByOrder(orderId)}>
        <DownloadIcon />
      </Link>
    );
  };

  const openModal = () => {
    setIsEditModalOpened(!isEditModalOpened);
  };

  const PlacedOrdersOptions = ({ row }: PlacedOrdersOptionsType) => {
    const orderId = row.original.orderId;

    if (!orderId) {
      return null;
    }

    const canDeleteOrder = can('canDeleteOrder');
    const canChangeOrderStatus = can('canChangeOrderStatus');

    const editStatus = () => {
      setEditTableData(row.original);
      openModal();
    };

    return (
      <OptionWrapper>
        <Link onClick={() => editOrder(orderId)}>
          <ExternalLinkIcon />
        </Link>
        {canChangeOrderStatus && (
          <Link onClick={editStatus}>
            <EditIcon />
          </Link>
        )}

        {canDeleteOrder && (
          <Link onClick={() => setOrderIdToDelete(orderId)}>
            <DeleteIcon />
          </Link>
        )}
      </OptionWrapper>
    );
  };

  const editOrder = (id: ITEPortal_Domain_Dto_AdminOrderSummary['orderId']) => {
    navigate({
      to: '/admin/exhibitions/$exhibitionId/view/placed-orders/$orderId/edit',
      params: {
        exhibitionId: exhibitionId,
        orderId: id,
      },
    });
  };

  const deleteOrder = async () => {
    try {
      if (isNil(orderIdToDelete)) {
        return;
      }

      await typedAxios.order.deleteOrders({
        orderId: orderIdToDelete,
      });

      refresh();
    } catch {
      toast.error(
        t('unable-to-delete', {
          entity: t('order').toLowerCase(),
        })
      );
    }
  };

  const closeModal = () => setIsEditModalOpened(false);

  const placedOrdersColumns = React.useMemo<
    Record<any, Column<ITEPortal_Domain_Dto_AdminOrderSummary>>
  >(() => {
    return {
      id: {
        Header: `${t('orderNumber')}`,
        accessor: 'orderId',
        width: '5%',
      },
      exhibitorName: {
        Header: `${t('orderName')}`,
        accessor: 'exhibitorName',
        width: '30%',
      },
      status: {
        Header: `${t('status')}`,
        accessor: 'orderStatus',
        width: '10%',
        Cell: ({ value }) => <OrderBadge status={value} />,
      },
      createdDate: {
        Header: `${t('createDate')}`,
        accessor: 'createdDate',
        width: '10%',
        Cell: ({ row: { values } }: any) => (
          <>
            {values?.createdDate
              ? dayjs(values?.createdDate).format('DD.MM.YYYY')
              : ''}
          </>
        ),
      },
      lastModifiedDate: {
        Header: `${t('editDate')}`,
        accessor: 'lastModifiedDate',
        width: '10%',
        Cell: ({ row: { values } }: any) => (
          <>
            {values?.lastModifiedDate
              ? dayjs(values?.lastModifiedDate).format('DD.MM.YYYY')
              : ''}
          </>
        ),
      },
      orderSummary: {
        Header: () => (
          <Box w="100%" textAlign="right">
            {t('orderTotal')}
          </Box>
        ),
        accessor: 'orderSummary',
        width: '20%',
        Cell: ({ row }: PlacedOrdersOptionsType) => {
          return (
            <Box
              whiteSpace="nowrap"
              textAlign="right"
            >{`${row?.values?.orderSummary} ${row?.values?.currency}`}</Box>
          );
        },
      },
      currency: {
        accessor: 'currency',
        show: false,
      },
      downloadPdf: {
        Header: `${t('downloadPdf')}`,
        id: 'downloadPdf',
        width: '10%',
        Cell: ({ row }) => <DownloadPDF row={row} />,
      },
      options: {
        Header: '',
        id: 'options',
        width: '5%',
        Cell: ({ row }: PlacedOrdersOptionsType) => (
          <PlacedOrdersOptions row={row} />
        ),
      },
    };
  }, []);

  const getOrderColumns = () => {
    const {
      id,
      exhibitorName,
      status,
      createdDate,
      lastModifiedDate,
      orderSummary,
      currency,
      downloadPdf,
      options,
    } = placedOrdersColumns;

    if (!can('canEditOrders')) {
      return [
        id,
        exhibitorName,
        status,
        createdDate,
        lastModifiedDate,
        orderSummary,
        currency,
        downloadPdf,
      ];
    }

    return [
      id,
      exhibitorName,
      status,
      createdDate,
      lastModifiedDate,
      orderSummary,
      currency,
      downloadPdf,
      options,
    ];
  };

  return (
    <LoaderWrapper loading={isLoading}>
      <ToExhibitionWrapper>
        <ToExhibition />
        <Select
          defaultValue={orderStatus}
          bg="white"
          onChange={(event) => {
            navigate({
              to: './',
              search: {
                orderStatus: event.target.value
                  ? +event.target.value
                  : undefined,
                exhibitorId,
              },
            });
          }}
          placeholder={t('status')}
          width="200px"
          ml="auto"
          mr={4}
        >
          {STATUS_CONFIG.map((cfg) => {
            if (cfg.value === ORDER_STATUSES.Undefined) {
              return null;
            }

            return (
              <option key={cfg.value} value={cfg.value}>
                {cfg.label}
              </option>
            );
          })}
        </Select>
        {canCreateOrder && (
          <Button
            colorScheme="red"
            onClick={() => {
              navigate({
                to: './create',
                search: {
                  exhibitorId: exhibitorId ?? undefined,
                },
              });
            }}
          >
            + {t('createNewOrder')}
          </Button>
        )}
      </ToExhibitionWrapper>

      <Card>
        <Table columns={getOrderColumns()} data={orders} />
        {editTableData && isEditModalOpened && (
          <EditStatusModal
            openConfirm={isEditModalOpened}
            closeModal={closeModal}
            refresh={refresh}
            editTableData={editTableData}
          />
        )}
        {orderIdToDelete && (
          <ConfirmModal
            modalMessage={t('delete-order')}
            isOpen={!!orderIdToDelete}
            closeModal={() => setOrderIdToDelete(null)}
            confirm={deleteOrder}
          />
        )}
      </Card>
    </LoaderWrapper>
  );
};
