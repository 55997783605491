import { createGate, useGate } from 'effector-react';
import { PropsWithChildren } from 'react';

export const CatalogGate = createGate('catalog-gate');

export const CatalogGateHOC = (props: PropsWithChildren) => {
  useGate(CatalogGate);

  return <>{props.children}</>;
};
