/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ITEPortal_Domain_Dto_Bitrix_EventEdition_EventEditionDropdownDto } from '../models/ITEPortal_Domain_Dto_Bitrix_EventEdition_EventEditionDropdownDto';
import type { ITEPortal_Domain_Dto_Bitrix_EventMaster_EventMasterDto } from '../models/ITEPortal_Domain_Dto_Bitrix_EventMaster_EventMasterDto';
import type { ITEPortal_Domain_Dto_Bitrix_Update_BitrixUpdateDto } from '../models/ITEPortal_Domain_Dto_Bitrix_Update_BitrixUpdateDto';
import type { ITEPortal_Domain_Dto_Order_ExportOrderDto } from '../models/ITEPortal_Domain_Dto_Order_ExportOrderDto';
import type { ITEPortal_Domain_Dto_PriceListDto } from '../models/ITEPortal_Domain_Dto_PriceListDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class BitrixService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @returns ITEPortal_Domain_Dto_Bitrix_EventMaster_EventMasterDto Success
   * @throws ApiError
   */
  public getBitrixEventMasters(): CancelablePromise<
    Array<ITEPortal_Domain_Dto_Bitrix_EventMaster_EventMasterDto>
  > {
    return this.httpRequest.request({
      method: 'GET',
      url: '/bitrix/event-masters',
      errors: {
        400: `Bad Request`,
      },
    });
  }

  /**
   * @returns ITEPortal_Domain_Dto_Bitrix_EventEdition_EventEditionDropdownDto Success
   * @throws ApiError
   */
  public getBitrixEventEditions({
    eventMasterId,
  }: {
    eventMasterId?: number;
  }): CancelablePromise<
    Array<ITEPortal_Domain_Dto_Bitrix_EventEdition_EventEditionDropdownDto>
  > {
    return this.httpRequest.request({
      method: 'GET',
      url: '/bitrix/event-editions/{eventMasterId}',
      path: {
        eventMasterId: eventMasterId,
      },
      errors: {
        400: `Bad Request`,
      },
    });
  }

  /**
   * @returns number Success
   * @throws ApiError
   */
  public postBitrixExhibition({
    eventEditionId,
    culture = 'ru',
    requestBody,
  }: {
    eventEditionId: number;
    culture?: string;
    requestBody?: Array<number>;
  }): CancelablePromise<number> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/bitrix/exhibition/{eventEditionId}',
      path: {
        eventEditionId: eventEditionId,
      },
      query: {
        culture: culture,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        400: `Bad Request`,
      },
    });
  }

  /**
   * @returns binary Success
   * @throws ApiError
   */
  public postBitrixExhibitionExhibitors({
    exhibitionId,
  }: {
    exhibitionId: number;
  }): CancelablePromise<Blob> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/bitrix/exhibition/{exhibitionId}/exhibitors',
      path: {
        exhibitionId: exhibitionId,
      },
      errors: {
        400: `Bad Request`,
      },
    });
  }

  /**
   * @returns ITEPortal_Domain_Dto_PriceListDto Success
   * @throws ApiError
   */
  public postBitrixPriceList({
    exhibitionId,
  }: {
    exhibitionId: number;
  }): CancelablePromise<Array<ITEPortal_Domain_Dto_PriceListDto>> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/bitrix/price-list/{exhibitionId}',
      path: {
        exhibitionId: exhibitionId,
      },
      errors: {
        400: `Bad Request`,
      },
    });
  }

  /**
   * @returns any Success
   * @throws ApiError
   */
  public postBitrixSalesOffices(): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/bitrix/sales-offices',
      errors: {
        400: `Bad Request`,
      },
    });
  }

  /**
   * @returns any Success
   * @throws ApiError
   */
  public postBitrixResponsible(): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/bitrix/responsible',
      errors: {
        400: `Bad Request`,
      },
    });
  }

  /**
   * @returns any Success
   * @throws ApiError
   */
  public postBitrixOrders({
    orderId,
    requestBody,
  }: {
    orderId: number;
    requestBody?: ITEPortal_Domain_Dto_Order_ExportOrderDto;
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/bitrix/orders/{orderId}',
      path: {
        orderId: orderId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        400: `Bad Request`,
      },
    });
  }

  /**
   * @returns boolean Success
   * @throws ApiError
   */
  public postBitrixUpdateFromBitrix({
    requestBody,
  }: {
    requestBody?: ITEPortal_Domain_Dto_Bitrix_Update_BitrixUpdateDto;
  }): CancelablePromise<Record<string, boolean>> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/bitrix/update-from-bitrix',
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        400: `Bad Request`,
      },
    });
  }

  /**
   * @returns any Success
   * @throws ApiError
   */
  public postBitrixUpdateContactId({
    userId,
    contactId,
  }: {
    userId: number;
    contactId: number;
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/bitrix/update-contact-id/{userId}/{contactId}',
      path: {
        userId: userId,
        contactId: contactId,
      },
      errors: {
        400: `Bad Request`,
      },
    });
  }
}
