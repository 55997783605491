import { IdType } from './common';
import { ReactNode } from 'react';
import {
  ITEPortal_Domain_Dto_Exhibition_ExhibitionStatisticsWithSummary,
  ITEPortal_Domain_Dto_ExhibitionSummary,
} from '../api';
import dayjs from 'dayjs';

export type ExhibitionSummaryType = {
  id: IdType;
  name: string;
  logoUrl: string;
  description?: string | ReactNode;
  startDate: string;
  endDate: string;
  place: string;
};

// prettier-ignore
export class IExhibitionSummary
  implements ITEPortal_Domain_Dto_Exhibition_ExhibitionStatisticsWithSummary {
  currency?: string | null = null;
  exhibitionSummary?: ITEPortal_Domain_Dto_ExhibitionSummary;
  orders?: number;
  sellingOfficeId?: number;
  stands?: number;

  constructor(
    json: ITEPortal_Domain_Dto_Exhibition_ExhibitionStatisticsWithSummary
  ) {
    Object.assign(this, json);
  }

  get isPast() {
    if (!this.exhibitionSummary || !this.exhibitionSummary.endDate) {
      return true;
    }

    const currentDate = dayjs();
    const endDate = dayjs(this.exhibitionSummary.endDate);

    return endDate.isBefore(currentDate);
  }

  get formattedStartDate() {
    if (!this.exhibitionSummary?.startDate) {
      return 'n/a';
    }

    return dayjs(this.exhibitionSummary.startDate).format('DD.MM.YYYY');
  }

  get formattedEndDate() {
    if (!this.exhibitionSummary?.endDate) {
      return 'n/a';
    }

    return dayjs(this.exhibitionSummary.endDate).format('DD.MM.YYYY');
  }
}
