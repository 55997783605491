import { useTranslation } from 'react-i18next';
import {
  ButtonGroup,
  Card,
  CardBody,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Flex,
} from '@chakra-ui/react';
import styled from 'styled-components';
import { MainInfo } from '@/pages/AdminPanel/PersonalAccounts/MainInfo';
import { StandsTab } from '@/pages/AdminPanel/PersonalAccounts/StandsTab';
import { Button as OrderButton } from '@/components/Buttons/Button/Button';
import React, { useState } from 'react';
import {
  addExhibitionAccountRoute,
  editExhibitionAccountRoute,
  EditPersonalAccountsPageSearchSchemaType,
} from '../../../features/Routing/protected/admin-exhibitions';
import { useNavigate } from '@tanstack/react-router';
import { UsersTab } from '@/pages/AdminPanel/PersonalAccounts/UsersTab';
import {
  ExhibitionBox,
  ExhibitionTitle,
} from '@/components/Sidebar/OrderList/OrderList.styled';
import { Nullable } from 'vitest';
import { ProductsTab } from '@/pages/AdminPanel/PersonalAccounts/ProductsTab/ProductsTab';
import { SubexponentsTab } from '@/pages/AdminPanel/PersonalAccounts/SubexponentsTab/SubexponentsTab';

const tabStyles = {
  borderColor: 'blackAlpha.200',
  borderBottomColor: '#F7FAFC',
  color: 'red',
};

const TabWrapper = styled(TabPanel)`
  && {
    padding-top: 50px;
    padding-left: 0;
  }
`;

interface NewAccountProps {
  type: 'edit' | 'add';
}

interface ShowExhibitionInfoProps {
  label?: Nullable<string>;
}

const TabsMap: Record<EditPersonalAccountsPageSearchSchemaType['tab'], number> =
  {
    main: 0,
    stands: 1,
    users: 2,
    products: 3,
    subexponents: 4,
  };

const ExhibitionInfoWrapper = styled(Flex)`
  flex-direction: row;
  justify-content: flex-end;
  position: absolute;
  right: 20px;
  top: 10px;
`;

const ShowExhibitionInfo = ({ label }: ShowExhibitionInfoProps) => {
  if (!label) {
    return null;
  }

  return (
    <ExhibitionInfoWrapper>
      <ExhibitionBox>
        <ExhibitionTitle>{label}</ExhibitionTitle>
      </ExhibitionBox>
    </ExhibitionInfoWrapper>
  );
};

export const NewAccount = ({ type }: NewAccountProps): JSX.Element => {
  const [openConfirm, setOpenConfirm] = useState(false);
  const navigate = useNavigate();
  const isEdit = type === 'edit';
  const { t } = useTranslation();

  const { tab } = (
    isEdit ? editExhibitionAccountRoute : addExhibitionAccountRoute
  ).useSearch();

  const { data } = (
    isEdit ? editExhibitionAccountRoute : addExhibitionAccountRoute
  ).useLoaderData();

  const selectedTabFromUrl = isEdit ? TabsMap[tab] || 0 : 0;

  const WarningModal = () => {
    const closeModal = () => setOpenConfirm(false);

    return (
      <Modal isOpen={openConfirm} onClose={closeModal} size="lg" isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t('warning')}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>{t('new-account-modal-text')}</ModalBody>
          <ModalFooter onClick={closeModal} justifyContent="center">
            <ButtonGroup gap="1" justifyContent="center">
              <OrderButton colorScheme="secondary" onClick={closeModal}>
                {t('cancel')}
              </OrderButton>
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  };

  const handleTabsChange = (index: number) => {
    if (isEdit) {
      navigate({
        to: './',
        search: {
          tab: Object.keys(TabsMap)[index],
        },
      });
    }
  };

  return (
    <Card>
      <CardBody>
        <ShowExhibitionInfo label={data?.name} />
        <Tabs
          isManual
          isLazy
          variant="enclosed"
          index={selectedTabFromUrl}
          onChange={handleTabsChange}
        >
          <TabList>
            <Tab _selected={tabStyles}>{t('main-info')}</Tab>
            <Tab isDisabled={!isEdit} _selected={tabStyles}>
              {t('stands-tab')}
            </Tab>
            <Tab isDisabled={!isEdit} _selected={tabStyles}>
              {t('employees')}
            </Tab>
            <Tab isDisabled={!isEdit} _selected={tabStyles}>
              {t('products')}
            </Tab>
            <Tab isDisabled={!isEdit} _selected={tabStyles}>
              {t('subexponents')}
            </Tab>
          </TabList>
          <TabPanels>
            <TabWrapper maxWidth={600}>
              <MainInfo type={type} />
            </TabWrapper>
            <TabWrapper>{isEdit && <StandsTab />}</TabWrapper>
            <TabWrapper>{isEdit && <UsersTab />}</TabWrapper>
            <TabWrapper>{isEdit && <ProductsTab />}</TabWrapper>
            <TabWrapper>{isEdit && <SubexponentsTab />}</TabWrapper>
          </TabPanels>
        </Tabs>
        <WarningModal />
      </CardBody>
    </Card>
  );
};
