import * as v from 'zod';

export const ServiceDictionaryValidationSchema = v.object({
  name: v.string().min(1, { message: 'ID field is required' }),
  displayNameRu: v
    .string()
    .min(1, { message: 'Category name RU field is required' }),
  displayNameEn: v
    .string()
    .min(1, { message: 'Category name EN  field is required' }),
  unitOfMeasure: v.object({
    value: v.string(),
    label: v.string(),
  }),
});
