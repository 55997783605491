import styled from 'styled-components';

const Item = styled.li<{ active: boolean }>`
  margin: 0;

  color: ${({ active }) =>
    active ? 'rgba(0, 0, 0, 0.7)' : 'rgba(0, 0, 0, 0.5)'};

  transition: color ease-in-out 15ms;
  cursor: pointer;

  &:hover {
    color: rgba(0, 0, 0, 0.7);
  }

  > * {
    padding: 0.5rem 1rem;
  }
`;

interface MenuItem {
  children?: React.ReactNode;
  active?: boolean;
}

const MenuItem: React.FC<MenuItem> = ({ children, active = false }) => {
  return <Item active={active}>{children}</Item>;
};

export default MenuItem;
