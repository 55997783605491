import {
  FieldValues,
  useForm,
  FormProvider,
  useController,
} from 'react-hook-form';
import {
  ButtonGroup,
  FormControl,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
} from '@chakra-ui/react';
import { useNameTranslate } from '@/hooks/useStringName';
import { SelectComponent } from '@/components/MultiSelect/MultiSelect';
import Input from '@/components/Fields/Input';
import { Button as OrderButton } from '@/components/Buttons/Button/Button';
import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { t } from 'i18next';
import { toast } from 'react-toastify';
import { OptionType } from '@/model/common';
import { editOrderRoute } from '@/features/Routing/protected/admin-exhibitions';
import { useRouter } from '@tanstack/react-router';
import { typedAxios } from '@/api/typed-axios';
import {
  ITEPortal_Domain_Dto_AdminPriceListItem,
  ITEPortal_Domain_Dto_OrderItem_CreateOrderItemDto,
} from '@/api';

const FormControlWrapper = styled(FormControl)`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

interface AddBuildingTypeProductProps {
  openConfirm: boolean;
  closeModal: () => void;
  editTableData?: any;
}

const defaultValues = {
  id: null,
  amount: null,
  dayAmount: null,
  peopleAmount: null,
  language: null,
};

export const EditOrderForm = ({
  openConfirm,
  closeModal,
  editTableData,
}: AddBuildingTypeProductProps) => {
  const { orderId, exhibitionId } = editOrderRoute.useParams();
  const router = useRouter();
  const { items } = editOrderRoute.useLoaderData();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [editData, setEditData] = useState<any>();

  const products = useMemo(() => {
    return items.map((i) => ({
      value: i.name || i.id,
      label: `${i.name || i.id} (${i.displayName})`,
      meta: i,
    })) satisfies OptionType<ITEPortal_Domain_Dto_AdminPriceListItem>[];
  }, [items]);

  useEffect(() => {
    if (!editTableData) {
      setEditData(defaultValues);
    }

    if (editTableData) {
      const orderItemData = [] as any;

      Object.keys(editTableData).forEach((field) => {
        orderItemData[field] =
          field === 'id'
            ? {
                value: editTableData?.id,
                label: editTableData?.productName,
              }
            : editTableData[field];
      });

      setEditData(orderItemData);
    }
  }, [editTableData]);

  useEffect(() => {
    methods.reset({
      ...editData,
    });
  }, [editData]);

  const methods = useForm({
    mode: 'onBlur',
  });

  const {
    field: { ...idProps },
  } = useController({ name: 'id', control: methods?.control });

  const submitHandler = async (values: FieldValues) => {
    const id = values?.id?.value;
    const dayAmount = values?.dayAmount || 0;
    const peopleAmount = values?.peopleAmount || 0;
    const amount = values?.amount || 0;
    const language = values?.language || '';

    const data = {
      dayAmount,
      peopleAmount,
      language,
      amount,
      id,
      included: editTableData ? editTableData.included : undefined,
    } satisfies ITEPortal_Domain_Dto_OrderItem_CreateOrderItemDto;

    try {
      setIsSubmitting(true);

      if (editTableData) {
        await typedAxios.order.putExhibitionsOrders({
          orderId: +orderId,
          exhibitionId: +exhibitionId,
          orderItemId: editTableData.orderItemId,
          requestBody: data,
        });
      } else {
        await typedAxios.order.postExhibitionsOrders({
          orderId: +orderId,
          exhibitionId: +exhibitionId,
          requestBody: data,
        });
      }

      closeModal();
      router.invalidate();
    } catch (error: any) {
      const fieldErrors = error?.response?.data?.errors;

      if (fieldErrors) {
        toast.error(fieldErrors);
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Modal
      isOpen={openConfirm}
      onClose={closeModal}
      size="xl"
      scrollBehavior="inside"
      isCentered
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{useNameTranslate('add-product')}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormProvider {...methods}>
            <form>
              <Stack spacing="5">
                <FormControlWrapper>
                  <SelectComponent
                    label={useNameTranslate('service-name')}
                    maxWidth="100%"
                    options={products}
                    defaultValue={editData?.['id']}
                    isRequired
                    {...idProps}
                  />
                  <Input
                    label={`${useNameTranslate('amount')} / ${useNameTranslate(
                      'sqm'
                    )}`}
                    maxWidth="100%"
                    defaultValue={editData?.['amount']}
                    name="amount"
                  />
                  <Input
                    label={useNameTranslate('dayAmountPlaceholder')}
                    maxWidth="100%"
                    defaultValue={editData?.['dayAmount']}
                    name="dayAmount"
                  />
                  <Input
                    label={useNameTranslate('personAmountPlaceholder')}
                    maxWidth="100%"
                    defaultValue={editData?.['peopleAmount']}
                    name="peopleAmount"
                  />
                  <Input
                    label={useNameTranslate('language')}
                    maxWidth="100%"
                    defaultValue={editData?.['language']}
                    name="language"
                  />
                </FormControlWrapper>
              </Stack>
            </form>
          </FormProvider>
        </ModalBody>
        <ModalFooter justifyContent="center">
          <ButtonGroup gap="1" justifyContent="center">
            <OrderButton
              colorScheme="red"
              onClick={methods.handleSubmit(submitHandler)}
              disabled={isSubmitting}
            >
              {t('save')}
            </OrderButton>
            <OrderButton colorScheme="secondary" onClick={closeModal}>
              {t('cancel')}
            </OrderButton>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
