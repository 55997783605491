import * as Sentry from '@sentry/react';

export const bootstrapSentry = () => {
  if (process.env.NODE_ENV === 'development') {
    return;
  }

  try {
    Sentry.init({
      dsn: 'https://0131d4d8a994bb3eee122cac062f6017@o308535.ingest.sentry.io/4506558801379328',
      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      tracesSampleRate: 1.0,

      // Capture Replay for 10% of all sessions,
      // plus for 100% of sessions with an error
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
      release: process.env.PUBLIC_API_URL,
    });
  } catch {
    console.warn(`sentry initialisation failed`);
  }
};
