import {
  Footer as StyledFooter,
  StyledButton,
  StyledText,
} from './OrderList.styled';
import { useTranslation } from 'react-i18next';
import { useNavigate } from '@tanstack/react-router';
import { replacePaths } from '@/utils/route';
import { CREATE_EXHIBITION_CART_PATH } from '@/constants/router';
import { createExhibitionOrderServiceRoute } from '@/features/Routing/protected/exhibitions-by-role';
import { useOrder, useOrderTotal } from '@/store/effector/order';
import { useExhibition } from '@/store/effector/exhibition';
import { useOrderCompleteness } from '@/store/effector/order-completeness';

const Footer = () => {
  const { t } = useTranslation();
  const { items } = useOrder();
  const total = useOrderTotal();
  const { currency } = useExhibition();
  const { isOrderCompleted } = useOrderCompleteness();
  const isDisabled = !items?.length || !isOrderCompleted;
  const navigate = useNavigate();
  const { exhibitionId, stand } = createExhibitionOrderServiceRoute.useParams();

  const addToCart = () => {
    const cartPath = replacePaths(CREATE_EXHIBITION_CART_PATH, {
      ':exhibitionId': exhibitionId,
      ':stand': stand,
    });

    navigate({
      to: cartPath,
    });
  };

  return (
    <StyledFooter>
      <StyledText>
        {t('summary', {
          cost: t('result-price', {
            cost: (total || 0).toLocaleString(),
            currency,
          }),
        })}
      </StyledText>
      <StyledButton onClick={addToCart} isDisabled={isDisabled}>
        {t('checkout')}
      </StyledButton>
    </StyledFooter>
  );
};

export default Footer;
