import styled from 'styled-components';
import { Box, TableContainer, Td, Text, Th } from '@chakra-ui/react';

export const PricesWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-bottom: 10px;
`;

export const PriceRowContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #edf2f7;
  border-radius: 5px;
  height: 40px;
  padding: 10px 0 10px 10px;
  width: 100%;
`;

export const PriceNumber = styled(Box)`
  background-color: #a0aec0;
  color: #ffffff;
  width: 30%;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
`;

export const PriceLabel = styled(Box)`
  width: 70%;
  font-size: 14px;
  line-height: 1;
`;

export const ImageDescription = styled(Box)`
  display: flex;
  justify-content: center;
  font-size: 12px;
  padding-top: 5px;
`;

export const ImageWrapper = styled(Box)`
  padding-bottom: 10px;
`;

export const BuildingDescriptionWrapper = styled(Box)`
  padding-bottom: 5px;
`;

export const BuildingDescriptionLabelContainer = styled(Box)`
  font-size: 16px;
  font-weight: 600;
  padding-bottom: 5px;
`;

export const BuildingDescriptionBodyRow = styled(Text)`
  font-size: 14px;
`;

export const BuildingTableTitle = styled(Th)`
  font-size: 14px;
  font-weight: 600;
  padding-left: 0 !important;
`;

export const BuildingTableRow = styled(Td)`
  font-size: 14px;
  padding-left: 0 !important;
`;

export const TableContainerWrapper = styled(TableContainer)`
  white-space: unset !important;
  padding: 15px 0;
`;

export const NoteSectionContainer = styled(Box)`
  background-color: #edf2f7;
  border-radius: 5px;
  padding: 10px;
  font-size: 12px;
`;

export const BuildingCardWrapper = styled.form``;
