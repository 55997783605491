import { useEffect, useState } from 'react';
import { typedAxios } from '../../../api/typed-axios';
import { ExhibitionUtils } from '../../../utils/exhibition-utils';
import { useBoolean } from '@chakra-ui/react';
import { IExhibitionSummary } from '../../../model/IExhibitionSummary';

export function useExhibitionLoader() {
  const [exhibitions, setExhibitions] = useState<
    ReturnType<typeof ExhibitionUtils.splitExhibitionByDate>
  >({ currentExhibitions: [], pastExhibitions: [] });

  const [isLoading, { off }] = useBoolean(true);

  useEffect(() => {
    const load = async () => {
      try {
        const response =
          await typedAxios.exhibition.getExhibitionsExhibitorStatistics();

        setExhibitions(
          ExhibitionUtils.splitExhibitionByDate(
            response.map((ex) => new IExhibitionSummary(ex))
          )
        );
      } finally {
        off();
      }
    };

    load();
  }, []);

  return {
    exhibitions,
    isLoading,
  };
}
