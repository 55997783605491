import styled from 'styled-components';
import TechnicalServiceCard from '@/components/Card/TechnicalServiceCard';
import { useTranslation } from 'react-i18next';
import { PageTitle } from '@/components/Typography/PageTitle';
import { ITechnicalService } from '@/model/ITechnicalService';
import { SimpleGrid } from '@chakra-ui/react';
import { IdType } from '@/model/common';

import { Loader } from '@/components/Loader/Loader';
import { LoaderWrapper } from '@/theme/css/styles';
import { useCategoriesByData } from '@/hooks/categoriesConverter/useCategoriesByData';
import { useSubCategoriesByData } from '@/hooks/categoriesConverter/useSubCategoriesByData';
import { TKey } from '../../i18n/types';
import { useCatalog } from '@/store/effector/catalog';
import { useAppLoading } from '@/store/effector/loading';
import { Category } from '@/model/ICategory';

export const Grid = styled(SimpleGrid)`
  margin-left: auto;
  margin-right: auto;
`;

const CenteredTitle = styled(PageTitle)`
  margin-top: 40px;
  margin-bottom: 32px;
  padding-bottom: 0;
  text-align: center;
`;

interface CategoriesProps {
  config?: ITechnicalService[];
  title?: TKey;
  category?: Category;
}

export type CategoryType = {
  description: string;
  displayName: string;
  id: IdType;
  imageUrl: string;
  name: string;
  parentCategory: string;
  parentCategoryId: IdType;
};

const TechnicalServicesGrid: React.FC<CategoriesProps> = ({
  title,
  category,
}) => {
  const isLoader = useAppLoading();
  const { rootCategories } = useCatalog();
  const categoryConfig = useCategoriesByData(rootCategories);
  const subcategoriesByCategory = useSubCategoriesByData(category?.tree || []);
  const { t } = useTranslation();

  const categoriesToRender =
    subcategoriesByCategory.length > 0
      ? subcategoriesByCategory
      : categoryConfig;

  return (
    <>
      {title && <CenteredTitle>{t(title)}</CenteredTitle>}
      {isLoader ? (
        <LoaderWrapper>
          <Loader />
        </LoaderWrapper>
      ) : (
        <Grid
          maxWidth="1600px"
          gap="32px"
          rowGap="32px"
          columns={{
            sm: 2,
            md: 3,
            xl: 4,
          }}
        >
          {categoriesToRender.map((service) => {
            return (
              <TechnicalServiceCard
                key={service?.['category']?.['id']}
                service={service}
              />
            );
          })}
        </Grid>
      )}
    </>
  );
};

export default TechnicalServicesGrid;
