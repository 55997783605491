import Lightbox, { SlideImage } from 'yet-another-react-lightbox';
import Inline from 'yet-another-react-lightbox/plugins/inline';
import 'yet-another-react-lightbox/styles.css';
import 'yet-another-react-lightbox/plugins/thumbnails.css';

import { useMemo, useState } from 'react';
import styled from 'styled-components';
import { Box, HStack } from '@chakra-ui/react';

interface BuildingStandardBodyProps {
  images: string[];
}

const DotsWrapper = styled(HStack)`
  justify-content: center;
  position: absolute;
  bottom: 8px;
  width: 100%;
`;

const DotsContainer = styled(Box)`
  cursor: pointer;
  margin: 0 2px;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;

  &:hover {
    background: black;
  }
`;

export const BuildingGallery = ({
  images,
}: BuildingStandardBodyProps): JSX.Element => {
  const [open, setOpen] = useState(false);
  const [index, setIndex] = useState(0);

  const slides = useMemo<Array<SlideImage>>(() => {
    return images.map((image) => {
      return {
        id: image,
        src: image,
      };
    });
  }, [images]);

  const toggleOpen = (state: boolean) => () => setOpen(state);

  const updateIndex = ({ index: current }: { index: number }) =>
    setIndex(current);

  const singleImage = slides.length <= 1;

  return (
    <Box position="relative">
      <Lightbox
        index={index}
        slides={slides}
        plugins={[Inline]}
        on={{
          view: updateIndex,
          click: toggleOpen(true),
        }}
        carousel={{
          padding: 0,
          spacing: 0,
          imageFit: 'cover',
          finite: singleImage,
        }}
        render={{
          iconPrev: singleImage ? () => null : undefined,
          iconNext: singleImage ? () => null : undefined,
        }}
        inline={{
          style: {
            width: '100%',
            maxWidth: '430px',
            aspectRatio: '3 / 2',
            margin: '0 auto',
          },
        }}
      />
      {!singleImage && (
        <DotsWrapper>
          {Array.from({ length: slides.length }).map((_, slide) => (
            <DotsContainer
              key={`dots-${slide}`}
              boxSize={['7px', null, '10px']}
              bg={index === slide ? '#ffffff' : 'blackAlpha.200'}
              onClick={() => updateIndex({ index: slide })}
            />
          ))}
        </DotsWrapper>
      )}

      <Lightbox
        open={open}
        close={toggleOpen(false)}
        index={index}
        slides={slides}
        carousel={{
          finite: singleImage,
        }}
        on={{ view: updateIndex }}
        animation={{ fade: 0 }}
        render={{
          iconPrev: singleImage ? () => null : undefined,
          iconNext: singleImage ? () => null : undefined,
        }}
        controller={{ closeOnPullDown: true, closeOnBackdropClick: true }}
        styles={{ root: { '--yarl__color_backdrop': 'rgba(0, 0, 0, .7)' } }}
      />
    </Box>
  );
};
