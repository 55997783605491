import { IdType } from '../model/common';
import { Nullable } from '../types/support-types';
import { useCatalog } from '@/store/effector/catalog';

export const useSearchCategoryForProduct = () => {
  const { flatMap } = useCatalog();
  const source = [...flatMap.values()];

  return {
    findCategoryForProduct: (categoryId: Nullable<IdType>) => {
      return source.find(
        (cat) => cat.id === categoryId || cat.name === categoryId
      );
    },
  };
};
