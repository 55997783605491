import { Category } from '@/model/ICategory';
import { ITechnicalService } from '@/model/ITechnicalService';
import { Nullable } from '../types/support-types';

type CategoryInfo = {
  category: undefined | Category;
  description: Nullable<string>;
};

export const useProductCategory = (
  categories: ITechnicalService[],
  id: number | string
): undefined | CategoryInfo => {
  const category = categories.find(
    (exhibitionCategory: ITechnicalService) =>
      exhibitionCategory?.category?.name === id
  );

  return {
    category: category?.['category'],
    description: category?.['description'],
  };
};
