import { Column } from 'react-table';
import { Table } from '@/components/Table/Table';
import { Flex } from '@chakra-ui/react';
import i18n from '@/i18n';
import { editExhibitionAccountRoute } from '@/features/Routing/protected/admin-exhibitions';
import { useSuspenseQuery } from '@tanstack/react-query';
import { QueryOptions } from '@/features/query/query-options';
import { ITEPortal_Domain_Dto_Stand_GetStandProductDto } from '@/api';
import { Loader } from '@/components/Loader/Loader';

const columns = [
  {
    Header: i18n.t('product-name'),
    accessor: 'displayName',
    width: '30%',
  },
  {
    Header: i18n.t('product-id'),
    accessor: 'productId',
  },
  {
    Header: i18n.t('price'),
    accessor: 'price',
  },
  {
    Header: i18n.t('amount'),
    accessor: 'quantity',
  },
  {
    Header: i18n.t('discount'),
    accessor: 'discount',
  },
  {
    Header: i18n.t('tax'),
    accessor: 'vat',
  },
  //Need formula
  // {
  //   Header: i18n.t('taxIncluded'),
  //   accessor: 'vatIncluded',
  // },
  {
    Header: i18n.t('currency'),
    accessor: 'currency',
  },
] satisfies Array<Column<ITEPortal_Domain_Dto_Stand_GetStandProductDto>>;

export const ProductsTab = () => {
  const { accountId, exhibitionId } = editExhibitionAccountRoute.useParams();

  const { data: stands = [] } = useSuspenseQuery(
    QueryOptions.standsQueryOptions({
      exhibitionId: +exhibitionId,
      exhibitorId: +accountId,
    })
  );

  const { data = [], isLoading } = useSuspenseQuery(
    QueryOptions.productsQueryOptions({
      standId: stands?.[0]?.id,
    })
  );

  return (
    <Flex flexDir="column">
      {isLoading ? <Loader /> : <Table columns={columns} data={data} />}
    </Flex>
  );
};
