import { ReactNode } from 'react';
import { Main } from './Layout.styled';
import Header from '@/components/Header';

export interface AuthLayoutProps {
  children?: ReactNode | ReactNode[];
}

const Layout = ({ children }: AuthLayoutProps): JSX.Element => {
  return (
    <Main>
      <Header />
      {children}
    </Main>
  );
};

export default Layout;
