import { redirect, Route } from '@tanstack/react-router';
import { flatRouteTree } from '@/features/Routing/protected';
import { AgentHome } from '@/pages/AgentHome';
import { User } from '@/model/IUser';
import { indexRouteFactory } from '@/features/Routing/userPaths';
import { RoleType } from '@/model/common';

export const agentRoutesRoot = new Route({
  path: '/agent-home',
  getParentRoute: () => flatRouteTree,
  component: AgentHome,
  beforeLoad: async ({ context: { role } }) => {
    if (role !== User.Role.Agent) {
      throw redirect({
        to: indexRouteFactory(role as RoleType),
        replace: true,
      });
    }
  },
});
