/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ITEPortal_Domain_Dto_Discount_GetCleaningDiscountDto } from '../models/ITEPortal_Domain_Dto_Discount_GetCleaningDiscountDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class DiscountService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns ITEPortal_Domain_Dto_Discount_GetCleaningDiscountDto Success
     * @throws ApiError
     */
    public getDiscountsCleaning(): CancelablePromise<Array<ITEPortal_Domain_Dto_Discount_GetCleaningDiscountDto>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/discounts/cleaning',
            errors: {
                400: `Bad Request`,
            },
        });
    }

    /**
     * @returns number Success
     * @throws ApiError
     */
    public postDiscountsCleaning({
        areaFrom,
        areaTo,
        discountPercentage,
    }: {
        areaFrom?: number,
        areaTo?: number,
        discountPercentage?: number,
    }): CancelablePromise<number> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/discounts/cleaning',
            query: {
                'AreaFrom': areaFrom,
                'AreaTo': areaTo,
                'DiscountPercentage': discountPercentage,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }

    /**
     * @returns boolean Success
     * @throws ApiError
     */
    public putDiscountsCleaning({
        discountId,
        areaFrom,
        areaTo,
        discountPercentage,
    }: {
        discountId: number,
        areaFrom?: number,
        areaTo?: number,
        discountPercentage?: number,
    }): CancelablePromise<boolean> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/discounts/cleaning/{discountId}',
            path: {
                'discountId': discountId,
            },
            query: {
                'AreaFrom': areaFrom,
                'AreaTo': areaTo,
                'DiscountPercentage': discountPercentage,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }

    /**
     * @returns boolean Success
     * @throws ApiError
     */
    public deleteDiscountsCleaning({
        discountId,
    }: {
        discountId: number,
    }): CancelablePromise<boolean> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/discounts/cleaning/{discountId}',
            path: {
                'discountId': discountId,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }

}
