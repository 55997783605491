import { Category } from '@/model/ICategory';

export const useSubCategoriesByData = (subCategoriesData: Category[]) => {
  return subCategoriesData.map((subCategory) => {
    return {
      category: subCategory,
      image: subCategory.imageUrl,
      id: subCategory.id,
      description: subCategory.description,
    };
  });
};
