import { AdminPanelContent, ContentInner } from '@/components/Content';
import Layout from '@/layout/Default';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { FlexedPageTitle } from '@/components/Typography/PageTitle';
import { IBreadcrumbItem } from '@/model/common';
import { replacePaths } from '@/utils/route';
import {
  SHOW_SUPPORT_MANAGER_ACTION_PATH,
  SHOW_SUPPORT_MANAGER_PATH,
} from '@/constants/router';
import { useTranslation } from 'react-i18next';
import Breadcrumbs from '@/components/Breadcrumbs';

import { Category } from '@/model/ICategory';
import { Outlet, useMatches } from '@tanstack/react-router';
import { ConnectedSidebar } from '../ConnectedSidebar/ConnectedSidebar';

const StyledContentInner = styled(ContentInner)<{
  $withToolbar?: boolean;
}>`
  padding: ${({ $withToolbar }) => ($withToolbar ? '26px 24px' : '0 24px')};
  padding-bottom: 10px;
`;

export const AdminPanelPage = (): JSX.Element => {
  // todo ad refactoring
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { context = {} } = useMatches().at(-1);
  const { page, type, backPath, pageTitle, subHeader } = context;

  const { t } = useTranslation();
  const category = new Category({
    id: page,
    name: page,
    displayName: t(page),
  });

  if (!category) {
    return <>not found</>;
  }

  const pageName = (() => {
    if (pageTitle) {
      return pageTitle;
    }

    return type
      ? // @ts-expect-error this should be resolved on react router level
        t(`${page}-${type}`)
      : category.displayName;
  })();

  const breadcrumbsItems = useMemo<IBreadcrumbItem[]>(() => {
    const items: IBreadcrumbItem[] = [
      {
        label: t(page),
        link:
          backPath ||
          replacePaths(SHOW_SUPPORT_MANAGER_PATH, {
            ':page': page,
          }),
      },
    ];

    if (type) {
      items.push({
        label:
          // @ts-expect-error this should be resolved on react router level
          t(`${page}-${type}`),
        link: replacePaths(SHOW_SUPPORT_MANAGER_ACTION_PATH, {
          ':page': page,
          ':type': type,
        }),
      });
    }

    return [...items];
  }, [page, type]);

  return (
    <Layout>
      <ConnectedSidebar />
      <AdminPanelContent>
        <StyledContentInner $withToolbar={!!type}>
          {type && <Breadcrumbs items={breadcrumbsItems} id="breadcrumbsId" />}
          <FlexedPageTitle>
            {pageName}
            {subHeader}
          </FlexedPageTitle>
          <Outlet />
        </StyledContentInner>
      </AdminPanelContent>
    </Layout>
  );
};
