import { Table } from '../../../../components/Table/Table';
import React, { useMemo } from 'react';
import { NumberColumnComponent } from '../shared/NumberColumnComponent';
import { useTranslation } from 'react-i18next';
import { OrderControls } from './OrderControls';
import { Box } from '@chakra-ui/react';
import { useCreateOrderContext } from './CreateOrderContext';
import { CreateOrderButton } from './CreateOrderButton';
import { DeleteIcon } from '@chakra-ui/icons';
import { Column, Row } from 'react-table';
import { ITEPortal_Domain_Dto_OrderItemDto } from '../../../../api';

export const AddedToOrderItemsTable = () => {
  const { t } = useTranslation();
  const { selectedItems, removeItem } = useCreateOrderContext();

  const columns = useMemo<
    Array<Column<ITEPortal_Domain_Dto_OrderItemDto>>
  >(() => {
    return [
      {
        Header: t('service-id'),
        accessor: 'id',
        width: '15%',
      },
      {
        Header: t('service-name'),
        accessor: 'productName',
        width: '30%',
      },
      {
        accessor: 'orderItemId',
        show: false,
      },
      {
        Header: `${t('amount')}`,
        accessor: 'amount',
        width: '10%',
        Cell: ({ row: { values } }: any) => (
          <NumberColumnComponent value={values?.amount} />
        ),
      },
      {
        Header: `${t('dayAmountPlaceholder')}`,
        accessor: 'dayAmount',
        width: '10%',
        Cell: ({ row: { values } }: any) => (
          <NumberColumnComponent value={values?.dayAmount} />
        ),
      },
      {
        Header: `${t('personAmountPlaceholder')}`,
        accessor: 'peopleAmount',
        width: '10%',
        Cell: ({ row: { values } }: any) => (
          <NumberColumnComponent value={values?.peopleAmount} />
        ),
      },
      {
        Header: `${t('language')}`,
        accessor: 'language',
        width: '10%',
        Cell: ({ row: { values } }: any) => (
          <NumberColumnComponent value={values?.language} />
        ),
      },
      {
        Header: '',
        id: 'options',
        width: '5%',
        Cell: ({ row }: { row: Row<ITEPortal_Domain_Dto_OrderItemDto> }) => {
          return (
            <>
              <DeleteIcon onClick={() => removeItem?.(row.original)} />
            </>
          );
        },
      },
    ];
  }, []);

  return (
    <Box marginTop="20px">
      <OrderControls />
      <Table columns={columns} data={selectedItems} />
      <CreateOrderButton />
    </Box>
  );
};
