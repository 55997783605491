import { Global, css } from '@emotion/react';

export const GlobalStyles = () => (
  <Global
    styles={css`
      input[disabled],
      input[aria-disabled='true'],
      input[data-disabled],
      textarea[disabled],
      textarea[aria-disabled='true'],
      textarea[data-disabled] {
        background-color: #f7f7f7;
        opacity: 1 !important;
        color: #999999;
      }
    `}
  />
);
