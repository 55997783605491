import en from './en.json';
import { OptionType } from '@/model/common';
import i18n from '@/i18n';
import { typedObjectKeys } from '@/utils/typed-object-keys';

type GetColorCode<
  Scope extends string,
  Path extends string
> = Path extends `${Scope}.${infer R}` ? (R extends string ? R : never) : never;

export const FASCIA_INSCRIPTION_COLORS = typedObjectKeys(en)
  .filter((key) => key.startsWith('fascia-inscription-colors'))
  .map((colorCode) => {
    const [scope, code] = colorCode.split('.') as [
      'fascia-inscription-colors',
      GetColorCode<'fascia-inscription-colors', typeof colorCode>
    ];

    return {
      value: code,
      label: i18n.t(`${scope}.${code}`),
    } satisfies OptionType;
  })
  .filter(Boolean);

export const CARPET_COLORS = typedObjectKeys(en)
  .filter((key) => key.startsWith('carpet-colors'))
  .map((colorCode) => {
    const [scope, code] = colorCode.split('.') as [
      'carpet-colors',
      GetColorCode<'carpet-colors', typeof colorCode>
    ];

    return {
      value: code,
      label: i18n.t(`${scope}.${code}`),
    } satisfies OptionType;
  })
  .filter(Boolean);
