import { IBaseOrder } from '../../../../../model/IBaseOrder';
import { ModalFactory } from '../../../../../components/Modal/api';
import { useTranslation } from 'react-i18next';
import { typedAxios } from '../../../../../api/typed-axios';
import { isNil } from '../../../../../utils/is-nil';
import { toast } from 'react-toastify';
import { EditChargeContextType } from './MarkupForm';

export const useManageCommonExtraCharge = () => {
  const { t } = useTranslation();
  const toggleCommonExtraCharge = (order: IBaseOrder, onSaved?: () => void) => {
    if (isNil(order.orderId)) {
      return;
    }

    ModalFactory.confirm({
      Component: () => <>{t('delete-markup-confirmation')}</>,
      onOk: async () => {
        const chargeState = order.removeExtraCharge !== true;

        try {
          if (isNil(order.orderId)) {
            return;
          }

          await typedAxios.order.putOrdersRemoveExtraCharge({
            orderId: order.orderId,
            requestBody: {
              removeExtraCharge: chargeState,
            },
          });

          onSaved?.();
        } catch {
          toast.error(
            chargeState === false
              ? t('unable-to-remove-common-extra-charge')
              : t('unable-to-add-common-extra-charge')
          );
        }
      },
    });
  };

  return {
    toggleCommonExtraCharge,
  };
};

export const useManagePersonalExtraCharge = () => {
  const savePersonalExtraCharge = async (
    order: IBaseOrder,
    values: EditChargeContextType,
    onSaved?: () => void
  ) => {
    if (order.orderId && !isNil(values.personalExtraChargePercentage)) {
      await typedAxios.order.putOrdersPersonalExtraCharge({
        orderId: order.orderId,
        requestBody: {
          personalExtraChargePercentage: values.personalExtraChargePercentage,
        },
      });

      onSaved?.();
    }
  };

  return {
    savePersonalExtraCharge,
  };
};
