import { Route } from '@tanstack/react-router';
import { cabinetRoute } from '../protected';
import { NewExhibition } from '@/pages/AdminPanel/Exhibitions/NewExhibition';
import { PlacedOrders } from '@/pages/AdminPanel/PlacedOrders/PlacedOrders';
import { EditOrder } from '@/pages/AdminPanel/PlacedOrders/EditOrder/EditOrder';
import { NewAccount } from '@/pages/AdminPanel/PersonalAccounts/NewAccount';
import * as v from 'zod';
import { ManagerType, SellingOfficeType } from '@/types/open-api/domain-types';
import { typedAxios } from '@/api/typed-axios';
import { CreateOrder } from '@/pages/AdminPanel/PlacedOrders/CreateOrder';
import { Loader } from '@/components/Loader/Loader';
import { OptionType } from '@/model/common';
import {
  type ITEPortal_Domain_Dto_AdminExhibitorSummary,
  type ITEPortal_Domain_Dto_CatalogExhibitorSummary,
  ITEPortal_Domain_Dto_CurrencyDto,
} from '@/api';
import { IBaseOrder } from '@/model/IBaseOrder';
import { SearchBoxOptionsType } from '@/components/AdminSearch/types';
import i18n from '@/i18n';
import { QueryOptions } from '@/features/query/query-options';
import { ExhibitionsTabsPage } from '@/pages/AdminPanel/Exhibitions/ExhibitionsTabsPage';
import { routerRBACGuard } from '@/features/Routing/guard';
import { PersonalAccountsTab } from '@/pages/AdminPanel/PersonalAccounts/PersonalAccountsTabs';

export const adminExhibitionRoutesRoot = new Route({
  path: '/exhibitions',
  getParentRoute: () => cabinetRoute,
  beforeLoad: async ({ context: { user } }) => {
    await routerRBACGuard(user, 'canAccessAdminExhibitions');

    return { page: 'exhibitions' };
  },
});

export const exhibitionsTabs = ['current', 'past'] as const;
export const ExhibitionAccountsTabs = ['posted', 'process'] as const;

const ExhibitionListRouteSearchSchema = v.object({
  tab: v.enum(exhibitionsTabs).catch('current'),
});

const ExhibitionAccountsListRouteSearchSchema = v.object({
  tab: v.enum(ExhibitionAccountsTabs).catch('posted'),
});

export type ExhibitionListRouteSearchSchemaType = v.infer<
  typeof ExhibitionListRouteSearchSchema
>;

export const exhibitionListRoute = new Route({
  path: '/',
  getParentRoute: () => adminExhibitionRoutesRoot,
  component: () => <ExhibitionsTabsPage />,
  beforeLoad: () => ({
    searchOptions: [
      {
        placeholder: i18n.t('search-exhibition'),
      } satisfies SearchBoxOptionsType,
    ],
  }),

  validateSearch: (searchObj) => {
    return ExhibitionListRouteSearchSchema.parse(searchObj);
  },
});

export const createExhibitionRoute = new Route({
  path: 'add',
  getParentRoute: () => adminExhibitionRoutesRoot,
  component: () => <NewExhibition type="add" />,
  beforeLoad: () => ({ type: 'add' }),
  loader: async () => {
    const technicalManagers = await typedAxios.user
      .getUsersTechnicalManagers()
      .catch(() => []);

    const technicalManagersOptions = technicalManagers.map((manager) => {
      return {
        label: manager.email || manager.id,
        value: manager?.email || manager.id,
      };
    });

    return {
      technicalManagers: technicalManagersOptions,
    };
  },
});

export const editExhibitionRoute = new Route({
  path: '/$exhibitionId/edit',
  getParentRoute: () => adminExhibitionRoutesRoot,
  component: () => <NewExhibition type="edit" />,
  beforeLoad: () => ({ type: 'edit' }),
  loader: async () => {
    const technicalManagers = await typedAxios.user
      .getUsersTechnicalManagers()
      .catch(() => []);

    const technicalManagersOptions = technicalManagers.map((manager) => {
      return {
        label: manager.email || manager.id,
        value: manager?.email || manager.id,
      };
    });

    return {
      technicalManagers: technicalManagersOptions,
    };
  },
});

export const viewExhibitionRoute = new Route({
  path: '/$exhibitionId/view',
  getParentRoute: () => adminExhibitionRoutesRoot,
  loader: async () => {
    // const { exhibitionId } = store.getState().common;
    //
    // if (+exhibitionId !== +opts.params.exhibitionId) {
    //   const exhibition = await typedAxios.exhibition.getExhibitions({
    //     id: +opts.params.exhibitionId,
    //   });
    //
    //   store.dispatch(setExhibitionId(opts.params.exhibitionId));
    //   store.dispatch(setExhibitionName(exhibition.name));
    // }
  },
});

export const viewExhibitionAccountsRoute = new Route({
  path: 'personal-accounts',
  getParentRoute: () => viewExhibitionRoute,
  component: () => <PersonalAccountsTab />,
  validateSearch: (searchObj) => {
    return ExhibitionAccountsListRouteSearchSchema.parse(searchObj);
  },
  beforeLoad: async (opts) => {
    await routerRBACGuard(
      opts.context.user,
      'canAccessExhibitionPersonalAccounts'
    );

    return {
      page: 'personal-accounts',
      searchOptions: [
        {
          placeholder: i18n.t('search-exhibitors'),
        } satisfies SearchBoxOptionsType,
      ],
    };
  },
});

export const settingsTabs = [
  'main',
  'stands',
  'users',
  'products',
  'subexponents',
] as const;

const EditPersonalAccountsPageSearchSchema = v.object({
  tab: v.enum(settingsTabs).catch('main'),
});

export type EditPersonalAccountsPageSearchSchemaType = v.infer<
  typeof EditPersonalAccountsPageSearchSchema
>;

export const editExhibitionAccountRoute = new Route({
  path: 'personal-accounts/$accountId/edit',
  getParentRoute: () => viewExhibitionRoute,
  component: () => <NewAccount type="edit" />,
  pendingComponent: () => <Loader centralized />,
  validateSearch: (searchObj) => {
    return EditPersonalAccountsPageSearchSchema.parse(searchObj);
  },
  shouldReload: (match) => {
    return match.cause === 'enter';
  },
  beforeLoad: async (opts) => {
    await routerRBACGuard(
      opts.context.user,
      'canAccessExhibitionPersonalAccounts'
    );

    return {
      page: 'personal-accounts',
      type: 'edit',
      backPath: `/admin/exhibitions/${opts.params.exhibitionId}/view/personal-accounts`,
    };
  },
  loader: async (match) => {
    const {
      context: { queryClient },
      params: { exhibitionId, accountId },
    } = match;
    const accountData = await queryClient.ensureQueryData(
      QueryOptions.exhibitorQueryOptions({
        exhibitorId: +accountId,
        exhibitionId: +exhibitionId,
      })
    );

    const technicalManagers = await typedAxios.user.getUsersTechnicalManagers1({
      exhibitionId: +exhibitionId,
    });
    const sellingOffices = await typedAxios.sellingOffice.getSellingOffices();

    const currencyData = await typedAxios.currency
      .getCurrency()
      .catch(() => []);

    const officesOptions = sellingOffices.map((office) => {
      return { label: office.name || office.id, value: office.id || office.id };
    });
    const technicalManagersOptions = technicalManagers.map((manager) => {
      return {
        label: manager?.email || manager.id,
        value: manager?.email || manager.id,
      };
    });

    const currencyOptions = currencyData?.map(
      (option: ITEPortal_Domain_Dto_CurrencyDto) => {
        return {
          label: option?.name,
          value: option?.id,
        };
      }
    );

    const agents = await (async () => {
      if (accountData.isAgent) {
        return [] as Array<OptionType>;
      }

      return await typedAxios.exhibitor
        .getExhibitorsExhibitionsAgents({
          exhibitionId: +exhibitionId,
        })
        .then((agentsModels) => {
          return agentsModels.map(
            (ag) =>
              ({
                value: ag.id,
                label: ag.name,
              } satisfies OptionType)
          );
        })
        .catch(() => [] as Array<OptionType>);
    })();

    return {
      technicalManagers: technicalManagersOptions,
      currencyOptions,
      data: accountData,
      offices: officesOptions,
      agents,
      companies: [] as Array<
        OptionType<ITEPortal_Domain_Dto_CatalogExhibitorSummary>
      >,
    };
  },
});

export const addExhibitionAccountRoute = new Route({
  path: 'personal-accounts/add',
  getParentRoute: () => viewExhibitionRoute,
  component: () => <NewAccount type="add" />,
  pendingComponent: () => <Loader centralized />,
  validateSearch: (searchObj) => {
    return EditPersonalAccountsPageSearchSchema.parse(searchObj);
  },
  beforeLoad: async (opts) => {
    await routerRBACGuard(
      opts.context.user,
      'canAccessExhibitionPersonalAccounts'
    );

    return {
      page: 'personal-accounts',
      type: 'add',
      backPath: `/admin/exhibitions/${opts.params.exhibitionId}/view/personal-accounts`,
    };
  },
  loader: async (match) => {
    const {
      params: { exhibitionId },
    } = match;

    const technicalManagers = await typedAxios.user.getUsersTechnicalManagers1({
      exhibitionId: +exhibitionId,
    });

    const companies = await (async () => {
      try {
        return await typedAxios.exhibitor.getAdminSummaries({
          pageSize: -1,
        });
      } catch {
        return [];
      }
    })();

    const sellingOffices = await typedAxios.sellingOffice.getSellingOffices();

    const currencyData = await typedAxios.currency
      .getCurrency()
      .catch(() => []);

    const technicalManagersOptions = technicalManagers.map(
      (manager: ManagerType) => {
        return {
          label: manager.email || manager.id,
          value: manager.email || manager.id,
        };
      }
    );

    const agents = await typedAxios.exhibitor
      .getExhibitorsExhibitionsAgents({
        exhibitionId: +exhibitionId,
      })
      .then((agentsModels) => {
        return agentsModels.map(
          (ag) =>
            ({
              value: ag.id,
              label: ag.name,
            } satisfies OptionType)
        );
      })
      .catch(() => [] as Array<ITEPortal_Domain_Dto_AdminExhibitorSummary>);

    const officesOptions = sellingOffices.map((office: SellingOfficeType) => {
      return {
        label: office.name || office.id,
        value: office.id || office.id,
      };
    });

    const currencyOptions = currencyData?.map(
      (option: ITEPortal_Domain_Dto_CurrencyDto) => {
        return {
          label: option?.name,
          value: option?.id,
        };
      }
    );

    return {
      technicalManagers: technicalManagersOptions,
      currencyOptions,
      offices: officesOptions,
      data: {},
      agents,
      companies: companies.map((c) => ({
        value: c.id,
        label: c.name,
        meta: c,
      })) satisfies Array<
        OptionType<ITEPortal_Domain_Dto_CatalogExhibitorSummary>
      >,
    };
  },
});

const PlacedOrdersRouteSearchSchema = v.object({
  exhibitorId: v.coerce.number().optional(),
  orderStatus: v.coerce.number().optional(),
});

export const viewExhibitionPlacedOrdersRoute = new Route({
  path: 'placed-orders',
  getParentRoute: () => viewExhibitionRoute,
  component: PlacedOrders,
  beforeLoad: () => ({
    page: 'placed-orders',
    searchOptions: [
      {
        placeholder: i18n.t('search-orders'),
      } satisfies SearchBoxOptionsType,
    ],
  }),

  validateSearch: (searchObj) => {
    return PlacedOrdersRouteSearchSchema.parse(searchObj);
  },
});

export const editOrderRoute = new Route({
  path: 'placed-orders/$orderId/edit',
  getParentRoute: () => viewExhibitionRoute,
  component: EditOrder,
  beforeLoad: (opts) => {
    const { exhibitionId } = opts.params;

    return {
      page: 'placed-orders',
      type: 'edit',
      backPath: `/admin/exhibitions/${exhibitionId}/view/placed-orders/`,
    };
  },
  pendingComponent: () => <Loader centralized />,
  loader: async (opts) => {
    const {
      params: { exhibitionId, orderId },
      context: { user },
    } = opts;

    const isAgent = user?.isAgent || null;

    const order = await typedAxios.order.getOrders1({
      orderId: +orderId,
    });

    const alreadyAddedItemIds =
      order.products?.filter((p) => !p.included).map((p) => p.id) || [];

    const exhibitors = await typedAxios.exhibitor.getExhibitorsExhibition({
      exhibitionId: +exhibitionId,
    });

    const exhibitor = exhibitors.find((e) => e.id === order.exhibitorId);

    const items = await typedAxios.priceListItem.getExhibitionsPriceListsItems({
      exhibitionId: +exhibitionId,
      priceListId: exhibitor?.priceListId || 0,
    });

    const sellingOffices = isAgent
      ? null
      : await typedAxios.sellingOffice.getSellingOffices();

    return {
      order: new IBaseOrder(order),
      exhibitor,
      sellingOffices,
      currency: isAgent ? null : order?.currency,
      items: items.filter(
        (i) => i.name && !alreadyAddedItemIds.includes(i.name)
      ),
    };
  },
});

export const createOrderRoute = new Route({
  path: 'placed-orders/create',
  getParentRoute: () => viewExhibitionRoute,
  component: CreateOrder,
  validateSearch: (searchObj) => {
    return PlacedOrdersRouteSearchSchema.parse(searchObj);
  },
  pendingComponent: () => <Loader centralized />,
  loader: async (opts) => {
    const {
      params: { exhibitionId },
    } = opts;

    const exhibitors = await typedAxios.exhibitor.getExhibitorsExhibition({
      exhibitionId: +exhibitionId,
    });

    return {
      exhibitors: exhibitors.map((exhibitor) => {
        return {
          label: exhibitor.name,
          value: exhibitor.id,
          meta: exhibitor,
        };
      }) satisfies Array<
        OptionType<ITEPortal_Domain_Dto_AdminExhibitorSummary>
      >,
    };
  },
  beforeLoad: (opts) => {
    const { exhibitionId } = opts.params;

    return {
      page: 'placed-orders',
      type: 'create',
      backPath: `/admin/exhibitions/${exhibitionId}/view/placed-orders/`,
    };
  },
});

export const exhibitionsRoutesTree = adminExhibitionRoutesRoot.addChildren([
  exhibitionListRoute,
  createExhibitionRoute,
  editExhibitionRoute,
  viewExhibitionRoute.addChildren([
    viewExhibitionAccountsRoute,
    editExhibitionAccountRoute,
    addExhibitionAccountRoute,
    viewExhibitionPlacedOrdersRoute,
    editOrderRoute,
    createOrderRoute,
  ]),
]);
