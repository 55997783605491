import * as v from 'zod';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
} from '@chakra-ui/react';
import { useNameTranslate } from '../../../../hooks/useStringName';
import { t } from 'i18next';
import React, { useEffect } from 'react';
import InputNumber from '../../../../components/Fields/InputNumber';
import {
  ApiError,
  ITEPortal_Domain_Dto_Discount_GetCleaningDiscountDto,
} from '../../../../api';
import { typedAxios } from '../../../../api/typed-axios';
import i18n from '../../../../i18n';

const AREA_EXISTS_MESSAGE = 'Discount already exists for the specified range.';

const isAreaExistsMessage = (message: string) =>
  message.toLowerCase() === AREA_EXISTS_MESSAGE.toLowerCase();

const preprocessNumber = (value: unknown) => {
  if (value === '') {
    return value;
  }

  return typeof value === 'string' ? +value : value;
};

const ValidationSchema = v
  .object({
    areaFrom: v.preprocess(preprocessNumber, v.number().safe()),
    areaTo: v.preprocess(preprocessNumber, v.number().safe()),
    discountPercentage: v.preprocess(preprocessNumber, v.number().lte(100)),
  })
  .refine(
    (schema) => {
      return schema.areaFrom < schema.areaTo;
    },
    {
      path: ['areaFrom'],
      message: i18n.t('areaFrom-gt-to-error'),
    }
  )
  .refine(
    (schema) => {
      return schema.areaTo > schema.areaFrom;
    },
    {
      path: ['areaTo'],
      message: i18n.t('areaTo-lt-form-error'),
    }
  );

type FormValues = v.infer<typeof ValidationSchema>;

type EditDiscountModalPropsType = {
  close: () => void;
  onSaved?: () => void;
  model: ITEPortal_Domain_Dto_Discount_GetCleaningDiscountDto | null;
};

export const EditDiscountModal = (props: EditDiscountModalPropsType) => {
  const { close, model, onSaved } = props;
  const instance = useForm<FormValues>({
    resolver: zodResolver(ValidationSchema),
  });

  useEffect(
    function setFormValuesOfLoadedModel() {
      instance.reset({
        ...model,
      });
    },
    [model]
  );

  const save: SubmitHandler<FormValues> = async (values) => {
    try {
      if (model?.id && model.id > 0) {
        await typedAxios.discount.putDiscountsCleaning({
          ...values,
          discountId: model.id,
        });
      } else {
        await typedAxios.discount.postDiscountsCleaning(values);
      }

      instance.reset();
      close();

      onSaved?.();
    } catch (e) {
      if (e instanceof ApiError && typeof e.body === 'string') {
        instance.setError('areaFrom', {
          message: isAreaExistsMessage(e.body)
            ? t('cleaning-discount-area-error')
            : e.body,
        });
      }
    }
  };

  return (
    <Modal isOpen onClose={close} size="xl" scrollBehavior="inside" isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{useNameTranslate('discount')}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormProvider {...instance}>
            <form>
              <Stack spacing="5">
                <InputNumber<FormValues>
                  name="areaFrom"
                  precision={2}
                  step={0.1}
                  min={0}
                  label={t('areaFrom')}
                />
                <InputNumber<FormValues>
                  name="areaTo"
                  precision={2}
                  step={0.1}
                  min={0}
                  label={t('areaTo')}
                />
                <InputNumber<FormValues>
                  name="discountPercentage"
                  max={100}
                  min={0}
                  precision={2}
                  step={0.1}
                  label={`${t('discount')} (%)`}
                />
              </Stack>
            </form>
          </FormProvider>
        </ModalBody>
        <ModalFooter justifyContent="center">
          <ButtonGroup gap="1" justifyContent="center">
            <Button colorScheme="red" variant="outline" onClick={close}>
              {t('cancel')}
            </Button>
            <Button colorScheme="red" onClick={instance.handleSubmit(save)}>
              {t('save')}
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
