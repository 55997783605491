import { Button as OrderButton } from '@/components/Buttons/Button/Button';
import { Box, Divider } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { SystemLangType } from '../../model/common';
import { commonApi, useCommonStore } from '@/store/effector/common';

const ButtonsWrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const LanguageSwitcher = (): JSX.Element => {
  const { i18n } = useTranslation();
  const { lang: language } = useCommonStore();
  const [lngValue, setLngValue] = useState(language);

  useEffect(() => {
    setLngValue(language);
    setLanguageValue(language);
  }, [language]);

  const setLanguageValue = (languageValue: SystemLangType) => {
    commonApi.setLanguage(languageValue);

    if (localStorage.getItem('lang') !== languageValue) {
      localStorage.setItem('lang', languageValue);
      i18n.changeLanguage(languageValue);
      location.reload();
    }
  };

  return (
    <ButtonsWrapper>
      <OrderButton
        colorScheme="transparent"
        width="70px"
        defaultColor={lngValue === 'ru' ? '#E8431A' : ''}
        onClick={() => setLanguageValue('ru')}
      >
        Русский
      </OrderButton>
      <Divider orientation="vertical" height="20px" colorScheme="red" />
      <OrderButton
        colorScheme="transparent"
        width="70px"
        defaultColor={lngValue === 'en' ? '#E8431A' : ''}
        onClick={() => setLanguageValue('en')}
      >
        English
      </OrderButton>
    </ButtonsWrapper>
  );
};
