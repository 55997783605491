import superAdminMenu from '@/__mocks__/categoriesSidebars/superAdminMenu';
import adminMenu from '@/__mocks__/categoriesSidebars/adminMenu';
import supportMenu from '@/__mocks__/categoriesSidebars/supportMenu';
import technicalManagerMenu from '@/__mocks__/categoriesSidebars/technicalManagerMenu';
import moderatorMenu from '@/__mocks__/categoriesSidebars/moderatorMenu';
import { User } from '@/model/IUser';
import { agentMenu } from '@/__mocks__/categoriesSidebars/agentMenu';

export const getCategoriesByRole = (user: User) => {
  switch (true) {
    case user.isAgent:
      return agentMenu();
    case user.isSuperAdmin:
      return superAdminMenu();
    case user.isAdmin:
      return adminMenu();
    case user.isSupportManager:
      return supportMenu();
    case user.isTechnicalManager:
      return technicalManagerMenu();
    case user.isModerator:
      return moderatorMenu();
    default:
      return [];
  }
};
