import { Outlet, rootRouteWithContext } from '@tanstack/react-router';
import { TanStackRouterDevtools } from '@tanstack/router-devtools';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { queryClient } from '../query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

export const rootRoute = rootRouteWithContext<{
  page?: string;
  type?: string;
  queryClient: QueryClient;
}>()({
  component: () => {
    return (
      <QueryClientProvider client={queryClient}>
        <Outlet />
        {process.env.NODE_ENV === 'development' && <TanStackRouterDevtools />}
        {process.env.NODE_ENV === 'development' && <ReactQueryDevtools />}
      </QueryClientProvider>
    );
  },
});
