import { Flex } from '@chakra-ui/react';
import { ProjectDescription } from '@/pages/StandProject/StandProjectDescription/ProjectDescription';
import { ITEPortal_Domain_Dto_Stand_GetStandProjectDto } from '@/api';

type StandProjectDescriptionPropsType = {
  project: ITEPortal_Domain_Dto_Stand_GetStandProjectDto;
};
export const StandProjectDescription = (
  props: StandProjectDescriptionPropsType
) => {
  const { project } = props;

  if (!project) {
    return null;
  }

  const { projectDescription } = project;

  return (
    <Flex flexDirection="column" height="100%">
      <ProjectDescription
        projectDescription={projectDescription}
        projectChangesHistory={project.projectChangesHistory}
        approved={project.approved}
      />
    </Flex>
  );
};
