import { useSearch } from '@tanstack/react-router';
import {
  keepPreviousData,
  queryOptions,
  useQuery,
} from '@tanstack/react-query';
import { ITEPortal_Domain_Dto_CategoryDto } from '@/api';
import { typedAxios } from '@/api/typed-axios';

export const useProductsLoaderOptions = () => {
  const { searchProduct } = useSearch({ strict: false });

  return {
    getProductsLoaderOptions: (
      categoryId: ITEPortal_Domain_Dto_CategoryDto['id']
    ) =>
      queryOptions({
        queryKey: ['category-products', categoryId, searchProduct],
        queryFn: async () => {
          return typedAxios.product.getProducts({
            categoryId: categoryId,
            search: searchProduct,
            pageSize: -1,
          });
        },
        refetchOnMount: false,
        structuralSharing: true,
        retryOnMount: false,
        placeholderData: keepPreviousData,
      }),
  };
};

export const useProductsLoader = (
  categoryId: ITEPortal_Domain_Dto_CategoryDto['id']
) => {
  const { getProductsLoaderOptions } = useProductsLoaderOptions();

  return useQuery(getProductsLoaderOptions(categoryId));
};
