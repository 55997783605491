import {
  SystemStyleObject,
  Tab,
  TabList,
  TabPanel,
  TabPanelProps,
  TabPanels,
  TabProps,
  Tabs,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useExhibitionLoader } from './hooks/use-exhibition-loader';
import { ExhibitionsList } from './ExhibitionsList';

const selectedTabStyles: SystemStyleObject = {
  background: 'blackAlpha.300',
};
const tabStyles: TabProps = {
  background: 'blackAlpha.100',
  minW: '100px',
};

const tabPanelsStyle: TabPanelProps = {
  width: '100%',
  maxWidth: '800px',
};

export const ExhibitionsTabs = (
  props: Pick<ReturnType<typeof useExhibitionLoader>, 'exhibitions'>
) => {
  const {
    exhibitions: { pastExhibitions, currentExhibitions },
  } = props;

  const { t } = useTranslation();

  return (
    <Tabs variant="unstyled" align="center" w="100%">
      <TabList>
        <Tab
          _selected={selectedTabStyles}
          {...tabStyles}
          borderTopLeftRadius="md"
          borderBottomLeftRadius="md"
        >
          {t('current-exhibitions')}
        </Tab>
        <Tab
          _selected={selectedTabStyles}
          {...tabStyles}
          borderTopRightRadius="md"
          borderBottomRightRadius="md"
        >
          {t('past-exhibitions')}
        </Tab>
      </TabList>
      <TabPanels>
        <TabPanel {...tabPanelsStyle}>
          <ExhibitionsList exhibitions={currentExhibitions} />
        </TabPanel>
        <TabPanel {...tabPanelsStyle}>
          <ExhibitionsList exhibitions={pastExhibitions} />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};
