import {
  ITEPortal_Domain_Dto_CategoryDto,
  ITEPortal_Domain_Dto_ProductDto,
} from '@/api';
import { LoaderWrapper } from '@/components/Loader/Loader';
import { Column } from 'react-table';
import i18n from '@/i18n';
import { Badge, Flex, Text, Tooltip } from '@chakra-ui/react';
import { DeleteIcon, EditIcon } from '@chakra-ui/icons';
import { editProductApi } from '@/pages/AdminPanel/ServiceDirectory/EditProductFieldsets/useProductForm';
import { DraggableHandlerIcon } from '@/components/Icons/DraggableHandlerIcon';
import { DraggableTable } from '@/components/Table/DraggableTable';
import {
  useProductsLoader,
  useProductsLoaderOptions,
} from '@/pages/AdminPanel/Accordion/use-products-loader';
import { useProductsOrderUpdate } from '@/pages/AdminPanel/Accordion/use-products-order-update';
import { OnDragEndResponder } from 'react-beautiful-dnd';
import { BUILDING_TYPES } from '@/constants/admin-pages';
import { CreateProductUtils } from '@/pages/AdminPanel/ServiceDirectory/EditProductFieldsets/utils';
import { ModalFactory } from '@/components/Modal/api';
import { typedAxios } from '@/api/typed-axios';
import { toast } from 'react-toastify';
import { queryClient } from '@/features/query';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHasProductSearchCriteria } from '@/pages/AdminPanel/Accordion/use-categories-loader';

type ProductsListPropsType = {
  categoryId: ITEPortal_Domain_Dto_CategoryDto['id'];
};

const BuildingTypes = ({ row }: { row: ITEPortal_Domain_Dto_ProductDto }) => {
  const buildingTypes = row.buildingTypes;

  const buildingTypeNames =
    buildingTypes?.map((type: number) => {
      return BUILDING_TYPES.find(
        (dictionaryTypeName) => dictionaryTypeName.value === type
      )?.label;
    }) || [];

  const total = buildingTypeNames.length;
  const tailLength = total - 2;

  if (tailLength > 0) {
    return (
      <Tooltip
        label={buildingTypeNames.join(', ')}
        placement="top"
        hasArrow
        openDelay={500}
      >
        <Flex
          flexDirection="row"
          flexWrap="wrap"
          gap={1}
          alignItems="center"
          cursor="help"
        >
          {buildingTypeNames.slice(0, 2).map((bt, i) => (
            <Badge fontSize="0.7em" key={i}>
              {bt}
            </Badge>
          ))}{' '}
          {tailLength > 0 && <Text fontSize="0.7em">+{tailLength} </Text>}
        </Flex>
      </Tooltip>
    );
  }

  return (
    <Flex flexDirection="row" flexWrap="wrap" gap={1} alignItems="center">
      {buildingTypeNames.slice(0, 2).map((bt, i) => (
        <Badge fontSize="0.7em" key={i}>
          {bt}
        </Badge>
      ))}
    </Flex>
  );
};

const getColumns = (opts: {
  isDraggable: boolean;
  onRemoved: () => void;
}): Array<Column<ITEPortal_Domain_Dto_ProductDto>> => {
  const handleProductDelete = (product: ITEPortal_Domain_Dto_ProductDto) => {
    ModalFactory.confirm({
      title: i18n.t('attention'),
      Component: () => <>{i18n.t('delete-order-item-message')}</>,
      okLabel: i18n.t('delete'),
      onOk: async () => {
        if (!product.name) {
          return;
        }

        try {
          await typedAxios.product.deleteProducts({
            name: product.name,
          });

          toast.success(i18n.t('order-item-deleted'));

          ModalFactory.close();

          opts.onRemoved();
        } catch {
          toast.error(i18n.t('error-occurred'));
        }
      },
    });
  };

  const columns: Array<Column<ITEPortal_Domain_Dto_ProductDto>> = [
    {
      id: 'drag-handler',
      Header: 'ID',
      width: '2%',
      Cell: () => <DraggableHandlerIcon />,
    },
    {
      Header: opts.isDraggable ? () => null : 'ID',
      accessor: 'name',
      width: '5%',
    },
    {
      Header: i18n.t('service-name'),
      accessor: 'displayName',
      width: '550%',
    },
    {
      Header: i18n.t('building-type'),
      accessor: 'buildingTypes',
      minWidth: 250,
      Cell: ({ row }) => <BuildingTypes row={row.original} />,
    },
    {
      id: 'actions',
      Cell: ({ row }) => (
        <Flex flexDirection="row" gap={4}>
          <EditIcon
            cursor="pointer"
            onClick={() => {
              if (row.original.categoryId) {
                editProductApi.open({
                  product: row.original,
                  productType: CreateProductUtils.getProductType(row.original),
                  categoryName: row.original.categoryName,
                  categoryId: +row.original.categoryId,
                });
              }
            }}
          />
          <DeleteIcon
            cursor="pointer"
            onClick={() => handleProductDelete(row.original)}
          />
        </Flex>
      ),
    },
  ];

  return columns.filter((c) => {
    if (opts.isDraggable) {
      return true;
    }

    return c.id !== 'drag-handler';
  });
};

export const ProductsList = (props: ProductsListPropsType) => {
  const hasSearch = useHasProductSearchCriteria();

  const { t } = useTranslation();
  const { getProductsLoaderOptions } = useProductsLoaderOptions();
  const { isFetching, data = [] } = useProductsLoader(props.categoryId);
  const { mutate: updateProductsOrder, isPending: isUpdatingOrder } =
    useProductsOrderUpdate(props.categoryId);

  const dropHandler: OnDragEndResponder = (result) => {
    const { source, destination } = result;

    if (!destination) {
      return;
    }

    const startIndex = source.index;
    const endIndex = destination.index;

    updateProductsOrder({
      productsSource: data,
      startIndex,
      endIndex,
    });
  };

  if (data.length === 0 && !isFetching) {
    return null;
  }

  return (
    <>
      <Text fontWeight="500" mb={2}>
        {t('services-list')}
      </Text>
      <LoaderWrapper loading={isFetching || isUpdatingOrder}>
        <DraggableTable
          columns={getColumns({
            isDraggable: !hasSearch,
            onRemoved: () =>
              queryClient.refetchQueries({
                queryKey: getProductsLoaderOptions(props.categoryId).queryKey,
              }),
          })}
          data={data}
          onDragEnd={dropHandler}
        />
      </LoaderWrapper>
    </>
  );
};
