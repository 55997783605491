import { ITEPortal_Domain_Dto_ProductDto } from '@/api';
import {
  CATEGORY_TYPES,
  PRODUCT_TYPES,
  ProductTypesType,
} from '@/constants/categorie';
import i18n from '@/i18n';

export const CreateProductUtils = {
  getProductType: (product: ITEPortal_Domain_Dto_ProductDto | null) => {
    if (!product) {
      return null;
    }

    const productCategory = product.categoryName;

    switch (productCategory) {
      case CATEGORY_TYPES.SELECT_BUILDING: {
        return PRODUCT_TYPES.BUILDING;
      }

      case CATEGORY_TYPES.TEMPORARY_STAFF: {
        return PRODUCT_TYPES.TEMPORARY_STAFF;
      }

      default: {
        return PRODUCT_TYPES.COMMON;
      }
    }
  },

  getCreateButtonLabelByType: (productType: ProductTypesType | null) => {
    switch (productType) {
      case PRODUCT_TYPES.BUILDING: {
        return i18n.t('add-service-for-buildings');
      }

      case PRODUCT_TYPES.TEMPORARY_STAFF: {
        return i18n.t('add-service-for-temporary-staff');
      }

      default: {
        return i18n.t('add-service');
      }
    }
  },
};
