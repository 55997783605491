import * as v from 'zod';
import type { ITEPortal_Domain_Dto_AdminExhibitorSummary } from '@/api';

export const CreateOrderFormSchema = v.object({
  exhibitorId: v.object({
    value: v.coerce.string(),
    label: v.string(),
    meta: v.custom<ITEPortal_Domain_Dto_AdminExhibitorSummary>(
      (data): data is ITEPortal_Domain_Dto_AdminExhibitorSummary => {
        return (
          typeof (data as ITEPortal_Domain_Dto_AdminExhibitorSummary)?.[
            'id'
          ] === 'number'
        );
      }
    ),
  }),
  employeeId: v.object({
    value: v.coerce.string(),
    label: v.string(),
  }),
  standId: v.object({
    value: v.coerce.string(),
    label: v.string(),
  }),
});

export type CreateOrderFormSchemaType = v.infer<typeof CreateOrderFormSchema>;
