import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  CardBody,
  Card,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { ExhibitionDescription } from '@/pages/AdminPanel/Exhibitions/ExhibitionDescription';
import styled from 'styled-components';
import { PriceLists } from '@/pages/AdminPanel/Exhibitions/PriceLists';
import { useEffect } from 'react';

const tabStyles = {
  borderColor: 'blackAlpha.200',
  borderBottomColor: '#F7FAFC',
  color: 'red',
};

const TabWrapper = styled(TabPanel)`
  padding-top: 50px;
  max-width: 600px;
`;

interface NewExhibitionProps {
  type: 'add' | 'edit';
}

export const NewExhibition = ({ type }: NewExhibitionProps): JSX.Element => {
  const { t } = useTranslation();
  const disabled = type === 'add';

  useEffect(() => {
    const element = document.getElementById('breadcrumbsId');

    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  return (
    <Card>
      <CardBody>
        <Tabs isManual variant="enclosed">
          <TabList>
            <Tab _selected={tabStyles}>{t('exhibition-description')}</Tab>
            <Tab _selected={tabStyles} isDisabled={disabled}>
              {t('priceLists')}
            </Tab>
          </TabList>
          <TabPanels>
            <TabWrapper>
              <ExhibitionDescription type={type} />
            </TabWrapper>
            <TabPanel>{!disabled && <PriceLists />}</TabPanel>
          </TabPanels>
        </Tabs>
      </CardBody>
    </Card>
  );
};
