import {
  Box,
  Card,
  CardBody,
  CardFooter,
  Divider,
  Heading,
  Image,
  Stack,
  Text,
} from '@chakra-ui/react';
import styled from 'styled-components';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Nullable } from '../../types/support-types';

interface ExhibitionCardProps {
  image: string;
  name: string;
  subtitles?: Array<Nullable<string>>;
  description?: string | ReactNode;
  stands?: number;
  orders?: number;
}

interface SubtitleProps {
  subtitles: Array<Nullable<string>>;
}

interface FooterSectionProps {
  stands: number | undefined;
  orders: number | undefined;
}

const SubtitlesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  color: #718096;
  padding-top: 10px;
`;

const DescriptionWrapper = styled(Text)`
  color: #718096;
  font-size: 12px;
  padding-top: 20px;
`;

const ImageContainer = styled(Image)`
  height: max-content;
  max-width: 200px;
`;

const ImageWrapper = styled.div`
  border-right: 1px solid #e2e8f0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 25px;
`;

const DotsContainer = styled(Box)`
  margin: 0 2px;
  border-radius: 50%;
  display: inline-block;
`;

const FooterTextWrapper = styled.div`
  font-size: 14px;
  color: #718096;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: center;
`;

const SubtitleRowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

const Subtitle = ({ subtitles }: SubtitleProps): JSX.Element => {
  return (
    <SubtitlesWrapper>
      {subtitles.map((subtitle, index) => {
        return (
          <SubtitleRowWrapper key={index}>
            <Text>{subtitle}</Text>
            {index !== subtitles.length - 1 && (
              <Divider orientation="vertical" height="25px" />
            )}
          </SubtitleRowWrapper>
        );
      })}
      <Divider orientation="vertical" />
    </SubtitlesWrapper>
  );
};

const FooterSection = ({ stands, orders }: FooterSectionProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <FooterTextWrapper>
      {`${t('stands')} ${stands}`}
      <DotsContainer boxSize={['7px', null, '8px']} bg="blackAlpha.400" />
      {`${t('orders')} ${orders}`}
    </FooterTextWrapper>
  );
};

export const ExhibitionCard = ({
  image,
  name,
  subtitles,
  description,
  stands,
  orders,
}: ExhibitionCardProps): JSX.Element => {
  return (
    <Card
      direction={{ base: 'column', sm: 'row' }}
      overflow="hidden"
      variant="elevated"
      h="100%"
      w="100%"
    >
      <ImageWrapper>
        {image && <ImageContainer src={image} alt="Caffe Latte" />}
      </ImageWrapper>

      <Stack w="100%">
        <CardBody textAlign="left">
          <Heading size="md" textAlign="left">
            {name}
          </Heading>
          {subtitles && <Subtitle subtitles={subtitles} />}
          {description && (
            <DescriptionWrapper>{description}</DescriptionWrapper>
          )}
        </CardBody>

        <CardFooter>
          <FooterSection stands={stands} orders={orders} />
        </CardFooter>
      </Stack>
    </Card>
  );
};
