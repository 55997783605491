import { AttachmentIcon, DeleteIcon } from '@chakra-ui/icons';
import { Box, Button } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { InputHTMLAttributes, useRef, useState } from 'react';

type AttachmentUploaderPropsType = {
  onFileChange: (file: File) => void;
};

const allowedMimeTypes = (
  [
    'image/*',
    'application/pdf',
    'application/vnd.ms-excel',
    'application/msword',
    'text/csv',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  ] satisfies Array<InputHTMLAttributes<HTMLInputElement>['accept']>
).join();

export const AttachmentUploader = (props: AttachmentUploaderPropsType) => {
  const { t } = useTranslation();
  const inputRef = useRef<HTMLInputElement>(null);
  const [attachment, setAttachment] = useState<File | null>(null);

  return (
    <>
      <input
        ref={inputRef}
        type="file"
        style={{ display: 'none' }}
        accept={allowedMimeTypes}
        multiple={false}
        onChange={(event) => {
          const file = event.target.files?.item(0);

          if (file) {
            setAttachment(file);

            if (typeof props.onFileChange === 'function') {
              props.onFileChange(file);
            }
          }
        }}
      />

      {attachment?.name ? (
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="flex-start"
          alignItems="center"
          mr="auto"
          color="red.500"
          fontSize="sm"
        >
          <AttachmentIcon mr={2} />
          {attachment.name}
          <DeleteIcon ml={2} onClick={() => setAttachment(null)} />
        </Box>
      ) : (
        <Button
          size="sm"
          variant="ghost"
          colorScheme="red"
          mr="auto"
          onClick={() => {
            inputRef.current?.click();
          }}
        >
          {t('attach-file')} <AttachmentIcon ml={2} />
        </Button>
      )}
    </>
  );
};
