/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ITEPortal_Contracts_Enums_OrderStatus } from '../models/ITEPortal_Contracts_Enums_OrderStatus';
import type { ITEPortal_Domain_Dto_AdminOrderSummary } from '../models/ITEPortal_Domain_Dto_AdminOrderSummary';
import type { ITEPortal_Domain_Dto_Order_CreateOrderDto } from '../models/ITEPortal_Domain_Dto_Order_CreateOrderDto';
import type { ITEPortal_Domain_Dto_Order_CreateOrderFromAdminDto } from '../models/ITEPortal_Domain_Dto_Order_CreateOrderFromAdminDto';
import type { ITEPortal_Domain_Dto_Order_ExtraChargeRemovingDto } from '../models/ITEPortal_Domain_Dto_Order_ExtraChargeRemovingDto';
import type { ITEPortal_Domain_Dto_Order_PersonalDiscountDto } from '../models/ITEPortal_Domain_Dto_Order_PersonalDiscountDto';
import type { ITEPortal_Domain_Dto_Order_PersonalExtraChargeDto } from '../models/ITEPortal_Domain_Dto_Order_PersonalExtraChargeDto';
import type { ITEPortal_Domain_Dto_Order_UpdateOrderDto } from '../models/ITEPortal_Domain_Dto_Order_UpdateOrderDto';
import type { ITEPortal_Domain_Dto_OrderDto } from '../models/ITEPortal_Domain_Dto_OrderDto';
import type { ITEPortal_Domain_Dto_OrderItem_CreateOrderItemDto } from '../models/ITEPortal_Domain_Dto_OrderItem_CreateOrderItemDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class OrderService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns ITEPortal_Domain_Dto_AdminOrderSummary Success
     * @throws ApiError
     */
    public getOrders({
        exhibitionId,
        orderStatus,
        exhibitorId,
        search,
    }: {
        exhibitionId?: number,
        orderStatus?: ITEPortal_Contracts_Enums_OrderStatus,
        exhibitorId?: number,
        search?: string,
    }): CancelablePromise<Array<ITEPortal_Domain_Dto_AdminOrderSummary>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/orders',
            query: {
                'exhibitionId': exhibitionId,
                'orderStatus': orderStatus,
                'exhibitorId': exhibitorId,
                'search': search,
            },
            errors: {
                400: `Bad Request`,
                500: `Server Error`,
            },
        });
    }

    /**
     * @returns any Success
     * @throws ApiError
     */
    public postOrders({
        requestBody,
    }: {
        requestBody?: ITEPortal_Domain_Dto_Order_CreateOrderDto,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/orders',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @returns any Success
     * @throws ApiError
     */
    public putOrders({
        requestBody,
    }: {
        requestBody?: ITEPortal_Domain_Dto_Order_UpdateOrderDto,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/orders',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @returns any Success
     * @throws ApiError
     */
    public postOrdersExhibitors({
        exhibitorId,
        requestBody,
    }: {
        exhibitorId: number,
        requestBody?: ITEPortal_Domain_Dto_Order_CreateOrderFromAdminDto,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/orders/exhibitors/{exhibitorId}',
            path: {
                'exhibitorId': exhibitorId,
            },
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @returns ITEPortal_Domain_Dto_OrderDto Created
     * @throws ApiError
     */
    public getOrders1({
        orderId,
    }: {
        orderId: number,
    }): CancelablePromise<ITEPortal_Domain_Dto_OrderDto> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/orders/{orderId}',
            path: {
                'orderId': orderId,
            },
            errors: {
                400: `Bad Request`,
                500: `Server Error`,
            },
        });
    }

    /**
     * @returns any Success
     * @throws ApiError
     */
    public deleteOrders({
        orderId,
    }: {
        orderId: number,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/orders/{orderId}',
            path: {
                'orderId': orderId,
            },
        });
    }

    /**
     * @returns binary Success
     * @throws ApiError
     */
    public getOrdersPdf({
        orderId,
    }: {
        orderId: number,
    }): CancelablePromise<Blob> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/orders/{orderId}/pdf',
            path: {
                'orderId': orderId,
            },
            errors: {
                400: `Bad Request`,
                500: `Server Error`,
            },
        });
    }

    /**
     * @returns any Success
     * @throws ApiError
     */
    public postExhibitionsOrders({
        exhibitionId,
        orderId,
        requestBody,
    }: {
        exhibitionId: number,
        orderId: number,
        requestBody?: ITEPortal_Domain_Dto_OrderItem_CreateOrderItemDto,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/exhibitions/{exhibitionId}/orders/{orderId}',
            path: {
                'exhibitionId': exhibitionId,
                'orderId': orderId,
            },
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @returns any Success
     * @throws ApiError
     */
    public putExhibitionsOrders({
        exhibitionId,
        orderId,
        orderItemId,
        requestBody,
    }: {
        exhibitionId: number,
        orderId: number,
        orderItemId?: number,
        requestBody?: ITEPortal_Domain_Dto_OrderItem_CreateOrderItemDto,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/exhibitions/{exhibitionId}/orders/{orderId}',
            path: {
                'exhibitionId': exhibitionId,
                'orderId': orderId,
            },
            query: {
                'orderItemId': orderItemId,
            },
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @returns any Success
     * @throws ApiError
     */
    public deleteExhibitionsOrders({
        exhibitionId,
        orderId,
        orderItemId,
    }: {
        exhibitionId: number,
        orderId: number,
        orderItemId?: number,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/exhibitions/{exhibitionId}/orders/{orderId}',
            path: {
                'exhibitionId': exhibitionId,
                'orderId': orderId,
            },
            query: {
                'orderItemId': orderItemId,
            },
        });
    }

    /**
     * @returns any Success
     * @throws ApiError
     */
    public deleteOrdersDraft({
        orderId,
    }: {
        orderId: number,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/orders/{orderId}/draft',
            path: {
                'orderId': orderId,
            },
        });
    }

    /**
     * @returns any Success
     * @throws ApiError
     */
    public putOrdersStatus({
        orderId,
        orderStatus,
    }: {
        orderId: number,
        orderStatus?: ITEPortal_Contracts_Enums_OrderStatus,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/orders/{orderId}/status',
            path: {
                'orderId': orderId,
            },
            query: {
                'orderStatus': orderStatus,
            },
        });
    }

    /**
     * @returns boolean Success
     * @throws ApiError
     */
    public postOrdersSendOrderChangedConfirmation({
        orderId,
        requestBody,
    }: {
        orderId: number,
        requestBody?: string,
    }): CancelablePromise<boolean> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/orders/{orderId}/send-order-changed-confirmation',
            path: {
                'orderId': orderId,
            },
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @returns any Success
     * @throws ApiError
     */
    public postOrdersPersonalDiscount({
        orderId,
        requestBody,
    }: {
        orderId: number,
        requestBody?: ITEPortal_Domain_Dto_Order_PersonalDiscountDto,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/orders/{orderId}/personal-discount',
            path: {
                'orderId': orderId,
            },
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @returns any Success
     * @throws ApiError
     */
    public putOrdersPersonalDiscount({
        orderId,
        requestBody,
    }: {
        orderId: number,
        requestBody?: ITEPortal_Domain_Dto_Order_PersonalDiscountDto,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/orders/{orderId}/personal-discount',
            path: {
                'orderId': orderId,
            },
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @returns any Success
     * @throws ApiError
     */
    public deleteOrdersPersonalDiscount({
        orderId,
    }: {
        orderId: number,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/orders/{orderId}/personal-discount',
            path: {
                'orderId': orderId,
            },
        });
    }

    /**
     * @returns any Success
     * @throws ApiError
     */
    public putOrdersRemoveExtraCharge({
        orderId,
        requestBody,
    }: {
        orderId: number,
        requestBody?: ITEPortal_Domain_Dto_Order_ExtraChargeRemovingDto,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/orders/{orderId}/remove-extra-charge',
            path: {
                'orderId': orderId,
            },
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @returns any Success
     * @throws ApiError
     */
    public putOrdersPersonalExtraCharge({
        orderId,
        requestBody,
    }: {
        orderId: number,
        requestBody?: ITEPortal_Domain_Dto_Order_PersonalExtraChargeDto,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/orders/{orderId}/personal-extra-charge',
            path: {
                'orderId': orderId,
            },
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

}
