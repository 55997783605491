import { Flex } from '@chakra-ui/react';
import { ProjectImage } from '@/pages/StandProject/StandProjectImage/StandProjectImage.styled';
import Lightbox from 'yet-another-react-lightbox';
import Download from 'yet-another-react-lightbox/plugins/download';
import React, { useState } from 'react';
import { useNameTranslate } from '@/hooks/useStringName';
import { UploadFileDropzone } from '@/components/FileUpload/FileUpload';
import { ITEPortal_Domain_Dto_Stand_GetStandProjectDto } from '@/api';
import { FileUtils } from '@/utils/file-utils';

type StandProjectImagePropsType = Pick<
  ITEPortal_Domain_Dto_Stand_GetStandProjectDto,
  'projectImageUrl'
> & {
  editable: boolean;
};

export const ProjectImageUploader = (props: StandProjectImagePropsType) => {
  const { projectImageUrl, editable } = props;
  const [open, setOpen] = useState(false);

  return (
    <Flex flexDirection="column" maxW="100%" gap={6}>
      {editable && (
        <UploadFileDropzone
          label={useNameTranslate('upload-image')}
          defaultValue={projectImageUrl || ''}
          multiple={false}
          name="file"
        />
      )}

      {projectImageUrl && (
        <>
          {props.projectImageUrl && (
            <ProjectImage
              key={projectImageUrl}
              style={{ width: 100, height: 'auto' }}
              src={projectImageUrl}
              onClick={() => setOpen(true)}
            />
          )}

          <Lightbox
            open={open}
            close={() => setOpen(false)}
            index={0}
            slides={[
              {
                src: projectImageUrl,
              },
            ]}
            animation={{ fade: 0 }}
            controller={{ closeOnPullDown: true, closeOnBackdropClick: true }}
            styles={{ root: { '--yarl__color_backdrop': 'rgba(0, 0, 0, .7)' } }}
            plugins={[Download]}
            download={{
              download: ({ slide }) => {
                FileUtils.saveUrlToBlob(slide.src);
              },
            }}
          />
        </>
      )}
    </Flex>
  );
};
