import { FieldValues, useForm, FormProvider } from 'react-hook-form';
import {
  ButtonGroup,
  FormControl,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
} from '@chakra-ui/react';
import { useNameTranslate } from '@/hooks/useStringName';
import Input from '@/components/Fields/Input';
import { Button as OrderButton } from '@/components/Buttons/Button/Button';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { t } from 'i18next';
import fetchApi from '@/utils/fetchAPI';
import { EDIT_MEMBERS } from '@/constants/endpoints';
import { FetchMethods } from '@/utils/constants';
import { toast } from 'react-toastify';

const FormControlWrapper = styled(FormControl)`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

interface AddBuildingTypeProductProps {
  openConfirm: boolean;
  closeModal: () => void;
  refresh: () => void;
  editData: any;
}

export const EditMemberForm = ({
  openConfirm,
  closeModal,
  refresh,
  editData,
}: AddBuildingTypeProductProps) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const methods = useForm({
    mode: 'onBlur',
  });

  useEffect(() => {
    Object.keys(editData).forEach((field) => {
      methods.setValue(field, editData[field]);
    });
  }, [editData]);

  const submitHandler = async (values: FieldValues) => {
    const formData = {
      name: values?.name,
    };

    try {
      setIsSubmitting(true);
      await fetchApi(EDIT_MEMBERS(values?.id), {
        method: FetchMethods.Put,
        data: formData,
      });

      closeModal();
      refresh();
    } catch (error: any) {
      const fieldErrors = error?.response?.data?.errors;

      if (fieldErrors) {
        toast.error(fieldErrors);
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Modal isOpen={openConfirm} onClose={closeModal} size="xl" isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{useNameTranslate('edit-company')}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormProvider {...methods}>
            <form>
              <Stack spacing="5">
                <FormControlWrapper>
                  <Input
                    label={useNameTranslate('username')}
                    maxWidth="100%"
                    defaultValue={editData?.['name']}
                    name="name"
                    isRequired
                  />
                </FormControlWrapper>
              </Stack>
            </form>
          </FormProvider>
        </ModalBody>
        <ModalFooter justifyContent="center">
          <ButtonGroup gap="1" justifyContent="center">
            <OrderButton
              colorScheme="red"
              onClick={methods.handleSubmit(submitHandler)}
              disabled={isSubmitting}
            >
              {t('save')}
            </OrderButton>
            <OrderButton colorScheme="secondary" onClick={closeModal}>
              {t('cancel')}
            </OrderButton>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
