import { useTranslation } from 'react-i18next';
import {
  ExhibitionBox,
  ExhibitionTitle,
  HeaderTitle,
  StyledHeader,
} from './OrderList.styled';

import { StandDescription } from '@/pages/Exhibition/shared/StandDescription';
import { Box } from '@chakra-ui/react';
import { useExhibition } from '@/store/effector/exhibition';
import { useStand } from '@/store/effector/stand';

const Header = () => {
  const { t } = useTranslation();
  const { exhibitionName } = useExhibition();
  const { standForOrder, originalBuildingType } = useStand();

  return (
    <StyledHeader>
      <ExhibitionBox>
        <ExhibitionTitle>{exhibitionName}</ExhibitionTitle>
      </ExhibitionBox>
      {standForOrder && (
        <Box mt="20px">
          <StandDescription
            stand={standForOrder}
            originalBuildingType={originalBuildingType}
            includeStandNumber
            inline
          />
        </Box>
      )}
      <HeaderTitle>{t('orderedServices')}</HeaderTitle>
    </StyledHeader>
  );
};

export default Header;
