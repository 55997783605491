import { useTranslation } from 'react-i18next';
import { useBoolean } from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import { useParams } from '@tanstack/react-router';
import { useFormErrorsHandler } from '@/hooks/useFormErrorsHandler';
import { typedAxios } from '@/api/typed-axios';
import { formOptionsAsValues } from '@/utils/form-options-as-values';
import { toast } from 'react-toastify';
import { isNil } from '@/utils/is-nil';
import { StandFormValues } from './types';
import { useQueryClient } from '@tanstack/react-query';
import { QueryOptions } from '@/features/query/query-options';

export const useStandSave = () => {
  const { t } = useTranslation();
  const client = useQueryClient();

  const [isSaving, { toggle }] = useBoolean(false);
  const instance = useFormContext<StandFormValues>();
  const { accountId: exhibitorId, exhibitionId } = useParams({ strict: false });

  const { processErrorsObject } = useFormErrorsHandler(instance);

  const saveStand = async (
    stand: StandFormValues['stands'][number],
    index: number
  ) => {
    if (!isNil(stand.area) && stand.area % 0.25 !== 0) {
      instance.setError(`stands.${index}.area`, {
        message: t('divisible-by-quarter', {
          unit: t('sqm'),
        }),
      });

      return;
    }

    if (!isNil(stand.secondFloorArea) && stand.secondFloorArea % 0.25 !== 0) {
      instance.setError(`stands.${index}.secondFloorArea`, {
        message: t('divisible-by-quarter', {
          unit: t('sqm'),
        }),
      });

      return;
    }

    try {
      toggle();

      if (stand.id) {
        await typedAxios.stand.putExhibitionsStands({
          id: stand.id,
          exhibitionId: exhibitionId,
          requestBody: {
            ...formOptionsAsValues(stand),
            exhibitorId: exhibitorId,
          },
        });
        toast.success(t('stand-saved'));
      } else {
        const rawValues = formOptionsAsValues(stand);

        if (!isNil(stand.id)) {
          // @ts-expect-error desc
          delete rawValues.id;
        }

        await typedAxios.stand.postExhibitionsStands({
          exhibitionId: exhibitionId,
          requestBody: { ...rawValues, exhibitorId },
        });

        toast.success(t('stand-created'));
      }

      await client.refetchQueries({
        queryKey: QueryOptions.standsQueryOptions({
          exhibitionId: +exhibitionId,
          exhibitorId: +exhibitorId,
        }).queryKey,
      });
    } catch (e) {
      processErrorsObject(e, {
        fieldPrefix: `stands.${index}.`,
        ignoreFields: [`stands.${index}.stand`],
      });
    } finally {
      toggle();
    }
  };

  return { isSaving, saveStand };
};
