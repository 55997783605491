import styled from 'styled-components';
import { Link } from '@tanstack/react-router';
import { DEFAULT_LOGO_PATH } from '@/utils/constants';

const Wrapper = styled.div`
  width: 100%;
  max-width: var(--chakra-sizes-header-brand-width);
`;

const StyledLink = styled(Link)`
  display: block;
`;

const Image = styled.img`
  width: 100%;
  height: auto;
  max-height: 70px;
  min-height: 60px;
  max-width: 60px;
  min-width: 60px;
  margin-top: -10px;
`;

const BrandBox = () => {
  return (
    <Wrapper>
      <StyledLink to="/">
        <Image src={DEFAULT_LOGO_PATH} alt="Logo" />
      </StyledLink>
    </Wrapper>
  );
};

export default BrandBox;
