/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseHttpRequest } from './core/BaseHttpRequest';
import type { OpenAPIConfig } from './core/OpenAPI';
import { AxiosHttpRequest } from './core/AxiosHttpRequest';

import { AuthService } from './services/AuthService';
import { BitrixService } from './services/BitrixService';
import { CategoryService } from './services/CategoryService';
import { CurrencyService } from './services/CurrencyService';
import { DiscountService } from './services/DiscountService';
import { ExhibitionService } from './services/ExhibitionService';
import { ExhibitorService } from './services/ExhibitorService';
import { GlobalSettingsService } from './services/GlobalSettingsService';
import { IteCompanyService } from './services/IteCompanyService';
import { OrderService } from './services/OrderService';
import { PriceListService } from './services/PriceListService';
import { PriceListItemService } from './services/PriceListItemService';
import { ProductService } from './services/ProductService';
import { SellingOfficeService } from './services/SellingOfficeService';
import { StandService } from './services/StandService';
import { UserService } from './services/UserService';

type HttpRequestConstructor = new (config: OpenAPIConfig) => BaseHttpRequest;

export class axios {

    public readonly auth: AuthService;
    public readonly bitrix: BitrixService;
    public readonly category: CategoryService;
    public readonly currency: CurrencyService;
    public readonly discount: DiscountService;
    public readonly exhibition: ExhibitionService;
    public readonly exhibitor: ExhibitorService;
    public readonly globalSettings: GlobalSettingsService;
    public readonly iteCompany: IteCompanyService;
    public readonly order: OrderService;
    public readonly priceList: PriceListService;
    public readonly priceListItem: PriceListItemService;
    public readonly product: ProductService;
    public readonly sellingOffice: SellingOfficeService;
    public readonly stand: StandService;
    public readonly user: UserService;

    public readonly request: BaseHttpRequest;

    constructor(config?: Partial<OpenAPIConfig>, HttpRequest: HttpRequestConstructor = AxiosHttpRequest) {
        this.request = new HttpRequest({
            BASE: config?.BASE ?? '',
            VERSION: config?.VERSION ?? '1',
            WITH_CREDENTIALS: config?.WITH_CREDENTIALS ?? false,
            CREDENTIALS: config?.CREDENTIALS ?? 'include',
            TOKEN: config?.TOKEN,
            USERNAME: config?.USERNAME,
            PASSWORD: config?.PASSWORD,
            HEADERS: config?.HEADERS,
            ENCODE_PATH: config?.ENCODE_PATH,
        });

        this.auth = new AuthService(this.request);
        this.bitrix = new BitrixService(this.request);
        this.category = new CategoryService(this.request);
        this.currency = new CurrencyService(this.request);
        this.discount = new DiscountService(this.request);
        this.exhibition = new ExhibitionService(this.request);
        this.exhibitor = new ExhibitorService(this.request);
        this.globalSettings = new GlobalSettingsService(this.request);
        this.iteCompany = new IteCompanyService(this.request);
        this.order = new OrderService(this.request);
        this.priceList = new PriceListService(this.request);
        this.priceListItem = new PriceListItemService(this.request);
        this.product = new ProductService(this.request);
        this.sellingOffice = new SellingOfficeService(this.request);
        this.stand = new StandService(this.request);
        this.user = new UserService(this.request);
    }
}

