import { useEffect } from 'react';
import { useTable, useSortBy, useRowSelect, Column } from 'react-table';
import {
  Table as ChakraTable,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Flex,
  Box,
  Text,
  Checkbox,
} from '@chakra-ui/react';
import { ChevronUpIcon, ChevronDownIcon } from '@chakra-ui/icons';
import i18n from '@/i18n';

interface TableProps<T extends object = object> {
  columns: Array<Column<T>>;
  data: Array<T>;
  onBulkAction?: (selectedRows: Array<T>) => void;
  showBulkAction?: boolean;
}

export const Table = <T extends object = object>({
  columns,
  data,
  onBulkAction,
  showBulkAction = false,
}: TableProps<T>) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    selectedFlatRows,
    toggleAllRowsSelected,
  } = useTable<T>(
    {
      columns,
      data,
      initialState: {
        hiddenColumns: columns.map((column: any) => {
          if (column.show === false) {
            return column.accessor || column.id;
          }
        }),
      },
    },
    useSortBy,
    useRowSelect,
    (hooks) => {
      if (showBulkAction) {
        hooks.visibleColumns.push((columnsData) => [
          {
            id: 'selection',
            width: '3%',
            Header: ({ getToggleAllRowsSelectedProps }) => {
              const isSelected = getToggleAllRowsSelectedProps()?.checked;

              return (
                <Checkbox
                  {...getToggleAllRowsSelectedProps()}
                  isChecked={isSelected}
                />
              );
            },
            Cell: ({ row }: { row: any }) => {
              const isSelected = row.isSelected;

              return (
                <Checkbox
                  {...row.getToggleRowSelectedProps()}
                  isChecked={isSelected}
                />
              );
            },
          },
          ...columnsData,
        ]);
      }
    }
  );

  useEffect(
    function setSelectedRowIds() {
      const rowIds = selectedFlatRows.map((d) => d.original);

      if (onBulkAction) {
        onBulkAction(rowIds);
      }
    },
    [selectedFlatRows]
  );

  useEffect(
    function resetPivotIds() {
      if (!showBulkAction) {
        toggleAllRowsSelected(false);
      }
    },
    [showBulkAction]
  );

  const isEmpty = rows.length === 0;

  return (
    <>
      <ChakraTable {...getTableProps()}>
        <Thead>
          {headerGroups.map((headerGroup, index) => (
            <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
              {headerGroup.headers.map((column: any, headerIndex) => (
                <Th
                  userSelect="none"
                  {...column.getHeaderProps({
                    ...column.getSortByToggleProps(),
                    width: column?.width,
                  })}
                  key={headerIndex}
                >
                  <Flex alignItems="center">
                    {column.render('Header')}
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <ChevronDownIcon ml={1} w={4} h={4} />
                      ) : (
                        <ChevronUpIcon ml={1} w={4} h={4} />
                      )
                    ) : (
                      ''
                    )}
                  </Flex>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {rows.map((row, index) => {
            prepareRow(row);

            return (
              <Tr {...row.getRowProps()} key={index}>
                {row.cells.map((cell, cellIndex) => {
                  return (
                    <Td
                      {...cell.getCellProps()}
                      key={cellIndex}
                      verticalAlign="top"
                    >
                      {cell.render('Cell')}
                    </Td>
                  );
                })}
              </Tr>
            );
          })}
        </Tbody>
      </ChakraTable>
      {isEmpty && (
        <Box m={4} mt={6} display="flex" justifyContent="center" w="100%">
          <Text color="gray.400">{i18n.t('no-data')}</Text>
        </Box>
      )}
    </>
  );
};
