import { Store, EventCallable, is, Effect } from 'effector';

type LogType = '$' | 'ev' | 'fx' | string;

const fontFamily =
  'font-family: "Apple Color Emoji","Segoe UI Emoji","Noto Color Emoji","Android Emoji","EmojiOne Mozilla","Twemoji Mozilla","Noto Emoji","Segoe UI Symbol",EmojiSymbols,emoji;';

const log = (type: LogType, ...args: any[]) => {
  const [name, ...rest] = args;

  console.groupCollapsed('%c☄️', fontFamily, type, name);
  console.log(...rest);
  console.groupEnd();
};

export const createLogger = (
  units: Record<string, Store<any> | EventCallable<any> | Effect<any, any, any>>
) => {
  if (process.env.NODE_ENV !== 'development') {
    return;
  }

  Object.entries(units).forEach(([name, unit]) => {
    if (is.effect(unit)) {
      unit.pending.watch((pending) => {
        log('fx', `${name} -> pending`, pending);
      });

      unit.done.watch(({ params, result }) => {
        log('fx', `${name} -> done`, { params, result });
      });

      return;
    }

    unit.watch((s) => {
      switch (true) {
        case is.store(unit): {
          log('$', name, s);
          break;
        }

        case is.event(unit): {
          log('ev', name, s);
          break;
        }

        default:
          log('untyped', name, s);
      }
    });
  });
};
