import {
  BuildingDescriptionBodyRow,
  BuildingDescriptionLabelContainer,
  BuildingDescriptionWrapper,
  BuildingTableRow,
  BuildingTableTitle,
  ImageDescription,
  ImageWrapper,
  NoteSectionContainer,
  PriceLabel,
  PriceNumber,
  PriceRowContainer,
  PricesWrapper,
  TableContainerWrapper,
} from '@/components/DevelopmentCard/DevelopmentCard.styled';
import { Table, Tbody, Thead, Tr } from '@chakra-ui/react';
import React, { ReactNode } from 'react';
import {
  CardPricesType,
  TableDataType,
} from '@/components/DevelopmentCard/DevelopmentCard';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';
import { Nullable } from '../../types/support-types';
import { useExhibition } from '@/store/effector/exhibition';

interface ModalPricesProps {
  prices?: CardPricesType;
  header?: ReactNode;
}

interface ImageSectionProps {
  description?: Nullable<string>;
  children?: ReactNode;
}

interface BuildingDescriptionSectionProps {
  buildingLabel?: Nullable<string>;
  buildingDescription?: Nullable<string>;
}

interface TableSectionProps {
  tableData?: TableDataType;
}

interface NoteSectionProps {
  note?: string;
}

export const ModalPrices = ({ prices, header }: ModalPricesProps) => {
  const { t } = useTranslation();
  const { currency } = useExhibition();

  return (
    <PricesWrapper>
      {prices?.current && (
        <PriceRowContainer>
          <PriceLabel>{header}</PriceLabel>
          <PriceNumber>
            {t('building-info-price', { price: prices?.current, currency })}
          </PriceNumber>
        </PriceRowContainer>
      )}
      {prices?.transition &&
        prices?.transition?.map((transition, index) => {
          return (
            <PriceRowContainer key={index}>
              <PriceLabel>{transition?.label}</PriceLabel>
              <PriceNumber>
                {t('building-info-price', {
                  price: transition?.price,
                  currency,
                })}
              </PriceNumber>
            </PriceRowContainer>
          );
        })}
    </PricesWrapper>
  );
};

export const ImageSection = ({
  description,
  children,
}: ImageSectionProps): JSX.Element => {
  return (
    <ImageWrapper>
      {children}
      <ImageDescription>{description}</ImageDescription>
    </ImageWrapper>
  );
};

export const BuildingDescriptionSection = ({
  buildingLabel,
  buildingDescription,
}: BuildingDescriptionSectionProps): JSX.Element => {
  return (
    <BuildingDescriptionWrapper>
      <BuildingDescriptionLabelContainer>
        {buildingLabel}
      </BuildingDescriptionLabelContainer>
      {buildingDescription && (
        <BuildingDescriptionBodyRow>
          {parse(buildingDescription)}
        </BuildingDescriptionBodyRow>
      )}
    </BuildingDescriptionWrapper>
  );
};

export const TableSection = ({ tableData }: TableSectionProps): JSX.Element => {
  return (
    <TableContainerWrapper>
      <Table variant="simple" size="sm">
        <Thead>
          <Tr>
            {tableData?.titles?.map((columnTitle, index) => (
              <BuildingTableTitle key={index}>{columnTitle}</BuildingTableTitle>
            ))}
          </Tr>
        </Thead>
        <Tbody width="100%">
          {tableData?.rows?.map((row, index) => {
            return (
              <Tr key={index}>
                {row?.map((value, key) => (
                  <BuildingTableRow key={key}>{value}</BuildingTableRow>
                ))}
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </TableContainerWrapper>
  );
};

export const NoteSection = ({ note }: NoteSectionProps): JSX.Element => {
  return <NoteSectionContainer>{note}</NoteSectionContainer>;
};
