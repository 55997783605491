import { User } from '@/model/IUser';
import { checkRBACRule, RBACRulesType } from '@/features/rbac/useRBAC';
import { redirect } from '@tanstack/react-router';
import { indexRouteFactory } from '@/features/Routing/userPaths';
import { RoleType } from '@/model/common';

export const routerRBACGuard = async (
  user: User | null,
  rule: RBACRulesType
) => {
  if (!user) {
    return;
  }

  if (!checkRBACRule(user)(rule)) {
    throw redirect({
      to: indexRouteFactory(user.role as RoleType),
      replace: true,
    });
  }
};
