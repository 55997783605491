import React, { useEffect, useState } from 'react';
import { Box, Link, Text } from '@chakra-ui/react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import fetchApi from '@/utils/fetchAPI';
import { LOGIN } from '@/constants/endpoints';
import { FetchMethods } from '@/utils/constants';
import { useAuth } from '@/store/effector/auth';

const CodeWrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 10px;
`;

const CodeLabel = styled(Text)`
  color: grey;
`;

const CodeTimer = styled(Text)`
  font-weight: 600;
  color: grey;
  font-size: 16px;
`;

interface TimerProps {
  sec: number;
}

export const Timer = ({ sec }: TimerProps) => {
  const culture = localStorage.getItem('lang');
  const authInfo = useAuth();
  const userEmail = authInfo.userEmail;
  const [over, setOver] = useState(false);
  const [time, setTime] = useState(sec);
  const { t } = useTranslation();

  const tick = () => {
    if (over) {
      return;
    }

    if (time === 0) {
      setOver(true);
    } else {
      setTime(time - 1);
    }
  };

  const resendCode = async () => {
    await fetchApi(LOGIN, {
      method: FetchMethods.Post,
      data: {
        email: userEmail,
      },
      params: {
        culture,
      },
    });

    setTime(sec);
    setOver(false);
  };

  useEffect(() => {
    const timerID = setInterval(() => tick(), 1000);

    return () => clearInterval(timerID);
  });

  return (
    <CodeWrapper>
      {time === 0 ? (
        <Link onClick={resendCode}>{t('resend')}</Link>
      ) : (
        <>
          <CodeTimer>{`00:${time.toString().padStart(2, '0')}`}</CodeTimer>
          <CodeLabel>{t('resend')}</CodeLabel>
        </>
      )}
    </CodeWrapper>
  );
};
