import React, { useState } from 'react';
import {
  Button,
  CardBody,
  CardHeader,
  Heading,
  HStack,
  PinInput,
  PinInputField,
  Stack,
  FormControl,
  FormErrorMessage,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { FieldValues, useForm } from 'react-hook-form';
import { Logo, StyledCard } from '@/pages/common/Auth.styled';
import { Timer } from '@/components/Timer/Timer';
import fetchApi from '@/utils/fetchAPI';
import { CODE_VERIFY } from '@/constants/endpoints';
import { DEFAULT_LOGO_PATH, FetchMethods } from '@/utils/constants';
import { useNavigate } from '@tanstack/react-router';
import { FormError, IdType } from '@/model/common';
import { User } from '@/model/IUser';
import { indexRouteFactory } from '@/features/Routing/userPaths';
import { LanguageSwitcher } from '@/components/LanguageSwitcher/LanguageSwitcher';
import { verifyEmailRoute } from '@/features/Routing/auth';
import { authApi, useAuth } from '@/store/effector/auth';

interface VerifyEmailPageProps {
  pinLength?: number;
}

export type OfficeType = {
  currency: string;
  id: IdType;
  location: string;
  name: string;
};

const VerifyEmailPage: React.FC<VerifyEmailPageProps> = ({ pinLength = 4 }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const authInfo = useAuth();
  const { redirect } = verifyEmailRoute.useSearch();
  const [errors, setErrors] = useState<FormError>({});
  const navigate = useNavigate();

  const { t } = useTranslation();

  const methods = useForm();

  const submitHandler = async (values: FieldValues) => {
    const code = (values.code as string[]).join('');
    const userEmail = authInfo.userEmail;

    setIsSubmitting(true);

    try {
      const { data } = await fetchApi(CODE_VERIFY, {
        method: FetchMethods.Post,
        data: {
          email: userEmail,
          code: code,
        },
      });

      const currentUser = new User({
        fullName: data?.name,
        role: data?.role,
        id: data?.id,
      });

      localStorage.setItem('token', data?.token);
      localStorage.setItem('user', JSON.stringify(currentUser));

      authApi.setUser(currentUser);
      authApi.setIsLoggedIn(true);
      authApi.setToken(data?.token);

      // dispatch(clearProducts());

      const indexRoute = (() => {
        if (redirect) {
          return redirect;
        }

        if (currentUser.role) {
          return indexRouteFactory(currentUser.role);
        }

        return '/';
      })();

      navigate({
        to: indexRoute,
      });
    } catch (error) {
      setErrors({ verify: ['Неверный код доступа'] });
    } finally {
      setIsSubmitting(false);
    }
  };

  const setValues = (values: string) => {
    const codeValues = Array.from(values);

    Object.values(codeValues).forEach((field, index) => {
      const fieldName = `code.${index}`;

      methods.setValue(fieldName, field);
    });
  };

  return (
    <StyledCard>
      <CardHeader>
        <Logo src={DEFAULT_LOGO_PATH} alt="Logo" />
        <LanguageSwitcher />
        <Heading size={{ base: 'xs', md: 'sm' }}>{t('welcomeAuth')}</Heading>
        <Heading size={{ base: 'xs' }} fontWeight="400" marginTop="7px">
          {t('verifyEmailAddress')}
        </Heading>
      </CardHeader>
      <CardBody>
        <form onSubmit={methods.handleSubmit(submitHandler)}>
          <FormControl isInvalid={!!errors['verify']}>
            <Stack spacing="5">
              <HStack marginLeft="auto" marginRight="auto">
                <PinInput
                  type="number"
                  onComplete={(values) => setValues(values)}
                >
                  {new Array(pinLength).fill(undefined).map((_, index) => (
                    <PinInputField
                      key={index}
                      {...methods.register(`code.${index}`)}
                    />
                  ))}
                </PinInput>
              </HStack>
              {errors['verify'] && (
                <FormErrorMessage justifyContent="center">
                  {errors['verify'].join(' ')}
                </FormErrorMessage>
              )}
              <Button type="submit" isLoading={isSubmitting}>
                {t('confirm')}
              </Button>

              <Timer sec={59} />
            </Stack>
          </FormControl>
        </form>
      </CardBody>
    </StyledCard>
  );
};

export default VerifyEmailPage;
