import CategoriesSidebar from '../../../components/Sidebar/CategoriesSidebar';
import { getCategoriesByRole } from '@/__mocks__/categoriesSidebars';
import { useAuth } from '@/store/effector/auth';

export const SidebarByRole = () => {
  const { user } = useAuth();

  if (!user) {
    return null;
  }

  return (
    <CategoriesSidebar
      categories={getCategoriesByRole(user)}
      type="adminPanel"
    />
  );
};
