import { merge, sample } from 'effector';
import { $catalog, loadCategoriesFX } from '@/store/effector/catalog';
import { $stand } from '@/store/effector/stand';
import {
  $exhibition,
  exhibitionInitialState,
} from '@/store/effector/exhibition';
import { persist as persistLocal } from 'effector-storage/local';
import { persist as persistAbstract } from 'effector-storage';
import { isNil } from '@/utils/is-nil';
import { createLogger } from '@/store/effector/logger';
import { $order } from '@/store/effector/order';
// import { $auth } from '@/store/effector/auth';
import { CatalogGate } from '@/store/effector/catalog-gate';
import { $vatSettings } from '@/store/effector/vat-settings';
import { StorageAdapter } from 'effector-storage';
import { $orderCompleteness } from '@/store/effector/order-completeness';

const merged = merge([$stand, $exhibition, CatalogGate.open]);

sample({
  clock: merged,
  target: loadCategoriesFX,
  fn: () => {
    const stand = $stand.getState();
    const exhibition = $exhibition.getState();

    return {
      buildingType: stand.standForOrder.buildingType,
      exhibitionId: exhibition.exhibitionId,
      sellingOfficeId: exhibition.sellingOfficeId,
      currency: exhibition.currency,
    };
  },
  filter: () => {
    const stand = $stand.getState();
    const exhibition = $exhibition.getState();

    return (
      CatalogGate.status.getState() &&
      !isNil(stand.standForOrder.buildingType) &&
      !isNil(exhibition.exhibitionId) &&
      !isNil(exhibition.sellingOfficeId)
    );
  },
});

const exhibitionAdapter: StorageAdapter = (key) => ({
  get: () => {
    const state = localStorage.getItem(key);

    if (!state) {
      return exhibitionInitialState;
    }

    try {
      const deserialized = JSON.parse(state);

      // commented for now, should be tested in production
      // for now - just load exhibition each time route matched
      // if (deserialized.exhibition) {
      //   deserialized.exhibition = IExhibition.fromJSON(deserialized.exhibition);
      // }

      // console.log(deserialized);

      return deserialized;
    } catch {
      return exhibitionInitialState;
    }
  },
  set: (value) => localStorage.setItem(key, JSON.stringify(value)),
});

persistAbstract({
  store: $exhibition,
  key: 'exhibition',
  adapter: exhibitionAdapter,
});

persistLocal({
  store: $stand,
  key: 'stand',
  sync: 'force',
});

createLogger({
  // $auth,
  $stand,
  $order,
  $exhibition,
  $catalog,
  loadCategoriesFX: loadCategoriesFX,
  $vatSettings,
  $orderCompleteness,
});
