import styled from 'styled-components';
import { Box, Flex, Text } from '@chakra-ui/react';

export const SelectedPriceWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  padding-top: 40px;
  padding-bottom: 30px;
`;

export const LoaderWrapper = styled(Flex)`
  justify-content: center;
  padding-top: 10%;
`;

export const SelectedPriceContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 5px;
`;

export const OptionWrapper = styled(Flex)`
  flex-direction: row;
  gap: 20px;
  max-width: 100px;
`;

export const ToolBarWrapper = styled(Box)`
  padding-bottom: 10px;
  display: flex;
  flex-direction: row;
  justify-content: end;
  gap: 20px;
`;

export const ButtonContentContainer = styled(Flex)`
  flex-direction: row;
  align-items: center;
  gap: 5px;
`;

export const TransparentButtonsTextContainer = styled(Text)`
  font-size: 16px;
  color: var(--chakra-colors-gray-600);
`;
