import { UseFormReturn } from 'react-hook-form';
import { EmployeeFormValues } from '@/pages/AdminPanel/PersonalAccounts/UsersTab/types';
import { useBoolean } from '@chakra-ui/react';
import { typedAxios } from '@/api/typed-axios';

export const useEmployeeSearch = () => {
  const [isSearching, { on, off }] = useBoolean();

  return {
    isSearching,
    findUser: async (formInstance: UseFormReturn<EmployeeFormValues>) => {
      const isValid = await formInstance.trigger('email');

      if (!isValid) {
        return;
      }

      const employeeEmail = formInstance.getValues('email');

      try {
        on();

        const company = await typedAxios.exhibitor.getExhibitors1({
          email: employeeEmail,
        });

        if (!company || !company.exhibitorEmployees) {
          return null;
        }

        const existingEmployee = company.exhibitorEmployees.find(
          (e) => e.email === employeeEmail
        );

        if (!existingEmployee) {
          return null;
        }

        return existingEmployee;
      } catch {
        return null;
      } finally {
        off();
      }
    },
  };
};
