import {
  SystemStyleObject,
  Tab,
  TabList,
  TabPanel,
  TabPanelProps,
  TabPanels,
  TabProps,
  Tabs,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { Exhibitions } from './Exhibitions';
import {
  exhibitionListRoute,
  exhibitionsTabs,
} from '../../../features/Routing/protected/admin-exhibitions';
import { useNavigate } from '@tanstack/react-router';

const selectedTabStyles: SystemStyleObject = {
  background: 'blackAlpha.300',
};

const tabStyles: TabProps = {
  background: 'blackAlpha.100',
  minW: '100px',
};

const tabPanelsStyle: TabPanelProps = {
  width: '100%',
};

export const ExhibitionsTabsPage = () => {
  const { t } = useTranslation();
  const { tab } = exhibitionListRoute.useSearch();
  const indexFromUrl = exhibitionsTabs.indexOf(tab);
  const navigate = useNavigate();

  const handleTabChange = (index: number) => {
    navigate({
      search: { tab: exhibitionsTabs[index] || 'current' },
    });
  };

  return (
    <Tabs
      variant="unstyled"
      align="center"
      w="100%"
      index={indexFromUrl}
      onChange={handleTabChange}
    >
      <TabList>
        <Tab
          _selected={selectedTabStyles}
          {...tabStyles}
          borderTopLeftRadius="md"
          borderBottomLeftRadius="md"
        >
          {t('current-exhibitions')}
        </Tab>
        <Tab
          _selected={selectedTabStyles}
          {...tabStyles}
          borderTopRightRadius="md"
          borderBottomRightRadius="md"
        >
          {t('past-exhibitions')}
        </Tab>
      </TabList>
      <TabPanels>
        <TabPanel {...tabPanelsStyle}>
          {tab === 'current' && <Exhibitions />}
        </TabPanel>
        <TabPanel {...tabPanelsStyle}>
          {tab === 'past' && <Exhibitions />}
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};
