import { ITechnicalService } from '@/model/ITechnicalService';
import DevelopmentIcon from '@/components/Icons/Development';
import { Category } from '@/model/ICategory';
import { EXHIBITIONS } from '@/constants/technical-services';
import i18n from '@/i18n';

const moderatorMenu = () => {
  const exhibitions = new Category({
    id: EXHIBITIONS,
    name: EXHIBITIONS,
    displayName: i18n.t(EXHIBITIONS),
  });

  return [
    {
      category: exhibitions,
      image: <DevelopmentIcon />,
    },
  ] as ITechnicalService[];
};

export default moderatorMenu;
