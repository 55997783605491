import { FormLabel as ChakraFormLabel, FormLabelProps } from '@chakra-ui/react';
import { PropsWithChildren } from 'react';

const formLabelStyle = {
  color: 'grey',
  fontWeight: 500,
  fontSize: '16px',
};

export const FormLabel = (
  props: PropsWithChildren<FormLabelProps>
): JSX.Element => {
  return <ChakraFormLabel style={formLabelStyle} {...props} />;
};
