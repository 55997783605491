import React, { useState } from 'react';
import { useNavigate, useRouter } from '@tanstack/react-router';
import { Card } from '@/components/Card/Card';
import { Content, ContentInner } from '@/components/Content';
import { Table } from '@/components/Table/Table';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Button,
  Flex,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import {
  AccordionButtonDescription,
  AccordionButtonWrapper,
  AccordionItemWrapper,
  AccordionTitleContainer,
  AccordionTitleWrapper,
  AccordionWrapper,
  AddOrderButtonContainer,
  OptionBox,
  OrderInfoContainer,
  OrderOptionsContainer,
  OrderRowWrapper,
  OrdersWrapper,
  PageTitleContainer,
} from './Exhibition.styled';
import { MdDelete, MdModeEdit } from 'react-icons/md';
import { AiFillFilePdf } from 'react-icons/ai';
import Layout from '@/layout/Default';
import fetchApi from '@/utils/fetchAPI';
import { Loader } from '@/components/Loader/Loader';
import { GET_PDF_BY_ORDER } from '@/constants/endpoints';
import { FetchMethods } from '@/utils/constants';
import { DeleteModal } from '@/pages/Exhibition/DeleteModal';
import { exhibitionViewRoute } from '@/features/Routing/protected/exhibitions-by-role';
import {
  ITEPortal_Domain_Dto_OrderItemDto,
  ITEPortal_Domain_Dto_StandOrderDto,
  ITEPortal_Domain_Dto_StandSummariesDto,
} from '@/api';
import { typedAxios } from '@/api/typed-axios';
import { toast } from 'react-toastify';
import { useEditOrderLoader } from './useEditOrderLoader';
import { InfoIcon } from '@chakra-ui/icons';
import { StandDescription } from './shared/StandDescription';
import { useExhibitionStateReset } from '@/hooks/useExhibitionStateReset';
import { useExhibition } from '@/store/effector/exhibition';
import { standApi } from '@/store/effector/stand';
import { orderApi } from '@/store/effector/order';
import { loadingApi, useAppLoading } from '@/store/effector/loading';
import { FileUtils } from '../../utils/file-utils';
import { OrderBadge } from '@/components/OrderBadge';
import { ORDER_STATUSES } from '@/constants/order';
import { filesStorageApi } from '@/pages/Exhibition/FilesStorage/filesStorageApi';
import { FilesStorage } from '@/pages/Exhibition/FilesStorage/FilesStorage';
import { useGetOrderItems } from '@/pages/Exhibition/useGetOrderItems';
import { orderTranscriptTableColumns } from '@/pages/Exhibition/orderTranscriptTableColumns';

interface AccordionButtonContentProps {
  standName: string;
  stand: ITEPortal_Domain_Dto_StandSummariesDto;
}

type OrderRowContentProps = {
  title: string;
  stand: ITEPortal_Domain_Dto_StandSummariesDto;
  editable: boolean;
} & Pick<ITEPortal_Domain_Dto_StandOrderDto, 'status' | 'orderId'>;

type OrderRowProps = Required<{
  status: OrderRowContentProps['status'];
  order: Required<OrderRowContentProps['orderId']>;
  stand: OrderRowContentProps['stand'];
  canEdit: boolean;
  canRemove: boolean;
}>;

const defaultAccordionValue = [0];

const AccordionButtonContent = ({
  stand,
  standName,
}: AccordionButtonContentProps) => {
  const { standNumber } = stand;
  const { t } = useTranslation();

  return (
    <AccordionTitleWrapper>
      <AccordionTitleContainer>
        {standName}
        {standNumber && (
          <>
            , {standNumber}
            <Tooltip label={t('stand-number')} hasArrow placement="top">
              <InfoIcon color="lightgray" boxSize={4} />
            </Tooltip>
          </>
        )}
      </AccordionTitleContainer>
    </AccordionTitleWrapper>
  );
};

const OrderOptions = ({
  status,
  order,
  stand,
  canEdit,
  canRemove,
}: OrderRowProps): JSX.Element => {
  const culture = localStorage.getItem('lang');
  const router = useRouter();
  const { t } = useTranslation();
  const [openConfirm, setOpenConfirm] = useState(false);

  const { exhibitionId } = exhibitionViewRoute.useParams();

  const { editOrder } = useEditOrderLoader({
    orderId: order,
    exhibitionId: +exhibitionId,
    stand,
  });

  const showPdf = async (e: MouseEvent) => {
    e.stopPropagation();

    try {
      const { data } = await fetchApi(GET_PDF_BY_ORDER(order), {
        method: FetchMethods.Get,
        responseType: 'blob',
        params: {
          culture,
        },
      });

      FileUtils.saveBlobAsFile(data, `order-${order}.pdf`);
    } catch (error) {}
  };

  const deleteOrder = async () => {
    loadingApi.on();
    try {
      if (typeof order === 'number') {
        await typedAxios.order.deleteOrdersDraft({
          orderId: order,
        });

        router.invalidate();
      }
    } catch {
      toast.error(
        t('unable-to-delete', {
          entity: t('order').toLowerCase(),
        })
      );
    } finally {
      loadingApi.off();
    }
  };

  const openDeleteModal = (e: MouseEvent) => {
    e.stopPropagation();

    setOpenConfirm(!openConfirm);
  };

  const closeDeleteModal = () => setOpenConfirm(false);

  return (
    <>
      <OptionBox onClick={(e: MouseEvent) => showPdf(e)}>
        <AiFillFilePdf />
      </OptionBox>
      <DeleteModal
        onSubmit={deleteOrder}
        isOpen={openConfirm}
        onClose={closeDeleteModal}
      />
      {status === ORDER_STATUSES.Draft && (
        <>
          {canEdit && (
            <OptionBox onClick={editOrder}>
              <MdModeEdit />
            </OptionBox>
          )}
          {canRemove && (
            <OptionBox onClick={(e: MouseEvent) => openDeleteModal(e)}>
              <MdDelete />
            </OptionBox>
          )}
        </>
      )}
    </>
  );
};

const OrderRowContent = ({
  title,
  status,
  orderId,
  stand,
  editable,
}: OrderRowContentProps): JSX.Element => {
  return (
    <OrdersWrapper>
      <OrderRowWrapper>
        <OrderInfoContainer>
          <Text minW="15%">{title}</Text>
          <Text minW="5%">{orderId}</Text>
          <OrderBadge status={status} />
        </OrderInfoContainer>
        <OrderOptionsContainer>
          <OrderOptions
            canEdit={editable}
            canRemove={editable}
            status={status}
            order={orderId}
            stand={stand}
          />
        </OrderOptionsContainer>
      </OrderRowWrapper>
    </OrdersWrapper>
  );
};

const Exhibition = () => {
  const { exhibitionId } = exhibitionViewRoute.useParams();
  const { standsData } = exhibitionViewRoute.useLoaderData();
  const { exhibition } = useExhibition();
  const isLoader = useAppLoading();
  const { getOrderItems, orderLoading } = useGetOrderItems();
  const [orderItems, setOrderItems] = useState<
    Array<ITEPortal_Domain_Dto_OrderItemDto>
  >([]);

  const { t } = useTranslation();
  const navigate = useNavigate();

  useExhibitionStateReset();

  if (!exhibition) {
    return null;
  }

  const { name } = exhibition;

  const canManageOrder = exhibition.canEditOrders;

  const createOrder = (stand: ITEPortal_Domain_Dto_StandSummariesDto) => {
    orderApi.clearProducts();
    standApi.setStandForOrder(stand);

    navigate({
      to: `/exhibitions/$exhibitionId/$stand/create`,
      params: {
        exhibitionId: exhibitionId,
        stand: stand.standId,
      },
    });
  };

  return (
    <Layout>
      <Content>
        <ContentInner>
          <PageTitleContainer centered="true">{name}</PageTitleContainer>
          <AccordionWrapper defaultIndex={defaultAccordionValue} allowMultiple>
            {isLoader ? (
              <Loader centralized />
            ) : (
              standsData?.map((stand, index) => {
                const { orders } = stand;
                const hasOrders = !!orders?.length;
                const standName = `${t('stand')} ${index + 1}`;

                const getOrderData = (orderIndex: number) => {
                  setOrderItems([]);
                  const order = orders?.[orderIndex];
                  const products = getOrderItems(order?.orderId);

                  products.then(function (result) {
                    if (result) {
                      setOrderItems(result);
                    }
                  });
                };

                return (
                  <AccordionItemWrapper key={index}>
                    <Card>
                      <AccordionButtonWrapper>
                        <AccordionButton
                          _hover={{ backgroundColor: 'inherit' }}
                        >
                          <AccordionButtonDescription as="span">
                            <AccordionButtonContent
                              standName={standName}
                              stand={stand}
                            />
                          </AccordionButtonDescription>
                          <AccordionIcon />
                        </AccordionButton>

                        <AccordionPanel pb={4}>
                          <StandDescription stand={stand} />

                          <AddOrderButtonContainer gap={2}>
                            {canManageOrder && (
                              <Button
                                size="sm"
                                onClick={() => createOrder(stand)}
                              >
                                {t('createNewOrder')}
                              </Button>
                            )}
                            {stand.projectId && (
                              <Button
                                colorScheme="red"
                                variant="outline"
                                size="sm"
                                onClick={() => {
                                  navigate({
                                    to: `/exhibitions/${exhibitionId}/${stand.standId}/project/${stand.projectId}`,
                                  });
                                }}
                              >
                                {t('stand-project')}
                              </Button>
                            )}
                            <Button
                              size="sm"
                              variant="outline"
                              colorScheme="red"
                              onClick={() => {
                                filesStorageApi.setStand(stand);
                              }}
                            >
                              {t('my-files')}
                            </Button>
                          </AddOrderButtonContainer>
                          <Accordion
                            onChange={(tabIndex: number) =>
                              getOrderData(tabIndex)
                            }
                            allowToggle
                            reduceMotion
                          >
                            {hasOrders &&
                              orders.map((order, orderKey) => {
                                const orderTitle = `${t('order')} ${
                                  orderKey + 1
                                }`;

                                return (
                                  <AccordionItem key={orderKey}>
                                    <AccordionButton>
                                      <OrderRowContent
                                        title={orderTitle}
                                        status={order?.status}
                                        orderId={order?.orderId}
                                        key={orderKey}
                                        stand={stand}
                                        editable={canManageOrder}
                                      />
                                      <AccordionIcon />
                                    </AccordionButton>

                                    <AccordionPanel
                                      pb={4}
                                      style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                      }}
                                    >
                                      {orderLoading ? (
                                        <Loader />
                                      ) : (
                                        <Flex flexDir="column">
                                          <Table
                                            columns={
                                              orderTranscriptTableColumns
                                            }
                                            data={orderItems}
                                          />
                                        </Flex>
                                      )}
                                    </AccordionPanel>
                                  </AccordionItem>
                                );
                              })}
                          </Accordion>
                        </AccordionPanel>
                      </AccordionButtonWrapper>
                    </Card>
                  </AccordionItemWrapper>
                );
              })
            )}
          </AccordionWrapper>
          <FilesStorage canUploadNewFiles />
        </ContentInner>
      </Content>
    </Layout>
  );
};

export default Exhibition;
