import {
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
} from '@chakra-ui/react';
import Profile from './Profile';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useNavigate } from '@tanstack/react-router';
import { authApi, useAuth } from '@/store/effector/auth';
import { exhibitorPathRootPath } from '@/features/Routing/userPaths';
import { useRBAC } from '@/features/rbac/useRBAC';

const StyledMenuList = styled(MenuList)`
  font-size: 0.8125rem;
`;

const StyledMenuItem = styled(MenuItem)`
  padding: 0.35rem 1.2rem;
`;

const ProfileMenu: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { can } = useRBAC();

  const logOutHandler = useCallback(() => {
    authApi.logout();
  }, []);

  const toExhibitions = () => {
    navigate({
      to: exhibitorPathRootPath,
    });
  };

  const { user } = useAuth();

  if (!user) {
    return null;
  }

  return (
    <>
      <Menu>
        <MenuButton>
          <Profile user={user} />
        </MenuButton>
        <StyledMenuList marginY={0.5} borderRadius={0}>
          {can('canViewPublicExhibitions') && (
            <>
              <StyledMenuItem onClick={toExhibitions}>
                {t('myExhibitions')}
              </StyledMenuItem>

              <MenuDivider />
            </>
          )}
          <StyledMenuItem onClick={logOutHandler}>{t('logout')}</StyledMenuItem>
        </StyledMenuList>
      </Menu>
    </>
  );
};

export default ProfileMenu;
