import { FormProvider, useForm } from 'react-hook-form';
import React, { useState } from 'react';
import { FormControl, Stack, Button } from '@chakra-ui/react';
import { useNameTranslate } from '@/hooks/useStringName';
import styled from 'styled-components';
import { CHANGE_MAIN_LOGO } from '@/constants/endpoints';
import { toast } from 'react-toastify';
import fetchApi from '@/utils/fetchAPI';
import { FetchMethods } from '@/utils/constants';
import { UploadFileDropzone } from '@/components/FileUpload/FileUpload';
import { useTranslation } from 'react-i18next';

const FormControlWrapper = styled(FormControl)`
  display: flex;
  flex-direction: column;
  gap: 15px;
  max-width: 600px;
`;

export const LogoTab = (): JSX.Element => {
  const methods = useForm();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { t } = useTranslation();

  const submitHandler = async (value: any) => {
    if (value?.['logo']) {
      setIsSubmitting(true);
      try {
        await fetchApi(
          CHANGE_MAIN_LOGO,
          {
            method: FetchMethods.Post,

            data: { logo: value?.['logo'][0] },
          },
          { 'Content-Type': 'multipart/form-data' }
        );

        toast.success(t('success-logo-download'));
      } catch (error: any) {
        const fieldErrors = error?.response?.data?.errors;

        if (fieldErrors) {
          toast.error(fieldErrors);
        }
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  return (
    <FormProvider {...methods}>
      <form>
        <Stack spacing="5">
          <FormControlWrapper>
            <UploadFileDropzone
              label={useNameTranslate('change-logo')}
              name="logo"
              accept={{
                'image/png': ['.png'],
              }}
            />
            <Button
              colorScheme="red"
              width="100px"
              isLoading={isSubmitting}
              onClick={methods.handleSubmit(submitHandler)}
            >
              {useNameTranslate('save')}
            </Button>
          </FormControlWrapper>
        </Stack>
      </form>
    </FormProvider>
  );
};
