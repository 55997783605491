import {
  ITEPortal_Domain_Dto_Category_CategoryOrderIndexDto,
  ITEPortal_Domain_Dto_CategoryDto,
} from '@/api';
import { DraggableUtils } from '@/utils/draggable-utils';
import { useMutation } from '@tanstack/react-query';
import { queryClient } from '@/features/query';
import { useCategoriesLoaderQueryOptions } from '@/pages/AdminPanel/Accordion/use-categories-loader';
import { typedAxios } from '@/api/typed-axios';
import { toast } from 'react-toastify';
import i18n from '@/i18n';

export const useCategoriesOrderUpdate = () => {
  const { queryKey } = useCategoriesLoaderQueryOptions();

  return useMutation({
    mutationKey: ['reorder-categories-mutation', queryKey],
    mutationFn: async (opts: {
      categoriesSource: ITEPortal_Domain_Dto_CategoryDto[];
      startIndex: number;
      endIndex: number;
      preSaveFn: (ordered: ITEPortal_Domain_Dto_CategoryDto[]) => void;
    }) => {
      const { categoriesSource, startIndex, endIndex, preSaveFn } = opts;

      const initialTableData = queryClient.getQueryData(queryKey);

      if (startIndex === endIndex) {
        return Promise.resolve(initialTableData);
      }

      const localOrdered = DraggableUtils.reorder(
        categoriesSource,
        startIndex,
        endIndex,
        true
      );

      preSaveFn(localOrdered);

      queryClient.setQueryData(queryKey, localOrdered);

      try {
        await typedAxios.category.postCategoriesOrderIndex({
          requestBody: localOrdered.map((ct) => {
            return {
              categoryId: ct.id,
              orderIndex: ct.orderIndex || 0,
            } satisfies ITEPortal_Domain_Dto_Category_CategoryOrderIndexDto;
          }),
        });

        return localOrdered;
      } catch {
        toast.error(i18n.t('unable-to-apply-reorder'));

        queryClient.setQueryData(queryKey, initialTableData);

        return initialTableData;
      }
    },
  });
};
