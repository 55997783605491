import { FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import React, { useEffect } from 'react';
import { ModalFactory } from '@/components/Modal/api';
import { Stack } from '@chakra-ui/react';
import * as vali from 'zod';
import { UploadFileDropzone } from '@/components/FileUpload/FileUpload';
import { useNameTranslate } from '@/hooks/useStringName';

const ValidationSchema = vali.object({
  file: vali.array(vali.instanceof(File)).optional().nullish(),
});

export type ImportCSVFormValues = vali.infer<typeof ValidationSchema>;

export const ImportCSVForm = () => {
  const instance = useForm<ImportCSVFormValues>({
    resolver: zodResolver(ValidationSchema),
  });

  useEffect(() => {
    ModalFactory.updateContext(instance);
  }, [instance]);

  return (
    <FormProvider {...instance}>
      <Stack spacing={4}>
        <UploadFileDropzone
          label={useNameTranslate('upload-csv')}
          multiple={false}
          name="file"
          accept={{ 'text/csv': ['.csv'] }}
        />
      </Stack>
    </FormProvider>
  );
};
