import { IBreadcrumbItem } from '@/model/common';

import styled from 'styled-components';
import { Box } from '@chakra-ui/react';
import { ArrowBackIcon } from '@chakra-ui/icons';
import Breadcrumbs from '@/components/Breadcrumbs';
import { useTranslation } from 'react-i18next';
import { useParams } from '@tanstack/react-router';

const BackButtonWrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 16px;
  gap: 10px;
`;

export const ToExhibition = () => {
  const { t } = useTranslation();

  const { exhibitionId } = useParams({ strict: false });

  if (!exhibitionId) {
    return null;
  }

  const BackButton = () => {
    return (
      <BackButtonWrapper>
        <ArrowBackIcon w={5} h={5} color="grey.300" />
        {t('to-exhibitions')}
      </BackButtonWrapper>
    );
  };

  const getBack: IBreadcrumbItem[] = [
    {
      label: <BackButton />,
      link: '/admin/exhibitions',
    },
  ];

  return <Breadcrumbs items={getBack} />;
};
