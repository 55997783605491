import { Route } from '@tanstack/react-router';
import { cabinetRoute } from '../protected';

import { UsersTable } from '@/pages/AdminPanel/UsersTable/UsersTable';
import { NewUser } from '@/pages/AdminPanel/UsersTable/NewUsers/NewUser';
import i18n from '@/i18n';
import { SearchBoxOptionsType } from '@/components/AdminSearch/types';
import { routerRBACGuard } from '@/features/Routing/guard';

export const usersRoutesRoot = new Route({
  getParentRoute: () => cabinetRoute,
  path: '/users',
  beforeLoad: async ({ context: { user } }) => {
    await routerRBACGuard(user, 'canAccessAdminUsers');

    return {
      page: 'users',
      searchOptions: [
        {
          placeholder: i18n.t('search-users'),
        } satisfies SearchBoxOptionsType,
      ],
    };
  },
});

export const usersListRoute = new Route({
  getParentRoute: () => usersRoutesRoot,
  path: '/',
  component: () => <UsersTable />,
});

export const addNewUserRoute = new Route({
  getParentRoute: () => usersRoutesRoot,
  path: '/add',
  component: () => <NewUser type="add" />,
  beforeLoad: () => ({ page: 'create-user-add' }),
});
export const editUserRoute = new Route({
  getParentRoute: () => usersRoutesRoot,
  path: '/$userId/edit',
  component: () => <NewUser type="edit" />,
  beforeLoad: () => ({ page: 'create-user-edit' }),
});

export const usersRoutesRootTree = usersRoutesRoot.addChildren([
  usersListRoute,
  addNewUserRoute,
  editUserRoute,
]);
