import { t } from 'i18next';
import {
  type ITEPortal_Contracts_Enums_BuildingType,
  ITEPortal_Contracts_Enums_StandConfiguration,
} from '../api';
import i18n from '../i18n';

export const FetchMethods = {
  Delete: 'DELETE',
  Patch: 'PATCH',
  Post: 'POST',
  Get: 'GET',
  Put: 'PUT',
};

export const DatePickerMonths = [
  'Январь',
  'Февраль',
  'Март',
  'Апрель',
  'Май',
  'Июнь',
  'Июль',
  'Август',
  'Сентябрь',
  'Октябрь',
  'Ноябрь',
  'Декабрь',
];

export const DatePickerDays = ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'];

export const StandTypeLabelsByType: Record<
  ITEPortal_Contracts_Enums_BuildingType,
  string
> = {
  0: '',
  1: i18n.t('stand-space-only'),
  2: i18n.t('stand-standard'),
  3: i18n.t('stand-standard-plus'),
  4: i18n.t('stand-premium-ferrara'),
  5: i18n.t('stand-premium-verona'),
  6: i18n.t('stand-premium-ravenna'),
  7: i18n.t('stand-premium-kremona'),
  8: i18n.t('stand-premium-ancona'),
  9: i18n.t('stand-premium-fabriano'),
  10: i18n.t('stand-premium-livorno'),
  11: i18n.t('stand-optima'),
};

export const StandConfLabelsByConf: Record<
  ITEPortal_Contracts_Enums_StandConfiguration,
  string
> = {
  1: '',
  2: i18n.t('stand-conf-cor'),
  3: i18n.t('stand-conf-inl'),
  4: i18n.t('stand-conf-isl'),
  5: i18n.t('stand-conf-pen'),
};

export const StandConfDescriptionByConf: Record<
  ITEPortal_Contracts_Enums_StandConfiguration,
  string
> = {
  1: '',
  2: i18n.t('stand-conf-cor-description'),
  3: i18n.t('stand-conf-inl-description'),
  4: i18n.t('stand-conf-isl-description'),
  5: i18n.t('stand-conf-pen-description'),
};

export const AreaTypeConfig = [
  { label: 'Space Only', value: 1 },
  { label: 'Standard', value: 2 },
  { label: 'Standard Plus', value: 3 },
  { label: 'Premium – Ferrara', value: 4 },
  { label: 'Premium – Verona', value: 5 },
  { label: 'Premium – Ravenna', value: 6 },
  { label: 'Premium – Kremona', value: 7 },
  { label: 'Premium – Ancona', value: 8 },
  { label: 'Premium – Fabriano', value: 9 },
  { label: 'Premium – Livorno', value: 10 },
  { label: 'Optima', value: 11 },
];

export const StandConfiguration = [
  { label: '', value: 1 },
  { label: 'COR', value: 2 },
  { label: 'INL', value: 3 },
  { label: 'ISL', value: 4 },
  { label: 'PEN', value: 5 },
];

export const FieldTypeConfig = [
  { value: 'none', label: t('without-language') || '' },
  { value: 'select', label: t('select-list') || '' },
  { value: 'input', label: t('input') || '' },
];

export const DEFAULT_LOGO_PATH =
  'https://storage.yandexcloud.net/prod.techstore-files/main-logo/main-logo.png';
