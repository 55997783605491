import { IdType, WithIdType, WithPrivateProps } from './common';
import { Serializable } from './Serializable';
import { ITEPortal_Domain_Dto_CategoryDto } from '../api';
import { Nullable } from '../types/support-types';
import { isNil } from '../utils/is-nil';
import { CATEGORY_TYPES } from '@/constants/categorie';

type ConstructionOptionsType = WithIdType<ITEPortal_Domain_Dto_CategoryDto> & {
  parent?: Nullable<Category | ConstructionOptionsType>;
  parentCategoryName?: Nullable<string>;
  resolveUrl?: () => string | null;
};

// prettier-ignore
export class Category
  extends Serializable
  implements WithPrivateProps<WithIdType<ITEPortal_Domain_Dto_CategoryDto>> {
  private readonly _urlResolver?: () => string | null;

  id: IdType = 0;
  name: string = '';
  displayName?: string | null | undefined;
  imageUrl?: string | null | undefined;
  description?: string | null | undefined;
  parentCategory?: string | null | undefined;
  parentCategoryId?: string | null | undefined;
  isDecimal?: boolean | null | undefined;
  unitOfMeasure?: string | null | undefined;
  parentCategoryName?: Nullable<string>;

  tree: Array<Category> = []

  parent?: Category | null = null;

  constructor(data: ConstructionOptionsType) {
    super();

    const { parent, resolveUrl, ...rest } = data;

    Object.assign(this, rest);

    this.parent =
      parent && !(parent instanceof Category) ? new Category(parent) : parent;

    if (!this.parentCategoryName && this.parent) {
      this.parentCategoryName = parent?.displayName
    }

    this._urlResolver = resolveUrl;
  }

  get isBuilding() {
    return this.id === CATEGORY_TYPES.SELECT_BUILDING ||
    this.name === CATEGORY_TYPES.SELECT_BUILDING
  }

  resolveUrl() {
    if (typeof this._urlResolver === 'function') {
      return this._urlResolver();
    }

    return null;
  }

  toJSON(): ConstructionOptionsType {
    return {
      id: this.id,
      name: this.name,
      parent: this.parent?.toJSON(),
      isDecimal: this.isDecimal,
      unitOfMeasure: this.unitOfMeasure,
      parentCategoryId: this.parentCategoryId,
      description: this.description,
      displayName: this.displayName,
      imageUrl: this.imageUrl,
    };
  }

  static fromJSON(json: ConstructionOptionsType): Category {
    const parent = !isNil(json.parent) ? Category.fromJSON(json.parent) : undefined;

    return new Category({
      ...json,
      id: json.id,
      name: json.name,
      parent: parent,
      isDecimal: json.isDecimal,
      unitOfMeasure: json.unitOfMeasure,
    });
  }

  static empty() {
    return new Category({
      id: 'empty',
      name: 'empty',
    });
  }
}
