export const UrlUtils = {
  appendTimestampToUrl: (url: string) => {
    const urlObject = new URL(url);

    const queryParams = new URLSearchParams(urlObject.search);

    queryParams.set('timestamp', Date.now() + '');

    urlObject.search = queryParams.toString();

    return urlObject.toString();
  },
};
