import { ITEPortal_Domain_Dto_CategoryDto } from '@/api';
import {
  DragDropContext,
  Draggable,
  Droppable,
  OnDragEndResponder,
} from 'react-beautiful-dnd';
import { DraggableUtils } from '@/utils/draggable-utils';
import { useSubCategoriesOrderUpdate } from '@/pages/AdminPanel/Accordion/use-sub-categories-order-update';
import { CategoryItem } from '@/pages/AdminPanel/ServiceDirectory/ServiceDirectory.Accordion/CategoryItem';
import { DraggableHandlerIcon } from '@/components/Icons/DraggableHandlerIcon';
import React, { useMemo, useState } from 'react';
import { LoaderWrapper } from '@/components/Loader/Loader';
import { Accordion, Text } from '@chakra-ui/react';

import {
  useHasCategorySearchCriteria,
  useSubCategoriesLoader,
} from '@/pages/AdminPanel/Accordion/use-categories-loader';
import { useTranslation } from 'react-i18next';

type SubCategoriesListPropsType = {
  parentCategoryId: ITEPortal_Domain_Dto_CategoryDto['id'];
};

export const SubCategoriesList = (props: SubCategoriesListPropsType) => {
  const { parentCategoryId } = props;
  const { t } = useTranslation();
  const [currentExpandedIndex, setExpandedIndex] = useState<number | undefined>(
    undefined
  );

  const hasCategorySearchCriteria = useHasCategorySearchCriteria();

  const { data: categories = [], isFetching } =
    useSubCategoriesLoader(parentCategoryId);

  const canBeReordered = useMemo(() => {
    if (hasCategorySearchCriteria) {
      return false;
    }

    if (!categories.length) {
      return false;
    }

    return DraggableUtils.canBeReordered(categories);
  }, [categories, hasCategorySearchCriteria]);

  const { mutateAsync: reorderAsync, isPending: isReordering } =
    useSubCategoriesOrderUpdate(parentCategoryId);

  const dropHandler: OnDragEndResponder = (result) => {
    const { source, destination } = result;

    if (!destination) {
      return;
    }

    const currentOpened = categories.find(
      (_, index) => index === currentExpandedIndex
    );

    const startIndex = source.index;
    const endIndex = destination.index;

    reorderAsync({
      categoriesSource: categories,
      startIndex,
      endIndex,
      preSaveFn: (reorderedList) => {
        if (reorderedList) {
          const reorderedExpandedIndex = reorderedList.findIndex(
            (it) => it.id === currentOpened?.id
          );

          if (reorderedExpandedIndex > -1) {
            setExpandedIndex(reorderedExpandedIndex);
          }
        }
      },
    });
  };

  if (categories.length === 0) {
    return null;
  }

  return (
    <>
      <Text fontWeight="500" mb={2}>
        {t('sub-categories')}
      </Text>
      <Accordion
        allowToggle
        index={currentExpandedIndex}
        onChange={(expandedIndex) => {
          if (typeof expandedIndex === 'number') {
            setExpandedIndex(expandedIndex);
          }
        }}
      >
        <LoaderWrapper loading={isReordering || isFetching}>
          <DragDropContext onDragEnd={dropHandler}>
            <Droppable
              droppableId="first-level-categories"
              type="draggable-accordion-sub-categories"
            >
              {(provided, snapshot) => {
                return (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={{
                      ...DraggableUtils.getListStyle(snapshot.isDraggingOver),
                      padding: 0,
                      marginBottom: '1rem',
                    }}
                  >
                    {categories?.map((category, index) => {
                      return (
                        <Draggable
                          key={category.name}
                          draggableId={category.id.toString()}
                          index={index}
                          isDragDisabled={!canBeReordered}
                        >
                          {(itemProvided, itemSnapshot) => {
                            return (
                              <div
                                ref={itemProvided.innerRef}
                                {...itemProvided.draggableProps}
                                style={DraggableUtils.getItemStyle(
                                  itemSnapshot.isDragging,
                                  itemProvided.draggableProps.style
                                )}
                              >
                                <CategoryItem
                                  key={category.name}
                                  category={category}
                                  isDragDisabled={!canBeReordered}
                                  dragHandle={
                                    canBeReordered ? (
                                      <div {...itemProvided.dragHandleProps}>
                                        <DraggableHandlerIcon />
                                      </div>
                                    ) : null
                                  }
                                />
                              </div>
                            );
                          }}
                        </Draggable>
                      );
                    })}
                    {provided.placeholder}
                  </div>
                );
              }}
            </Droppable>
          </DragDropContext>
        </LoaderWrapper>
      </Accordion>
    </>
  );
};
