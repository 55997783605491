/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ITEPortal_Domain_Dto_AdminExhibitionSummary } from '../models/ITEPortal_Domain_Dto_AdminExhibitionSummary';
import type { ITEPortal_Domain_Dto_Exhibition_ExhibitionStatisticsWithSummary } from '../models/ITEPortal_Domain_Dto_Exhibition_ExhibitionStatisticsWithSummary';
import type { ITEPortal_Domain_Dto_ExhibitionDto } from '../models/ITEPortal_Domain_Dto_ExhibitionDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ExhibitionService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns ITEPortal_Domain_Dto_Exhibition_ExhibitionStatisticsWithSummary Created
     * @throws ApiError
     */
    public getExhibitionsExhibitorStatistics(): CancelablePromise<Array<ITEPortal_Domain_Dto_Exhibition_ExhibitionStatisticsWithSummary>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/exhibitions/exhibitor-statistics',
            errors: {
                400: `Bad Request`,
                500: `Server Error`,
            },
        });
    }

    /**
     * @returns ITEPortal_Domain_Dto_AdminExhibitionSummary Success
     * @throws ApiError
     */
    public getExhibitionsAdminSummaries({
        search,
        type,
        pageNumber,
        pageSize,
    }: {
        search?: string,
        type?: string,
        pageNumber?: number,
        pageSize?: number,
    }): CancelablePromise<Array<ITEPortal_Domain_Dto_AdminExhibitionSummary>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/exhibitions/admin-summaries',
            query: {
                'Search': search,
                'Type': type,
                'PageNumber': pageNumber,
                'PageSize': pageSize,
            },
        });
    }

    /**
     * @returns ITEPortal_Domain_Dto_ExhibitionDto Success
     * @throws ApiError
     */
    public getExhibitions({
        id,
    }: {
        id: number,
    }): CancelablePromise<ITEPortal_Domain_Dto_ExhibitionDto> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/exhibitions/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns any Success
     * @throws ApiError
     */
    public postExhibitionsLogo({
        id,
        formData,
    }: {
        id: number,
        formData?: {
            logo?: Blob;
        },
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/exhibitions/{id}/logo',
            path: {
                'id': id,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @returns any Success
     * @throws ApiError
     */
    public postExhibitions({
        requestBody,
    }: {
        requestBody?: ITEPortal_Domain_Dto_ExhibitionDto,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/exhibitions',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @returns any Success
     * @throws ApiError
     */
    public putExhibitions({
        exhibitionId,
        requestBody,
    }: {
        exhibitionId: number,
        requestBody?: ITEPortal_Domain_Dto_ExhibitionDto,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/exhibitions/{exhibitionId}',
            path: {
                'exhibitionId': exhibitionId,
            },
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

}
