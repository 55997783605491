import {
  ButtonGroup,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { useNameTranslate } from '../../../../../hooks/useStringName';
import { FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button } from '../../../../../components/Buttons/Button/Button';
import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import { IBaseOrder } from '../../../../../model/IBaseOrder';

import * as v from 'zod';
import InputNumber from '../../../../../components/Fields/InputNumber';
import { SubmitHandler } from 'react-hook-form/dist/types/form';
import { typedAxios } from '../../../../../api/typed-axios';
import i18n from '../../../../../i18n';
import { SelectComponent } from '../../../../../components/MultiSelect/MultiSelect';
import { PERSONAL_DISCOUNT_TYPE } from '../../../../../constants/order';
import { editOrderRoute } from '../../../../../features/Routing/protected/admin-exhibitions';
import { toast } from 'react-toastify';
import { isNil } from '../../../../../utils/is-nil';
import { useRouter } from '@tanstack/react-router';

type PersonalDiscountFormPropsType = {
  order: IBaseOrder;
  onClose: () => void;
};

const schema = v.object({
  typeOfDiscount: v.object({
    value: v.number(),
    label: v.string(),
  }),
  discount: v.preprocess(
    (value: unknown) => {
      if (value === '') {
        return value;
      }

      return typeof value === 'string' ? +value : value;
    },
    v.number({
      required_error: i18n.t('required-field'),
      invalid_type_error: i18n.t('required-field'),
    })
  ),
});

type FormValues = v.infer<typeof schema>;

const SubmitTypes = {
  Delete: 'delete',
  Save: 'save',
} as const;

type SubmitType = (typeof SubmitTypes)[keyof typeof SubmitTypes];

export const PersonalDiscountForm = (props: PersonalDiscountFormPropsType) => {
  const router = useRouter();
  const { order, onClose } = props;
  const isUpdate = !isNil(order.personalDiscount);
  const [submitType, setSubmitType] = useState<SubmitType | null>(null);
  const isDeleting = submitType === SubmitTypes.Delete;
  const isSaving = submitType === SubmitTypes.Save;

  const { currency } = editOrderRoute.useLoaderData();
  const instance = useForm<FormValues>({
    resolver: zodResolver(schema),
  });

  const [discountType, setDiscountType] = useState<number>(
    order.typeOfDiscount || PERSONAL_DISCOUNT_TYPE.PERCENT
  );
  const isPercentSelected = discountType === PERSONAL_DISCOUNT_TYPE.PERCENT;

  const save: SubmitHandler<FormValues> = async (values) => {
    if (!order.orderId) {
      return;
    }

    const typeOfDiscount = values.typeOfDiscount.value;
    const value = values.discount;

    if (
      typeOfDiscount === PERSONAL_DISCOUNT_TYPE.AMOUNT &&
      !isNil(order.orderSummary) &&
      value > order.orderSummary
    ) {
      instance.setError('discount', {
        message: t('discount-could-not-exceed-total'),
      });

      return;
    }

    if (typeOfDiscount === PERSONAL_DISCOUNT_TYPE.PERCENT && value > 100) {
      instance.setError('discount', {
        message: t('discount-gte-100'),
      });

      return;
    }

    setSubmitType(SubmitTypes.Save);

    try {
      await typedAxios.order[
        isUpdate ? 'putOrdersPersonalDiscount' : 'postOrdersPersonalDiscount'
      ]({
        orderId: order.orderId,
        requestBody: {
          // @ts-expect-error desc
          typeOfDiscount,
          value,
        },
      });

      toast.success(t('personal-discount-applied'));

      router.invalidate();
      onClose();
    } catch (e) {
      instance.setError('discount', {
        message: t('unable-to-apply-discount'),
      });
    } finally {
      setSubmitType(null);
    }
  };

  const removeDiscount = async () => {
    if (!order.orderId) {
      return;
    }

    setSubmitType(SubmitTypes.Delete);

    try {
      await typedAxios.order.deleteOrdersPersonalDiscount({
        orderId: order.orderId,
      });
      toast.success(t('personal-discount-removed'));

      router.invalidate();
      onClose();
    } catch {
      toast.error(t('unable-to-remove-discount'));
    } finally {
      setSubmitType(null);
    }
  };

  useEffect(() => {
    const defaultDiscountType =
      order.typeOfDiscount || PERSONAL_DISCOUNT_TYPE.PERCENT;
    const label =
      defaultDiscountType === PERSONAL_DISCOUNT_TYPE.PERCENT
        ? t('percent')
        : t('fixed-amount');

    instance.reset({
      discount: order.personalDiscount,
      typeOfDiscount: {
        value: defaultDiscountType,
        label: label,
      },
    });
  }, [order]);

  useEffect(() => {
    instance.watch((value, { name }) => {
      if (name === 'typeOfDiscount') {
        setDiscountType(
          value.typeOfDiscount?.value || PERSONAL_DISCOUNT_TYPE.PERCENT
        );
      }
    });
  }, []);

  return (
    <Modal
      isOpen
      size="xl"
      onClose={onClose}
      scrollBehavior="inside"
      isCentered
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{useNameTranslate('personal-discount')}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormProvider {...instance}>
            <Flex gap="20px" flexDirection="column">
              <SelectComponent
                name="typeOfDiscount"
                label={t('type-of-personal-discount')}
                options={[
                  {
                    value: PERSONAL_DISCOUNT_TYPE.PERCENT,
                    label: t('percent'),
                  },
                  {
                    value: PERSONAL_DISCOUNT_TYPE.AMOUNT,
                    label: t('fixed-amount'),
                  },
                ]}
              />
              <InputNumber
                maxWidth="100%"
                label={t('personal-discount')}
                name="discount"
                max={isPercentSelected ? 100 : undefined}
                precision={isPercentSelected ? 2 : 0}
                step={isPercentSelected ? 0.1 : 1}
                keepWithinRange
                addon={isPercentSelected ? '%' : currency}
              />
            </Flex>
          </FormProvider>
        </ModalBody>
        <ModalFooter justifyContent="center">
          <ButtonGroup gap="1" justifyContent="center">
            <Button colorScheme="secondary" onClick={onClose}>
              {t('cancel')}
            </Button>
            {order.hasDiscount && (
              <Button
                colorScheme="secondary"
                disabled={isDeleting}
                isLoading={isDeleting}
                onClick={removeDiscount}
              >
                {t('remove-personal-discount')}
              </Button>
            )}
            <Button
              colorScheme="red"
              disabled={isSaving}
              isLoading={isSaving}
              onClick={instance.handleSubmit(save)}
            >
              {t(
                isUpdate
                  ? 'update-personal-discount'
                  : 'apply-personal-discount'
              )}
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
