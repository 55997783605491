import { DomainRecord } from '@/model/common';
import { Product } from '@/model/IProduct';

export default function getConfig(products: Product[]): DomainRecord[] {
  return products.map((product) => ({
    key: product.getProductId(),
    text:
      product.productDisplayName ||
      product.productName ||
      product.getProductId(),
    product: product,
    params: [
      {
        type: 'price',
        text: product.formattedPrice,
      },
    ],
  }));
}
