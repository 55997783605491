import React, { useCallback, useRef } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import {
  AutoComplete,
  AutoCompleteInput,
  AutoCompleteItem,
  AutoCompleteList,
} from '@choc-ui/chakra-autocomplete';
import { Box, InputGroup, InputLeftElement } from '@chakra-ui/react';
import { Search2Icon } from '@chakra-ui/icons';
import { Item } from '@choc-ui/chakra-autocomplete/dist/types';
import { useNavigate } from '@tanstack/react-router';
import { getPathByProduct } from '@/utils/route';
import { useCategoriesByData } from '@/hooks/categoriesConverter/useCategoriesByData';
import { queryOptions, useQuery, useQueryClient } from '@tanstack/react-query';
import { ProductUtils } from '@/utils/product-utils';
import { useStand } from '@/store/effector/stand';
import { useCatalog } from '@/store/effector/catalog';
import { typedAxios } from '@/api/typed-axios';

const Wrapper = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
`;

const StyledInputGroup = styled(InputGroup)`
  color: #a0aec0;
`;

interface SearchProps {
  className?: string;
  exhibitionId: string;
  stand: string;
}

const filter = (query: string, optionValue: string, optionLabel: string) => {
  if (query.length > 0) {
    return optionLabel
      .trim()
      .toLowerCase()
      .includes(query.toLowerCase().trim());
  }

  return false;
};

const Search: React.FC<SearchProps> = ({ className, exhibitionId, stand }) => {
  const queryClient = useQueryClient();
  const { flatMap } = useCatalog();

  const { standForOrder } = useStand();
  const source = useCategoriesByData([...flatMap.values()]);

  const categoryIds = source?.map((category) => category?.['id'])?.toString();

  const ref = useRef();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const onSelectHandler = useCallback(({ item }: { item: Item }) => {
    navigate({
      to: getPathByProduct(exhibitionId, stand, item.originalValue),
      replace: true,
    });

    return false;
  }, []);

  const autoCompleteQueryOptions = queryOptions({
    queryKey: [
      'products-search',
      standForOrder?.buildingType,
      exhibitionId,
      categoryIds,
      standForOrder?.currency,
    ],
    enabled: false,
    queryFn: async () => {
      const response = await typedAxios.priceListItem.getPricelistItems({
        exhibitionId: +exhibitionId,
        currency: standForOrder?.currency || undefined,
        categoryIds,
        buildingType: standForOrder?.buildingType,
      });
      // const { data: response } = await fetchApi<
      //   Array<ITEPortal_Domain_Dto_PriceListItemSummary>
      // >(GET_PRICELIST_PRODUCTS, {
      //   method: FetchMethods.Get,
      //   params: {
      //     exhibitionId,
      //     sellingOfficeId,
      //     categoryIds,
      //     buildingType: standForOrder?.buildingType,
      //   },
      // });

      return response.map((product) => {
        return ProductUtils.createProductFromPriceListItem(product);
      });
    },
  });

  const { data = [], isFetching } = useQuery(autoCompleteQueryOptions);

  return (
    <Wrapper className={className}>
      <AutoComplete
        ref={ref}
        filter={filter}
        onSelectOption={onSelectHandler}
        freeSolo
        emptyState={
          <Box textAlign="center">{t('notFoundOption') as string}</Box>
        }
        openOnFocus
        isLoading={isFetching}
      >
        <StyledInputGroup>
          <InputLeftElement pointerEvents="none">
            <Search2Icon />
          </InputLeftElement>
          <AutoCompleteInput
            placeholder={t('searchService') as string}
            onFocus={() => {
              queryClient.ensureQueryData(autoCompleteQueryOptions);
            }}
          />
        </StyledInputGroup>
        <AutoCompleteList>
          {data.map((product) => (
            <AutoCompleteItem
              key={`option-${product.id}`}
              value={product}
              label={product.productDisplayName || product.getProductId()}
            >
              {product.productDisplayName || product.getProductId()}
            </AutoCompleteItem>
          ))}
        </AutoCompleteList>
      </AutoComplete>
    </Wrapper>
  );
};

export default Search;
