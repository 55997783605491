import {
  useCategoriesLoader,
  useHasCategorySearchCriteria,
} from '@/pages/AdminPanel/Accordion/use-categories-loader';
import { Accordion } from '@chakra-ui/react';
import { CategoryItem } from '@/pages/AdminPanel/ServiceDirectory/ServiceDirectory.Accordion/CategoryItem';
import { EditCategoryModalForm } from '@/pages/AdminPanel/ServiceDirectory/ServiceDirectory.Accordion/EditCategoryModalForm';
import {
  DragDropContext,
  Droppable,
  Draggable,
  OnDragEndResponder,
} from 'react-beautiful-dnd';
import { DraggableUtils } from '@/utils/draggable-utils';
import { useCategoriesOrderUpdate } from '@/pages/AdminPanel/Accordion/use-categories-order-update';
import { LoaderWrapper } from '@/components/Loader/Loader';
import React, { useMemo, useState } from 'react';
import { DraggableHandlerIcon } from '@/components/Icons/DraggableHandlerIcon';
import { EditProductDrawer } from '@/pages/AdminPanel/ServiceDirectory/EditProductFieldsets/EditProductDrawer';

export const ServiceDirectory = (): JSX.Element => {
  const { data = [] } = useCategoriesLoader();
  const [currentExpandedIndex, setExpandedIndex] = useState<number | undefined>(
    undefined
  );
  const hasSearchCriteria = useHasCategorySearchCriteria();
  const canBeReordered = useMemo(() => {
    if (hasSearchCriteria) {
      return false;
    }

    return DraggableUtils.canBeReordered(data);
  }, [data, hasSearchCriteria]);
  const { mutateAsync: reorderAsync, isPending: isReordering } =
    useCategoriesOrderUpdate();

  const dropHandler: OnDragEndResponder = (result) => {
    const { source, destination } = result;

    if (!destination) {
      return;
    }

    const currentOpened = data.find(
      (_, index) => index === currentExpandedIndex
    );

    const startIndex = source.index;
    const endIndex = destination.index;

    reorderAsync({
      categoriesSource: data,
      startIndex,
      endIndex,
      preSaveFn: (reorderedList) => {
        if (reorderedList) {
          const reorderedExpandedIndex = reorderedList.findIndex(
            (it) => it.id === currentOpened?.id
          );

          if (reorderedExpandedIndex > -1) {
            setExpandedIndex(reorderedExpandedIndex);
          }
        }
      },
    });
  };

  return (
    <LoaderWrapper loading={isReordering}>
      <Accordion
        index={currentExpandedIndex}
        allowToggle
        onChange={(expandedIndex) => {
          if (typeof expandedIndex === 'number') {
            setExpandedIndex(expandedIndex);
          }
        }}
      >
        <DragDropContext onDragEnd={dropHandler}>
          <Droppable
            droppableId="first-level-categories"
            type="draggable-accordion"
          >
            {(provided, snapshot) => {
              return (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  style={DraggableUtils.getListStyle(snapshot.isDraggingOver)}
                >
                  {data?.map((category, index) => {
                    return (
                      <Draggable
                        key={category.name}
                        draggableId={category.id.toString()}
                        index={index}
                        isDragDisabled={!canBeReordered}
                      >
                        {(itemProvided, itemSnapshot) => {
                          return (
                            <div
                              ref={itemProvided.innerRef}
                              {...itemProvided.draggableProps}
                              style={DraggableUtils.getItemStyle(
                                itemSnapshot.isDragging,
                                itemProvided.draggableProps.style
                              )}
                            >
                              <CategoryItem
                                key={category.name}
                                category={category}
                                isDragDisabled={!canBeReordered}
                                dragHandle={
                                  canBeReordered ? (
                                    <div {...itemProvided.dragHandleProps}>
                                      <DraggableHandlerIcon />
                                    </div>
                                  ) : null
                                }
                              />
                            </div>
                          );
                        }}
                      </Draggable>
                    );
                  })}
                  {provided.placeholder}
                </div>
              );
            }}
          </Droppable>
        </DragDropContext>
      </Accordion>
      <EditCategoryModalForm />
      <EditProductDrawer />
    </LoaderWrapper>
  );
};
