/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ITEPortal_Domain_Dto_ChangePasswordSettingsDto } from '../models/ITEPortal_Domain_Dto_ChangePasswordSettingsDto';
import type { ITEPortal_Domain_Dto_GlobalSettingsDto } from '../models/ITEPortal_Domain_Dto_GlobalSettingsDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class GlobalSettingsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns ITEPortal_Domain_Dto_GlobalSettingsDto Success
     * @throws ApiError
     */
    public getGlobalSettings(): CancelablePromise<ITEPortal_Domain_Dto_GlobalSettingsDto> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/global-settings',
            errors: {
                400: `Bad Request`,
            },
        });
    }

    /**
     * @returns any Success
     * @throws ApiError
     */
    public postGlobalSettingsEditFromEmail({
        fromEmail,
    }: {
        fromEmail?: string,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/global-settings/edit-fromEmail',
            query: {
                'fromEmail': fromEmail,
            },
        });
    }

    /**
     * @returns any Success
     * @throws ApiError
     */
    public postGlobalSettingsEditUsername({
        username,
    }: {
        username?: string,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/global-settings/edit-username',
            query: {
                'username': username,
            },
        });
    }

    /**
     * @returns any Success
     * @throws ApiError
     */
    public postGlobalSettingsEditPassword({
        requestBody,
    }: {
        requestBody?: ITEPortal_Domain_Dto_ChangePasswordSettingsDto,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/global-settings/edit-password',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @returns any Success
     * @throws ApiError
     */
    public postGlobalSettingsEditHost({
        host,
    }: {
        host?: string,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/global-settings/edit-host',
            query: {
                'host': host,
            },
        });
    }

    /**
     * @returns any Success
     * @throws ApiError
     */
    public postGlobalSettingsEditPort({
        port,
    }: {
        port?: number,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/global-settings/edit-port',
            query: {
                'port': port,
            },
        });
    }

    /**
     * @returns any Success
     * @throws ApiError
     */
    public postGlobalSettingsEditLogo({
        formData,
    }: {
        formData?: {
            logo?: Blob;
        },
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/global-settings/edit-logo',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @returns any Created
     * @throws ApiError
     */
    public postGlobalSettingsEditExhibitorManualFile({
        culture,
        formData,
    }: {
        culture: string,
        formData?: {
            manual?: Blob;
        },
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/global-settings/edit-exhibitor-manual-file/{culture}',
            path: {
                'culture': culture,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                400: `Bad Request`,
                500: `Server Error`,
            },
        });
    }

    /**
     * @returns any Created
     * @throws ApiError
     */
    public postGlobalSettingsEditExhibitorManualUrl({
        culture,
        url,
    }: {
        culture: string,
        url?: string,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/global-settings/edit-exhibitor-manual-url/{culture}',
            path: {
                'culture': culture,
            },
            query: {
                'url': url,
            },
            errors: {
                400: `Bad Request`,
                500: `Server Error`,
            },
        });
    }

    /**
     * @returns string Created
     * @throws ApiError
     */
    public getGlobalSettingsExhibitorManual(): CancelablePromise<string> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/global-settings/exhibitor-manual',
            errors: {
                400: `Bad Request`,
                500: `Server Error`,
            },
        });
    }

}
