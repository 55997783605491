import axios, { AxiosRequestConfig, ResponseType } from 'axios';
import { FetchMethods } from '@/utils/constants';
import { appendTrailingSlash, removeTrailingSlash } from '@/utils/url';

export function generateAPIURL(baseUrl: string, endpoint: string) {
  return appendTrailingSlash(baseUrl) + removeTrailingSlash(endpoint);
}

/**
 * perform a query with specified parameters
 * @param {string} endpoint URL for the query
 * @param {Object} options options wor the query
 */

export type XhrOptions = {
  method: string;
  data?: object;
  params?: object;
  responseType?: ResponseType;
} & Omit<AxiosRequestConfig, 'method'>;

type AdditionalHeadersType = {
  'Content-Type': string;
};

export default async function fetchApi<T = any>(
  endpoint: string,
  options: XhrOptions = { method: FetchMethods.Get },
  additionalHeaders?: AdditionalHeadersType
) {
  return axios<T>(
    generateAPIURL(process.env.PUBLIC_API_URL as string, endpoint),
    {
      withCredentials: true,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        ...additionalHeaders,
      },
      ...options,
    }
  );
}
