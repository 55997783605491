import { Product } from '@/model/IProduct';
import { DomainRecord } from '@/model/common';
import { CardInfoType } from '@/components/DevelopmentCard';
import { ITEPortal_Domain_Dto_PriceListItemSummary } from '../../../../api';

export function getSelectBuildingInfo(
  products: Product[],
  data: ITEPortal_Domain_Dto_PriceListItemSummary[]
): DomainRecord[] {
  return products.map((product) => {
    const cardInfo: CardInfoType = {};

    const selectBuildingData = data?.find(
      (productData) => productData?.productName === product?.productName
    );

    const buildingType = selectBuildingData?.buildingTypes?.find(
      (type) => type !== 0
    );

    cardInfo['prices'] = {
      current: product?.price,
    };
    cardInfo['imageDescription'] = selectBuildingData?.imageDescription;
    cardInfo['buildingLabel'] = selectBuildingData?.productDisplayName;
    cardInfo['buildingDescription'] = selectBuildingData?.description;
    cardInfo['note'] = selectBuildingData?.additionalNotes;
    cardInfo['images'] = selectBuildingData?.imageUrls;

    return {
      key: product.getProductId(),
      text:
        product.productDisplayName ||
        product.productName ||
        product.getProductId(),
      product: product,
      buildingType,
      cardInfo,
    } satisfies DomainRecord;
  });
}
