import { $order } from '@/store/effector/order';
import { useUnit } from 'effector-react';

export const $orderCompleteness = $order.map(({ items }) => {
  const itemsWithDependencies = items.filter(
    (it) => it.product.hasLinkedProducts
  );

  if (itemsWithDependencies.length === 0) {
    return [];
  }

  const allOrderItemsIds = items.map((item) => item.product.getProductId());

  return itemsWithDependencies
    .filter((productWithDeps) => {
      const depsIDS =
        productWithDeps.product.linkedProducts?.map((pr) =>
          pr.getProductId()
        ) || [];

      return !depsIDS.every((dependencyId) =>
        allOrderItemsIds.includes(dependencyId)
      );
    })
    .map((productWithDeps) => {
      return {
        productId: productWithDeps.product.getProductId(),
        outstandingDeps: productWithDeps.product.linkedProducts?.filter(
          (dep) => !allOrderItemsIds.includes(dep.getProductId())
        ),
      };
    });
});

export const useOrderCompleteness = () => {
  const incompleteItemsDueToDependency = useUnit($orderCompleteness);

  return {
    isOrderCompleted: incompleteItemsDueToDependency.length === 0,
    incompleteItemsDueToDependency,
  };
};
