import { useForm, FormProvider } from 'react-hook-form';
import { FormControl, Stack } from '@chakra-ui/react';
import Input from '@/components/Fields/Input';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { TextArea } from '@/components/TextArea/TextArea';
import { SelectComponent } from '@/components/MultiSelect/MultiSelect';
import { BUILDING_TYPES } from '@/constants/admin-pages';
import { useTranslation } from 'react-i18next';
import { QueryOptions } from '@/features/query/query-options';
import { useQuery } from '@tanstack/react-query';
import {
  ITEPortal_Domain_Dto_AddProductDto,
  ITEPortal_Domain_Dto_ProductDto,
} from '@/api';
import { useCommonProductEditLoader } from '@/pages/AdminPanel/ServiceDirectory/EditProductForm/useCommonProductEditLoader';
import { zodResolver } from '@hookform/resolvers/zod';
import { UploadFileDropzone } from '@/components/FileUpload/FileUpload';
import {
  CommonProductValidationSchema,
  CommonProductValidationSchemaType,
} from '@/pages/AdminPanel/ServiceDirectory/EditProductFieldsets/validation-schemas';
import {
  editProductApi,
  useProductForm,
} from '@/pages/AdminPanel/ServiceDirectory/EditProductFieldsets/useProductForm';
import { OptionType } from '@/model/common';

const FormControlWrapper = styled(FormControl)`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

interface AddBuildingTypeProductProps {
  editTableData: ITEPortal_Domain_Dto_ProductDto | null;
}

const mapValues = (
  values: CommonProductValidationSchemaType
): ITEPortal_Domain_Dto_AddProductDto => {
  const buildingTypes = values?.buildingTypes?.map(
    (type: OptionType) => type?.value
  );

  return {
    name: values.name,
    // @ts-expect-error to think how to map Enum to zod type
    buildingTypes: buildingTypes,
    linkedProductIds: Array.isArray(values.linkedProductIds)
      ? values.linkedProductIds.map((opt) => +opt.value)
      : [],
    categoryName: values.categoryName?.value as string,
    resources: [
      {
        key: 'name',
        value: values?.displayNameRu || '',
        cultureName: 'ru',
      },
      {
        key: 'name',
        value: values?.displayNameEn || '',
        cultureName: 'en',
      },
      {
        key: 'description',
        value: values?.descriptionRu || '',
        cultureName: 'ru',
      },
      {
        key: 'description',
        value: values?.descriptionEn || '',
        cultureName: 'en',
      },
    ],
  };
};

export const EditDefaultProductFieldset = ({
  editTableData,
}: AddBuildingTypeProductProps) => {
  const { t } = useTranslation();
  const { data: dependencyOptions } = useQuery(
    QueryOptions.loadPriceListItemsShort()
  );

  const { categoryOptions, categoryName } = useProductForm();

  console.log({ categoryName });

  const { data: editData } = useCommonProductEditLoader(
    editTableData,
    categoryName
  );

  console.log(editData);

  const methods = useForm<CommonProductValidationSchemaType>({
    mode: 'onBlur',
    resolver: zodResolver(CommonProductValidationSchema),
  });

  useEffect(() => {
    methods.reset({
      ...editData,
    });
  }, [editData]);

  useEffect(() => {
    editProductApi.setFormInstance({
      formInstance: methods,
      map: mapValues,
    });
  }, []);

  return (
    <FormProvider {...methods}>
      <form>
        <Stack spacing="5">
          <FormControlWrapper>
            <Input<CommonProductValidationSchemaType>
              label="ID"
              maxWidth="100%"
              name="name"
              disabled={Boolean(editTableData?.id)}
              isRequired
            />
            <Input<CommonProductValidationSchemaType>
              label={`${t('service-name')} (RU)`}
              maxWidth="100%"
              name="displayNameRu"
              isRequired
            />
            <Input<CommonProductValidationSchemaType>
              label={`${t('service-name')} (EN)`}
              maxWidth="100%"
              name="displayNameEn"
              isRequired
            />
            <SelectComponent<CommonProductValidationSchemaType>
              label={t('building-type')}
              options={BUILDING_TYPES}
              closeMenuOnSelect={false}
              name="buildingTypes"
              isMulti
              isRequired
            />
            <SelectComponent<CommonProductValidationSchemaType>
              label={t('category-name')}
              options={categoryOptions}
              isRequired
              name="categoryName"
            />
            <UploadFileDropzone<CommonProductValidationSchemaType>
              label={t('upload-image')}
              defaultValue={editData?.imageUrls?.join('\n')}
              multiple={true}
              name="imageFiles"
            />
            <TextArea<CommonProductValidationSchemaType>
              label={`${t('service-description')} (RU)`}
              name="descriptionRu"
            />
            <TextArea<CommonProductValidationSchemaType>
              label={`${t('service-description')} (EN)`}
              name="descriptionEn"
            />
            <SelectComponent<CommonProductValidationSchemaType>
              name="linkedProductIds"
              label={t('dependencies')}
              options={dependencyOptions}
              isMulti
            />
          </FormControlWrapper>
        </Stack>
      </form>
    </FormProvider>
  );
};
