import styled, { css } from 'styled-components';
import { ifProp } from 'styled-tools';

const Button = styled.button`
  opacity: 0.5;

  transition: all ease-in-out 0.15s;

  &:hover {
    opacity: 1;
  }
`;

export const CounterButtonDec = styled(Button)<{
  suffix: string | undefined;
}>`
  //padding-left: ${({ suffix }) => (suffix ? '0' : '20px')};

  ${ifProp(
    'disabled',
    css`
      cursor: not-allowed;
    `
  )}
`;

export const AmountWrapper = styled.div<{
  suffix: string | undefined;
  disabled: boolean | undefined;
}>`
  width: ${({ suffix }) => (suffix ? '170px' : '120px')};
  text-align: center;
  padding: 0 20px;
  ${ifProp(
    'suffix',
    css`
      padding: 0 10px;
    `
  )}
  border: 1px solid silver;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  justify-content: center;

  ${ifProp(
    'disabled',
    css`
      cursor: not-allowed;
      color: #999999;
      background-color: #f7f7f7;
    `
  )}

  :focus {
    text-align: center;
    border: none;
    outline: none;
  }
`;

export const CounterButtonInc = styled(Button)<{
  suffix: string | undefined;
}>`
  //padding-right: ${({ suffix }) => (suffix ? '0' : '20px')};

  ${ifProp(
    'disabled',
    css`
      cursor: not-allowed;
    `
  )}
`;
