import { useTable, Column } from 'react-table';
import {
  Table as ChakraTable,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Flex,
  Box,
  Text,
} from '@chakra-ui/react';
import i18n from '@/i18n';
import {
  DragDropContext,
  Draggable,
  Droppable,
  OnDragEndResponder,
} from 'react-beautiful-dnd';
import { DraggableUtils } from '@/utils/draggable-utils';

interface TableProps<T extends object = object> {
  columns: Array<Column<T>>;
  data: Array<T>;
  onDragEnd: OnDragEndResponder;
}

export const DraggableTable = <T extends object = object>({
  columns,
  data,
  onDragEnd,
}: TableProps<T>) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable<T>({
      columns,
      data,
      initialState: {
        hiddenColumns: columns.map((column: any) => {
          if (column.show === false) {
            return column.accessor || column.id;
          }
        }),
      },
    });

  const isEmpty = rows.length === 0;

  return (
    <>
      <DragDropContext onDragEnd={onDragEnd}>
        <ChakraTable
          {...getTableProps({
            style: {
              backgroundColor: 'white',
            },
          })}
        >
          <Thead>
            {headerGroups.map((headerGroup, index) => (
              <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column: any, headerIndex) => (
                  // Add the sorting props to control sorting. For this example
                  // we can add them into the header props
                  <Th
                    userSelect="none"
                    {...column.getHeaderProps({
                      width: column?.width,
                      ...(column.id === 'drag-handler'
                        ? { paddingLeft: 0, paddingRight: 0 }
                        : {}),
                      minWidth: column.minWidth,
                    })}
                    padding={3}
                    key={headerIndex}
                  >
                    <Flex alignItems="center">{column.render('Header')}</Flex>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Droppable droppableId="draggable-table" type="draggable-table">
            {(provided) => {
              return (
                <Tbody
                  {...getTableBodyProps({
                    style: {
                      backgroundColor: 'white',
                    },
                  })}
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                >
                  {rows.map((row, index) => {
                    prepareRow(row);

                    return (
                      <Draggable
                        key={row.id}
                        draggableId={row.id}
                        index={index}
                      >
                        {(providedRow, rowSnapshot) => {
                          return (
                            <Tr
                              ref={providedRow.innerRef}
                              {...providedRow.draggableProps}
                              {...row.getRowProps({
                                style: {
                                  backgroundColor: rowSnapshot.isDragging
                                    ? 'var(--chakra-colors-orange-100)'
                                    : 'white',
                                  ...DraggableUtils.getItemStyle(
                                    rowSnapshot.isDragging,
                                    providedRow.draggableProps.style
                                  ),
                                  display: rowSnapshot.isDragging
                                    ? 'table'
                                    : undefined,
                                  cursor: 'initial',
                                },
                              })}
                            >
                              {row.cells.map((cell, cellIndex) => {
                                return (
                                  <Td
                                    {...(cell.column.id === 'drag-handler'
                                      ? providedRow.dragHandleProps
                                      : {})}
                                    {...cell.getCellProps({
                                      style: {
                                        ...(cell.column.id === 'drag-handler'
                                          ? {
                                              paddingLeft: 0,
                                              paddingRight: 0,
                                              cursor: 'grab',
                                            }
                                          : {}),
                                      },
                                    })}
                                    key={cellIndex}
                                    verticalAlign="top"
                                    fontSize="0.9em"
                                    padding={3}
                                  >
                                    {cell.render('Cell')}
                                  </Td>
                                );
                              })}
                            </Tr>
                          );
                        }}
                      </Draggable>
                    );
                  })}
                  {provided.placeholder}
                </Tbody>
              );
            }}
          </Droppable>
        </ChakraTable>
      </DragDropContext>
      {isEmpty && (
        <Box m={4} mt={6} display="flex" justifyContent="center" w="100%">
          <Text color="gray.400">{i18n.t('no-data')}</Text>
        </Box>
      )}
    </>
  );
};
