import React, { useCallback } from 'react';
import { FileWithPath, useDropzone, Accept } from 'react-dropzone';
import { Box, FormControl, FormErrorMessage, Text } from '@chakra-ui/react';
import styled from 'styled-components';
import { FieldProps } from '@/components/Fields/interfaces';
import { useTranslation } from 'react-i18next';
import { FormLabel } from '@/components/FormLabel/FormLabel';
import { FieldValues, useController, useFormContext } from 'react-hook-form';

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5px;
  border-width: 2px;
  border-radius: 5px;
  border-color: silver;
  border-style: dashed;
  color: grey;
  outline: none;
  transition: border 0.24s ease-in-out;
  cursor: pointer;
`;

interface UploadFileDropzoneProps<T extends FieldValues = FieldValues>
  extends FieldProps<T> {
  multiple?: boolean;
  defaultValue?: string;
  accept?: Accept;
  defaultLabel?: React.ReactNode;
}

export const UploadFileDropzone = <T extends FieldValues = FieldValues>({
  label,
  multiple = false,
  // @ts-expect-error as default string doesn't found in generic T
  name = 'download-file',
  isRequired,
  defaultValue,
  accept,
  // accept = { 'image/*': [] },
  defaultLabel: defaultLabelFromProps,
}: UploadFileDropzoneProps<T>) => {
  const { t } = useTranslation();

  const { setValue } = useFormContext<T>();
  const onDrop = useCallback(
    (droppedFiles: File[]) => {
      setValue(
        name,
        // @ts-expect-error as generic T doesn't know that value is File
        droppedFiles,
        { shouldValidate: true }
      );
    },
    [setValue, name]
  );
  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
    accept,
    multiple: multiple,
    onDrop,
  });

  const { fieldState } = useController({
    name: name,
  });

  const filesLength = acceptedFiles.length;
  const defaultLabel = defaultLabelFromProps || t('upload-file-default-label');

  const files = acceptedFiles.map((file: FileWithPath) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  const uploadFileLabel = filesLength
    ? acceptedFiles.length > 1
      ? t('multiple-files-selected', { filesCount: acceptedFiles.length })
      : files
    : defaultValue || defaultLabel;

  return (
    <Box>
      <FormControl isRequired={isRequired} isInvalid={fieldState.invalid}>
        {label && <FormLabel>{label}</FormLabel>}
        <Box className="container">
          <Container {...getRootProps()}>
            <input {...getInputProps()} />
            <Text>{uploadFileLabel}</Text>
          </Container>
        </Box>
        {fieldState.error?.message && (
          <FormErrorMessage>{fieldState.error.message}</FormErrorMessage>
        )}
      </FormControl>
    </Box>
  );
};
