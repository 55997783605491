/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ITEPortal_Domain_Dto_PriceListDto } from '../models/ITEPortal_Domain_Dto_PriceListDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class PriceListService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns ITEPortal_Domain_Dto_PriceListDto Success
     * @throws ApiError
     */
    public getExhibitionsPriceLists({
        exhibitionId,
    }: {
        exhibitionId: number,
    }): CancelablePromise<Array<ITEPortal_Domain_Dto_PriceListDto>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/exhibitions/{exhibitionId}/price-lists',
            path: {
                'exhibitionId': exhibitionId,
            },
        });
    }

    /**
     * @returns any Success
     * @throws ApiError
     */
    public deleteExhibitionsPriceLists({
        priceListId,
        exhibitionId,
    }: {
        priceListId: number,
        exhibitionId: string,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/exhibitions/{exhibitionId}/price-lists/{priceListId}',
            path: {
                'priceListId': priceListId,
                'exhibitionId': exhibitionId,
            },
        });
    }

}
