import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import {
  Box,
  Button,
  ButtonGroup,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  FormControl,
  Stack,
} from '@chakra-ui/react';
import { SelectComponent } from '@/components/MultiSelect/MultiSelect';
import Input from '@/components/Fields/Input';
import { Button as OrderButton } from '@/components/Buttons/Button/Button';
import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { IdType, OptionType } from '@/model/common';
import { viewExhibitionRoute } from '@/features/Routing/protected/admin-exhibitions';
import { ITEPortal_Domain_Dto_AdminPriceListItem } from '@/api';
import * as vali from 'zod';
import i18n from '@/i18n';
import { zodResolver } from '@hookform/resolvers/zod';
import { TKey } from '@/i18n/types';
import { FEATURES, useFeature } from '@/features/feautres';
import { DeleteIcon } from '@chakra-ui/icons';
import { typedAxios } from '@/api/typed-axios';
import { useQuery } from '@tanstack/react-query';
import { QueryOptions } from '@/features/query/query-options';

const FormControlWrapper = styled(FormControl)`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const OptionSchema = vali.object(
  {
    value: vali.coerce.number(),
    label: vali.string(),
  },
  {
    required_error: i18n.t('required-field'),
  }
);

const ValidationSchema = vali.object({
  name: OptionSchema,
  price: vali.coerce.number(),
  linked: vali
    .array(
      vali.object({
        product: OptionSchema,
        amount: vali.coerce.number().min(1, {
          message: i18n.t('should-be-gte', { n: 1 }),
        }),
      })
    )
    .optional()
    .nullable(),
});

type ValidationSchemaType = vali.infer<typeof ValidationSchema>;

interface EditPriceListItemFormProps {
  openConfirm: boolean;
  closeModal: () => void;
  editTableData: ITEPortal_Domain_Dto_AdminPriceListItem | null;
  priceListId?: IdType;
  currency: OptionType;
  reloadPriceList: (currency: OptionType) => void;
  existingPriceListItems: Array<
    OptionType<ITEPortal_Domain_Dto_AdminPriceListItem>
  >;
}

export const EditPriceListItemForm = ({
  openConfirm,
  closeModal,
  editTableData,
  priceListId,
  currency,
  reloadPriceList,
  existingPriceListItems,
}: EditPriceListItemFormProps) => {
  const zeroPriceProductsEnabled = useFeature(
    FEATURES.LINKED_ZERO_PRICE_PRODUCTS
  );
  const [entityId, setEntityId] = useState<number | null>(null);
  const { exhibitionId } = viewExhibitionRoute.useParams();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { t } = useTranslation();

  const { data: priceListItemOptions } = useQuery(
    QueryOptions.loadPriceListItemsShort(priceListId)
  );

  const filteredPricelistItemOptions = useMemo(() => {
    const existingIds = existingPriceListItems.map((pi) => pi.value);

    return (
      priceListItemOptions?.filter(
        (pi) => existingIds.indexOf(pi.value) === -1
      ) || []
    );
  }, [priceListItemOptions, existingPriceListItems]);

  const formInstance = useForm<ValidationSchemaType>({
    // mode: 'onBlur',
    resolver: zodResolver(ValidationSchema),
  });
  const {
    fields: linkedFieldset,
    append,
    remove,
  } = useFieldArray({
    name: 'linked',
    control: formInstance.control,
  });

  useEffect(
    function setInitialDataToForm() {
      if (editTableData?.id) {
        setEntityId(editTableData.id);
      }

      formInstance.reset({
        ...editTableData,
        name: {
          value: editTableData?.productId || undefined,
          label: editTableData?.name || undefined,
        },
        // @ts-expect-error desc
        linked: editTableData?.includedPriceListItems?.map((linked) => {
          return {
            product: existingPriceListItems?.find(
              (it) => it.value === linked.includedPricelistItemId
            ),
            amount: linked.quantity,
          };
        }),
      });
    },
    [editTableData, existingPriceListItems]
  );

  const filteredLinkedProducts = useMemo(() => {
    return existingPriceListItems;
  }, [existingPriceListItems, formInstance.getValues('name')]);

  const submitHandler = async (
    values: ValidationSchemaType,
    addAnother: boolean
  ) => {
    if (priceListId) {
      try {
        setIsSubmitting(true);
        if (entityId) {
          await typedAxios.priceListItem.putExhibitionsPriceListsItems({
            priceListId: +priceListId,
            priceListItemId: entityId,
            exhibitionId: +exhibitionId,
            requestBody: {
              productId: values.name.value,
              price: values.price,
              includedPriceListItems: values.linked?.map((linked) => {
                return {
                  includedPriceListItemId: linked.product.value,
                  quantity: linked.amount,
                };
              }),
            },
          });
        } else {
          await typedAxios.priceListItem.postExhibitionsPriceListsItems({
            priceListId: +priceListId,
            exhibitionId: +exhibitionId,
            requestBody: {
              productId: values.name.value,
              price: values.price,
              includedPriceListItems: values.linked?.map((linked) => {
                return {
                  includedPriceListItemId: linked.product.value,
                  quantity: linked.amount,
                };
              }),
            },
          });
        }

        if (!addAnother) {
          closeModal();
        } else {
          setEntityId(null);
          formInstance.reset({});
        }

        reloadPriceList(currency);
      } catch (error) {
        console.log(error);
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  const headerMessage: TKey = editTableData
    ? 'edit-pricelist-item'
    : 'add-pricelist-item';

  return (
    <Drawer isOpen={openConfirm} onClose={closeModal} size="lg">
      <DrawerOverlay />
      <DrawerContent>
        <DrawerHeader>{t(headerMessage)}</DrawerHeader>
        <DrawerCloseButton />
        <DrawerBody>
          <FormProvider {...formInstance}>
            <form>
              <Stack spacing="5">
                <FormControlWrapper>
                  <SelectComponent<ValidationSchemaType>
                    label={t('service-id')}
                    options={filteredPricelistItemOptions}
                    isRequired
                    name="name"
                  />
                  <Input<ValidationSchemaType>
                    label={t('price')}
                    maxWidth="100%"
                    name="price"
                    isRequired
                  />
                  {zeroPriceProductsEnabled && (
                    <>
                      {t('linked-services')}
                      {linkedFieldset.map((field, index) => (
                        <Flex
                          key={field.id}
                          flexDirection="column"
                          width="100%"
                        >
                          <Flex flexDirection="row" gap={4}>
                            <Box flexBasis="50%">
                              <SelectComponent<ValidationSchemaType>
                                label={t('service')}
                                options={filteredLinkedProducts}
                                isRequired
                                name={`linked.${index}.product`}
                              />
                            </Box>
                            <Box flexBasis="30%">
                              <Input<ValidationSchemaType>
                                label={t('amount')}
                                isRequired
                                name={`linked.${index}.amount`}
                                maxWidth="unset"
                              />
                            </Box>
                            <Box flexBasis="20%" pt="37px">
                              <DeleteIcon onClick={() => remove(index)} />
                            </Box>
                          </Flex>
                        </Flex>
                      ))}
                      <Button
                        variant="ghost"
                        colorScheme="red"
                        onClick={() =>
                          append(
                            // @ts-expect-error desc
                            {}
                          )
                        }
                      >
                        {t('add-service')}
                      </Button>
                    </>
                  )}
                </FormControlWrapper>
              </Stack>
            </form>
          </FormProvider>
        </DrawerBody>
        <DrawerFooter justifyContent="center">
          <ButtonGroup gap="1" justifyContent="center">
            <OrderButton
              colorScheme="red"
              onClick={formInstance.handleSubmit((values) =>
                submitHandler(values, false)
              )}
              disabled={isSubmitting}
            >
              {t('save')}
            </OrderButton>
            {/*<OrderButton*/}
            {/*  colorScheme="red"*/}
            {/*  onClick={formInstance.handleSubmit((values) =>*/}
            {/*    submitHandler(values, true)*/}
            {/*  )}*/}
            {/*  disabled={isSubmitting}*/}
            {/*>*/}
            {/*  {t('save-and-add-another')}*/}
            {/*</OrderButton>*/}
            <OrderButton colorScheme="secondary" onClick={closeModal}>
              {t('cancel')}
            </OrderButton>
          </ButtonGroup>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};
