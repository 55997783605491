import { IdType, AdminRoleType, SelectItem, RoleType } from './common';
import { UserRoleNameType, UserRoleType } from '@/types/open-api/domain-types';

export interface IUser {
  fullName: string;
  name?: string;
  id?: IdType;
  email?: string;
  image?: string;
  jobTitle?: SelectItem;
  role?: AdminRoleType | RoleType;
  subdivision?: SelectItem;
  get firstName(): string;
  get isSuperAdmin(): boolean;
  get isAdmin(): boolean;
  get isSupportManager(): boolean;
  get isTechnicalManager(): boolean;
  get isModerator(): boolean;
  get isExhibitor(): boolean;
}

type UserInstanceOptions = {
  fullName: string;
  id?: IdType;
  email?: string;
  image?: string;
  jobTitle?: SelectItem;
  role?: UserRoleType;
  subdivision?: SelectItem;
};

export class User implements IUser {
  private readonly _fullName: string;
  private readonly _id?: IdType;
  private readonly _email?: string;
  private readonly _image?: string;
  private readonly _jobTitle?: SelectItem;
  private readonly _role?: UserRoleType;
  private readonly _subdivision?: SelectItem;

  static Role: Record<UserRoleNameType, UserRoleType> = {
    SuperAdmin: 1,
    Admin: 2,
    SupportManager: 3,
    TechnicalManager: 4,
    Moderator: 5,
    Exhibitor: 6,
    Agent: 7,
  };

  constructor(options: UserInstanceOptions) {
    const { fullName, id, email, image, jobTitle, role, subdivision } = options;

    this._fullName = fullName;
    this._id = id;
    this._email = email;
    this._image = image;
    this._jobTitle = jobTitle;
    this._role = role;
    this._subdivision = subdivision;
  }

  get id() {
    return this._id;
  }

  get email() {
    return this._email;
  }

  get image() {
    return this._image;
  }

  get fullName() {
    return this._fullName;
  }

  get jobTitle() {
    return this._jobTitle;
  }

  get role() {
    return this._role;
  }

  get subdivision() {
    return this._subdivision;
  }

  get firstName(): string {
    return this._fullName.split(' ')[0];
  }

  get isSuperAdmin() {
    return this._role === User.Role.SuperAdmin;
  }

  get isAdmin() {
    return this._role === User.Role.Admin;
  }

  get isSupportManager() {
    return this._role === User.Role.SupportManager;
  }

  get isTechnicalManager() {
    return this._role === User.Role.TechnicalManager;
  }

  get isModerator() {
    return this._role === User.Role.Moderator;
  }

  get isExhibitor() {
    return this._role === User.Role.Exhibitor;
  }

  get isAgent() {
    return this._role === User.Role.Agent;
  }

  toJSON() {
    return {
      id: this.id,
      fullName: this.fullName,
      email: this.email,
      image: this.image,
      jobTitle: this.jobTitle,
      role: this.role,
      subdivision: this.subdivision,
    };
  }
}
