import { Button, ButtonProps } from '@chakra-ui/react';
import { useState } from 'react';

export const AsyncButton = (props: ButtonProps) => {
  const { onClick, ...rest } = props;
  const [isLoading, toggleLoading] = useState(false);

  const handleAsyncClick = async (e: any) => {
    try {
      const promise = onClick?.(e);

      if (
        promise &&
        // @ts-expect-error expecting that void could not be promise
        promise instanceof Promise
      ) {
        toggleLoading(true);
        await promise;
      }
    } finally {
      toggleLoading(false);
    }
  };

  return (
    <Button
      {...rest}
      onClick={handleAsyncClick}
      isDisabled={isLoading}
      isLoading={isLoading}
    />
  );
};
