import { Content, ContentInner } from '@/components/Content';
import { PageTitleContainer } from '@/pages/Exhibition/Exhibition.styled';
import Layout from '@/layout/Default';
import { standProjectRoute } from '@/features/Routing/protected/exhibitions-by-role';
import { Box, Card, CardBody, Flex, Heading } from '@chakra-ui/react';
import { StandProjectImage } from './StandProjectImage';
import { StandProjectDescription } from '@/pages/StandProject/StandProjectDescription';
import { useTranslation } from 'react-i18next';
import { useExhibition } from '@/store/effector/exhibition';
import { Link } from '@tanstack/react-router';
import { ChevronLeftIcon } from '@chakra-ui/icons';

export const StandProject = () => {
  const { exhibitionId } = standProjectRoute.useParams();
  const { project } = standProjectRoute.useLoaderData();
  const { t } = useTranslation();
  const { exhibitionName } = useExhibition();

  if (!project) {
    return null;
  }

  return (
    <Layout>
      <Content>
        <ContentInner>
          <Box mt={8} ml={8}>
            <Link to={`/exhibitions/${exhibitionId}`}>
              <ChevronLeftIcon mr={2} />
              {t('back-to-my-stands')}
            </Link>
          </Box>
          <PageTitleContainer centered="true" pt={0} mt={0} mb={8}>
            {exhibitionName}
          </PageTitleContainer>
          <Flex justifyContent="center">
            <Card maxW={['90%', '80%']} maxH="80vh" position="relative">
              <CardBody pl={12} pr={12} height="100%">
                <Heading mb={6} as="h2" size="lg">
                  {t('stand-project')}
                </Heading>
                <Flex
                  gap={project.projectImageUrl ? 12 : 0}
                  height="calc(100% - 70px)"
                >
                  <div style={{ flexBasis: '50%' }}>
                    {project.projectImageUrl && (
                      <StandProjectImage
                        projectImageUrl={project.projectImageUrl}
                      />
                    )}
                  </div>
                  <div style={{ flexBasis: '50%' }}>
                    {project && <StandProjectDescription project={project} />}
                  </div>
                </Flex>
              </CardBody>
            </Card>
          </Flex>
        </ContentInner>
      </Content>
    </Layout>
  );
};
