import { Button, Flex } from '@chakra-ui/react';
import { ProjectImage } from '@/pages/StandProject/StandProjectImage/StandProjectImage.styled';
import Lightbox from 'yet-another-react-lightbox';
import Download from 'yet-another-react-lightbox/plugins/download';
import { useState } from 'react';
import { FileUtils } from '@/utils/file-utils';
import { useTranslation } from 'react-i18next';
import { ITEPortal_Domain_Dto_Stand_GetStandProjectDto } from '@/api';

type StandProjectImagePropsType = Pick<
  ITEPortal_Domain_Dto_Stand_GetStandProjectDto,
  'projectImageUrl'
>;

export const StandProjectImage = (props: StandProjectImagePropsType) => {
  const { projectImageUrl } = props;
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  if (!projectImageUrl) {
    return null;
  }

  const fileExtension = projectImageUrl.split('.').at(-1)?.toUpperCase();
  const fileName = projectImageUrl.split('/').at(-1);

  return (
    <Flex flexDirection="column" maxW="100%" gap={6}>
      {props.projectImageUrl && (
        <ProjectImage
          src={props.projectImageUrl}
          onClick={() => setOpen(true)}
        />
      )}
      <Button
        width="fit-content"
        size="sm"
        onClick={() => {
          if (fileName) {
            FileUtils.saveUrlAsFile(projectImageUrl, fileName);
          }
        }}
      >
        {t('download-file')}, {fileExtension}
      </Button>
      <Lightbox
        open={open}
        close={() => setOpen(false)}
        index={0}
        slides={[
          {
            src: projectImageUrl,
          },
        ]}
        animation={{ fade: 0 }}
        controller={{ closeOnPullDown: true, closeOnBackdropClick: true }}
        styles={{ root: { '--yarl__color_backdrop': 'rgba(0, 0, 0, .7)' } }}
        plugins={[Download]}
        download={{
          download: ({ slide }) => {
            FileUtils.saveUrlToBlob(slide.src);
          },
        }}
      />
    </Flex>
  );
};
