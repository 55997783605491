import { useCallback, useEffect, useState } from 'react';
import fetchApi, { XhrOptions } from '../../utils/fetchAPI';
import { FetchMethods } from '../../utils/constants';
import { useNavigate, useSearch } from '@tanstack/react-router';
import { PaginationInfoType } from '../../model/common';
import { AxiosHeaders } from 'axios';

export type UseTableLoaderOptionsType<T = object> = {
  resource: string;
  additionalPrams?: Record<string, unknown>;
  map?: (data: T[]) => unknown[];
  onError?: (error: any) => void;
  ignorePagination?: boolean;
  xhrOptions?: Partial<XhrOptions>;
};

const DEFAULT_PAGE_INDEX = 1;
const DEFAULT_PAGE_SIZE = 10;

type QueryType = {
  s?: number;
  p?: number;
  search?: string;
};

export const useTableLoader = <T = object>({
  resource,
  onError,
  map,
  ignorePagination,
  additionalPrams = {},
  xhrOptions,
}: UseTableLoaderOptionsType<T>) => {
  const search = useSearch<any, unknown, false, QueryType>({ strict: false });
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<T[]>([]);
  const navigate = useNavigate();
  const [paginationInfo, setPaginationInfo] = useState<PaginationInfoType>({
    TotalCount: 0,
    TotalPages: 1,
    PageSize: DEFAULT_PAGE_SIZE,
    CurrentPage: DEFAULT_PAGE_INDEX,
  });

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const { data: responseData, headers } = await fetchApi(resource, {
        method: FetchMethods.Get,
        params: {
          ...(ignorePagination === true
            ? {}
            : {
                pageNumber: search?.p || DEFAULT_PAGE_INDEX,
                pageSize: search?.s || DEFAULT_PAGE_SIZE,
              }),

          search: search?.search || undefined,
          ...additionalPrams,
        },
        ...xhrOptions,
      });

      let responseArray = responseData;

      if (
        Object.hasOwn(responseData, 'data') &&
        Array.isArray(responseData.data)
      ) {
        responseArray = responseData.data;
      }

      try {
        if (headers instanceof AxiosHeaders) {
          const paginationInfoHeader = headers?.get?.('pagination', (value) => {
            if (typeof value === 'string') {
              return JSON.parse(value);
            }

            return value;
          });

          if (
            paginationInfoHeader &&
            typeof paginationInfoHeader === 'object' &&
            // if header contains CurrentPage let's assume that this is pagination object
            Object.hasOwn(paginationInfoHeader, 'CurrentPage')
          ) {
            setPaginationInfo(
              paginationInfoHeader as unknown as PaginationInfoType
            );
          }
        }
      } catch {
        setPaginationInfo({
          CurrentPage: search?.p || DEFAULT_PAGE_INDEX,
          PageSize: search?.s || DEFAULT_PAGE_SIZE,
          TotalPages: search?.p || DEFAULT_PAGE_INDEX,
          TotalCount: responseArray.length,
        });
      }

      setData(typeof map === 'function' ? map(responseArray) : responseArray);
    } catch (error) {
      console.log(error);

      if (typeof onError === 'function') {
        onError(error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [search]);

  const refresh = () => fetchData();

  const onPageSizeChange = useCallback(
    (size: number) => {
      navigate({
        to: './',
        search: {
          ...search,
          s: size,
          p: DEFAULT_PAGE_INDEX,
        },
      });
    },
    [search]
  );
  const onPageChange = useCallback(
    (page: number) => {
      navigate({
        to: './',
        search: {
          ...search,
          p: page,
        },
      });
    },
    [search]
  );

  return {
    data,
    isLoading,
    onPageSizeChange,
    onPageChange,
    currentPage: search?.p || DEFAULT_PAGE_INDEX,
    currentSize: search?.s || DEFAULT_PAGE_SIZE,
    refresh,
    paginationInfo,
  };
};
