import { t } from 'i18next';
import { ORDER_STATUSES } from '@/constants/order';
import { User } from '@/model/IUser';

export const ROLES = [
  { value: User.Role.SuperAdmin, label: t('super-admin') },
  { value: User.Role.Admin, label: t('admin') },
  { value: User.Role.SupportManager, label: t('support') },
  { value: User.Role.TechnicalManager, label: t('technical-manager') },
  { value: User.Role.Moderator, label: t('moderator') },
];

export const BUILDING_TYPES = [
  { value: 0, label: 'All' },
  { value: 1, label: 'SpaceOnly' },
  { value: 2, label: 'Standard' },
  { value: 3, label: 'StandardPlus' },
  { value: 4, label: 'PremiumFerrara' },
  { value: 5, label: 'PremiumVerona' },
  { value: 6, label: 'PremiumRavenna' },
  { value: 7, label: 'PremiumKremona' },
  { value: 8, label: 'PremiumAncona' },
  { value: 9, label: 'PremiumFabriano' },
  { value: 10, label: 'PremiumLivorno' },
  { value: 11, label: 'Optima' },
];

export const STATUS_CONFIG = [
  { value: 0, label: 'Undefined' },
  { value: ORDER_STATUSES.Draft, label: t('order-status-draft') },
  { value: ORDER_STATUSES.Submitted, label: t('order-status-submitted') },
  { value: ORDER_STATUSES.Processing, label: t('order-status-processing') },
  { value: ORDER_STATUSES.Invoiced, label: t('order-status-invoiced') },
  { value: ORDER_STATUSES.Paid, label: t('order-status-paid') },
  { value: ORDER_STATUSES.Cancelled, label: t('order-status-cancelled') },
];
