import i18n from '@/i18n';
import { Column } from 'react-table';
import { ITEPortal_Domain_Dto_OrderItemDto } from '@/api';
import styled from 'styled-components';
import { Box } from '@chakra-ui/react';

const TranscriptTableRow = styled(Box)`
  font-size: 12px;
`;

const TranscriptTableHeader = styled(Box)`
  font-size: 10px;
  font-weight: 600;
`;

export const orderTranscriptTableColumns = [
  {
    Header: (
      <TranscriptTableHeader>
        {i18n.t('product-type-common')}
      </TranscriptTableHeader>
    ),
    accessor: 'productName',
    width: '60%',
    Cell: ({ value }) => {
      return <TranscriptTableRow>{value}</TranscriptTableRow>;
    },
  },
  {
    Header: <TranscriptTableHeader>{i18n.t('language')}</TranscriptTableHeader>,
    accessor: 'language',
    Cell: ({ value }) => {
      return <TranscriptTableRow>{value}</TranscriptTableRow>;
    },
  },
  {
    Header: (
      <TranscriptTableHeader>
        {i18n.t('peopleMountShort')}
      </TranscriptTableHeader>
    ),
    accessor: 'peopleAmount',
    Cell: ({ value }) => {
      return (
        <TranscriptTableRow>
          {value && value > 0 ? value : ''}
        </TranscriptTableRow>
      );
    },
  },
  {
    Header: (
      <TranscriptTableHeader>{i18n.t('daysMountShort')}</TranscriptTableHeader>
    ),
    accessor: 'dayAmount',
    Cell: ({ value }) => {
      return (
        <TranscriptTableRow>
          {value && value > 0 ? value : ''}
        </TranscriptTableRow>
      );
    },
  },
  {
    Header: <TranscriptTableHeader>{i18n.t('amount')}</TranscriptTableHeader>,
    accessor: 'amount',
    Cell: ({ value }) => {
      return <TranscriptTableRow>{value}</TranscriptTableRow>;
    },
  },
] satisfies Array<Column<ITEPortal_Domain_Dto_OrderItemDto>>;
