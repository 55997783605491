import { Flex } from '@chakra-ui/react';
import { Content, ContentInner } from '@/components/Content';
import styled from 'styled-components';
import { CenteredPageTitle } from '@/components/Typography/PageTitle';
import { useTranslation } from 'react-i18next';
import Layout from '@/layout/Default';
import { Loader } from '@/components/Loader/Loader';

import { useExhibitionLoader } from './hooks/use-exhibition-loader';
import { ExhibitionsTabs } from './ExhibitionsTabs';

const ExhibitionsWrapper = styled(Flex)`
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding-bottom: 10px;
`;

export const Profile = (): JSX.Element => {
  const { t } = useTranslation();
  const { exhibitions, isLoading } = useExhibitionLoader();

  return (
    <Layout>
      <Content>
        <ContentInner>
          <ExhibitionsWrapper>
            <CenteredPageTitle>{t('myExhibitions')}</CenteredPageTitle>

            {isLoading ? (
              <Loader />
            ) : (
              <ExhibitionsTabs exhibitions={exhibitions} />
            )}
          </ExhibitionsWrapper>
        </ContentInner>
      </Content>
    </Layout>
  );
};
