import Lightbox, { SlideImage } from 'yet-another-react-lightbox';
import 'yet-another-react-lightbox/styles.css';
import 'yet-another-react-lightbox/plugins/thumbnails.css';

import { useMemo, useState } from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { Thumbnail } from '@/components/ImageGalleries/CommonProductGallery.styled';
import { useFeatureValue } from '@/features/feautres';

interface BuildingStandardBodyProps {
  images: string[];
}

export const CommonProductGallery = ({ images }: BuildingStandardBodyProps) => {
  const [open, setOpen] = useState(false);
  const [index, setIndex] = useState(0);
  const thumbnailsCount = useFeatureValue(
    'PRODUCT_GALLERY_PREVIEW_IMAGES_COUNT',
    3
  );

  const slides = useMemo<Array<SlideImage>>(() => {
    return images.map((image) => {
      return {
        id: image,
        src: image,
      };
    });
  }, [images]);

  const visibleSlides = slides.slice(0, thumbnailsCount);
  const hiddenSlidesCount = slides.length - thumbnailsCount;

  const toggleOpen = (state: boolean) => () => setOpen(state);

  const updateIndex = ({ index: current }: { index: number }) =>
    setIndex(current);

  if (!visibleSlides.length) {
    return null;
  }

  return (
    <>
      <Flex flexDirection="row" alignItems="center">
        {visibleSlides.map((slide, i) => (
          <Thumbnail
            key={slide.src}
            src={slide.src}
            onClick={() => {
              updateIndex({ index: i });
              toggleOpen(true)();
            }}
          />
        ))}
        {hiddenSlidesCount > 0 && (
          <Text color="gray.400" whiteSpace="nowrap">
            + {hiddenSlidesCount}
          </Text>
        )}
      </Flex>

      <Lightbox
        open={open}
        close={toggleOpen(false)}
        index={index}
        slides={slides}
        on={{ view: updateIndex }}
        animation={{ fade: 0 }}
        controller={{ closeOnPullDown: true, closeOnBackdropClick: true }}
        styles={{ root: { '--yarl__color_backdrop': 'rgba(0, 0, 0, .7)' } }}
      />
    </>
  );
};
