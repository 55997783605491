import { BUILDING_TYPES } from '@/constants/admin-pages';
import { useQuery } from '@tanstack/react-query';
import { ITEPortal_Domain_Dto_ProductDto } from '@/api';
import { typedAxios } from '@/api/typed-axios';
import { CommonProductValidationSchemaType } from '@/pages/AdminPanel/ServiceDirectory/EditProductForm/types';
import { OptionType } from '@/model/common';
import { useProductForm } from '@/pages/AdminPanel/ServiceDirectory/EditProductFieldsets/useProductForm';

export const useCommonProductEditLoader = (
  product: ITEPortal_Domain_Dto_ProductDto | null,
  categoryName?: string | null
) => {
  const { categoryOptions } = useProductForm();

  return useQuery<CommonProductValidationSchemaType>({
    queryKey: ['edit-product', product?.id],
    queryFn: async () => {
      if (!product?.name) {
        // @ts-expect-error desc
        return {
          categoryName: categoryOptions.find((ct) => categoryName === ct.value),
        } as CommonProductValidationSchemaType;
      }

      const resources = await typedAxios.product.getProductsResources({
        name: product?.name,
      });

      const buildingTypeValues = product?.buildingTypes?.map((type: number) => {
        const buildingTypeLabel = BUILDING_TYPES.find(
          (dictionaryType) => dictionaryType.value === type
        )?.label;

        return {
          value: type,
          label: buildingTypeLabel,
        } satisfies OptionType;
      });

      const editDataWithResources: CommonProductValidationSchemaType = {
        name: product.name,
        displayNameRu: resources?.ru?.name,
        displayNameEn: resources?.en?.name,
        // @ts-expect-error to think how to map Enum to zod type
        buildingTypes: buildingTypeValues || [],
        descriptionRu: resources?.ru?.description,
        descriptionEn: resources?.en?.description,
        imageUrls: product.imageUrls || [],
        // @ts-expect-error desc
        categoryName: categoryOptions.find((ct) =>
          categoryName
            ? categoryName === ct.value
            : ct.value === product.categoryName
        ),
        // categoryName: {
        //   value: product.categoryName || '',
        //   label: product.categoryName || '',
        // },
        linkedProductIds: Array.isArray(product.linkedProductIds)
          ? product.linkedProductIds.map((id) => ({ value: id, label: null }))
          : [],
        // dependencies: [
        //   {
        //     value: 474,
        //     // label: 474,
        //   },
        // ],
      };

      return editDataWithResources;
    },
  });
};
