import { BitrixPassportTableFields } from '@/pages/AdminPanel/Settings/BitrixPassport/BitrixIntegrationPassportTab';

export const BitrixTableData = [
  {
    name: 'getBitrixEventMasters',
    description: 'Event masters list',
    method: 'GET',
    url: '/bitrix/event-masters',
    queryParams: '',
    requestBody: '',
    response: `{
  id: number, 
  title?: string | null
}[]`,
  },
  {
    name: 'getBitrixEventEditions',
    description: 'Master exhibitions list',
    method: 'GET',
    url: '/bitrix/event-editions/{eventMasterId}',
    queryParams: `{
  eventMasterId?: number
}`,
    requestBody: '',
    response: `{
  id: number, 
  title?: string | null
}[]`,
  },
  {
    name: 'postBitrixExhibition',
    description: 'import exhibition from Bitrix',
    method: 'POST',
    url: '/bitrix/exhibition/{eventEditionId}',
    queryParams: `{
  eventEditionId: number
}`,
    requestBody: 'number[]',
    response: '',
  },
  {
    name: 'postBitrixExhibitionExhibitors',
    description: 'Import exhibitors from Bitrix',
    method: 'POST',
    url: '/bitrix/exhibition/{exhibitionId}/exhibitors',
    queryParams: `{
  exhibitionId: number
}`,
    requestBody: '',
    response: '',
  },
  {
    name: 'postBitrixPriceList',
    description: 'Import price lists from Bitrix',
    method: 'POST',
    url: '/bitrix/price-list/{exhibitionId}',
    queryParams: `{
  exhibitionId: number
}`,
    requestBody: '',
    response: '',
  },
  {
    name: 'postBitrixSalesOffices',
    description: 'Import sales offices from Bitrix',
    method: 'POST',
    url: '/bitrix/sales-offices',
    queryParams: '',
    requestBody: '',
    response: '',
  },
  {
    name: 'postBitrixOrders',
    description: 'Update order in Bitrix',
    method: 'POST',
    url: '/bitrix/orders/{orderId}',
    queryParams: `{
  orderId: number
}`,
    requestBody: `{
bankAccountId?: number;
iteCompanyId?: number;
}`,
    response: '',
  },
  {
    name: 'postBitrixUpdateContactId',
    description: 'Update contact ID',
    method: 'POST',
    url: '/bitrix/update-contact-id/{userId}/{contactId}',
    queryParams: `{
  userId: number,
  contactId: number,
}`,
    requestBody: '',
    response: '',
  },
  {
    name: 'getCompanies',
    description: 'Get companies list',
    method: 'GET',
    url: '/companies',
    queryParams: '',
    requestBody: '',
    response: `{
  bitrixId?: number;
  title?: string | null;
  email?: string | null;
  id: number;
}[]`,
  },
  {
    name: 'getCompaniesBankAccounts',
    description: 'Get companies bank accounts',
    method: 'GET',
    url: '/companies/{companyId}/bank-accounts',
    queryParams: `{
  companyId: number,
}`,
    requestBody: '',
    response: `{
  bitrixId?: number;
  title?: string;
  bitrixCompanyId?: number;
  bankName?: string;
  bic?: string;
  checkingAccount?: string;
  correspondentAccount?: string;
  accountCurrency?: string;
  bankAddressRu?: string;
  swift?: string;
  bankAddressEng?: string;
  iban?: string;
  id: number;
}[]`,
  },
  {
    name: 'getStandsSharers',
    description: 'Get stands sharers',
    method: 'GET',
    url: '/stands/{standId}/sharers',
    queryParams: `{
  standId: number,
}`,
    requestBody: '',
    response: `{
  companyId?: number;
  area?: number;
  standNumber?: string;
  standConfiguration?: ITEPortal_Contracts_Enums_StandConfiguration;
}[]`,
  },
  {
    name: 'getStandsProducts',
    description: 'Get stands products',
    method: 'GET',
    url: '/stands/{standId}/products',
    queryParams: `{
  standId: number,
}`,
    requestBody: '',
    response: `{
  productId?: number;
  standId?: number;
  discount?: number;
  currency?: string;
  price?: number;
  quantity?: number;
  vat?: number;
  vatIncluded?: boolean;
  displayName?: string;
}[]`,
  },
] satisfies BitrixPassportTableFields[];
