import { typedAxios } from '@/api/typed-axios';
import { ITEPortal_Domain_Dto_OrderDto } from '@/api';
import { useState } from 'react';

export const useGetOrderItems = () => {
  const [orderLoading, setOrderLoading] = useState<boolean>(false);

  const getOrderItems = async (
    orderId: ITEPortal_Domain_Dto_OrderDto['orderId']
  ) => {
    if (!orderId) {
      return;
    }

    setOrderLoading(true);

    try {
      const orders = await typedAxios.order.getOrders1({
        orderId: +orderId,
      });

      const { products } = orders;

      return products;
    } finally {
      setOrderLoading(false);
    }
  };

  return { getOrderItems, orderLoading };
};
