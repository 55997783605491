import { FormProvider, UseFormReturn } from 'react-hook-form';
import React, { useEffect, useState } from 'react';
import { Stack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { SelectComponent } from '@/components/MultiSelect/MultiSelect';
import { OptionType } from '@/model/common';
import { typedAxios } from '@/api/typed-axios';
import { useQuery } from '@tanstack/react-query';
import { QueryOptions } from '@/features/query/query-options';
import { ITEPortal_Domain_Dto_Bitrix_EventEdition_EventEditionDropdownDto } from '@/api/models/ITEPortal_Domain_Dto_Bitrix_EventEdition_EventEditionDropdownDto';
import { ExportToBitrixFormValidationSchemaType } from '@/pages/AdminPanel/PlacedOrders/hooks/use-export-order';

export const ExportToBitrixForm = ({
  instance,
}: {
  instance: UseFormReturn<ExportToBitrixFormValidationSchemaType>;
}) => {
  const { t } = useTranslation();
  const [companyId, setCompanyId] = useState<number>();
  const [bankAccounts, setBankAccounts] =
    useState<
      Array<
        OptionType<ITEPortal_Domain_Dto_Bitrix_EventEdition_EventEditionDropdownDto>
      >
    >();

  const { data: companyOptions = [] } = useQuery(
    QueryOptions.loadCompanyOptions()
  );

  useEffect(() => {
    const fetchBankAccounts = async () => {
      if (companyId) {
        const bankAccountsData =
          await typedAxios.iteCompany.getCompaniesBankAccounts({
            companyId,
          });

        const bankAccountOptions = bankAccountsData.map((ba) => ({
          value: ba.id,
          label: ba.title,
          meta: ba,
        })) satisfies Array<
          OptionType<ITEPortal_Domain_Dto_Bitrix_EventEdition_EventEditionDropdownDto>
        >;

        setBankAccounts(bankAccountOptions);
      }
    };

    if (companyId) {
      instance.resetField('bankAccount');
      fetchBankAccounts();
    }
  }, [companyId]);

  return (
    <FormProvider {...instance}>
      <Stack spacing={4}>
        <SelectComponent<ExportToBitrixFormValidationSchemaType>
          label={t('company')}
          options={companyOptions}
          isRequired
          name="company"
          onChange={({ value }) => setCompanyId(value)}
        />
        {companyId && (
          <SelectComponent<ExportToBitrixFormValidationSchemaType>
            label={t('bank-account')}
            options={bankAccounts}
            isRequired
            name="bankAccount"
          />
        )}
      </Stack>
    </FormProvider>
  );
};
