import { Badge, BadgeProps } from '@chakra-ui/react';
import { ITEPortal_Domain_Dto_AdminOrderSummary } from '@/api';
import { ORDER_STATUSES } from '@/constants/order';
import { isNil } from '@/utils/is-nil';
import i18n from '@/i18n';

type BadgeConfigType = {
  [key: string]: { color: BadgeProps['colorScheme']; label: string };
};

const badgeConfig: BadgeConfigType = {
  [ORDER_STATUSES.Draft]: {
    color: 'gray',
    label: i18n.t('order-status-draft'),
  },
  [ORDER_STATUSES.Submitted]: {
    color: 'orange',
    label: i18n.t('order-status-submitted'),
  },
  [ORDER_STATUSES.Processing]: {
    color: 'yellow',
    label: i18n.t('order-status-processing'),
  },
  [ORDER_STATUSES.Invoiced]: {
    color: 'blue',
    label: i18n.t('order-status-invoiced'),
  },
  [ORDER_STATUSES.Paid]: {
    color: 'green',
    label: i18n.t('order-status-paid'),
  },
  [ORDER_STATUSES.Cancelled]: {
    color: 'red',
    label: i18n.t('order-status-cancelled'),
  },
};

export const OrderBadge = ({
  status,
}: {
  status: ITEPortal_Domain_Dto_AdminOrderSummary['orderStatus'];
}) => {
  if (isNil(status)) {
    return null;
  }

  const badgeType = badgeConfig[status];

  return <Badge colorScheme={badgeType?.color}>{badgeType?.label}</Badge>;
};
