import { ITEPortal_Domain_Dto_AdminPriceListItem } from '@/api';
import React, { Fragment, useMemo } from 'react';
import { Badge, Flex, Tooltip, Text, TooltipProps } from '@chakra-ui/react';

type TableIncludedPriceListItemsBadge = {
  includedPriceListItems: ITEPortal_Domain_Dto_AdminPriceListItem['includedPriceListItems'];
  source: ITEPortal_Domain_Dto_AdminPriceListItem[];
};

export const TableIncludedPriceListItemsBadge = (
  props: TableIncludedPriceListItemsBadge
) => {
  const threshold = 5;
  const { includedPriceListItems, source } = props;

  const itemsWithDisplayName = useMemo(() => {
    if (!includedPriceListItems || includedPriceListItems.length === 0) {
      return [];
    }

    return (
      includedPriceListItems?.map((ipi) => {
        return {
          ...ipi,
          displayName:
            source.find((pi) => pi.id === ipi.includedPricelistItemId)?.name ||
            ipi.includedPricelistItemId,
        };
      }) || []
    );
  }, [includedPriceListItems, source]);

  if (!itemsWithDisplayName || !itemsWithDisplayName.length) {
    return <>-</>;
  }

  const total = itemsWithDisplayName.length;
  const tailLength = total - threshold;
  const TooltipWrapper = total >= threshold ? Tooltip : Fragment;
  let tooltipProps: Omit<TooltipProps, 'children'> = {};

  if (TooltipWrapper === Tooltip) {
    tooltipProps = {
      label: itemsWithDisplayName
        .map((pi) => `${pi.displayName} (${pi.quantity})`)
        .join(', '),
      placement: 'top',
      hasArrow: true,
      openDelay: 500,
      width: 'fit-content',
    };
  }

  return (
    <TooltipWrapper {...tooltipProps}>
      <Flex
        flexDirection="row"
        flexWrap="wrap"
        gap={1}
        alignItems="center"
        cursor={tailLength > 0 ? 'help' : undefined}
        width="fit-content"
      >
        {itemsWithDisplayName.slice(0, threshold).map((bt, i) => (
          <Badge fontSize="0.7em" key={i}>
            {bt.displayName || bt.includedPricelistItemId} ({bt.quantity})
          </Badge>
        ))}{' '}
        {tailLength > 0 && <Text fontSize="0.7em">+{tailLength} </Text>}
      </Flex>
    </TooltipWrapper>
  );
};
