import {
  Button,
  CardBody,
  CardHeader,
  FormControl,
  FormErrorMessage,
  Heading,
  Input,
  Stack,
} from '@chakra-ui/react';
import { FieldValues, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useNavigate } from '@tanstack/react-router';
import { VERIFY_EMAIL_PATH } from '@/features/Routing/RoutesConfig/authRoutesConfig';
import { Logo, StyledCard } from '@/pages/common/Auth.styled';
import fetchApi from '@/utils/fetchAPI';
import { LOGIN } from '@/constants/endpoints';
import { FetchMethods } from '@/utils/constants';
import { FormError } from '@/model/common';
import { LanguageSwitcher } from '@/components/LanguageSwitcher/LanguageSwitcher';
import { DEFAULT_LOGO_PATH } from '@/utils/constants';
import { loginRoute } from '../../features/Routing/auth';
import { authApi } from '@/store/effector/auth';

const LoginPage = (): JSX.Element => {
  const { t } = useTranslation();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errors, setErrors] = useState<FormError>({});
  const navigate = useNavigate();
  const { redirect } = loginRoute.useSearch();
  const culture = localStorage.getItem('lang');

  const { register, handleSubmit } = useForm();

  const submitHandler = async (values: FieldValues) => {
    const email = values.email;

    setIsSubmitting(true);

    try {
      await fetchApi(LOGIN, {
        method: FetchMethods.Post,
        data: {
          email: email,
        },
        params: {
          culture,
        },
      });

      localStorage.removeItem('user');
      authApi.addEmail(values.email);

      navigate({
        to: VERIFY_EMAIL_PATH,
        search: {
          redirect,
        },
      });
    } catch (error) {
      setErrors({ email: ['Email не зарегистрирован'] });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <StyledCard>
      <CardHeader>
        <Logo src={DEFAULT_LOGO_PATH} alt="Logo" />
        <LanguageSwitcher />
        <Heading size={{ base: 'xs', md: 'sm' }}>{t('welcomeAuth')}</Heading>
        <Heading size={{ base: 'xs' }} fontWeight="400" marginTop="7px">
          {t('loginTitle')}
        </Heading>
      </CardHeader>
      <CardBody>
        <form onSubmit={handleSubmit(submitHandler)}>
          <Stack spacing="5">
            <FormControl isInvalid={!!errors['email']}>
              <Input
                type="email"
                placeholder={t('emailPlaceholder') as string}
                {...register('email', { required: true })}
              />
              {errors['email'] && (
                <FormErrorMessage>{errors['email'].join(' ')}</FormErrorMessage>
              )}
            </FormControl>
            <Button type="submit" isLoading={isSubmitting}>
              {t('signIn')}
            </Button>
          </Stack>
        </form>
      </CardBody>
    </StyledCard>
  );
};

export default LoginPage;
