import { Text, TextProps } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { AttachmentIcon, DeleteIcon } from '@chakra-ui/icons';
import { FileUtils } from '@/utils/file-utils';
import styled from 'styled-components';
import { ModalFactory } from '@/components/Modal/api';
import React, { MouseEvent, useState } from 'react';
import Download from 'yet-another-react-lightbox/plugins/download';
import Lightbox from 'yet-another-react-lightbox';

type DownloadFileLinkPropsType = {
  attachmentUrl?: string | null;
  fileName?: string | null;
  onDelete?: () => Promise<void>;
  withIcon?: boolean;
} & TextProps;

const imageExtensions = ['png', 'jpg', 'jpeg', 'gif', 'svg', 'webp'];

const isImage = (fileName: string) => {
  return imageExtensions.some((ext) => fileName.endsWith(`.${ext}`));
};

const FileLink = styled(Text)`
  .delete-handler {
    visibility: hidden;
    margin-top: -5px;
    margin-left: 1em;
  }
  &:hover {
    .delete-handler {
      visibility: visible;
    }
  }
`;

export const DownloadFileLink = (props: DownloadFileLinkPropsType) => {
  const {
    attachmentUrl,
    fileName: fileNameFromProps,
    onDelete,
    withIcon = true,
    ...textProps
  } = props;
  const { t } = useTranslation();
  const canBeDeleted = typeof onDelete === 'function';
  const couldBeOpenedInPopup = attachmentUrl && isImage(attachmentUrl);
  const [open, setOpen] = useState(false);

  if (!attachmentUrl) {
    return null;
  }

  const handleDeleteFile = (e: MouseEvent<SVGElement>) => {
    e.preventDefault();
    e.stopPropagation();

    if (typeof onDelete !== 'function') {
      return;
    }

    ModalFactory.confirm({
      id: 'file-delete-confirmation',
      Component: () => <>{t('delete-file-confirmation')}</>,
      okLabel: t('confirm-delete'),
      onOk: async () => {
        await onDelete();

        ModalFactory.closeById('file-delete-confirmation');
      },
    });
  };

  const downloadAttachment = () => {
    if (attachmentUrl) {
      if (couldBeOpenedInPopup) {
        setOpen(true);

        return;
      }

      FileUtils.saveUrlToBlob(attachmentUrl);
    }
  };

  return (
    <FileLink
      cursor="pointer"
      fontSize={12}
      color="red"
      mt={2}
      onClick={downloadAttachment}
      {...textProps}
    >
      {withIcon && <AttachmentIcon />} {fileNameFromProps || t('download-file')}
      {canBeDeleted && (
        <DeleteIcon className="delete-handler" onClick={handleDeleteFile} />
      )}
      {couldBeOpenedInPopup && (
        <Lightbox
          open={open}
          close={() => setOpen(false)}
          index={0}
          slides={[
            {
              src: attachmentUrl,
            },
          ]}
          animation={{ fade: 0 }}
          controller={{ closeOnPullDown: true, closeOnBackdropClick: true }}
          styles={{ root: { '--yarl__color_backdrop': 'rgba(0, 0, 0, .7)' } }}
          plugins={[Download]}
          download={{
            download: ({ slide }) => {
              FileUtils.saveUrlToBlob(slide.src);
            },
          }}
        />
      )}
    </FileLink>
  );
};
