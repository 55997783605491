import React, { useCallback } from 'react';
import { Divider, Flex, Tooltip } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { Button } from '../Buttons/Button/Button';
import { FieldValues, RegisterOptions, UseFormRegister } from 'react-hook-form';
import { Product } from '@/model/IProduct';
import InputNumber from '../Fields/InputNumber';
import { IdType } from '@/model/common';
import { WarningIcon } from '@chakra-ui/icons';
import { TooltipWrapper } from '@/components/Forms/Services/TemporaryStaff/Row';
import {
  ButtonContainer,
  InfoSectionContainer,
  OrderTableRowContainer,
  OrderTableRowContainerBadgeComponent,
  OrderTableRowContainerInputComponent,
  OrderTableRowContainerInputWrapper,
  OrderTableRowContainerNameComponent,
  OrderTableRowContainerPriceComponent,
  OrderTableRowWrapper,
  ParamsSectionContainer,
} from './styled';
import { AmountCounter } from '@/components/AmountCounter';
import { Nullable } from '../../types/support-types';
import { OrderItem } from '../../model/IOrderItem';
import { orderApi, useOrder } from '@/store/effector/order';
import { ITEPortal_Domain_Dto_PriceListItemSummary } from '@/api';
import { CommonProductGallery } from '@/components/ImageGalleries/CommonProductGallery';

type RowDataType = 'price' | 'input' | 'badge' | 'inputNumber';

export interface RowDataStructure {
  type: RowDataType;
  text?: string | null;
  name?: string | null;
  options?: BadgeItem[];
  placeholder?: string;
  settings?: RegisterOptions<FieldValues, string>;
}

interface RowDataProps {
  rowData: {
    text: string;
    params: RowDataStructure[];
  };
  // submitHandler: (product: Product, values: FieldValues) => void;
  product: Product;
  withDivider?: boolean;
}

type TextDataProp = {
  text?: string;
  id?: IdType;
  description?: Nullable<string>;
} & Pick<ITEPortal_Domain_Dto_PriceListItemSummary, 'imageUrls'>;

export interface BadgeItem {
  key?: string | number;
  value: string | number;
}

interface BadgeDataProp {
  register?: UseFormRegister<FieldValues>;
  options?: BadgeItem[];
  name?: string;
  placeholder?: string;
  settings?: RegisterOptions<FieldValues, string>;
}

interface InputFieldProps {
  register?: UseFormRegister<FieldValues>;
  name?: string;
  placeholder?: string;
  settings?: RegisterOptions<FieldValues, string>;
}

const NameComponent = ({
  text,
  description,
  imageUrls,
}: TextDataProp): JSX.Element => {
  return (
    <OrderTableRowContainerNameComponent>
      <Flex direction="row" justifyContent="space-between">
        {text}
        {description && (
          <TooltipWrapper>
            <Tooltip hasArrow label={description}>
              <WarningIcon
                color="var(--chakra-colors-brandColor)"
                verticalAlign="initial"
              />
            </Tooltip>
          </TooltipWrapper>
        )}
      </Flex>
      {imageUrls && <CommonProductGallery images={imageUrls} />}
    </OrderTableRowContainerNameComponent>
  );
};

const PriceComponent = ({ text }: TextDataProp): JSX.Element => {
  return (
    <OrderTableRowContainerPriceComponent>
      {text}
    </OrderTableRowContainerPriceComponent>
  );
};

const InputComponent: React.FC<InputFieldProps> = ({
  register,
  placeholder,
  settings,
  name = 'field',
}) => {
  const registerFieldName = register ? { ...register(name, settings) } : {};

  return (
    <OrderTableRowContainerInputWrapper>
      <OrderTableRowContainerInputComponent
        placeholder={placeholder}
        size="xs"
        colorScheme="red"
        type="number"
        {...registerFieldName}
      />
    </OrderTableRowContainerInputWrapper>
  );
};

const SelectComponent: React.FC<BadgeDataProp> = ({
  register,
  options,
  placeholder,
  settings,
  name = 'select',
}) => {
  const registerFieldName = register ? { ...register(name, settings) } : {};

  return (
    <OrderTableRowContainerBadgeComponent
      size="xs"
      bg="twitter"
      name={name}
      rootProps={{ width: '150px' }}
      defaultValue=""
      {...registerFieldName}
    >
      <option value="" disabled>
        {placeholder}
      </option>
      {options?.map(({ key, value }) => {
        return (
          <option value={key} key={key}>
            {value}
          </option>
        );
      })}
    </OrderTableRowContainerBadgeComponent>
  );
};

const rowDataComponentsConfig = {
  price: PriceComponent,
  inputNumber: InputNumber,
  input: InputComponent,
  badge: SelectComponent,
};

const RowControl = ({ orderItem }: { orderItem: OrderItem }) => {
  const product = orderItem.product;

  const changeHandler = (value: number) => {
    orderApi.changeItem({
      id: product.getProductId(),
      product,
      amount: value,
    });
  };

  const isProductSupportsDecimals = product.category.isDecimal;

  return (
    <AmountCounter
      amountCounterValue={orderItem.getAmount()}
      onChange={(counterValue: number) => changeHandler(counterValue)}
      suffix={product instanceof Product ? product.unit : undefined}
      precision={isProductSupportsDecimals ? 2 : 0}
      step={product instanceof Product ? product.amountStep : 1}
    />
  );
};

const AddItemToOrderButton = ({ product }: { product: Product }) => {
  const { t } = useTranslation();

  const addItem = useCallback((productToAdd: Product) => {
    const item = new OrderItem({
      id: productToAdd.getProductId(),
      product: productToAdd,
    });

    item.setAmount(1);

    orderApi.addItem(item);
  }, []);

  return (
    <Button
      buttonType="button"
      height="100%"
      colorScheme="secondary"
      onClick={() => addItem(product)}
    >
      {t('choose')}
    </Button>
  );
};

export const OrderTableRow = ({
  rowData,
  product,
  withDivider,
}: RowDataProps): JSX.Element => {
  const { items } = useOrder();
  const { params, text } = rowData;
  const orderItem = items?.find((item) => item?.id === product?.productName);

  return (
    <OrderTableRowWrapper>
      <OrderTableRowContainer>
        <InfoSectionContainer>
          {text && (
            <NameComponent
              text={text}
              description={product?.description}
              imageUrls={product.imageUrls}
            />
          )}
          <ParamsSectionContainer>
            {params?.map((tableElement, key) => {
              const rowDataText = tableElement?.text as string;
              const rowDataOptions = tableElement?.options as BadgeItem[];

              const Component = rowDataComponentsConfig[tableElement?.type];

              if (!Component) {
                console.warn(`there is no Component for ${tableElement?.type}`);

                return null;
              }

              return (
                <Component
                  key={key}
                  text={rowDataText}
                  options={rowDataOptions}
                  name={tableElement.name || 'input'}
                  placeholder={tableElement.placeholder}
                  settings={tableElement.settings}
                />
              );
            })}
          </ParamsSectionContainer>
        </InfoSectionContainer>
        <ButtonContainer>
          {orderItem && !orderItem.included ? (
            <RowControl orderItem={orderItem} />
          ) : (
            <AddItemToOrderButton product={product} />
          )}
        </ButtonContainer>
      </OrderTableRowContainer>
      {withDivider && <Divider orientation="horizontal" />}
    </OrderTableRowWrapper>
  );
};
