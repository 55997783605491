import styled from 'styled-components';
import SimpleBar from 'simplebar-react';

interface ContentProps {
  className?: string;
}

export const Content = styled.div<ContentProps>`
  max-width: 100%;
  width: 100%;

  overflow: hidden;
`;

export const ContentInner = styled(SimpleBar)`
  height: 100%;

  overflow-y: auto;

  max-height: calc(100vh - var(--chakra-sizes-nav-bar-height) - 0px);
  padding-bottom: 20px;
`;

export const ContentWithSidebars = styled(Content)`
  max-width: calc(
    100% - var(--chakra-sizes-side-bar-width) -
      var(--chakra-sizes-order-list-width)
  );

  margin-left: var(--chakra-sizes-side-bar-width);
  margin-right: var(--chakra-sizes-side-bar-width);
`;

export const AdminPanelContent = styled(Content)`
  max-width: calc(100% - var(--chakra-sizes-side-bar-width));

  margin-left: var(--chakra-sizes-side-bar-width);
`;
