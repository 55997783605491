import { Card, CardBody, Flex } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import Layout from '@/layout/Default';
import { Link } from '@tanstack/react-router';

export const AgentHome = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Layout>
      <Flex
        direction="row"
        gap={4}
        alignSelf="center"
        position="absolute"
        top="50%"
        left="50%"
        transform="translate(-50%, -50%)"
      >
        <Link to="/admin/exhibitions">
          <Card>
            <CardBody>{t('agent-cabinet')}</CardBody>
          </Card>
        </Link>
        <Link to="/profile">
          <Card>
            <CardBody>{t('myExhibitions')}</CardBody>
          </Card>
        </Link>
      </Flex>
    </Layout>
  );
};
