import { useEffect } from 'react';
import { NewUsersForm } from '@/pages/AdminPanel/UsersTable/NewUsers/NewUsersForm';

interface NewUserProps {
  type: 'add' | 'edit';
}

export const NewUser = ({ type }: NewUserProps): JSX.Element => {
  useEffect(() => {
    const element = document.getElementById('breadcrumbsId');

    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  return <NewUsersForm type={type} />;
};
