import { Box, Spinner } from '@chakra-ui/react';

interface ButtonProps {
  type?: 'strict' | 'rounded';
  colorScheme?:
    | 'primary'
    | 'secondary'
    | 'disabled'
    | 'disabledSecondary'
    | 'black'
    | 'transparent'
    | 'red';
  children?: React.ReactNode;
  height?: string;
  width?: string;
  fontWeight?: string;
  onClick?: () => void;
  disabled?: boolean;
  buttonType?: 'submit' | 'button' | 'reset' | undefined;
  defaultColor?: string;
  isLoading?: boolean;
}

const schemeConfig = {
  primary: {
    backgroundImage: 'linear-gradient(120deg, #F18F18 0%, #E8431A 100%)',
    color: 'white',
  },
  secondary: {
    backgroundImage: '#fcfcfc',
    border: '1px',
    color: '#E8431A',
  },
  disabled: {
    backgroundImage: 'linear-gradient(120deg, #F18F18 0%, #E8431A 100%)',
    opacity: '0.5',
    color: 'white',
  },
  disabledSecondary: {
    border: '1px',
    opacity: '0.5',
    color: '#E8431A',
  },
  black: {
    color: '#1A202C',
    border: '1px solid silver',
  },
  transparent: {
    color: '#1A202C',
    border: 'none',
  },
  red: {
    backgroundColor: '#E8431A',
    color: 'white',
  },
};

const hoverConfig = {
  primary: {
    backgroundImage: 'linear-gradient(120deg, #e2820e 0%, #d33b15 100%)',
    color: '#e6e6e6',
  },
  secondary: {
    backgroundImage: '#fcfcfc',
    border: '1px',
    color: '#E8431A',
    opacity: '0.7',
  },
  disabled: {
    backgroundImage: 'linear-gradient(120deg, #e2820e 0%, #d33b15 100%)',
    opacity: '0.5',
    color: '#e6e6e6',
  },
  disabledSecondary: {
    border: '1px',
    opacity: '0.5',
    color: '#E8431A',
  },
  black: {
    color: '#1A202C',
    border: '1px solid silver',
    opacity: '0.5',
  },
  transparent: {
    color: '#E8431A',
    border: 'none',
    opacity: '0.5',
  },
  red: {
    backgroundColor: '#E8431A',
    color: 'white',
    opacity: '0.5',
  },
};

const focusConfig = {
  transparent: {
    color: '#E8431A',
  },
};

export const Button = ({
  type = 'rounded',
  colorScheme = 'primary',
  children = 'Test button',
  height = '40px',
  width = '120px',
  fontWeight = 'bold',
  onClick,
  disabled = false,
  buttonType,
  defaultColor,
  isLoading,
}: ButtonProps): JSX.Element => {
  const rounded = type === 'strict' ? '0px' : '6px';

  const defaultColorConfig = defaultColor
    ? {
        color: defaultColor,
      }
    : {};

  return (
    <Box
      as="button"
      minWidth="max-content"
      transition="all 0.2s cubic-bezier(.08,.52,.52,1)"
      px="8px"
      fontSize="14px"
      height={height}
      borderRadius={rounded}
      fontWeight={fontWeight}
      width={width}
      onClick={onClick}
      _hover={
        hoverConfig?.[
          disabled && colorScheme !== 'transparent' ? 'disabled' : colorScheme
        ]
      }
      _focus={colorScheme === 'transparent' ? focusConfig?.['transparent'] : {}}
      {...schemeConfig?.[
        disabled && colorScheme !== 'transparent' ? 'disabled' : colorScheme
      ]}
      disabled={disabled}
      type={buttonType}
      {...defaultColorConfig}
    >
      {isLoading ? <Spinner /> : children}
    </Box>
  );
};
