import { FieldValues, Path, UseFormReturn } from 'react-hook-form';
import i18n from '../i18n';
import { typedObjectKeys } from '../utils/typed-object-keys';

type HandlingOptions = {
  ignoreFields?: string[];
  fieldPrefix?: string;
};

const errorsOverride = {
  'Error converting value {null}': i18n.t('required-field'),
};

const getOverride = (m: string) =>
  typedObjectKeys(errorsOverride).find((o) => m.includes(o));

const overrideMessage = (messages: string[]) => {
  return messages.map((m) => {
    const override = getOverride(m);

    if (typeof override === 'string') {
      return errorsOverride[override];
    }

    return m;
  });
};

export const useFormErrorsHandler = <FV extends FieldValues = FieldValues>(
  formContext: UseFormReturn<FV>
) => {
  const processErrorsArray = (handledError: any, options?: HandlingOptions) => {
    const { ignoreFields = [] } = options || {};

    try {
      const errorsData = handledError?.response?.data as Array<{
        propertyName: string;
        errorMessage: string;
      }>;

      errorsData?.forEach((error) => {
        const messageErrorKey = error.propertyName?.split('.').at(-1) as string;
        const errorKey = (messageErrorKey?.charAt(0).toLowerCase() +
          messageErrorKey?.slice(1)) as Path<FV>;

        if (ignoreFields.includes(errorKey)) {
          return;
        }

        if (formContext.getFieldState(errorKey)) {
          formContext.setError(errorKey, {
            message: error?.errorMessage,
            type: 'custom',
          });
        }
      });
    } catch (e) {
      console.warn('Unable to process errors');
      console.warn(e);

      console.dir(handledError);
    }
  };

  const processErrorsObject = (
    handledError: any,
    options?: HandlingOptions
  ) => {
    const { ignoreFields = [], fieldPrefix = '' } = options || {};

    try {
      const errorsData = handledError?.body?.errors as Record<string, string[]>;

      Object.entries(errorsData)?.forEach(([key, error]) => {
        const errorKey = (fieldPrefix +
          (key?.charAt(0).toLowerCase() + key?.slice(1))) as Path<FV>;

        if (formContext.getFieldState(errorKey)) {
          if (ignoreFields.includes(errorKey)) {
            return;
          }

          formContext.setError(errorKey, {
            message: overrideMessage(error).join(' '),
            type: 'custom',
          });
        }
      });
    } catch (e) {
      console.warn('Unable to process errors');
      console.warn(e);

      console.dir(handledError);
    }
  };

  return { processErrorsArray, processErrorsObject };
};
