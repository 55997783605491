import styled from 'styled-components';
import SimpleBar from 'simplebar-react';
import SimpleBarCore from 'simplebar-core';

const SidebarWrapper = styled.div<{
  fixed: number;
  maxWidth: number | string;
  position: 'left' | 'right';
}>`
  height: 100%;
  width: 100%;
  max-width: ${({ maxWidth }) => maxWidth};

  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  background: #fff;

  ${({ fixed, position }) =>
    fixed &&
    `
    bottom: 0;
    ${position === 'left' ? 'left: 0;' : 'right: 0;'}
    position: fixed !important;
    top: 0;
    margin-top: var(--chakra-sizes-nav-bar-height);
    max-height: calc(100% - var(--chakra-sizes-nav-bar-height));
  `}
`;

const SidebarHeader = styled.div`
  text-align: center;
  padding: 10px 20px;
  font-size: 1.5rem;
`;

const SidebarFooter = styled.div`
  padding: 20px;
  border-top: 1px solid #e2e8f0;
`;

const SidebarInner = styled(SimpleBar)`
  overflow-y: auto;

  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
`;

interface SidebarProps {
  header?: React.ReactNode;
  footer?: React.ReactNode;
  children?: React.ReactNode | React.ReactNode[];
  fixed?: boolean;
  scrollRef?: React.RefObject<SimpleBarCore>;
  position?: 'left' | 'right';
  maxWidth?: string | number;
  className?: string;
}

export const Sidebar: React.FC<SidebarProps> = ({
  header,
  footer,
  children,
  fixed,
  scrollRef,
  position = 'left',
  maxWidth = 'var(--chakra-sizes-side-bar-width)',
  className,
}) => {
  return (
    <SidebarWrapper
      className={className}
      fixed={fixed ? 1 : 0}
      position={position}
      maxWidth={maxWidth}
    >
      {header && <SidebarHeader>{header}</SidebarHeader>}
      <SidebarInner ref={scrollRef}>{children}</SidebarInner>
      {footer && <SidebarFooter>{footer}</SidebarFooter>}
    </SidebarWrapper>
  );
};

export default Sidebar;
