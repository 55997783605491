import { ITechnicalService } from '@/model/ITechnicalService';
import DevelopmentIcon from '@/components/Icons/Development';
import { Category } from '@/model/ICategory';
import { CREATE_USER, SETTINGS } from '@/constants/technical-services';
import i18n from '@/i18n';

const adminMenu = () => {
  const settings = new Category({
    id: SETTINGS,
    name: SETTINGS,
    displayName: i18n.t(SETTINGS),
  });
  const createUser = new Category({
    id: CREATE_USER,
    name: CREATE_USER,
    displayName: i18n.t(CREATE_USER),
  });

  return [
    {
      category: createUser,
      image: <DevelopmentIcon />,
    },
    {
      category: settings,
      image: <DevelopmentIcon />,
    },
  ] as ITechnicalService[];
};

export default adminMenu;
