import { Text } from '@chakra-ui/react';

interface NumberStringType {
  value: number;
}

export const NumberColumnComponent = ({ value }: NumberStringType) => {
  const columnValue = value !== 0 ? value : '';

  return <Text>{columnValue}</Text>;
};
