import { useForm, FormProvider, useController } from 'react-hook-form';
import {
  ButtonGroup,
  FormControl,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
} from '@chakra-ui/react';
import { useNameTranslate } from '@/hooks/useStringName';
import { SelectComponent } from '@/components/MultiSelect/MultiSelect';
import { Button as OrderButton } from '@/components/Buttons/Button/Button';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { t } from 'i18next';
import { FetchMethods } from '@/utils/constants';
import { STATUS_CONFIG } from '@/constants/admin-pages';
import fetchApi from '@/utils/fetchAPI';
import { CHANGE_ORDER_STATUS } from '@/constants/endpoints';
import { toast } from 'react-toastify';
import { OptionType } from '@/model/common';
import { ITEPortal_Domain_Dto_AdminOrderSummary } from '@/api';
import { isNil } from '@/utils/is-nil';

const FormControlWrapper = styled(FormControl)`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

type FormValues = {
  orderStatus: OptionType;
};

interface AddBuildingTypeProductProps {
  openConfirm: boolean;
  closeModal: () => void;
  refresh: () => void;
  editTableData?: ITEPortal_Domain_Dto_AdminOrderSummary;
}

export const EditStatusModal = ({
  openConfirm,
  closeModal,
  refresh,
  editTableData,
}: AddBuildingTypeProductProps) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [editData, setEditData] = useState<any>();
  const culture = localStorage.getItem('lang');
  const methods = useForm<FormValues>({
    mode: 'onBlur',
  });

  useEffect(() => {
    const orderData = editTableData;

    if (orderData?.orderStatus) {
      const value = STATUS_CONFIG.find(
        (status: OptionType) => status?.value === orderData.orderStatus
      );

      if (value) {
        methods.setValue('orderStatus', value);
      }

      setEditData(orderData);
    }
  }, [editTableData]);

  useEffect(() => {
    methods.reset({
      ...editData,
    });
  }, [editData]);

  const {
    field: { ...statusProps },
  } = useController({ name: 'orderStatus', control: methods.control });

  const submitHandler = async (values: FormValues) => {
    const status = values?.orderStatus?.value;

    if (!editTableData?.orderId || isNil(status)) {
      return;
    }

    setIsSubmitting(true);
    try {
      await fetchApi(CHANGE_ORDER_STATUS(editTableData?.orderId), {
        method: FetchMethods.Put,
        params: { orderStatus: status, culture },
      });

      closeModal();
      refresh();
    } catch (error: any) {
      const fieldErrors = error?.response?.data?.errors;

      if (fieldErrors) {
        toast.error(fieldErrors);
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Modal isOpen={openConfirm} onClose={closeModal} size="xl" isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{useNameTranslate('edit-order')}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormProvider {...methods}>
            <form>
              <Stack spacing="5">
                <FormControlWrapper>
                  <SelectComponent
                    label={useNameTranslate('status')}
                    options={STATUS_CONFIG}
                    defaultValue={editData?.['status']}
                    isRequired
                    {...statusProps}
                  />
                </FormControlWrapper>
              </Stack>
            </form>
          </FormProvider>
        </ModalBody>
        <ModalFooter justifyContent="center">
          <ButtonGroup gap="1" justifyContent="center">
            <OrderButton
              colorScheme="red"
              onClick={methods.handleSubmit(submitHandler)}
              disabled={isSubmitting}
              isLoading={isSubmitting}
            >
              {t('save')}
            </OrderButton>
            <OrderButton colorScheme="secondary" onClick={closeModal}>
              {t('cancel')}
            </OrderButton>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
