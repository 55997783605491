import { useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import { UseFormReturn } from 'react-hook-form';
import { CompanyFormSchemaType } from '@/pages/AdminPanel/PersonalAccounts/MainInfoTab/types';
import { useBoolean } from '@chakra-ui/react';
import { typedAxios } from '@/api/typed-axios';
import { ITEPortal_Domain_Dto_ExhibitorDto } from '@/api';

export const useSearchCompanyByEmployee = (
  formInstance: UseFormReturn<CompanyFormSchemaType>
) => {
  const [foundCompany, setFoundCompany] =
    useState<ITEPortal_Domain_Dto_ExhibitorDto | null>(null);
  const [
    isEmployeeEmailFieldVisible,
    { toggle: toggleIsEmployeeEmailFieldVisible },
  ] = useBoolean(false);

  const [
    isCompanySelectionAllowed,
    { toggle: toggleIsCompanySelectionAllowed },
  ] = useBoolean(false);

  const [
    isCompanyFoundByEmployee,
    { on: markIsCompanyFoundByEmployee, off: markCompanyNotFoundByEmployee },
  ] = useBoolean(false);

  const findCompanyByEmployee = useCallback(async () => {
    const isValid = await formInstance.trigger('employeeEmail');

    if (!isValid) {
      return;
    }

    try {
      const employeeEmail = formInstance.getValues('employeeEmail');
      const company = await typedAxios.exhibitor.getExhibitors1({
        email: employeeEmail,
      });

      if (!company || !company.exhibitorEmployees) {
        markCompanyNotFoundByEmployee();
        setFoundCompany(null);

        return null;
      }

      setFoundCompany(company);

      const existingEmployee = company.exhibitorEmployees.find(
        (e) => e.email === employeeEmail
      );

      if (!existingEmployee) {
        return;
      }

      if (existingEmployee.name) {
        formInstance.setValue('employeeName', existingEmployee.name);
      }

      if (company.id) {
        formInstance.setValue('companyName', {
          value: company.id + '',
          label: company.name || company.id + '',
          // @ts-expect-error update types
          meta: company,
          __isNew__: false,
        });
      }

      if (company.phoneNumber) {
        formInstance.setValue('phoneNumber', company.phoneNumber);
      }

      toggleIsCompanySelectionAllowed();
      markIsCompanyFoundByEmployee();
    } catch {
      toast.error('Участник не найден');
    } finally {
      toggleIsEmployeeEmailFieldVisible();
    }
  }, []);

  return {
    isCompanyFoundByEmployee,
    isCompanySelectionAllowed,
    isEmployeeEmailFieldVisible,
    foundCompany,
    findCompanyByEmployee,
  };
};
