import React, { useCallback } from 'react';
import styled from 'styled-components';
import MenuItem from './MenuItem';
import { IMenuConfig } from './config';
import { Button } from '@chakra-ui/react';
import { useNavigate } from '@tanstack/react-router';

interface ListProps {
  direction?: 'row' | 'column';
}

const List = styled.ul<ListProps>`
  display: flex;
  flex-direction: ${(props) => props.direction};
  align-items: center;

  padding-left: 0;
  margin-left: 0;

  list-style: none;
`;

interface MenuProps extends ListProps {
  config: IMenuConfig;
  className?: string;
}

const Menu: React.FC<MenuProps> = ({
  config,
  className,
  direction = 'row',
}) => {
  const navigate = useNavigate();

  const redirectTo = useCallback(
    (path: string) => navigate({ to: path, replace: true }),
    []
  );

  return (
    <List direction={direction} className={className}>
      {config.items.map(({ key, name, icon, to }) => {
        const onClick = () => {
          if (to) {
            if (typeof to === 'function') {
              return to();
            }

            return redirectTo(to);
          }
        };

        return (
          <MenuItem key={key}>
            {to ? (
              <Button
                rightIcon={icon}
                height="auto"
                variant="brandSecondary"
                onClick={onClick}
              >
                {name}
              </Button>
            ) : (
              name
            )}
          </MenuItem>
        );
      })}
    </List>
  );
};

export default Menu;
