import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import React, { useEffect, useMemo } from 'react';
import { ButtonGroup, Button, Box, Flex } from '@chakra-ui/react';
import { useNameTranslate } from '@/hooks/useStringName';
import { useNavigate, useParams } from '@tanstack/react-router';
import { StandFieldSet } from './StandsTab/StandFieldSet';
import { StandFormValues } from './StandsTab/types';
import { useSuspenseQuery } from '@tanstack/react-query';
import { QueryOptions } from '@/features/query/query-options';
import { StandProjectForm } from '@/pages/AdminPanel/PersonalAccounts/StandsTab/StandProjectForm';
import { FilesStorage } from '@/pages/Exhibition/FilesStorage/FilesStorage';

const defaultValues = {
  id: 0,
  area: undefined,
  buildingType: undefined,
  secondFloorArea: undefined,
  standConfiguration: undefined,
  standNumber: undefined,
};

export const StandsTab = (): JSX.Element => {
  const { exhibitionId, accountId: exhibitorId } = useParams({ strict: false });

  const { data: stands = [], isLoading } = useSuspenseQuery(
    QueryOptions.standsQueryOptions({
      exhibitionId: +exhibitionId,
      exhibitorId: +exhibitorId,
    })
  );

  const sortedStands = useMemo(() => {
    return stands.sort((a, b) => a.id - b.id);
  }, [stands]);

  const navigate = useNavigate();

  const methods = useForm<StandFormValues>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
  });

  const { fields, append, remove } = useFieldArray({
    name: 'stands',
    control: methods.control,
  });

  useEffect(() => {
    methods.reset({
      stands: sortedStands,
    });

    if (Array.isArray(sortedStands) && sortedStands.length === 0) {
      append(defaultValues);
    }
  }, [sortedStands]);

  const toAccounts = () => {
    navigate({
      to: '/admin/exhibitions/$exhibitionId/view/personal-accounts',
      replace: true,
      params: {
        exhibitionId,
      },
    });
  };

  return (
    <>
      <FormProvider {...methods}>
        <Flex gap="20px" direction="row" flexWrap="wrap">
          {fields.map((field, index) => {
            const projectId = sortedStands[index]?.projectId;

            return (
              <StandFieldSet
                key={field.id}
                index={index}
                isNew={!Boolean(sortedStands[index])}
                onRemove={remove}
                standId={sortedStands[index]?.id}
                projectId={projectId}
              />
            );
          })}
        </Flex>
        <Box mt={4}>
          {!isLoading && (
            <ButtonGroup gap="1">
              <Button
                colorScheme="red"
                variant="outline"
                type="reset"
                onClick={toAccounts}
              >
                {useNameTranslate('cancel')}
              </Button>
              <Button
                type="button"
                variant="ghost"
                colorScheme="red"
                onClick={() => {
                  append(defaultValues, {
                    focusName: '',
                  });
                }}
              >
                + {useNameTranslate('add-stand')}
              </Button>
            </ButtonGroup>
          )}
        </Box>
        <FilesStorage canUploadNewFiles={false} />
      </FormProvider>
      <StandProjectForm />
    </>
  );
};
