import { Flex, Heading, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { CommentForm } from '@/pages/StandProject/StandProjectDescription/CommentForm';
import { CommentsHistory } from '@/pages/StandProject/StandProjectDescription/CommentsHistory';
import { ITEPortal_Domain_Dto_Stand_GetStandProjectDto } from '@/api';

type ProjectDescriptionPropsType = Pick<
  ITEPortal_Domain_Dto_Stand_GetStandProjectDto,
  'projectDescription' | 'projectChangesHistory' | 'approved'
>;

export const ProjectDescription = (props: ProjectDescriptionPropsType) => {
  const { projectDescription, projectChangesHistory, approved } = props;
  const { t } = useTranslation();

  return (
    <Flex flexDirection="column" gap={4} height="100%">
      {projectDescription && (
        <>
          <Heading as="h2" size="sm" noOfLines={1}>
            {t('project-tech-manager-comment-title')}
          </Heading>
          <Text>{projectDescription}</Text>
        </>
      )}

      <CommentsHistory comments={projectChangesHistory} />
      {!approved && <CommentForm />}
    </Flex>
  );
};
