import { t } from 'i18next';
import { BadgeItem } from '@/components/OrderTableRow/OrderTableRow';

export const EN_LANGUAGE = 'en';
export const DC_LANGUAGE = 'dc';
export const FR_LANGUAGE = 'fr';
export const IT_LANGUAGE = 'it';
export const SP_LANGUAGE = 'sp';

export const LANGUAGES_OPTIONS: BadgeItem[] = [
  {
    key: EN_LANGUAGE,
    value: t(EN_LANGUAGE) as string,
  },
  {
    key: DC_LANGUAGE,
    value: t(DC_LANGUAGE) as string,
  },
  {
    key: FR_LANGUAGE,
    value: t(FR_LANGUAGE) as string,
  },
  {
    key: IT_LANGUAGE,
    value: t(IT_LANGUAGE) as string,
  },
  {
    key: SP_LANGUAGE,
    value: t(SP_LANGUAGE) as string,
  },
];
