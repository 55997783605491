import { ITEPortal_Domain_Dto_Exhibitor_GetExhibitorEmployeeDto } from '@/api';
import { Row } from 'react-table';
import { Flex } from '@chakra-ui/react';
import { DeleteIcon, EditIcon } from '@chakra-ui/icons';
import { ModalFactory } from '@/components/Modal/api';
import { typedAxios } from '@/api/typed-axios';
import i18n from '@/i18n';
import { toast } from 'react-toastify';
import { useRouter } from '@tanstack/react-router';
import { useSaveEmployee } from '@/pages/AdminPanel/MembersDirectory/ManageExhibitorUsers/hooks/use-save-employee';

export const UsersTableActions = (props: {
  row: Row<ITEPortal_Domain_Dto_Exhibitor_GetExhibitorEmployeeDto>;
}) => {
  const { row } = props;
  const router = useRouter();
  const { openForm } = useSaveEmployee();
  const confirmRemoveUserFromExhibition = () => {
    ModalFactory.confirm({
      Component: () => <>{i18n.t('remove-employee')}</>,
      onOk: async () => {
        if (row.original.employeeId) {
          try {
            await typedAxios.exhibitor.deleteExhibitorsEmployees({
              employeeId: row.original.employeeId,
            });

            router.invalidate();

            ModalFactory.close();

            toast.success(i18n.t('employee-removed'));
          } catch {
            toast.error(i18n.t('error-occurred'));
          }
        }
      },
    });
  };

  return (
    <Flex
      flexDir="row"
      gap="10px"
      alignItems="center"
      justifyContent="flex-end"
    >
      <EditIcon onClick={() => openForm(row.original)} />
      <DeleteIcon onClick={confirmRemoveUserFromExhibition} />
    </Flex>
  );
};
