import { Button } from '../../../../../components/Buttons/Button/Button';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { PersonalDiscountForm } from './PersonalDiscountForm';
import { IBaseOrder } from '../../../../../model/IBaseOrder';

type PersonalDiscountPropsType = {
  order: IBaseOrder;
};

export const PersonalDiscount = (props: PersonalDiscountPropsType) => {
  const { order } = props;
  const { t } = useTranslation();
  const [isEditModalOpened, toggleOpenState] = useState(false);

  if (order.isDraft) {
    return null;
  }

  return (
    <>
      <Button colorScheme="red" onClick={() => toggleOpenState(true)}>
        {order.hasDiscount
          ? t('edit-personal-discount')
          : `+ ${t('apply-personal-discount')}`}
      </Button>
      {isEditModalOpened && (
        <PersonalDiscountForm
          order={order}
          onClose={() => toggleOpenState(false)}
        />
      )}
    </>
  );
};
