import { ITEPortal_Domain_Dto_Exhibitor_GetExhibitorEmployeeDto } from '@/api';
import { Row } from 'react-table';
import { Flex } from '@chakra-ui/react';
import { DeleteIcon } from '@chakra-ui/icons';
import { ModalFactory } from '@/components/Modal/api';
import { typedAxios } from '@/api/typed-axios';
import { editExhibitionAccountRoute } from '@/features/Routing/protected/admin-exhibitions';
import i18n from '@/i18n';
import { QueryOptions } from '@/features/query/query-options';
import { useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';

export const UsersTableActions = (props: {
  row: Row<ITEPortal_Domain_Dto_Exhibitor_GetExhibitorEmployeeDto>;
}) => {
  const { row } = props;
  const queryClient = useQueryClient();
  const { exhibitionId, accountId: exhibitorId } =
    editExhibitionAccountRoute.useParams();

  const confirmRemoveUserFromExhibition = () => {
    ModalFactory.confirm({
      Component: () => <>{i18n.t('remove-employee-from-exhibition')}</>,
      onOk: async () => {
        if (row.original.employeeId) {
          try {
            await typedAxios.exhibitor.deleteExhibitorsEmployeesExhibitions({
              exhibitionId: +exhibitionId,
              employeeId: row.original.employeeId,
            });

            await queryClient.invalidateQueries(
              QueryOptions.exhibitorQueryOptions({
                exhibitionId: +exhibitionId,
                exhibitorId: +exhibitorId,
              })
            );

            ModalFactory.close();

            toast.success(i18n.t('employee-removed-from-exhibition'));
          } catch {
            toast.error(i18n.t('error-occurred'));
          }
        }
      },
    });
  };

  return (
    <Flex flexDir="row" gap="10px" justifyContent="flex-end">
      <DeleteIcon onClick={confirmRemoveUserFromExhibition} />
    </Flex>
  );
};
