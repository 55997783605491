import { User } from '@/model/IUser';
import { Row, UserInfo } from './Profile.styled';
import { ChevronDownIcon } from '@chakra-ui/icons';
import styled from 'styled-components';

interface ProfileProps {
  user: User;
}

const StyledChevronDownIcon = styled(ChevronDownIcon)`
  margin-left: 8.7px;
`;

const Profile: React.FC<ProfileProps> = ({ user }) => {
  return (
    <Row>
      <UserInfo>{user.fullName}</UserInfo>
      <StyledChevronDownIcon />
    </Row>
  );
};

export default Profile;
