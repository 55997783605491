import { axios } from './axios';
import baseAxios from 'axios';
import { removeTrailingSlash } from '../utils/url';
import { AxiosHttpRequest } from './core/AxiosHttpRequest';
import type { ApiRequestOptions } from './core/ApiRequestOptions';
import type { CancelablePromise } from './core/CancelablePromise';
import { request as __request } from './core/request';
import { OpenAPIConfig } from '@/api/core/OpenAPI';

class TypedAxiosHttpRequest extends AxiosHttpRequest {
  axiosInstance = baseAxios.create();

  constructor(config: OpenAPIConfig) {
    super(config);
    this.axiosInstance.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        if (error.response?.status === 401) {
          localStorage.clear();
          window.location.href = '/login';
        }

        throw error;
      }
    );
  }

  public override request<T>(options: ApiRequestOptions): CancelablePromise<T> {
    return __request(
      this.config,
      {
        ...options,
        query: {
          ...options.query,
          culture: localStorage.getItem('lang'),
        },
        headers: {
          ...options.headers,
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      },
      this.axiosInstance
    );
  }
}

export const typedAxios = new axios(
  {
    BASE: removeTrailingSlash(process.env.PUBLIC_API_URL as string),
    WITH_CREDENTIALS: true,
    HEADERS: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  },
  TypedAxiosHttpRequest
);
