import { User } from '@/model/IUser';
import { IdType } from '@/model/common';
import { persist, StorageAdapter } from 'effector-storage';
import { createApi, createDomain, sample } from 'effector';
import { produce } from 'immer';
import { useUnit } from 'effector-react';

type TokenType = null | IdType;

interface State {
  isLoggedIn: boolean;
  user: null | User;
  userEmail: string;
  token: TokenType;
}

const initialState: State = {
  isLoggedIn: false,
  user: null,
  userEmail: '',
  token: localStorage.getItem('token') || null,
};

const auth = createDomain('auth');

export const $auth = auth.createStore<State>(initialState, { name: 'auth' });

const adapter: StorageAdapter = (key) => ({
  get: () => {
    const state = localStorage.getItem(key);

    if (!state) {
      return initialState;
    }

    try {
      const deserialized = JSON.parse(state);

      if (deserialized.user) {
        deserialized.user = new User({
          ...deserialized.user,
        });
      }

      return deserialized;
    } catch {
      return initialState;
    }
  },
  set: (value) => localStorage.setItem(key, JSON.stringify(value)),
});

persist({
  store: $auth,
  adapter: adapter,
});

export const authApi = createApi($auth, {
  addEmail: (state, payload: string) => {
    return produce(state, (draft) => {
      draft.userEmail = payload;
    });
  },
  setUser: (state, payload: null | User) => {
    return produce(state, (draft) => {
      draft.user = payload;
    });
  },
  setIsLoggedIn: (state, payload: boolean) => {
    return produce(state, (draft) => {
      draft.isLoggedIn = payload;
    });
  },
  setToken: (state, payload: TokenType) => {
    return produce(state, (draft) => {
      draft.token = payload;
    });
  },

  logout: () => initialState,
});

sample({
  clock: authApi.logout,
  target: auth.createEffect('auth/cleanup', {
    handler: async () => {
      console.log('auth clean up');
      try {
        localStorage.clear();
        window.location.href = '/login';
      } catch (e) {
        console.warn(e);
      }
    },
  }),
});

export const useAuth = () => useUnit($auth);
