import { OrderItem } from '@/model/IOrderItem';
import { useOrderCompleteness } from '@/store/effector/order-completeness';
import {
  Alert,
  AlertDescription,
  AlertProps,
  AlertTitle,
} from '@chakra-ui/react';
import { useParams } from '@tanstack/react-router';
import { Link } from '@/components/Sidebar/OrderList/OrderItemDependencyWarning.styled';
import { useTranslation } from 'react-i18next';
import { ArrowUpIcon } from '@chakra-ui/icons';
import { ReactNode } from 'react';

export const OrderItemDependencyWarning = (
  props: {
    orderItem: OrderItem;
    renderProps?: (node: ReactNode) => JSX.Element;
  } & AlertProps
) => {
  const { orderItem, renderProps, ...alertProps } = props;
  const { incompleteItemsDueToDependency, isOrderCompleted } =
    useOrderCompleteness();
  const { t } = useTranslation();
  const { exhibitionId, stand } = useParams({ strict: false });

  if (isOrderCompleted) {
    return null;
  }

  const inTheListOfIncomplete = incompleteItemsDueToDependency.find(
    (incomplete) => incomplete.productId === orderItem.id
  );

  if (!inTheListOfIncomplete) {
    return null;
  }

  const wrapInto =
    typeof renderProps === 'function' ? renderProps : (el: JSX.Element) => el;

  return wrapInto(
    <Alert
      status="warning"
      flexDirection="column"
      alignItems="flex-start"
      justifyContent="flex-start"
      {...alertProps}
    >
      <AlertTitle fontSize={12}>
        <ArrowUpIcon mt="-2px" ml="-15px" /> {t('service-require-dependency')}
      </AlertTitle>
      <AlertDescription fontSize={12}>
        <ul style={{ listStyle: 'none' }}>
          {inTheListOfIncomplete.outstandingDeps?.map((pr) => {
            return (
              <Link
                key={pr.getProductId()}
                to={`/exhibitions/${exhibitionId}/${stand}/create/${pr.categoryName}`}
              >
                <li>{pr.productDisplayName}</li>
              </Link>
            );
          })}
        </ul>
      </AlertDescription>
    </Alert>
  );
};
