import { useForm, FormProvider } from 'react-hook-form';
import {
  ButtonGroup,
  FormControl,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
} from '@chakra-ui/react';
import { useNameTranslate } from '@/hooks/useStringName';
import { SelectComponent } from '@/components/MultiSelect/MultiSelect';
import Input from '@/components/Fields/Input';
import { Button as OrderButton } from '@/components/Buttons/Button/Button';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { t } from 'i18next';
import { OptionType } from '@/model/common';

import { ITEPortal_Domain_Dto_AdminPriceListItem } from '../../../../api';
import { useCreateOrderContext } from './CreateOrderContext';

const FormControlWrapper = styled(FormControl)`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

interface AddBuildingTypeProductProps {
  openConfirm: boolean;
  closeModal: () => void;
  items: ITEPortal_Domain_Dto_AdminPriceListItem[];
}

type FormValues = {
  id: OptionType<{
    item: ITEPortal_Domain_Dto_AdminPriceListItem;
  }>;
  amount: number;
  dayAmount: number;
  peopleAmount: number;
  language: string;
};

export const AddItemToOrderModal = ({
  openConfirm,
  closeModal,
}: AddBuildingTypeProductProps) => {
  const { items, addItem } = useCreateOrderContext();

  const formInstance = useForm<FormValues>({
    mode: 'onBlur',
  });

  const submitHandler = async (values: FormValues) => {
    const id = values?.id;
    const dayAmount = values?.dayAmount || 0;
    const peopleAmount = values?.peopleAmount || 0;
    const amount = values?.amount || 0;
    const language = values?.language || '';

    const data = { dayAmount, peopleAmount, language, amount, id };

    const item = values.id?.meta?.item;

    if (!item || !item.name) {
      formInstance.setError('id', {
        message: 'todo error message',
      });

      return;
    }

    addItem?.({
      ...data,
      id: item.name,
      productName: item.displayName,
    });

    if (item.includedPriceListItems?.length) {
      item.includedPriceListItems.forEach((it) => {
        const originalPriceListItem = items.find(
          (oit) => oit.id === it.includedPricelistItemId
        );

        if (originalPriceListItem && originalPriceListItem.name) {
          addItem?.({
            id: originalPriceListItem.name,
            amount: it.quantity,
            productName: originalPriceListItem.displayName,
            included: true,
          });
        }
      });
    }

    closeModal();
  };

  const itemsOptions = useMemo(() => {
    return items.map((i) => {
      return {
        value: i.id,
        label: `${i.name} (${i.displayName})`,
        meta: {
          item: i,
        },
      };
    }) satisfies Array<
      OptionType<{
        item: ITEPortal_Domain_Dto_AdminPriceListItem;
      }>
    >;
  }, [items]);

  return (
    <Modal
      isOpen={openConfirm}
      onClose={closeModal}
      size="xl"
      scrollBehavior="inside"
      isCentered
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{useNameTranslate('add-product')}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormProvider {...formInstance}>
            <form>
              <Stack spacing="5">
                <FormControlWrapper>
                  <SelectComponent<FormValues>
                    label={useNameTranslate('service-name')}
                    maxWidth="100%"
                    name="id"
                    options={itemsOptions}
                    isRequired
                  />
                  <Input<FormValues>
                    label={`${useNameTranslate('amount')} / ${useNameTranslate(
                      'sqm'
                    )}`}
                    maxWidth="100%"
                    name="amount"
                  />
                  <Input<FormValues>
                    label={useNameTranslate('dayAmountPlaceholder')}
                    maxWidth="100%"
                    name="dayAmount"
                  />
                  <Input<FormValues>
                    label={useNameTranslate('personAmountPlaceholder')}
                    maxWidth="100%"
                    name="peopleAmount"
                  />
                  <Input<FormValues>
                    label={useNameTranslate('language')}
                    maxWidth="100%"
                    name="language"
                  />
                </FormControlWrapper>
              </Stack>
            </form>
          </FormProvider>
        </ModalBody>
        <ModalFooter justifyContent="center">
          <ButtonGroup gap="1" justifyContent="center">
            <OrderButton
              colorScheme="red"
              onClick={formInstance.handleSubmit(submitHandler)}
            >
              {t('save')}
            </OrderButton>
            <OrderButton colorScheme="secondary" onClick={closeModal}>
              {t('cancel')}
            </OrderButton>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
