import Row from '@/components/Sidebar/OrderList/Row';
import { StyledSidebar } from './OrderList.styled';
import Footer from './Footer';
import Header from './Header';
import { useOrder } from '@/store/effector/order';

const OrderListSidebar = () => {
  const { items } = useOrder();

  return (
    <StyledSidebar
      position="right"
      fixed
      header={<Header />}
      footer={<Footer />}
      maxWidth="var(--chakra-sizes-order-list-width)"
    >
      {items.map((item, index) => (
        <Row key={`${item.id}-${index}`} item={item} className="order-row" />
      ))}
    </StyledSidebar>
  );
};

export default OrderListSidebar;
