import * as v from 'zod';
import i18n from '@/i18n';

export const EmployeeFormSchema = v.object({
  id: v.number().optional(),
  email: v.string().email({
    message: i18n.t('invalid-email'),
  }),
  name: v.string().min(1, {
    message: i18n.t('required-field'),
  }),
  phoneNumber: v.string().optional().nullish(),
});

export type EmployeeFormValues = v.infer<typeof EmployeeFormSchema>;
