import { typedAxios } from '@/api/typed-axios';
import { Category } from '@/model/ICategory';
import { OrderItem, TemporaryStaffOrderItem } from '@/model/IOrderItem';
import {
  ITEPortal_Contracts_Enums_BuildingType,
  ITEPortal_Domain_Dto_OrderDto,
  ITEPortal_Domain_Dto_StandSummariesDto,
} from '@/api';
import { useSearchCategoryForProduct } from '@/hooks/useSearchCategoryForProduct';
import { useNavigate } from '@tanstack/react-router';
import { CATEGORY_TYPES } from '@/constants/categorie';
import { ProductUtils } from '@/utils/product-utils';
import { standApi } from '@/store/effector/stand';
import { orderApi } from '@/store/effector/order';
import { loadingApi } from '@/store/effector/loading';
import { Product } from '@/model/IProduct';

export const useEditOrderLoader = ({
  orderId,
  exhibitionId,
  stand,
}: {
  orderId: ITEPortal_Domain_Dto_OrderDto['orderId'];
  exhibitionId: ITEPortal_Domain_Dto_OrderDto['exhibitionId'];
  stand: ITEPortal_Domain_Dto_StandSummariesDto;
}) => {
  const { findCategoryForProduct } = useSearchCategoryForProduct();
  const navigate = useNavigate();

  const editOrder = async () => {
    if (!orderId || !exhibitionId || !stand.currency) {
      return;
    }

    loadingApi.on();

    try {
      let buildingType = stand.buildingType;

      const orderDto = await typedAxios.order.getOrders1({
        orderId: +orderId,
      });

      const { products: draftProducts, standId } = orderDto;

      const data = await typedAxios.priceListItem.getPricelistItems({
        exhibitionId: +exhibitionId,
        currency: stand.currency,
      });

      const buildings = data.filter(
        (p) => p.categoryName === CATEGORY_TYPES.SELECT_BUILDING
      );

      const orderedBuilding = buildings?.find((b) =>
        draftProducts?.find((dp) => b.productName === dp.id)
      );

      if (orderedBuilding && Array.isArray(orderedBuilding.buildingTypes)) {
        buildingType = Math.max(
          ...orderedBuilding.buildingTypes
        ) as ITEPortal_Contracts_Enums_BuildingType;
      }

      const productsInfo = data?.filter((product) => {
        if (!Array.isArray(draftProducts)) {
          return [];
        }

        const orderProductIds = draftProducts.map(
          (draftProduct) => draftProduct.id
        );

        if (!product.productName) {
          return false;
        }

        return orderProductIds.includes(product.productName);
      });

      if (!draftProducts) {
        return;
      }

      const draftOrderedItems: Array<OrderItem> = [];

      draftProducts.forEach((responseProduct) => {
        const filteredProduct = productsInfo?.find(
          (productInfo) => productInfo?.productName === responseProduct?.id
        );

        if (!filteredProduct) {
          return;
        }

        const category =
          findCategoryForProduct(filteredProduct?.categoryId) ||
          new Category({
            id: filteredProduct?.categoryName || '',
            name: filteredProduct?.categoryName,
            displayName: filteredProduct?.categoryDisplayName,
          });

        const product = responseProduct.included
          ? Product.linkedFromJSON({ ...filteredProduct, category })
          : ProductUtils.createProductFromPriceListItem(filteredProduct, {
              category,
            });

        if (TemporaryStaffOrderItem.is(responseProduct)) {
          draftOrderedItems.push(
            TemporaryStaffOrderItem.fromJSON({
              ...responseProduct,
              product,
            })
          );
        } else {
          draftOrderedItems.push(
            OrderItem.fromJSON({
              ...responseProduct,
              product,
              included: !!responseProduct.included,
              id: product.getProductId(),
            })
          );
        }
      });

      console.log(draftOrderedItems);

      if (draftOrderedItems.length > 0) {
        orderApi.setState(draftOrderedItems);
      }

      orderApi.setOrderDto(orderDto);

      standApi.setStandForOrder({ ...stand, buildingType });

      navigate({
        to: `/exhibitions/$exhibitionId/$stand/create`,
        params: {
          exhibitionId: exhibitionId,
          stand: standId,
        },
      });
    } finally {
      loadingApi.off();
    }
  };

  return {
    editOrder,
  };
};
