import { ITechnicalService } from '@/model/ITechnicalService';
import DevelopmentIcon from '@/components/Icons/Development';
import { Category } from '@/model/ICategory';
import {
  CREATE_USER,
  EXHIBITIONS,
  MEMBER_DIRECTORY,
  SERVICE_DICTIONARY,
  SETTINGS,
} from '@/constants/technical-services';
import i18n from '@/i18n';

const superAdminMenu = () => {
  const exhibitions = new Category({
    id: EXHIBITIONS,
    name: EXHIBITIONS,
    displayName: i18n.t(EXHIBITIONS),
  });
  const serviceDirectory = new Category({
    id: SERVICE_DICTIONARY,
    name: SERVICE_DICTIONARY,
    displayName: i18n.t(SERVICE_DICTIONARY),
  });
  const memberDirectory = new Category({
    id: MEMBER_DIRECTORY,
    name: MEMBER_DIRECTORY,
    displayName: i18n.t(MEMBER_DIRECTORY),
  });
  const settings = new Category({
    id: SETTINGS,
    name: SETTINGS,
    displayName: i18n.t(SETTINGS),
  });
  const createUser = new Category({
    id: CREATE_USER,
    name: CREATE_USER,
    displayName: i18n.t(CREATE_USER),
  });

  return [
    {
      category: exhibitions,
      image: <DevelopmentIcon />,
    },
    {
      category: serviceDirectory,
      image: <DevelopmentIcon />,
    },
    {
      category: memberDirectory,
      image: <DevelopmentIcon />,
    },
    {
      category: settings,
      image: <DevelopmentIcon />,
    },
    {
      category: createUser,
      image: <DevelopmentIcon />,
    },
  ] as ITechnicalService[];
};

export default superAdminMenu;
