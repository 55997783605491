import React, { useState } from 'react';
import { Table } from '@/components/Table/Table';
import { useTranslation } from 'react-i18next';
import { Card } from '@/components/Card/Card';
import { EditIcon } from '@chakra-ui/icons';
import { Flex } from '@chakra-ui/react';
import styled from 'styled-components';
import { Column, Row } from 'react-table';
import { MEMBERS } from '@/constants/endpoints';
import { LoaderWrapper } from '@/components/Loader/Loader';
import Pagination from '@/components/Table/Pagination';
import { EditMemberForm } from '@/pages/AdminPanel/MembersDirectory/EditMemberForm';
import { FieldValues } from 'react-hook-form';
import dayjs from 'dayjs';
import { useTableLoader } from '@/components/Table/useTableLoader';
import { UsersIcon } from '@/components/Icons/Users';
import { type ITEPortal_Domain_Dto_CatalogExhibitorSummary } from '@/api';
import { Link } from '@tanstack/react-router';

const OptionWrapper = styled(Flex)`
  flex-direction: row;
  align-items: center;
  gap: 20px;
`;

interface ExhibitionOptionsType {
  row: Row<ITEPortal_Domain_Dto_CatalogExhibitorSummary>;
}

const defaultValues = {
  id: null,
  name: null,
  email: null,
};

export const MembersDirectory = (): JSX.Element => {
  const { t } = useTranslation();
  const [openConfirmEditMember, setOpenConfirmEditMember] = useState(false);
  const [editData, setEditData] = useState<FieldValues>(defaultValues);

  const {
    data,
    isLoading,
    onPageSizeChange,
    onPageChange,
    paginationInfo,
    refresh,
  } = useTableLoader<ITEPortal_Domain_Dto_CatalogExhibitorSummary>({
    resource: MEMBERS,
  });

  const closeModalEditMember = () => setOpenConfirmEditMember(false);

  const ExhibitionOptions = ({ row }: ExhibitionOptionsType) => {
    return (
      <OptionWrapper>
        <Link to={`/admin/member-directory/${row.original.id}`}>
          <UsersIcon />
        </Link>

        <EditIcon onClick={() => editUser(row)} />
      </OptionWrapper>
    );
  };

  const editUser = (values: FieldValues) => {
    const editValues = values?.values;

    setEditData(editValues);
    setOpenConfirmEditMember(!openConfirmEditMember);
  };

  const exhibitionColumns = React.useMemo<
    Array<Column<ITEPortal_Domain_Dto_CatalogExhibitorSummary>>
  >(
    () => [
      {
        Header: `ID`,
        accessor: 'id',
        width: '10%',
      },
      {
        Header: `${t('participant-name')}`,
        accessor: 'name',
        width: '30%',
      },
      {
        Header: `${t('create-date')}`,
        accessor: 'createdDate',
        Cell: ({ row: { values } }) => (
          <>
            {values?.createdDate
              ? dayjs(values?.createdDate).format('DD.MM.YYYY')
              : ''}
          </>
        ),
      },
      {
        Header: `${t('last-modified-date')}`,
        accessor: 'lastModifiedDate',
        Cell: ({ row: { values } }) => (
          <>
            {values?.lastModifiedDate
              ? dayjs(values?.lastModifiedDate).format('DD.MM.YYYY')
              : ''}
          </>
        ),
      },
      {
        Header: `${t('phone-number')}`,
        accessor: 'phoneNumber',
      },
      {
        Header: '',
        id: 'options',
        width: '3%',
        Cell: ({ row }) => <ExhibitionOptions row={row} />,
      },
    ],
    []
  );

  return (
    <LoaderWrapper loading={isLoading}>
      <Card>
        <Table columns={exhibitionColumns} data={data} />
        <EditMemberForm
          openConfirm={openConfirmEditMember}
          closeModal={closeModalEditMember}
          refresh={refresh}
          editData={editData}
        />
        <Pagination
          setPageNumber={onPageChange}
          setPageSize={onPageSizeChange}
          paginationInfo={paginationInfo}
        />
      </Card>
    </LoaderWrapper>
  );
};
