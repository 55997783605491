import { useTranslation } from 'react-i18next';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import { ModalFactory } from '@/components/Modal/api';
import React, { useCallback } from 'react';
import { UseFormReturn } from 'react-hook-form';
import {
  EditManagerModal,
  ValidationSchemaType,
} from '@/pages/AdminPanel/PersonalAccounts/Modals/EditManagerModal';
import { ApiError, ITEPortal_Domain_Dto_AdminExhibitorSummary } from '@/api';
import { useParams } from '@tanstack/react-router';
import { typedAxios } from '@/api/typed-axios';
import { toast } from 'react-toastify';

export type SaveTechnicalManagers = ValidationSchemaType & {
  selectedRows: ITEPortal_Domain_Dto_AdminExhibitorSummary[];
};

export const useEditTechnicalManagers = () => {
  const { t } = useTranslation();
  const queryClient = new QueryClient();
  const { exhibitionId } = useParams({ strict: false });

  const saveTechnicalManagers = async (
    values: SaveTechnicalManagers,
    refresh: () => void,
    setShowBulkAction: (value: boolean) => void
  ) => {
    const rowIds = values?.selectedRows.map((row) => row?.id);

    try {
      await typedAxios.exhibitor.putExhibitorsExhibition({
        exhibitionId: +exhibitionId,
        requestBody: {
          technicalManagerEmail: values?.technicalManager?.label,
          exhibitorIds: rowIds,
        },
      });

      ModalFactory.close();
      refresh();
      setShowBulkAction(false);
      toast.success(t('exhibitorsUpdated'));
    } catch (e) {
      if (e instanceof ApiError) {
        const message = e.body;

        if (message && typeof message === 'string') {
          toast.error(message);
        } else {
          toast.error(t('error-occurred'));
        }
      }
    }
  };

  const openEditTechnicalManagersModal = useCallback(
    (
      selectedRows: ITEPortal_Domain_Dto_AdminExhibitorSummary[],
      refresh: () => void,
      setShowBulkAction: (value: boolean) => void
    ) => {
      ModalFactory.show({
        title: t('personal-accounts-edit'),
        Component: () => (
          <QueryClientProvider client={queryClient}>
            <EditManagerModal exhibitionId={exhibitionId} />
          </QueryClientProvider>
        ),
        okLabel: t('save'),
        onOk: async (ctx: UseFormReturn<ValidationSchemaType>) => {
          return ctx.handleSubmit(() =>
            saveTechnicalManagers(
              { ...ctx.getValues(), selectedRows },
              refresh,
              setShowBulkAction
            )
          )();
        },
      });
    },
    []
  );

  return {
    openEditTechnicalManagersModal,
  };
};
