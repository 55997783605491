import styled from 'styled-components';
import { Heading } from '@chakra-ui/react';

export const PageTitle = styled(Heading)`
  font-size: 1.8rem !important;
  margin-bottom: 24px;
  margin-top: 26px;
`;

export const FlexedPageTitle = styled(PageTitle)`
  display: flex;
  flex-direction: row;
`;

export const CenteredPageTitle = styled(PageTitle)`
  margin-top: 40px;
  margin-bottom: 32px;
  text-align: center;
`;
