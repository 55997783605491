import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
} from '@chakra-ui/react';
import {
  editProductApi,
  useProductForm,
} from '@/pages/AdminPanel/ServiceDirectory/EditProductFieldsets/useProductForm';
import { AbstractEditProductDrawerForm } from '@/pages/AdminPanel/ServiceDirectory/EditProductFieldsets/AbstractEditProductDrawerForm';
import { useTranslation } from 'react-i18next';
import { AsyncButton } from '@/components/AsyncButton';
import { useCommonProductSave } from '@/pages/AdminPanel/ServiceDirectory/EditProductFieldsets/useCommonProductSave';
import { CommonProductValidationSchemaType } from '@/pages/AdminPanel/ServiceDirectory/EditProductFieldsets/validation-schemas';
import { queryClient } from '@/features/query';
import { useEffect } from 'react';

export const EditProductDrawer = () => {
  const { categoryId, product, productType, isOpened, map, formInstance } =
    useProductForm();
  const { t } = useTranslation();

  const { mutateAsync, isSuccess } =
    useCommonProductSave<CommonProductValidationSchemaType>();

  const handleSubmit = async () => {
    if (!formInstance || typeof map !== 'function') {
      return;
    }

    const isValid = await formInstance.trigger();

    if (!isValid) {
      return;
    }

    const values = formInstance.getValues();

    return mutateAsync({
      isUpdate: Boolean(product?.id),
      values,
      map: map,
    });
  };

  useEffect(
    function refetchProductsData() {
      if (isSuccess) {
        queryClient
          .refetchQueries({
            queryKey: ['category-products', categoryId],
          })
          .then(() => {
            editProductApi.close();
          });
      }
    },
    [isSuccess]
  );

  return (
    <Drawer
      size="lg"
      isOpen={isOpened}
      placement="right"
      onClose={() => editProductApi.close()}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>
          {product?.id ? t('edit-product') : t('add-product')}
        </DrawerHeader>

        <DrawerBody>
          <AbstractEditProductDrawerForm
            product={product}
            productType={productType}
          />
        </DrawerBody>

        <DrawerFooter>
          <Button
            variant="outline"
            mr={3}
            onClick={() => editProductApi.close()}
          >
            {t('cancel')}
          </Button>
          <AsyncButton colorScheme="blue" onClick={handleSubmit}>
            {t('save')}
          </AsyncButton>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};
