import { UseFieldArrayRemove } from 'react-hook-form/dist/types/fieldArray';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  FormControl,
  Stack,
} from '@chakra-ui/react';
import Input from '@/components/Fields/Input';
import { SelectComponent } from '@/components/MultiSelect/MultiSelect';
import { AreaTypeConfig, StandConfiguration } from '@/utils/constants';

import styled from 'styled-components';
import { useStandSave } from './use-save-stand';
import { StandFormValues } from './types';
import InputNumber from '@/components/Fields/InputNumber';
import { standProjectApi } from '@/pages/AdminPanel/PersonalAccounts/StandsTab/StandProjectForm/api';
import { AsyncButton } from '@/components/AsyncButton';
import { PavilionOptions } from '@/pages/AdminPanel/PersonalAccounts/StandsTab/contsants';
import { filesStorageApi } from '@/pages/Exhibition/FilesStorage/filesStorageApi';

const FormControlWrapper = styled(FormControl)`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

export const StandFieldSet = (props: {
  index: number;
  isNew: boolean;
  standId: number | null;
  projectId?: number | null;
  onRemove: UseFieldArrayRemove;
}) => {
  const { t } = useTranslation();

  const { index, isNew, onRemove, standId, projectId } = props;
  const { isSaving, saveStand } = useStandSave();
  const { getValues } = useFormContext<StandFormValues>();
  const openEditStandProjectForm = () => {
    if (standId) {
      standProjectApi.setStandId({
        standId,
        projectId,
      });
    }
  };

  const openStandFileStorage = () => {
    if (standId) {
      filesStorageApi.setStand({
        standId: standId,
        standNumber: getValues(`stands.${index}.standNumber`),
      });
    }
  };

  return (
    <>
      <Card
        style={{ boxShadow: 'none' }}
        variant="outline"
        flexBasis={{
          base: '100%',
          lg: 'calc(50% - 20px)',
        }}
      >
        <CardHeader>
          {t('stand')} #{index + 1}
        </CardHeader>
        <CardBody>
          <Stack spacing="5">
            <FormControlWrapper>
              <InputNumber<StandFormValues>
                addon={t('sqm')}
                label={t('stand-footage')}
                maxWidth="100%"
                name={`stands.${index}.area`}
                step={0.25}
                precision={2}
              />
              <SelectComponent<StandFormValues>
                label={t('area-type')}
                options={AreaTypeConfig}
                name={`stands.${index}.buildingType`}
              />
              <SelectComponent<StandFormValues>
                label={t('stand-configuration')}
                options={StandConfiguration}
                name={`stands.${index}.standConfiguration`}
              />
              <InputNumber<StandFormValues>
                addon={t('sqm')}
                label={t('second-floor-area')}
                maxWidth="100%"
                name={`stands.${index}.secondFloorArea`}
                step={0.25}
                precision={2}
              />
              <Input<StandFormValues>
                label={t('stand-number')}
                maxWidth="100%"
                name={`stands.${index}.standNumber`}
              />
              <SelectComponent<StandFormValues>
                label={t('pavilion')}
                maxWidth="100%"
                options={PavilionOptions}
                name={`stands.${index}.pavilionHall`}
              />
            </FormControlWrapper>
          </Stack>

          <CardFooter justifyContent="flex-end" paddingX={0}>
            <ButtonGroup>
              {isNew && (
                <Button size="sm" onClick={() => onRemove(index)}>
                  {t('delete')}
                </Button>
              )}
              {!isNew && (
                <Button size="sm" onClick={() => openStandFileStorage()}>
                  {t('file-storage')}
                </Button>
              )}
              {!isNew && (
                <Button size="sm" onClick={() => openEditStandProjectForm()}>
                  {t('stand-project')}
                </Button>
              )}
              <AsyncButton
                colorScheme="red"
                type="button"
                size="sm"
                disabled={isSaving}
                isLoading={isSaving}
                onClick={async () => {
                  return saveStand(getValues('stands')[index], index);
                }}
              >
                {t('save')}
              </AsyncButton>
            </ButtonGroup>
          </CardFooter>
        </CardBody>
      </Card>
    </>
  );
};
