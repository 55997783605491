const arraysDiff = (arr1: string[], arr2: string[]): string[] => {
  const missingKeys = [];

  for (const element of arr1) {
    if (!arr2.includes(element)) {
      missingKeys.push(element);
    }
  }

  return missingKeys;
};

export const validateTranslationsMatch = (
  translations: Array<Record<string, string | Record<string, string>>>
) => {
  if (process.env.NODE_ENV !== 'development') {
    return;
  }

  if (translations.length === 0) {
    // If the array is empty, there is nothing to validate
    return true;
  }

  const referenceKeys = Object.keys(translations[0]);

  for (const translation of translations) {
    const currentKeys = Object.keys(translation);

    const diff = arraysDiff(referenceKeys, currentKeys);
    // Check if the current object has the same keys as the reference

    if (diff.length > 0) {
      throw new Error(`Missing translation key ${diff.join(',')}`);
    }
  }

  return true;
};
