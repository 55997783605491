import {
  SystemStyleObject,
  Tab,
  TabList,
  TabPanel,
  TabPanelProps,
  TabPanels,
  TabProps,
  Tabs,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import {
  ExhibitionAccountsTabs,
  viewExhibitionAccountsRoute,
} from '@/features/Routing/protected/admin-exhibitions';
import { useNavigate } from '@tanstack/react-router';
import { PersonalAccounts } from '@/pages/AdminPanel/PersonalAccounts/PersonalAccounts';

const selectedTabStyles: SystemStyleObject = {
  background: 'blackAlpha.300',
};

const tabStyles: TabProps = {
  background: 'blackAlpha.100',
  minW: '100px',
};

const tabPanelsStyle: TabPanelProps = {
  width: '100%',
};

export const PersonalAccountsTab = () => {
  const { t } = useTranslation();
  const { tab } = viewExhibitionAccountsRoute.useSearch();
  const indexFromUrl = ExhibitionAccountsTabs.indexOf(tab);
  const navigate = useNavigate();

  const handleTabChange = (index: number) => {
    navigate({
      search: { tab: ExhibitionAccountsTabs[index] || 'posted' },
    });
  };

  return (
    <Tabs
      variant="unstyled"
      align="center"
      w="100%"
      index={indexFromUrl}
      onChange={handleTabChange}
    >
      <TabList>
        <Tab
          _selected={selectedTabStyles}
          {...tabStyles}
          borderTopLeftRadius="md"
          borderBottomLeftRadius="md"
        >
          {t('distributed-accounts')}
        </Tab>
        <Tab
          _selected={selectedTabStyles}
          {...tabStyles}
          borderTopRightRadius="md"
          borderBottomRightRadius="md"
        >
          {t('unassigned-accounts')}
        </Tab>
      </TabList>
      <TabPanels>
        <TabPanel {...tabPanelsStyle}>
          {tab === 'posted' && <PersonalAccounts />}
        </TabPanel>
        <TabPanel {...tabPanelsStyle}>
          {tab === 'process' && <PersonalAccounts />}
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};
