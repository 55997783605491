import { Product } from '@/model/IProduct';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import styled from 'styled-components';
import { Button, Flex, Tooltip } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import Select from '@/components/Fields/Select';
import { WarningIcon } from '@chakra-ui/icons';
import {
  BadgeItem,
  RowDataStructure,
} from '@/components/OrderTableRow/OrderTableRow';
import InputNumber from '@/components/Fields/InputNumber';
import Input from '@/components/Fields/Input';

interface RowProps {
  label: string;
  submitHandler: (product: Product, values: FieldValues) => void;
  product: Product;
  params: RowDataStructure[];
}

const Form = styled.form`
  display: flex;
  flex-direction: row;
  align-items: center;

  padding: 20px 0;

  &:not(:last-child) {
    border-bottom: 1px solid #e2e8f0;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: auto;
  align-items: center;
`;

const Price = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-align: right;
  margin-right: 1rem;
`;

const Label = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 0.75rem;
`;

export const TooltipWrapper = styled.span`
  margin-left: 0.25rem;
  color: #cbd5e0;
  font-size: 16px;
`;

const rowDataComponentsConfig = {
  inputNumber: InputNumber,
  input: Input,
  badge: Select,
};

const Row: React.FC<RowProps> = ({ label, product, submitHandler, params }) => {
  const { t } = useTranslation();
  const instance = useForm();
  const {
    handleSubmit,
    formState: { isValid },
  } = instance;
  const description = product?.description;

  const onSubmit = handleSubmit((data) => {
    submitHandler(product, data);
  });

  return (
    <FormProvider {...instance}>
      <Form onSubmit={onSubmit}>
        <div>
          <Label>
            {label}
            {description && (
              <TooltipWrapper>
                <Tooltip hasArrow label={description}>
                  <WarningIcon color="var(--chakra-colors-brandColor)" />
                </Tooltip>
              </TooltipWrapper>
            )}
          </Label>

          <Flex gap="12px" alignItems="center">
            {params?.map((tableElement, key) => {
              const Component =
                rowDataComponentsConfig?.[
                  tableElement?.type as 'badge' | 'input' | 'inputNumber'
                ];

              return (
                <Component
                  key={key}
                  // @ts-expect-error as Component is generic
                  text={tableElement?.text as string}
                  options={tableElement?.options as BadgeItem[]}
                  name={tableElement.name as string}
                  placeholder={tableElement.placeholder}
                  settings={tableElement.settings}
                  min={0}
                />
              );
            })}
          </Flex>
        </div>
        <Wrapper>
          <Price>{product.formattedPrice}</Price>
          <Button type="submit" variant="brandSecondary" isDisabled={!isValid}>
            {t('choose')}
          </Button>
        </Wrapper>
      </Form>
    </FormProvider>
  );
};

export default Row;
