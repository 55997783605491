import { createApi, createStore } from 'effector';
import { produce } from 'immer';
import { useUnit } from 'effector-react';
import {
  ITEPortal_Contracts_Enums_BuildingType,
  ITEPortal_Domain_Dto_StandSummariesDto,
} from '@/api';
import { leaveExhibitionOrderEvent } from '@/store/effector/cleanup';

type StandType = ITEPortal_Domain_Dto_StandSummariesDto;

type StandInitialState = {
  standForOrder: StandType;
  originalBuildingType?: ITEPortal_Contracts_Enums_BuildingType;
};

const initialState: StandInitialState = {
  standForOrder: {
    buildingType: undefined,
  },
};

export const $stand = createStore<StandInitialState>(initialState).reset(
  leaveExhibitionOrderEvent
);

// $stand.watch(console.log);

export const standApi = createApi($stand, {
  setStandForOrder: (state, payload: StandType) => {
    return produce(state, (draft) => {
      draft.standForOrder = payload;
      draft.originalBuildingType = payload.buildingType;
    });
  },
  updateBuildingType: (
    state,
    payload: ITEPortal_Contracts_Enums_BuildingType
  ) => {
    return {
      ...state,
      standForOrder: {
        ...state.standForOrder,
        buildingType: payload,
      },
    };
  },
  resetOriginalBuildingType: (state) => {
    return produce(state, (draft) => {
      draft.standForOrder.buildingType = draft.originalBuildingType;
    });
  },
});

export const useStand = () => useUnit($stand);
