import { BuildingTypeInfo } from '@/model/common';
import { createApi, createEffect, createStore, sample } from 'effector';
import { produce } from 'immer';
import { persist } from 'effector-storage';
import { useUnit } from 'effector-react';
import {
  ITEPortal_Contracts_Enums_BuildingType,
  ITEPortal_Domain_Dto_OrderDto,
} from '@/api';
import {
  IOrderItem,
  OrderItem,
  TemporaryStaffOrderItem,
} from '@/model/IOrderItem';
import { Order } from '@/model/IOrder';
import { CATEGORY_TYPES } from '@/constants/categorie';
import { standApi } from '@/store/effector/stand';
import { leaveExhibitionOrderEvent } from '@/store/effector/cleanup';
import i18n from '@/i18n';

export type ChangeItemPayload = {
  id: IOrderItem['id'];
  amount: number;
  product: IOrderItem['product'];
};

export type InitialState = {
  items: Array<OrderItem | TemporaryStaffOrderItem>;
  buildings: BuildingTypeInfo[];
  orderDto?: ITEPortal_Domain_Dto_OrderDto | null;
};

const initialState: InitialState = {
  items: [],
  buildings: [],
  orderDto: null,
};

export const $order = createStore<InitialState>(initialState).reset(
  leaveExhibitionOrderEvent
);

const adapter = (
  // @ts-expect-error desc
  key
) => ({
  get: () => {
    const state = localStorage.getItem(key);

    if (!state) {
      return initialState;
    }

    try {
      const deserialized = JSON.parse(state);

      return Order.fromJSON(deserialized);
    } catch {
      return initialState;
    }
  },
  set: (value: InitialState) => {
    return localStorage.setItem(key, JSON.stringify(value));
  },
});

persist({
  store: $order,
  key: 'order',
  adapter,
});

export const orderApi = createApi($order, {
  addItem: (state, payload: OrderItem) => {
    return produce(state, (draft) => {
      draft.items.push(payload);
    });
  },
  /**
   * @description test covered by OE-3
   */
  addItemsBatch: (state, payload: Array<OrderItem>) => {
    return produce(state, (draft) => {
      draft.items = [...state.items, ...payload].reduce((acc, item) => {
        const existing = acc.find((oi) => oi.id === item.id);

        if (existing) {
          existing.setAmount(existing.getAmount() + item.getAmount());
          if (item.included) {
            existing.included = true;
          }
        } else {
          acc.push(item);
        }

        return acc;
      }, [] as Array<OrderItem>);
    });
  },
  /**
   * @description test covered by OE-4 OE-5
   */
  removeItem: (state, payload: OrderItem) => {
    return produce(state, (draft) => {
      const linkedProductIds =
        payload.product.includedPriceListItems?.map((lp) =>
          lp.getProductId()
        ) || [];

      draft.items = draft.items.filter((item) => {
        return item.id !== payload.id && !linkedProductIds.includes(item.id);
      });
    });
  },
  clearBuildingItems: () => {},
  /**
   * @description test covered by OE-6
   */
  changeItem: (state, payload: ChangeItemPayload) => {
    return produce(state, (draft) => {
      draft.items = draft.items.map((item) => {
        if (item.id === payload.id) {
          item.setAmount(payload.amount);
        }

        return item;
      });
    });
  },
  setState: (state, payload: OrderItem[]) => {
    return produce(state, (draft) => {
      draft.items = payload;
    });
  },
  clearProducts: (state) => {
    return produce(state, (draft) => {
      draft.items = initialState.items;
      // draft.total = initialState.total;
    });
  },
  setBuildings: () => {},
  setOrderDto: (state, payload: ITEPortal_Domain_Dto_OrderDto | null) => {
    return produce(state, (draft) => {
      draft.orderDto = payload;
    });
  },
  cleanUpOrderState: () => {
    return initialState;
  },
});

sample({
  clock: [orderApi.addItem],
  filter: (orderItem) => {
    return orderItem.product.category.name === CATEGORY_TYPES.SELECT_BUILDING;
  },
  target: createEffect({
    name: 'if-building-selected-update-building-type',
    handler: (orderItem: OrderItem) => {
      if (Array.isArray(orderItem.product.buildingTypes)) {
        standApi.updateBuildingType(
          Math.max(
            ...orderItem.product.buildingTypes
          ) as ITEPortal_Contracts_Enums_BuildingType
        );
      }
    },
  }),
});

sample({
  clock: orderApi.removeItem,
  filter: (orderItem) => {
    return orderItem.product.category.name === CATEGORY_TYPES.SELECT_BUILDING;
  },
  target: [
    createEffect({
      name: 'reset-original-building-type',
      handler: () => {
        standApi.resetOriginalBuildingType();
      },
    }),
    createEffect({
      name: 'remove-all-ordered-items-if-building-removed',
      handler: () => {
        orderApi.clearProducts();
      },
    }),
  ],
});

sample({
  clock: [orderApi.addItem],
  filter: (orderItem) => {
    return (
      Array.isArray(orderItem.product.includedPriceListItems) &&
      orderItem.product.includedPriceListItems.length > 0
    );
  },
  target: createEffect({
    name: 'if-product-has-linked-products-then-add-them-to-the-order-with-zero-price',
    handler: (orderItem: OrderItem) => {
      if (Array.isArray(orderItem.product.includedPriceListItems)) {
        orderApi.addItemsBatch(
          orderItem.product.includedPriceListItems?.map((product) => {
            return new OrderItem({
              id: product.getProductId(),
              product: product,
              amount: product.quantity,
              included: true,
              includedReason: orderItem.displayName
                ? (i18n.t(`included-into-price-of`, {
                    name: orderItem.displayName,
                  }) as string)
                : undefined,
            });
          })
        );
      }
    },
  }),
});

export const useOrder = () => {
  const state = useUnit($order);

  return {
    ...state,
    orderId: state.orderDto?.orderId,
  };
};

export const $orderTotal = $order.map((state) => {
  return state.items.reduce((accumulator, currentValue) => {
    return accumulator + currentValue.total;
  }, 0);
});

export const useOrderTotal = () => useUnit($orderTotal);
