import { createContext, useContext } from 'react';
import {
  ITEPortal_Domain_Dto_AdminPriceListItem,
  ITEPortal_Domain_Dto_OrderItemDto,
} from '../../../../api';

export type CreateOrderContextType = {
  addItem?: (item: ITEPortal_Domain_Dto_OrderItemDto) => void;
  removeItem?: (item: ITEPortal_Domain_Dto_OrderItemDto) => void;
  items: ITEPortal_Domain_Dto_AdminPriceListItem[];
  selectedItems: ITEPortal_Domain_Dto_OrderItemDto[];
  createOrder?: () => Promise<void>;
};

const CreateOrderContext = createContext<CreateOrderContextType>({
  items: [],
  selectedItems: [],
});

export const useCreateOrderContext = () => useContext(CreateOrderContext);

export const CreateOrderContextProvider = CreateOrderContext.Provider;
