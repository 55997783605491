import { useUnit } from 'effector-react';
import { createApi, createStore } from 'effector';
import { ITEPortal_Domain_Dto_CategoryDto } from '@/api';

const INITIAL_STATE = {
  isModalOpened: false,
  category: null,
  parentCategoryName: null,
};

const $editCategory = createStore<{
  isModalOpened: boolean;
  category: ITEPortal_Domain_Dto_CategoryDto | null;
  parentCategoryName: ITEPortal_Domain_Dto_CategoryDto['parentCategoryId'];
}>(INITIAL_STATE);

export const editCategoryApi = createApi($editCategory, {
  open: (
    state,
    payload: {
      category: ITEPortal_Domain_Dto_CategoryDto | null;
      parentCategoryName: ITEPortal_Domain_Dto_CategoryDto['name'];
    }
  ) => {
    return {
      ...state,
      category: payload.category,
      parentCategoryName: payload.parentCategoryName,
      isModalOpened: true,
    };
  },

  close: () => {
    return INITIAL_STATE;
  },
});

export const useEditCategoryApi = () => {
  return useUnit($editCategory);
};
