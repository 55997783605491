import { createOrderRoute } from '../../../../features/Routing/protected/admin-exhibitions';
import { FormProvider, useForm } from 'react-hook-form';
import { SelectComponent } from '../../../../components/MultiSelect/MultiSelect';
import { useTranslation } from 'react-i18next';
import { useEffect, useMemo, useReducer } from 'react';
import { typedAxios } from '../../../../api/typed-axios';
import {
  ITEPortal_Domain_Dto_AdminPriceListItem,
  type ITEPortal_Domain_Dto_Exhibitor_GetExhibitorEmployeeDto,
  ITEPortal_Domain_Dto_ExhibitorStandDto,
  ITEPortal_Domain_Dto_OrderItemDto,
} from '../../../../api';
import { AddedToOrderItemsTable } from './AddedToOrderItemsTable';
import {
  CreateOrderContextProvider,
  CreateOrderContextType,
} from './CreateOrderContext';
import { Box, Stack } from '@chakra-ui/react';
import { ORDER_STATUSES } from '../../../../constants/order';
import { useNavigate } from '@tanstack/react-router';
// import { useQueryClient } from '@tanstack/react-query';
// import { QueryOptions } from '@/features/query/query-options';
import { OptionType } from '@/model/common';
import {
  CreateOrderFormSchema,
  CreateOrderFormSchemaType,
} from '@/pages/AdminPanel/PlacedOrders/CreateOrder/types';
import { zodResolver } from '@hookform/resolvers/zod';
import { NoStandsLink } from '@/pages/AdminPanel/PlacedOrders/CreateOrder/NoStandsLink';
import { NoPriceListsLink } from '@/pages/AdminPanel/PlacedOrders/CreateOrder/NoPriceListsLink';
import { toast } from 'react-toastify';

type CreateOrderPageStateType = {
  itemsSource: ITEPortal_Domain_Dto_AdminPriceListItem[];
  availableItems: ITEPortal_Domain_Dto_AdminPriceListItem[];
  stands: ITEPortal_Domain_Dto_ExhibitorStandDto[];
  selectedItems: ITEPortal_Domain_Dto_OrderItemDto[];
  employees: Array<ITEPortal_Domain_Dto_Exhibitor_GetExhibitorEmployeeDto>;
  exhibitorId?: number;
  hasPriceList: boolean;
};

type ActionsType =
  | {
      type: 'ADD_ITEM';
      payload: ITEPortal_Domain_Dto_OrderItemDto;
    }
  | {
      type: 'REMOVE_ITEM';
      payload: ITEPortal_Domain_Dto_OrderItemDto;
    }
  | {
      type: 'PREPARE';
      payload: {
        stands: ITEPortal_Domain_Dto_ExhibitorStandDto[];
        sourceItems: ITEPortal_Domain_Dto_AdminPriceListItem[];
        employees: ITEPortal_Domain_Dto_Exhibitor_GetExhibitorEmployeeDto[];
        exhibitorId?: number;
        hasPriceList: boolean;
      };
    };

const reducer = (
  state: CreateOrderPageStateType,
  action: ActionsType
): CreateOrderPageStateType => {
  switch (action.type) {
    case 'PREPARE': {
      return {
        ...state,
        selectedItems: [],
        stands: action.payload.stands,
        itemsSource: action.payload.sourceItems,
        availableItems: action.payload.sourceItems,
        employees: action.payload.employees,
        exhibitorId: action.payload.exhibitorId,
        hasPriceList: action.payload.hasPriceList,
      };
    }

    case 'ADD_ITEM': {
      return {
        ...state,
        selectedItems: [...state.selectedItems, action.payload],
        availableItems: state.availableItems.filter(
          (i) => i.name !== action.payload.id
        ),
      };
    }

    case 'REMOVE_ITEM': {
      const selectedItems = state.selectedItems.filter(
        (s) => s.id !== action.payload.id
      );

      return {
        ...state,
        selectedItems,
        availableItems: state.itemsSource.filter(
          (s) => !selectedItems.find((selected) => selected.id !== s.name)
        ),
      };
    }

    default:
      action satisfies never;
  }

  return state;
};

export const CreateOrder = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  // const queryClient = useQueryClient();
  const { exhibitorId: exhibitorIdFromQuery } = createOrderRoute.useSearch();
  const [state, dispatch] = useReducer<typeof reducer>(reducer, {
    availableItems: [],
    itemsSource: [],
    stands: [],
    selectedItems: [],
    employees: [],
    hasPriceList: false,
  });

  const {
    exhibitorId,
    employees,
    availableItems,
    selectedItems,
    itemsSource,
    stands,
    hasPriceList,
  } = state;

  const hasStands = stands.length > 0;

  const couldAddItems = itemsSource.length > 0 && hasStands;

  const { exhibitors } = createOrderRoute.useLoaderData();
  const { exhibitionId } = createOrderRoute.useParams();

  const formInstance = useForm<CreateOrderFormSchemaType>({
    resolver: zodResolver(CreateOrderFormSchema),
  });

  useEffect(() => {
    if (exhibitorIdFromQuery) {
      const exhibitor = exhibitors.find(
        (ex) => ex.value === exhibitorIdFromQuery
      );

      if (exhibitor) {
        formInstance.setValue(
          'exhibitorId',
          // @ts-expect-error desc
          exhibitor,
          {
            shouldDirty: true,
            shouldTouch: true,
          }
        );
      }
    }
  }, [exhibitorIdFromQuery]);

  const ex = formInstance.watch('exhibitorId');

  const employeesOptions = useMemo<
    Array<OptionType<ITEPortal_Domain_Dto_Exhibitor_GetExhibitorEmployeeDto>>
  >(() => {
    return employees.map((e) => {
      return {
        value: e.userId ? `${e.userId}` : '',
        label: `${e.name} (${e.email})`,
        meta: e,
      };
    });
  }, [employees]);

  const standsOptions = useMemo<
    Array<OptionType<ITEPortal_Domain_Dto_ExhibitorStandDto>>
  >(() => {
    return stands.map((s) => {
      return {
        value: s.id,
        label: s.standNumber,
        meta: s,
      };
    });
  }, [stands]);

  useEffect(
    function updateDueToExhibitorChange() {
      const updateDueToExChange = async () => {
        if (!ex) {
          return;
        }

        const exhibitor = ex?.meta;

        if (!exhibitor?.id || !exhibitor?.priceListId) {
          dispatch({
            type: 'PREPARE',
            payload: {
              stands: [],
              sourceItems: [],
              employees: [],
              exhibitorId: exhibitor?.id,
              hasPriceList: false,
            },
          });

          return;
        }

        formInstance.resetField('employeeId');

        const exhibitorEmployees = Array.isArray(exhibitor.exhibitorEmployees)
          ? (exhibitor.exhibitorEmployees as Array<ITEPortal_Domain_Dto_Exhibitor_GetExhibitorEmployeeDto>)
          : [];

        console.log(
          exhibitorEmployees,
          !Array.isArray(employees),
          employees.length,
          employees.length === 0
        );

        // if (!Array.isArray(employees) || employees.length === 0) {
        //   const exhibitorModel = await queryClient.fetchQuery(
        //     QueryOptions.exhibitorQueryOptions({
        //       exhibitionId: +exhibitionId,
        //       exhibitorId: +exhibitor.id,
        //     })
        //   );
        //
        //   if (Array.isArray(exhibitorModel.exhibitorEmployees)) {
        //     exhibitorEmployees = exhibitorModel.exhibitorEmployees;
        //   }
        // }

        const loadedItems =
          await typedAxios.priceListItem.getExhibitionsPriceListsItems({
            exhibitionId: +exhibitionId,
            priceListId: exhibitor.priceListId,
          });

        let loadedStands: ITEPortal_Domain_Dto_ExhibitorStandDto[] = [];

        if (exhibitor.id) {
          const exhibitorStands =
            await typedAxios.stand.getExhibitionsExhibitorsStands({
              exhibitionId: +exhibitionId,
              exhibitorId: exhibitor.id,
            });

          loadedStands = exhibitorStands;
        }

        dispatch({
          type: 'PREPARE',
          payload: {
            stands: loadedStands,
            sourceItems: loadedItems,
            employees: exhibitorEmployees,
            exhibitorId: exhibitor.id,
            hasPriceList: true,
          },
        });
      };

      updateDueToExChange();
    },
    [ex]
  );

  const addItem: CreateOrderContextType['addItem'] = (item) => {
    dispatch({
      type: 'ADD_ITEM',
      payload: item,
    });
  };

  const removeItem: CreateOrderContextType['removeItem'] = (item) => {
    dispatch({
      type: 'REMOVE_ITEM',
      payload: item,
    });
  };

  const createOrder = async () => {
    const isValid = await formInstance.trigger();

    if (!isValid) {
      return;
    }

    try {
      const selectedExhibitorId = formInstance.getValues('exhibitorId.value');
      const employeeId = formInstance.getValues('employeeId.value');
      const standId = formInstance.getValues('standId.value');

      const createdOrderId = await typedAxios.order.postOrdersExhibitors({
        exhibitorId: +selectedExhibitorId,
        requestBody: {
          exhibitionId: +exhibitionId,
          orderStatus: ORDER_STATUSES.Submitted,
          standId: +standId,
          products: selectedItems,
          employeeUserId: +employeeId,
        },
      });

      navigate({
        to: `/admin/exhibitions/${exhibitionId}/view/placed-orders/${createdOrderId}/edit`,
      });
    } catch (e) {
      toast.error(t('order-save-error'));

      console.dir(e);
    }
  };

  return (
    <CreateOrderContextProvider
      value={{
        addItem,
        removeItem,
        items: availableItems,
        selectedItems,
        createOrder,
      }}
    >
      <FormProvider {...formInstance}>
        {/* this looks odd, but, ensures that dropdown of the select doesn't overlapped and visible */}
        <Box paddingBottom={270}>
          <Stack spacing={4}>
            <SelectComponent
              label={t('exhibitor')}
              name="exhibitorId"
              isRequired
              options={exhibitors}
            />
            {employeesOptions.length > 0 && (
              <SelectComponent
                label={t('employee')}
                name="employeeId"
                isRequired
                options={employeesOptions}
              />
            )}
            {hasStands && (
              <SelectComponent
                name="standId"
                isRequired
                label={t('stand')}
                options={standsOptions}
              />
            )}
          </Stack>
          {!hasPriceList && exhibitorId && (
            <NoPriceListsLink
              exhibitorId={+exhibitorId}
              exhibitionId={+exhibitionId}
            />
          )}
          {hasPriceList && !hasStands && exhibitorId && (
            <NoStandsLink
              exhibitionId={+exhibitionId}
              exhibitorId={exhibitorId}
            />
          )}

          {couldAddItems && <AddedToOrderItemsTable />}
        </Box>
      </FormProvider>
    </CreateOrderContextProvider>
  );
};
