import { defineStyle, defineStyleConfig, extendTheme } from '@chakra-ui/react';

const brandPrimary = defineStyle({
  padding: '6px 12px',
  background: 'var(--chakra-colors-brandColor) !important',
  color: '#fff',
  fontWeight: 600,
  fontSize: '14px',
  lineHeight: '20px',
  alignItems: 'center',
});

const brandSecondary = defineStyle({
  padding: '6px 12px',
  background: '#fff',
  color: 'var(--chakra-colors-brandColor)',
  border: '1px solid var(--chakra-colors-brandColor)',
  fontWeight: 600,
  fontSize: '14px',
  lineHeight: '20px',
  alignItems: 'center',
  height: 'auto',
});

export const buttonTheme = defineStyleConfig({
  variants: { brandPrimary, brandSecondary },
  defaultProps: {
    variant: 'brandPrimary',
  },
});

const theme = extendTheme({
  styles: {
    global: {
      '*': {
        boxSizing: 'border-box',
      },
      '#root': {
        backgroundColor: '#F7FAFC',
        color: '#2d3748',
      },
    },
  },
  sizes: {
    'nav-bar-height': '72px',
    'side-bar-width': '178px',
    'order-list-width': '350px',
    'header-brand-width': '40px',
  },
  colors: {
    brandColor: '#E8431A',
  },
  components: {
    Button: buttonTheme,
  },
});

export default theme;
